<template>
    <div>
        <b-card no-body class="drop-shadow">
            <b-form @submit.prevent="submitForm">
                <b-card-body>
                    <b-form-row>
                        <b-col cols="12" lg>
                            <p class="small text-muted mb-1">Posting As:</p>
                            <b-media class="align-items-center">
                                <template v-slot:aside>
                                    <b-avatar
                                        :src="postingUser.avatar"
                                        variant="grey"
                                        size="45"
                                    ></b-avatar>
                                </template>
                                <h5 class="mb-0">{{ postingUser.name }}</h5>
                            </b-media>
                        </b-col>
                        <b-col cols="12" lg="auto">
                            <b-form-group
                                label="Post Privacy"
                                label-for="privacy-select"
                                label-class="sr-only"
                            >
                                <p class="small text-muted mb-1">
                                    {{ privacyHelp }}
                                </p>
                                <b-select
                                    id="privacy-select"
                                    v-model="form.privacy"
                                    :options="privacyOptions"
                                ></b-select>
                            </b-form-group>
                        </b-col>
                    </b-form-row>
                </b-card-body>
                <FancyEditor
                    usageType="post"
                    ref="fancyEditor"
                    :initialContent="form.post"
                    :leagueId="leagueId"
                    @input-changed="handleFancyEditorChange"
                />
                <b-card-body>
                    <!-- show media files on edit -->
                    <template
                        v-if="
                            post &&
                            post.files &&
                            this.uploadCount == 1 &&
                            post.type != 5
                        "
                    >
                        <b-img
                            v-if="post.preview"
                            @click="joinLeague"
                            :src="post.files[0].file"
                            class="clickable"
                            fluid
                        >
                        </b-img>
                        <media-lightbox
                            v-else
                            :items="post.files"
                            :post-id="post.id"
                        ></media-lightbox>
                    </template>
                    <!-- Burst Preview on edit -->
                    <template v-if="isBurstPreview">
                        <b-img
                            :src="post.files[0].poster"
                            fluid
                            style="width:150px; background-color:black; padding:10px;"
                        ></b-img>
                    </template>
                    <template v-if="!(post && post.type == 5)">
                        <div v-if="form.links.length > 0">
                            <link-card
                                v-for="(link, index) in form.links"
                                :key="link.id"
                                :link="link"
                                :loading="link.loading"
                                deletable
                                class="mb-2"
                                @remove-link="removeLink(index)"
                            ></link-card>
                        </div>
                        <b-row no-gutters class="mb-2">
                            <b-col
                                cols="12"
                                md="4"
                                lg="3"
                                v-for="mediaItem in mediaItems"
                                :key="mediaItem.id"
                                class="mb-1"
                            >
                                <media-card
                                    :media="mediaItem"
                                    :loading="mediaItem.loading"
                                    deletable
                                    @delete-media="removeMedia"
                                    @edit-media="editMedia"
                                ></media-card>
                            </b-col>
                        </b-row>
                    </template>
                    <div
                        class="d-flex flex-column flex-md-row justify-content-between align-items-stretch align-items-md-center"
                    >
                        <input
                            type="file"
                            ref="fileInput"
                            :multiple="allowMultipleFiles"
                            :accept="acceptedFileTypes"
                            class="d-none"
                            @change="updateFiles"
                        />
                        <b-btn
                            v-show="!(post && post.type == 5)"
                            variant="link"
                            :disabled="fileQueueIsProcessing"
                            @click="addPhotoOrVideo"
                        >
                            <i class="fas fa-photo-video"></i>
                            Add Photo/Video
                        </b-btn>
                        <b-btn
                            v-show="!isEditPostMode"
                            variant="link"
                            @click="openCreateBurstModal"
                        >
                            <burst-icon/>
                            Burst!
                        </b-btn>
                        <div
                            class="d-flex flex-column flex-md-row align-item-center justify-content-end"
                        >
                            <div
                                class="d-flex align-item-center justify-content-center"
                            >
                                <b-btn
                                    v-if="league.price && league.price > 0"
                                    variant="ghost-purple"
                                    v-b-tooltip.hover
                                    title="Ask for Donations"
                                    @click="
                                        form.donation
                                            ? (form.donation = 0)
                                            : (form.donation = 1)
                                    "
                                    :class="form.donation ? 'active' : null"
                                >
                                    <i class="fas fa-donate"></i>
                                </b-btn>
                                <b-btn
                                    variant="ghost-purple"
                                    v-b-tooltip.hover
                                    title="Pin Post"
                                    @click="
                                        form.pinned
                                            ? (form.pinned = 0)
                                            : (form.pinned = 1)
                                    "
                                    :class="form.pinned ? 'active' : null"
                                >
                                    <i class="fas fa-thumbtack"></i>
                                </b-btn>
                            </div>
                            <b-btn
                                type="submit"
                                variant="purple"
                                pill
                                class="btn-padded"
                                :disabled="uploadingMedia || $v.form.$invalid"
                            >
                                <b-spinner v-if="loading" small></b-spinner>
                                <span v-else>Post</span>
                            </b-btn>
                            <b-btn
                                v-if="post"
                                type="cancel"
                                variant="ghost-purple"
                                pill
                                @click="$emit('cancel-edit')"
                            >
                                Cancel
                            </b-btn>
                        </div>
                    </div>
                </b-card-body>
            </b-form>
        </b-card>
        <create-burst-modal
            :modal-open.sync="createBurstModalOpen"
            :user-id="authUser.id"
            :league-id="league.id"
            @upload-burst-video="uploadBurstVideo"
        ></create-burst-modal>
        <edit-media-image
            :modal-open.sync="editMediaImageOpen"
        ></edit-media-image>
    </div>
</template>

<script>
import MediaModal from "../Modals/MediaModal";
import MediaCard from "../Cards/MediaCard";
import FancyEditor from "./FormComponents/FancyEditor";
import { requiredIf, required } from "vuelidate/lib/validators";
import LinkCard from "../Cards/LinkCard";
import CreateBurstModal from "../Modals/CreateBurstModal";
import EditMediaImage from "../Modals/EditMediaImage";
import Bowser from "bowser";
import BurstIcon from "../CustomIcons/BurstIcon";

export default {
    name: "PostForm",
    components: {
        LinkCard,
        MediaCard,
        MediaModal,
        FancyEditor,
        CreateBurstModal,
        EditMediaImage,
        BurstIcon
    },
    props: {
        post: Object,
        league: Object,
        authUser: Object,
        placeholderText: String,
        isEditPostMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            uploadCount: 1, // Number of times the upload function has run, prevent duplicate keys
            loading: false,
            mediaItems: [],
            allowMultipleFiles: false,
            form: {
                post: null,
                privacy: 1,
                pinned: 0,
                donation: 0,
                files: [],
                links: [],
                burst: false,
                parent_id: null,
            },
            submitted: false,
            privacyOptions: [{ value: 1, text: "Private Post" }],
            chunkSize: 10100462, // Filesize in bytes, 10mb
            currentFile: null,
            currentFileIndex: 0,
            currentFileChunks: [],
            currentFileToken: "",
            createBurstModalOpen: false,
            editMediaImageOpen: false,
            os: "",
            browser: "",
            acceptedFileTypes: "image/jpg, image/png, image/jpeg, image/gif, image/svg, image/webp, video/mp4,video/x-m4v,video/*,.mkv",
            fileQueue: [],
            fileQueueImage: [],
            fileQueueIsProcessing: false,
            formHasFiles: false,
            selected_image_media: '',
        };
    },
    validations() {
        if(!this.formHasFiles) {
            return {
                form: {
                    post: { required: requiredIf((form) => form.files === null) },
                    files: { required: requiredIf((form) => form.post === null) },
                }
            }
        } else {
            return {
                form: {
                    post: { },
                    files: { },
                }
            }
        }
    },
    beforeMount() {
        window.addEventListener("beforeunload", this.preventNav);

        if (this.post) {
            this.setForm();
        } else {
            if (this.league && this.league.permissions) {
                this.form.privacy =
                    this.league.permissions.privacy === "Private" ||
                    this.league.permissions.allowPublicPosts == "No"
                        ? 1
                        : 0;
            }
        }

        if (
            this.league &&
            this.league.permissions &&
            this.league.permissions.allowPublicPosts == "Yes"
        ) {
            this.privacyOptions.push({ value: 0, text: "Public Post" });
        }

        const agent = Bowser.getParser(window.navigator.userAgent);
        this.os = agent.getOS().name.toLowerCase();
        this.browser = agent.getOS().name.toLowerCase();
    },
    beforeDestroy() {
        window.removeEventListener("beforeunload", this.preventNav);
    },
    computed: {
        leagueId() {
            return this.post ? this.post.leagueId : this.league.id;
        },
        privacyHelp() {
            return this.form.privacy === 1
                ? "Only members can see this post"
                : "Everyone can see this post";
        },
        uploadingMedia() {
            return this.fileQueueIsProcessing;
        },
        postingUser() {
            if (this.post) {
                return {
                    name: this.post.posted_by.name,
                    avatar: this.post.posted_by.avatar,
                };
            } else {
                if (this.league.permissions.posts === "All members") {
                    return {
                        name: this.authUser.name,
                        avatar: this.authUser.avatar_path,
                    };
                } else {
                    return {
                        name: this.league.name,
                        avatar: this.league.photo,
                    };
                }
            }
        },
        isBurstPreview() {
            if(this.mediaItems?.length && this.mediaItems[0]?.type == 1){
                return this.post?.files[0]?.poster ? true : false
            }
        }
    },
    methods: {
        queueFile(file) {
            // this.fileQueue.push(file);
            // check if the file is video , will store it to fileQueue
            const otherVideoTypes =
                file.name.indexOf(".mkv") > -1 ||
                file.name.indexOf(".webm") > -1 ||
                file.name.indexOf(".wmv") > -1 ||
                file.name.indexOf(".mpg") > -1;
            if (
                file.type.substring(0, 5) === "video" ||
                otherVideoTypes
            ) {
                this.fileQueue.push(file);
            } else {
                this.fileQueueImage.push(file);
                // added formHasFiles to check that there is already pending photo upload
                this.formHasFiles = true;
            }
        },
        async processImageFileQueue() {
            //will set loading to true
            this.loading = true;
            // We want to prevent file uploads from failing, so once processing of a queue has begun,
            // we cannot allow the data to be changed or the process to be interrupted.
            // As a side-effect, this means that if someone tries to change the files being uploaded while they are
            // being uploaded, those files will not upload but the original ones will.
            // The interruption and smooth transition to a different set of files is currently considered out of scope due
            // to being an edge case. When we have more time, we may do this. But we also need to make sure to gracefully
            // stop any ongoing uploads and delete any partially upload files. It's complicated.
            if (this.fileQueueIsProcessing) {
                return false;
            }
            // In case something unexpected happens that changes the queue, we need to keep
            // up with our own internal queue.
            const files = this.mediaItems;
            const editedMedia = [];
            this.fileQueueIsProcessing = true;
            for (let i = 0; i < files.length; i++) {
                const tempId = `temp-${i}-${files.length}-${files[i].name}`;

                // added check for media if file was edited or a new file
                if ((files[i].new_file) || files[i].edited) {
                    // remove conversion of base64 as it is available already on medias
                    // let file = this.base64toImageFile(files[i].file, tempId)
                    const media = await this.uploadImage(files[i].file);
                    if (media && media.hasOwnProperty("id")) {
                        // Add the media id to the files array
                        this.form.files.push(media.id);
                        this.uploadCount += 1;
                    }
                }

                // if media was edited and already exist will upload new base64 image
                if (files[i].edited && !files[i].new_file) {
                    await this.detachMediaToPost(files[i])
                    editedMedia.push(files[i])
                }
            }

            // will remove the old edited media on file
            for (let i = 0; i < editedMedia.length; i++) {
                this.removeMedia(editedMedia[i])
            }

            // Clear the queue after all files have been handled
            this.fileQueueImage = [];
            this.fileQueueIsProcessing = false;
        },
        base64toImageFile(dataurl, filename) {
            let arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type:mime});
        },
        async processFileQueue() {
            // We want to prevent file uploads from failing, so once processing of a queue has begun,
            // we cannot allow the data to be changed or the process to be interrupted.
            // As a side-effect, this means that if someone tries to change the files being uploaded while they are
            // being uploaded, those files will not upload but the original ones will.
            // The interruption and smooth transition to a different set of files is currently considered out of scope due
            // to being an edge case. When we have more time, we may do this. But we also need to make sure to gracefully
            // stop any ongoing uploads and delete any partially upload files. It's complicated.
            if (this.fileQueueIsProcessing) {
                return false;
            }

            // In case something unexpected happens that changes the queue, we need to keep
            // up with our own internal queue.
            const files = this.fileQueue;
            this.fileQueueIsProcessing = true;
            for (let i = 0; i < files.length; i++) {
                const media = await this.uploadImage(files[i]);
                const tempId = `temp-${i}-${files.length}-${files[i].name}`;

                if (media && media.hasOwnProperty("id")) {
                    // Add the media id to the files array
                    this.form.files.push(media.id);

                    const mediaIndex = this.mediaItems.findIndex((item) => item.id === tempId);

                    this.mediaItems[mediaIndex].id = media.id;
                    this.mediaItems[mediaIndex].loading = false;
                    this.uploadCount += 1;
                } else {
                    // If no id was returned (due to server error or rejection, etc), remove the item
                    const mediaIndex = this.mediaItems.filter(function(item) {
                        return item.id != tempId });
                    this.mediaItems = mediaIndex;
                }
            }
            // Clear the queue after all files have been handled
            this.fileQueue = [];
            this.fileQueueIsProcessing = false;
        },
        shouldBeChunked(file) {
            // To be chunked, a file should be greater than our desired chunk size
            return file.size / this.chunkSize > 1;
        },
        createChunks(file) {
            this.currentFileChunks = [];
            let chunks = Math.ceil(file.size / this.chunkSize);

            for (let i = 0; i < chunks; i++) {
                this.currentFileChunks.push(
                    file.slice(
                        i * this.chunkSize,
                        Math.min(
                            i * this.chunkSize + this.chunkSize,
                            file.size,
                        ),
                        file.type,
                    ),
                );
            }
        },
        async createTicket(file) {
            // When uploads need to be chunked, we need to request permission to store the file first
            // We also send the first chunk with the request, as well as some file metadata
            // We will try this (and all requests) a total of 5 times, with each failure increasing the delay 1 second.

            const firstChunkData = new FormData();
            firstChunkData.set("data", this.currentFileChunks[0], "1");
            console.log("Appending leagueId" + this.league.id);
            firstChunkData.append("leagueId", this.league.id);

            let ticketResponse = null;
            let tries = 0;
            let requestSuccess = false;
            while (tries < 5 || !requestSuccess) {
                try {
                    ticketResponse = await axios({
                        url: "/media/getTicket",
                        method: "POST",
                        headers: {
                            "X-Content-Name": file.name,
                            "X-Content-Total-Length": file.size,
                            "Content-Type": "application/octet-stream",
                        },
                        data: firstChunkData,
                    });

                    if (ticketResponse.data.success) {
                        requestSuccess = true;
                        this.currentFileToken = ticketResponse.data.token;
                        return true;
                    } else {
                        // We treat this as a success so we can break the loop and display an error.
                        // The loop itself is for network/server errors.
                        requestSuccess = true;
                        await this.$swal(ticketResponse.data.message);
                        this.loading = false;
                        return false;
                    }
                } catch (error) {
                    tries++;
                    if (tries === 5) {
                        // At 5 tries, display the server error and quit trying to process.
                        await this.$swal(error.response.data.message);
                        this.loading = false;
                        return false;
                    }
                }
            }
        },
        async sendChunk(index) {
            const chunkData = new FormData();
            chunkData.set("data", this.currentFileChunks[index], index + 1);
            let tries = 0;
            let requestSuccess = false;
            while (tries < 5 && !requestSuccess) {
                try {
                    const uploadChunkResponse = await axios({
                        url: "/media/chunk",
                        method: "POST",
                        headers: {
                            "X-Media-Token": this.currentFileToken,
                            "X-Media-Chunk-Id": index + 1,
                            "X-Content-Name": this.currentFile.name,
                            "X-Content-Total-Length": this.currentFile.size,
                            "Content-Type": "application/octet-stream",
                        },
                        data: chunkData,
                    });

                    if (uploadChunkResponse.data.success) {
                        requestSuccess = true;
                        return true;
                    } else {
                        requestSuccess = true;
                        await this.$swal(uploadChunkResponse.data.message);
                        this.loading = false;
                        return false;
                    }
                } catch (error) {
                    tries++;
                    if (tries === 5) {
                        // At 5 tries, display the server error and quit trying to process.
                        await this.$swal(error.response.data.message);
                        this.loading = false;
                        return false;
                    }
                }
            }
            return false;
        },
        async closeTicket() {
            let tries = 0;
            let requestSuccess = false;
            while (tries < 5 && !requestSuccess) {
                try {
                    const closeTicketResponse = await axios({
                        url: "/media/closeTicket",
                        method: "POST",
                        headers: {
                            "X-Media-Token": this.currentFileToken,
                        },
                    });

                    if (closeTicketResponse.data.success) {
                        requestSuccess = true;
                        return closeTicketResponse.data.media;
                    } else {
                        requestSuccess = true;
                        await this.$swal(closeTicketResponse.data.message);
                        this.loading = false;
                        return false;
                    }
                } catch (error) {
                    await this.$swal(error.response.data.message);
                    this.loading = false;
                }
            }
        },
        async updateFiles(event) {
            const files = Array.from(event.target.files);

            files.map(async (file, index) => {
                const tempId = `temp-${index}-${files.length}-${files[index].name}`;

                // Display the file as loading
                const reader = new FileReader();
                reader.readAsDataURL(file);

                let _this = this;
                reader.onload = async function (e) {
                    const otherVideoTypes =
                        file.name.indexOf(".mkv") > -1 ||
                        file.name.indexOf(".webm") > -1 ||
                        file.name.indexOf(".wmv") > -1 ||
                        file.name.indexOf(".mpg") > -1;
                    if (
                        file.type.substring(0, 5) === "video" ||
                        otherVideoTypes
                    ) {
                        // We can't open a .mov to get a thumbnail so use a default image.
                        // iOS safari does not allow auto playing with video.play() which we
                        // need to get a thumbnail from other types of videos so use the default image.
                        if (
                            file.type == "video/quicktime" ||
                            _this.os == "ios" ||
                            _this.browser == "safari" ||
                            otherVideoTypes
                        ) {
                            getDefaultThumbnail(e, tempId, file);
                        } else {
                            getThumbnail(e, tempId, file);
                        }
                    } else {
                        // will set thumbnail to null to create preview without sending to backend
                        // tempId repeats when we click add photo/video and another file, results to have return console error
                        // added file.name instead
                        _this.mediaItems.push({
                            id: tempId+'-'+file.name,
                            name: file.name,
                            new_file: true,
                            file: e.target.result,
                            thumbnail: null,
                            type: file.type.substring(0, 5) === "video",
                            loading: file.type.substring(0, 5) === "video" ? true : false,
                        });
                    }
                };

                function getDefaultThumbnail(event, id, file) {
                    const defaultThumbnail = new Image();

                    // some purple bg
                    defaultThumbnail.src = "/images/VideoThumbnail.jpg";
                    _this.mediaItems.push({
                        id: id,
                        file: event.target.result,
                        type: file.type.substring(0, 5) === "video",
                        thumbnail: defaultThumbnail,
                        loading: true,
                    });
                }

                // Creates a thumbnail for videos to use in the preview (MediaCard)
                async function getThumbnail(event, id, file) {
                    // Keep track of frame drawn so we only draw the first frame. This is an issue because we may not be
                    // able to stop playback during the first frame.
                    let hasDrawn = false;
                    let thumbnail;
                    const url = URL.createObjectURL(file);
                    let thumbnailCanvas = document.createElement("canvas");
                    let video = document.createElement("video");
                    video.autoplay = true;
                    video.muted = true;
                    video.src = url;
                    video.className += "video-js";
                    video.preload = "auto";
                    let ctx = thumbnailCanvas.getContext("2d");

                    async function drawFrame(e) {
                        video.pause();
                        if (!hasDrawn) {
                            ctx.drawImage(video, 0, 0);
                            thumbnailCanvas.toBlob(setThumb, "image/jpeg");

                            hasDrawn = true;
                        }
                    }

                    async function revokeURL(e) {
                        URL.revokeObjectURL(video.src);
                    }

                    async function setThumb(blob) {
                        thumbnail = new Image();
                        thumbnail.onload = revokeURL;
                        thumbnail.src = URL.createObjectURL(blob);
                        _this.mediaItems.push({
                            id: id,
                            file: event.target.result,
                            type: file.type.substring(0, 5) === "video",
                            thumbnail: thumbnail,
                            loading: true,
                        });
                    }

                    video.addEventListener("loadedmetadata", function () {
                        thumbnailCanvas.width = video.videoWidth;
                        thumbnailCanvas.height = video.videoHeight;

                        video.play();
                    });

                    video.addEventListener("timeupdate", drawFrame, false);
                }

                // add file to queue to be processed once all files are loaded
                this.queueFile(file);
            });
            this.processFileQueue();
        },
        async uploadImage(file) {
            this.currentFile = file;
            if (this.shouldBeChunked(file)) {
                this.createChunks(file);

                if (await this.createTicket(file)) {
                    let allChunksUploaded = true;
                    for (let i = 1; i < this.currentFileChunks.length; i++) {
                        const chunkResult = await this.sendChunk(i);
                        if (chunkResult === false) {
                            console.log("Chunk failed to upload");
                            allChunksUploaded = false;
                            break;
                        }
                    }

                    // Once we have uploaded all the chunks, close the ticket and convert to media
                    if (allChunksUploaded) {
                        let closeTicketResponse = await this.closeTicket();
                        if (closeTicketResponse) {
                            // Upon success, we need to return the media object
                            return closeTicketResponse;
                        }

                        // If there are errors, the closeTicket method itself SHOULD handle them properly.
                    }
                }
            } else {
                /** Use formData since we are sending file data **/
                const data = new FormData();
                data.append("file", file);
                if (this.leagueId) {
                    data.append("leagueId", this.leagueId);
                }

                try {
                    const response = await axios.post("/media/add", data);
                    return response.data.media;
                } catch (error) {
                    // If there is an error for some reason, show alert
                    await this.$swal(error.response.data.message);
                    this.loading = false;
                }
            }
        },
        async detachMediaToPost(media) {
            try {
                const response = await axios.post("/media/delete/post", {
                    mediaId: media.id,
                });
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        },
        removeMedia(media) {
            const fileIndex = this.form.files.findIndex(
                (item) => item === media.id,
            );
            console.log("file index: " + fileIndex);
            this.form.files.splice(fileIndex, 1);

            const mediaIndex = this.mediaItems.findIndex(
                (item) => item.id === media.id,
            );
            console.log("media list index: " + mediaIndex);
            this.mediaItems.splice(mediaIndex, 1);
            this.uploadCount = this.uploadCount > 1 ? this.uploadCount - 1 : 1;

            this.formHasFiles = this.mediaItems.length > 0 ? true : false
        },
        setForm() {
            this.form = {
                post: this.post.post,
                files: this.post.files
                    ? this.post.files.map((media) => media.id)
                    : null,
                privacy: this.post.privacy,
                pinned: this.post.pinned,
                donation: this.post.donation,
                links: this.post.links,
            };
            this.mediaItems = this.post.files
                ? this.post.files.map(function (mediaItem) {
                      return { ...mediaItem, loading: false };
                  })
                : null;
        },
        resetForm() {
            this.$refs.fancyEditor.setContent("");
            const pm = this.league.permissions;
            this.form = {
                post: null,
                privacy:
                    pm.privacy === "Private" || pm.allowPublicPosts == "No"
                        ? 1
                        : 0,
                pinned: 0,
                files: [],
                links: [],
            };
            this.mediaItems = [];
            this.loading = false;
            this.submitted = false;

            this.$v.form.$reset();
        },
        async submitForm() {
            this.loading = true;
            // will send image files first
            await this.processImageFileQueue()
            this.formHasFiles = false

            try {
                const requestData = {
                    ...this.form,
                    links: this.form.links.map((link) => link.id),
                    postId: this.post ? this.post.id : null,
                    leagueId: this.post ? this.post.league.id : this.leagueId,
                };

                if (this.post) {
                    // Attempt to edit post
                    const response = await axios.post(
                        "/leagues/posts/edit",
                        requestData,
                    );
                    if (response.data.post.active) {
                        this.$emit("post-edited", response.data.post);
                    } else {
                        this.$root.$emit('video-posted', true);
                        await this.$swal({
                            title: "Video Converting...",
                            text:
                                "Your video needs to be converted before it is posted, we will let you know when it is live.",
                        });
                    }
                    this.$refs.fileInput.value = null;
                    this.submitted = true;
                } else {
                    // Attempt to create post
                    const response = await axios.post(
                        "/leagues/posts/add",
                        requestData,
                    );

                    if (response.data.post.active) {
                        this.$emit("post-created", response.data.post);
                    } else {
                        this.$root.$emit('video-posted', true);
                        await this.$swal({
                            title: "Video Converting...",
                            text:
                                "Your video needs to be converted before it is posted, we will let you know when it is live.",
                        });
                    }
                    this.$refs.fileInput.value = null;
                    this.submitted = true;
                }
                this.resetForm();
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        },
        preventNav(event) {
            if (this.$v?.$anyDirty) {
                event.preventDefault();
                event.returnValue = "";
            }
        },
        handleFancyEditorChange(val) {
            this.$v.form.post.$touch();

            // Check if there is a link
            const href = $("<div>").append(val).find("a:last").attr("href");
            if (href) {
                this.addLink(href);
            }

            // added check if contains only <p></p> , will set it to null for validation
            if (val === '<p></p>') {
                val = null
            }
            // Update our form data
            this.form.post = val;
        },
        async addLink(link) {
            const findIndex = this.form.links.findIndex(
                (item) => item.url === link,
            );

            if (findIndex === -1) {
                const index = this.form.links.length;

                try {
                    this.form.links.push({
                        loading: true,
                        url: link,
                        id: null,
                    });
                    const { data } = await axios.post("/links/getTags", {
                        url: link,
                    });

                    this.form.links[index].id = data.link.id;
                    this.form.links[index].title = data.link.title;
                    this.form.links[index].description = data.link.description;
                    this.form.links[index].image = data.link.image;
                    this.form.links[index].loading = false;
                } catch (error) {
                    // If there is an error for some reason, show alert
                    await this.$swal(error.response.data.message);
                    this.loading = false;
                }
            }
        },
        removeLink(index) {
            this.form.links.splice(index, 1);
        },
        addPhotoOrVideo(e) {
            this.allowMultipleFiles = true;
            setTimeout(() => {
                this.form.burst = false;
                this.form.parent_id = null;
                this.acceptedFileTypes =
                    "image/*, video/mp4,video/x-m4v,video/*,.mkv";
                this.$refs.fileInput.click();
            }, 200);
        },
        editMedia(media) {
            if (!media.name) {
                media.name = media.thumbnail
            }
            if (media.id > 0) {
                media.new_file = false
            }
            this.editMediaImageOpen = true;
            this.selected_image_media = media
        },
        openCreateBurstModal() {
            this.createBurstModalOpen = true;
        },
        uploadBurstVideo(e) {
            this.allowMultipleFiles = false;
            setTimeout(() => {
                this.form.burst = true;
                this.form.parent_id = null;
                this.acceptedFileTypes = "video/mp4,video/x-m4v,video/*,.mkv";
                this.$refs.fileInput.click();
                this.createBurstModalOpen = false;
            }, 200);
        },
    },
};
</script>

<style scoped>
textarea {
    border-radius: 0 !important;
}
</style>
