<template>
    <div>
        <h1 class="h4 font-weight-bold">My Media</h1>
        <hr />
        <media-list :user-id="user.id" :per-page="18"></media-list>
    </div>
</template>

<script>
import MediaList from "../../Lists/MediaList";
export default {
    name: "UserProfileMedia",
    components: { MediaList },
    props: {
        user: { type: Object, required: true },
    },
};
</script>

<style scoped>
.user-name {
    font-size: 48px;
    font-weight: bold;
}
</style>
