<template>
    <b-card :title="title" class="drop-shadow">
        <hr />
        <div v-if="leagues.length > 0">
            <league-card
                v-for="league in leaguesCopy"
                :key="league.id"
                :league="league"
                :is-owner="league.owner"
                :is-sub-league="league.isSubLeague"
                small
                class="mb-3"
                @click="routeToLeague"
            ></league-card>
        </div>
        <p v-else class="h6 text-center text-muted my-5">
            {{ noLeaguesText }}
        </p>

        <p v-if="leaguesErrorText" class="h6 text-center text-muted my-5">{{leaguesErrorText}}</p>
        <div class="text-center">
            <b-btn
                v-if="leagues.length > 0"
                variant="link"
                class="mb-n2"
                @click="showMore"
                :disabled="showMoreDisabled"
                >Show more <b-spinner v-show="loading" small class="ml-2"></b-spinner></b-btn
            >
        </div>

    </b-card>
</template>

<script>
import LeagueCard from "./LeagueCard";

export default {
    name: "LeaguesListCard",
    components: { LeagueCard },
    props: {
        leagues: {
            type: Array,
            required: true,
        },
        subscriptions: {
            type: Boolean,
            default: false,
        },
        userId: {
            type: Number,
            required: true,
        },
        parentLeagueId: {
            type: Number,
            required: false,
        }
    },
    data(){
      return {
          showMoreDisabled: false,
          leaguesErrorText: null,
          leaguesCopy: this.leagues,
          loading: false
      }
    },
    computed: {
        title() {
            return this.subscriptions ? "My Subscriptions" : this.isSubLeague ? "Sub Leagues" : "My Leagues";
        },
        noLeaguesText() {
            return this.subscriptions
                ? "You are not a part of any leagues."
                : "You do not have any leagues.";
        },
        isSubLeague() {
            return this.parentLeagueId ? true : false;
        }
    },
    methods: {
        async showMore() {
            if (this.isSubLeague) {
                this.showMoreSubLeagues();
            } else {
                this.showMoreLeagues();
            }
        },
        async showMoreLeagues(){
            try {
                this.loading = true;
                const response = await axios.post("/home/showMoreLeagues", {
                    isSubscription: this.subscriptions,
                    start: this.leaguesCopy.length,
                    amount: 12
                });
                if(response.data.success === 1){
                    this.leaguesCopy = this.leaguesCopy.concat(response.data.leagues)
                }else{
                    this.showMoreDisabled = true;
                    this.leaguesErrorText = response.data.message
                }
                this.loading = false;
            }catch(e){
                console.error(e);
                await this.$swal("Failed to retrieve leagues");
                this.loading = false;
            }
        },
        async showMoreSubLeagues(){
            try {
                this.loading = true;
                const response = await axios.post("/leagues/sub-leagues/showMore", {
                    parentLeagueId: this.parentLeagueId,
                    start: this.leaguesCopy.length,
                    amount: 12
                });
                if(response.data.success === 1){
                    this.leaguesCopy = this.leaguesCopy.concat(response.data.leagues)
                }else{
                    this.showMoreDisabled = true;
                    this.leaguesErrorText = response.data.message
                }
                this.loading = false;
            }catch(e){
                console.error(e);
                await this.$swal("Failed to retrieve sub leagues");
                this.loading = false;
            }
        },
        routeToLeague(league) {
            window.location.href = `/${league.slug}`;
        },
        routeToBrowse() {
            window.location.href = "/browse-leagues";
        },
    },
};
</script>

<style scoped></style>
