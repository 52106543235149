<template>
    <div>
        <b-modal
            id="create-product-modal"
            v-model="open"
            size="md"
            centered
            hide-header
            hide-footer
        >
            <div
                v-if="this.$mq === 'sm'"
                data-dismiss="modal"
                @click="closeModal"
                class="position-absolute float-right top-0 right-0 mt-3 mr-3 text-decoration-underline"
                style="z-index: 99"
            >
                Close
            </div>
            <b-form @submit.prevent="submitProductForm">
                <!-- Step 1 Product Information -->
                <b-tabs
                    id="create-product-modal-tabs"
                    content-class="mt-3"
                    align="center"
                    v-model="tabIndex"
                >
                    <b-tab title="First" :title-link-class="disabledTab" active>
                        <div class="my-4">
                            <header class="mb-4">
                                <div class="position-relative my-5">
                                    <div class="d-flex justify-content-center">
                                        <div
                                            class="bg-purple mr-3"
                                            style="
                                                width: 15px;
                                                height: 15px;
                                                border-radius: 100%;
                                            "
                                        ></div>
                                        <div
                                            class="bg-grey"
                                            style="
                                                width: 10px;
                                                height: 10px;
                                                border-radius: 100%;
                                                margin-top: 2px;
                                            "
                                        ></div>
                                    </div>
                                </div>
                                <h4
                                    class="my-4 mb-2 mt-md-n3 text-center font-weight-bolded"
                                >
                                    Details
                                </h4>
                            </header>

                            <b-form-group
                                label="Item Name *"
                                label-for="name-input"
                            >
                                <b-form-input
                                    id="name-input"
                                    type="text"
                                    v-model.trim="form.name"
                                    @change="$v.form.name.$touch()"
                                    class="form-control-white"
                                    :state="validateInput('name')"
                                    maxLength="60"
                                ></b-form-input>
                                <invalid-feedback
                                    :errors="nameErrors"
                                ></invalid-feedback>
                            </b-form-group>
                            <b-form-group
                                label="Description *"
                                label-for="description-input"
                            >
                                <b-form-textarea
                                    id="description-input"
                                    rows="4"
                                    v-model.trim="form.description"
                                    :state="validateInput('description')"
                                    @change="$v.form.description.$touch()"
                                    class="form-control-white"
                                    maxLength="800"
                                ></b-form-textarea>
                                <invalid-feedback
                                    :errors="descriptionErrors"
                                ></invalid-feedback>
                            </b-form-group>
                            <b-form-group
                                label="Final Price *"
                                label-for="price-input"
                            >
                                <b-input-group>
                                    <b-input-group-prepend>
                                        <b-input-group-text
                                            ><i
                                                class="fa fas fa-dollar-sign"
                                            ></i
                                        ></b-input-group-text>
                                    </b-input-group-prepend>
                                    <b-form-input
                                        id="price-input"
                                        type="number"
                                        class="form-control-white form-control"
                                        v-model="form.price"
                                        step="0.01"
                                        :state="validateInput('price')"
                                        @change="handlePriceChange($event)"
                                    />
                                </b-input-group>
                                <invalid-feedback
                                    :errors="priceErrors"
                                ></invalid-feedback>
                            </b-form-group>
                            <b-form-group
                                label="MSRP (optional)"
                                label-for="msrp-input"
                            >
                                <b-input-group>
                                    <b-input-group-prepend>
                                        <b-input-group-text
                                            ><i
                                                class="fa fas fa-dollar-sign"
                                            ></i
                                        ></b-input-group-text>
                                    </b-input-group-prepend>
                                    <input
                                        id="msrp-input"
                                        type="number"
                                        v-model.trim="form.msrp"
                                        step="0.01"
                                        :state="validateInput('msrp')"
                                        @change="handleMsrpChange($event)"
                                        class="form-control-white form-control"
                                    />
                                </b-input-group>
                                <invalid-feedback
                                    :errors="msrpErrors"
                                ></invalid-feedback>
                            </b-form-group>
                            <b-form-row>
                                <b-form-group
                                    label="How many times can a user download this item? *"
                                    label-for="download-limit"
                                    style="height: 120px"
                                >
                                    <div
                                        class="w-50 d-inline custom-control custom-checkbox"
                                    >
                                        <input
                                            id="download-limit-unlimited"
                                            type="checkbox"
                                            class="custom-control-input"
                                            value="1"
                                            unchecked-value="0"
                                            v-model="form.unlimitedDownloads"
                                            @change="
                                                handleUnlimitedCheckboxChange
                                            "
                                        /><label
                                            for="download-limit-unlimited"
                                            class="custom-control-label"
                                            >Unlimited</label
                                        >
                                    </div>
                                    <b-form-input
                                        id="download-limit"
                                        v-model.number="form.downloads"
                                        :disabled="downloadLimitDisabled"
                                        type="number"
                                        min="0"
                                        :state="validateInput('downloads')"
                                        @change="$v.form.downloads.$touch()"
                                        class="form-control-white w-50 d-inline form-control"
                                    />
                                    <invalid-feedback
                                        :errors="downloadErrors"
                                    ></invalid-feedback>
                                </b-form-group>
                            </b-form-row>
                            <b-form-row>
                                <b-form-group
                                    label="Promotional Label (optional)"
                                    label-for="promotional-label"
                                    class="w-100"
                                >
                                    <b-form-select
                                        v-model="form.label"
                                        :options="labelOptions"
                                        class="custom-select-white"
                                    ></b-form-select>
                                </b-form-group>
                            </b-form-row>
                            <b-col
                                align-h="center"
                                class="d-flex justify-content-center"
                            >
                                <submit-button
                                    text="Next"
                                    class="w-50"
                                    variant="red"
                                ></submit-button>
                            </b-col>
                        </div>
                    </b-tab>

                    <b-tab title="Second" :title-link-class="disabledTab">
                        <div class="my-4">
                            <!-- Step 2 Product Media (Images, Videos, Docs etc.. -->
                            <header class="mb-4">
                                <div class="position-relative my-5">
                                    <div class="d-flex justify-content-center">
                                        <div
                                            class="bg-purple mr-3"
                                            style="
                                                width: 15px;
                                                height: 15px;
                                                border-radius: 100%;
                                            "
                                        ></div>
                                        <div
                                            class="bg-purple"
                                            style="
                                                width: 15px;
                                                height: 15px;
                                                border-radius: 100%;
                                            "
                                        ></div>
                                    </div>
                                </div>
                                <h4
                                    class="my-5 mb-2 mt-md-n3 text-center font-weight-bolded"
                                >
                                    Upload Files
                                </h4>
                            </header>
                            <store-product-file-uploader
                                ref="storeProductFileUploader"
                                :product-id="productId"
                                :product="newProduct"
                                :leagueId="league.id"
                                class="mb-4"
                            ></store-product-file-uploader>

                            <!-- COMPLETE BUTTON -->
                            <b-col class="text-center">
                                <b-button
                                    pill
                                    variant="red"
                                    @click="completeUploadFiles"
                                    >Complete</b-button
                                >
                            </b-col>
                            <p
                                v-if="requiredMessage.toggle"
                                class="text-lg text-red font-weight-bold text-center mt-2"
                            >
                                {{ requiredMessage.title }} is required.
                            </p>
                        </div>
                    </b-tab>
                </b-tabs>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import InvalidFeedback from "../FormComponents/InvalidFeedback";
import StoreProductFileUploader from "../../Forms/LeagueForms/StoreProductFileUploader";
import SubmitButton from "../../Buttons/SubmitButton";
import StoreUploadForm from "../StoreUploadForm";

export default {
    name: "CreateProductModal",
    components: {
        SubmitButton,
        StoreProductFileUploader,
        InvalidFeedback,
        StoreUploadForm,
    },
    props: {
        league: {
            type: Object,
            required: true,
        },
        isModalOpen: {
            type: Boolean,
            default: false,
        },
        newProduct: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            tabIndex: 0,
            productId: null,
            disabledTab: "d-none",
            labelOptions: [],
            form: {
                id: null,
                name: "",
                description: "",
                price: "",
                msrp: "",
                downloads: 0,
                unlimitedDownloads: 0,
                label: null,
            },
            nextStep: false,
            requiredMessage: {
                toggle: false,
                title: null,
            },
            downloadLimitDisabled: false,
        };
    },
    validations: {
        form: {
            name: { required },
            description: { required },
            price: { required },
            msrp: {
                msrpValidation() {
                    return this.msrpErrors.length > 0 ? false : true;
                },
            },
            downloads: {
                required,
                downloadsValidation() {
                    return (
                        this.form.unlimitedDownloads ||
                        (this.form.downloads &&
                            parseInt(this.form.downloads) > 0)
                    );
                },
            },
        },
    },
    mounted() {
        this.getLabels();
    },
    watch: {
    },
    computed: {
        open: {
            get() {
                return this.isModalOpen;
            },
            set(value) {
                this.$emit("update:isModalOpen", value);
            },
        },
        nameErrors() {
            const errors = [];
            if (!this.$v.form.name.$dirty) return errors;
            !this.$v.form.name.required && errors.push("Name is required");
            return errors;
        },
        descriptionErrors() {
            const errors = [];
            if (!this.$v.form.description.$dirty) return errors;
            !this.$v.form.description.required &&
                errors.push("Description is required");
            return errors;
        },
        priceErrors() {
            const errors = [];
            if (!this.$v.form.price.$dirty) return errors;
            !this.$v.form.price.required && errors.push("Price is required");
            return errors;
        },
        downloadErrors() {
            const errors = [];
            if (!this.$v.form.price.$dirty) return errors;
            if (
                parseInt(this.form.downloads, 10) === 0 &&
                !this.downloadLimitDisabled
            ) {
                return ["Download quantity is required"];
            }

            return [];
        },
        msrpErrors() {
            // MSRP can't be lower than the final price.  It is also optional.
            if (parseFloat(this.form.price) > parseFloat(this.form.msrp)) {
                return ["MSRP can't be lower than the final price."];
            }
            return [];
        },
    },
    methods: {
        resetFields() {
            this.form.id = null
            this.form.name = ""
            this.form.description = ""
            this.form.price = ""
            this.form.msrp = ""
            this.form.downloads = 0
            this.form.unlimitedDownloads = 0
            this.form.label = null
        },
        handlePriceChange(event) {
            this.$v.form.price.$touch();
            this.form.price = parseFloat(event).toFixed(2);
        },
        handleMsrpChange(event) {
            this.$v.form.price.$touch();
            this.form.msrp = parseFloat(event.target.value).toFixed(2);
        },
        validateInput(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        async submitProductForm() {
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return false;
            }

            await this.addProduct();
        },
        async addProduct() {
            try {
                let downloadsLimit = null;

                const { status, data } = await axios.post("/store/addProduct", {
                    leagueId: this.league.id,
                    name: this.form.name,
                    description: this.form.description,
                    price: this.form.price,
                    msrp: this.form.msrp,
                    downloads: parseInt(this.form.downloads, 10),
                    unlimited_downloads: this.form.unlimitedDownloads ? 1 : 0,
                    labelId: this.form.label,
                });

                if (status == 200) {
                    this.resetFields()
                    this.productId = data.productId;
                }

                this.form.name = ""

                this.tabIndex++;
            } catch (error) {
                await this.$swal(error?.response?.data?.message);
            }
        },
        async getLabels() {
            try {
                const { status, data } = await axios.post("/store/getLabels");
                if (status == 200) {
                    this.labelOptions = [
                        { value: null, text: "Select--" },
                        ...data.labels.map((label) => ({
                            value: label.id,
                            text: label.title,
                        })),
                    ];
                }
            } catch (error) {
                await this.$swal(error.response.data.message);
            }
        },
        closeModal() {
            this.$emit("close-modal", false);
        },
        async completeUploadFiles() {
            if (!this.$refs.storeProductFileUploader.mainFileThumbnail) {
                this.requiredMessage.title = "Main product file ";
                return (this.requiredMessage.toggle = true);
            }

            if (!this.$refs.storeProductFileUploader.mediaFile1Thumbnail) {
                this.requiredMessage.title = "Cover Image ";
                return (this.requiredMessage.toggle = true);
            }

            try {
                let isEdit = this.newProduct ? true : false;
                const { data, status } = await axios.post(
                    "/store/completeProduct",
                    {
                        productId: this.productId,
                        isEdit,
                    },
                );
                if (status == 200) {
                    this.resetFields()
                    this.$emit("product-creation-complete", data.product);
                }
            } catch (error) {
                await this.$swal(
                    error.response
                        ? error.response.data.message
                        : "Oops! Something went wrong. Please try again.",
                );
            }
        },
        handleUnlimitedCheckboxChange() {
            this.downloadLimitDisabled = !this.downloadLimitDisabled;
        },
    },
};
</script>
