<template>
    <div>
        <!-- Show less text -->
        <div v-if="summary">
            {{ showLessCharacters(item)}}
            <!-- If description is more than 100 characters, so then add View More button -->
            <span v-if="item.length > 100">...
                <a href="#" @click="summary = !summary">
                    <span class="text-decoration-underline">
                        View more
                    </span>
                </a>
            </span>
        </div>
        <!-- Show more text -->
        <div v-else>
            {{ item }}
        </div>

        <!-- Response -->
        <div v-if="response.description" class="text-sm mb-3 mt-2 leading-6 bg-light-grey px-2 rounded">
            <span class="font-weigth-bold text-decoration-underline">Owner replied:</span>
            {{ response.description }} - 
            <span class="font-weigth-normal">{{ `${user.firstName} ${user.lastName}` }}</span>
        </div>

        <!-- REPLY Review -->
        <!-- Owners will be able to reply a review just ONE time. So I added !response.description -->
        <div class="pt-0 pr-5 " v-if="!replyReview.toggle && !response.description" style="margin-bottom:30px">
            <a href="#" v-if="isOwner" @click="replyReview.toggle = !replyReview.toggle" class="position-absolute bottom-0 right-0 mr-3 text-decoration-underline">
                Reply this review
            </a>
        </div>
        <b-form v-if="replyReview.toggle">
            <b-form-group
                label="Reply a review"
                label-for="response-input"
                class="position-relative font-weight-bold"
            >
                <b-form-textarea
                    id="response-input"
                    rows="2"
                    v-model.trim="form.response"
                    class="form-control-white"
                    maxLength="400"
                    required
                ></b-form-textarea>
                <div v-if="!replyReview.fieldRequired" class="position-absolute bottom-0 left-0 text-red text-sm mb-n4">
                    Description field can't be empty.
                </div>
            </b-form-group>
            <b-col class="float-right d-flex justify-content-end">
                <b-button pill variant="green" class="text-xs px-2 mr-2" @click="submitReply">Send</b-button>
                <b-button pill variant="light-grey" @click="replyReview.toggle = !replyReview.toggle" class="text-xs px-2 py-2" type="button">Cancel</b-button>
            </b-col>
        </b-form>
        
    </div>
</template>

<script>
export default {
    name: "ReviewToggleListItem",
    data(){
        return{
            summary: true,
            form:{
                productId: null,
                response: "",
                reviewId: null,
            },
            replyReview:{
                toggle: false,
                fieldRequired: true,
            }
        }
    },
    props: {
        item:{ //Review Description
            type: String,
            default: '',
        },
        showCharacterAmount:{
            type: Number,
            default: 0
        },
        productId:{
            type: Number,
            default: 0
        },
        isOwner:{
            type: Boolean,
            default: false,
        },
        reviewId:{
            type: Number,
            default: 0,
        },
        response:{
            type: Object,
            required: false,
        },
        user:{
            type: Object,
            required: false,
        }
    },
    mounted(){
        this.form.productId = this.productId;
        this.form.reviewId = this.reviewId;
    },
    methods:{
        showLessCharacters(description){
            var str = description;
            str = str.substring(0, this.showCharacterAmount); //First load show only 108 characters
            return str;
        },
        async submitReply() {
            try {
                if(this.form.response.length != ""){
                    const response = await axios.post("/store/reviews/respond", this.form);
                    // Hide reply form
                    this.replyReview.toggle = false;
                    // Reset form
                    this.form.response = '';
                    // Reload page
                    window.location.reload()
                }else{
                    this.showCantBeEmpty()
                }
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data);
                this.loading = false;
            }
        },
        showCantBeEmpty(){
            this.replyReview.fieldRequired = !this.replyReview.fieldRequired
        }
    }
}
</script>
