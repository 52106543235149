<template>
    <post-item
        v-if="!loading"
        :post="post"
        :auth-user="authUser"
        :reactions="reactions"
        show-league
        :list-type="''"
    >
    </post-item>
</template>

<script>
import PostItem from "../ListItems/PostItem";
export default {
    name: "LeaguePost",
    components: { PostItem },
    props: {
        post: {
            type: Object,
            required: true,
        },
        authUser: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            reactions: [],
        };
    },
    mounted() {
        this.getReactions();
    },
    methods: {
        async getReactions() {
            this.loading = true;
            try {
                const response = await axios.post("/leagues/getReactionList");
                this.reactions = response.data;
                this.loading = false;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped></style>
