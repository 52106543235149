<template>
    <b-form @submit.prevent="submitForm">
        <b-form-group aria-label="email" label-for="email-input">
            <b-form-input
                id="email-input"
                v-model.trim="form.email"
                :state="validateInput('email')"
                @input="checkUser"
                type="text"
                placeholder="Email"
                autocapitalize="off"
                :class="whiteTheme ? 'form-control-white' : 'form-control-black'"
            ></b-form-input>
            <invalid-feedback :errors="emailErrors"></invalid-feedback>
        </b-form-group>
        <b-form-group
            v-show="existingUser"
            aria-label="password"
            label-for="password-input"
            class="text-center"
        >
            <b-form-input
                id="password-input"
                type="password"
                v-model.trim="form.password"
                @change="$v.form.password.$touch()"
                placeholder="Password"
                :class="whiteTheme ? 'form-control-white' : 'form-control-black'"
            ></b-form-input>
            <invalid-feedback :errors="passwordErrors"></invalid-feedback>
            <a class="mt-1" :class="whiteTheme ? 'float-right mb-3' : null" href="/forgot">Forgotten password?</a>
        </b-form-group>
        <submit-button
            :loading="loading"
            :text="submitButtonText"
            :red-theme="whiteTheme"
        ></submit-button>
    </b-form>
</template>

<script>
import { required, requiredIf, email } from "vuelidate/lib/validators";
import InvalidFeedback from "./FormComponents/InvalidFeedback";
import SubmitButton from "../Buttons/SubmitButton";

export default {
    name: "LoginForm",
    components: { SubmitButton, InvalidFeedback },
    props: {
        emailAddress: String,
        whiteTheme: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            loading: false,
            checkingUser: false,
            existingUser: null,
            form: {
                email: "",
                password: "",
            },
        };
    },
  mounted() {
    this.form.email = this.emailAddress;
    if(this.emailAddress) {
      this.checkUser();
    }

  },
    validations: {
        form: {
            email: { required, email },
            password: {
                required: requiredIf(function () {
                    return this.existingUser;
                }),
            }, // password required if existing user
        },
    },
    computed: {
        submitButtonText() {
            if (this.existingUser !== null) {
                return this.existingUser ? "Sign In" : "Register";
            }
            return this.whiteTheme ? 'Login' : "Login / Register";
        },
        /* Validation Errors */
        emailErrors() {
            const errors = [];
            if (!this.$v.form.email.$dirty) return errors;
            !this.$v.form.email.required && errors.push("Email is required");
            !this.$v.form.email.email &&
                errors.push("Must be a valid Email Address");
            return errors;
        },
        passwordErrors() {
            const errors = [];
            if (!this.$v.form.password.$dirty) return errors;
            !this.$v.form.password.required &&
                errors.push("Password is required");
            return errors;
        },
    },
    methods: {
        validateInput(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        async checkUser() {
            this.$v.form.email.$touch();

            if (!this.$v.form.email.$anyError) {
                this.checkingUser = true;
                // Axios request to check if the email is in our system
                try {
                    const response = await axios.post(`/checkEmail`, {
                        email: this.form.email,
                    });
                    // If they exist update existingUser
                    this.existingUser = response.data.exists;
                } catch (error) {
                    // If there is an error for some reason, show SWAL
                    await this.$swal(
                        "There was an error looking up this email address"
                    );
                }
                this.checkingUser = false;
            }
        },
        async submitForm() {
            this.$v.form.$touch();

            if (!this.$v.form.$anyError && !this.checkingUser) {
                this.loading = true;

                if (!this.existingUser) {
                    // Redirect to the registration page
                    window.location.href = "/register?email=" + this.form.email;
                } else {
                    try {
                        // Attempt to login
                        const response = await axios.post("/login", {
                            email: this.form.email,
                            password: this.form.password,
                        });

                        const {reenabled} = response;
                        if(response.data.redirect) {
                          window.location.href = response.data.redirect;
                          return;
                        }

                        // Redirect once logged in
                        if(!this.whiteTheme){
                        window.location.href =
                           reenabled ? "/home" :
                           sessionStorage.getItem("redirectToLeague") || response.data.redirect || "/home";
                        sessionStorage.removeItem("redirectToLeague");
                        } else {
                            sessionStorage.setItem("redirectToLeague", window.location.href);
                            sessionStorage.setItem("joinLeague", true);
                            window.location.reload();
                        }
                    } catch (error) {
                        /// If there is an error for some reason, show alert
                        await this.$swal({title: 'Error', html: error.response.data.message, timer: 200000,});
                        this.loading = false;
                    }
                }
            }
        },
    },
};
</script>

<style scoped></style>
