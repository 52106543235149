<template>
    <div class="card chat-popup" :style="shouldShowWindow ? 'display: block;' : 'display: none;'">
        <div class="card-header bg-black">
            <strong class="header-font">Contact League Owner</strong>
            <div class="header-buttonend">
            <button class="btn-outline-purple rounded-pill change" @click="toggleForm"><i :class="this.showChat == true ? 'fas fa-window-minimize ' : 'fas fa-window-maximize' "></i></button>
            <button class="btn-outline-purple rounded-pill" @click="showWindow(false)"><i class="fas fa-window-close"></i></button>
            </div>
        </div>
        <div class="card-body" :style="showChat ? 'display: block;' : 'display: none;'">
            <contact-messages-list
                v-if="authUser"
                ref="messageList"
                :auth-user-id="typeof authUser.id !== 'undefined' ? authUser.id : 0"
                :league-id="league.id"
                @conversation-updated="updateConversation"
            >
            </contact-messages-list>
            <b-form
                @submit.prevent="submitForm"
            >
                <b-form-textarea
                    v-model="form.message"
                    placeholder="Send a message"
                    :rows="2"
                    :disabled="conversationId === null"
                ></b-form-textarea>
                <b-btn
                    variant="purple"
                    type="submit"
                    pill
                    class="btn-padded mt-1"
                    :disabled="conversationId === null"
                >
                    {{ submitText }}
                </b-btn>
            </b-form>
        </div>
    </div>
</template>

<script>

import ContactMessagesList from "../Lists/ContactMessagesList";

export default {
    name: "ContactCard",
    components: {ContactMessagesList},
    props: {
        league: {
            type: Object,
            required: true,
        },
        authUser: {
            type: Object,
        }
    },
    data() {
        return {
            loading: false,
            showChat: false,
            form: {
                message: "",
            },
            conversationId: null,
            // This is different from showChat. We want to not even show this window at
            // all until they click the contact link. Then once it's open, they can toggle
            // showChat at will.
            shouldShowWindow: false,
            initialized: false,
        };
    },
    methods: {
        showWindow(setting) {
            this.shouldShowWindow = setting;

            if (setting === true) {
                this.showForm();
            }
        },
        toggleForm() {
            this.showChat = !this.showChat;
        },
        showForm() {
            this.showChat = true;
            if (this.initialized === false) {
                this.$refs.messageList.init();
                this.initialized = true;
            }
        },
        async submitForm() {
            try {
                if (!this.conversationId) {
                    return false;
                }

                const response = await axios.post("/inbox/messages/add", {
                    chatId: this.conversationId,
                    ...this.form,
                });

                this.loading = false;
                this.form.message = "";
                this.$refs.messageList.addMessage(response.data.message);
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
        updateConversation(conversationId) {
            this.conversationId = conversationId;
        }
    },
    computed: {
        submitText() {
            return this.loading ? "Submitting..." : "Submit";
        },
    },
};
</script>

<style scoped>
.chat-popup {
    position: fixed;
    bottom: 0;
    left: 15px;
    max-width: 25vw;
    width: 25vw;
    border-style: solid;
    border-color: gray;
}
.header-font{
    color: white;
}
.header-buttonend{
    float:right;
}
.card{
    border: antiquewhit !important;
}
@media only screen and (max-width: 1200px) {
    .chat-popup {
        max-width: 40vw;
        width: 36vw;
    }
}
@media only screen and (max-width: 999px) {
    .chat-popup {
        max-width: 40vw;
        width: 36vw;
    }
}

@media only screen and (max-width: 969px) {
    .chat-popup {
        max-width: 40vw;
        width: 36vw;
    }
}
@media only screen and (max-width: 800px) {
    .chat-popup {
        max-width: 66vw;
        width: 66vw;
    }
}
@media only screen and (max-width: 500px) {
    .chat-popup {
        max-width: 89vw;
        width: 89vw;
    }
    
}

</style>