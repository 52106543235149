<template>
    <div>
        <b-btn
            v-if="!isOpen"
            variant="link"
            class="text-grey"
            @click="isOpen = true"
        >
            <i class="fas fa-share-square fa-fw"></i> Share
        </b-btn>
        <div v-else class="d-flex align-items-center justify-content-between">
            <div>
                <b-button
                    id="copyBtn"
                    variant="text"
                    class="text-grey"
                    v-clipboard="() => getLink()"
                >
                    <i class="fas fa-link"></i>
                    <span class="sr-only">Link</span>
                </b-button>
                <b-tooltip
                    target="copyBtn"
                    :show.sync="showCopied"
                    @shown="hideTooltipLater"
                    triggers
                    placement="bottom"
                    title="Link Copied!"
                >
                </b-tooltip>
            </div>
            <b-button
                variant="text"
                class="text-grey"
                v-b-tooltip.hover.bottom
                title="Email"
                @click="$emit('share-via-email')"
            >
                <i class="fas fa-at"></i>
                <span class="sr-only">Email</span>
            </b-button>
            <b-button
                variant="text"
                class="text-grey"
                v-b-tooltip.hover.bottom
                title="Message"
                @click="$emit('share-via-chat')"
            >
                <i class="fas fa-envelope"></i>
                <span class="sr-only">Message</span>
            </b-button>
        </div>
    </div>
</template>

<script>
export default {
    name: "ShareButton",
    props: {
        leagueId: Number,
        postId: Number,
    },
    data() {
        return {
            isOpen: false,
            showCopied: false,
        };
    },
    computed: {
        linkTooltip() {
            return this.copied ? "Copied!" : null;
        },
    },
    methods: {
        getLink() {
            this.showCopied = true;
            return `${window.location.hostname}/leagues/${this.leagueId}/post/${this.postId}`;
        },
        hideTooltipLater() {
            setTimeout(() => {
                this.showCopied = false;
            }, 2000);
        },
        triggerEmail() {},
    },
};
</script>

<style scoped>
.btn:hover {
    color: #5451de;
    text-decoration: none;
}
</style>
