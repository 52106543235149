<template>
    <b-form @submit.prevent="routeToSearch">
        <b-input-group :style="`margin-top:${this.$mq === 'sm' ? '0': '25'}px;`">
            <b-input-group-prepend class="clickable float-left" @click="routeToSearch">
                <span class="input-group-text">
                    <i class="fa fa-search text-grey text-xs"></i>
                </span>
            </b-input-group-prepend>
            <b-form-input 
                v-model="searchTerm" 
                class="text-black text-xs"  
                :placeholder="placeholder">
            </b-form-input>
        </b-input-group>
    </b-form>
</template>

<script>
export default {
    name: "StoreSearchForm",
    props: {
        dark: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: "",
        }
    },
    data() {
        return {
            searchTerm: "",
            loading: false,
        };
    },
    methods: {
        routeToSearch() {
            this.loading = true;
            if (this.searchTerm) {
                window.location.href = `/store?search=${this.searchTerm}`;
            } else {
                window.location.href = `/store`;
            }
        },
    },
};
</script>

<style scoped></style>
