<template>
    <b-card>
        <b-nav tabs justified class="mb-5">
            <b-nav-item
                :active="activeTab === 'info'"
                @click="updateActiveTab('info')"
            >
                Account
            </b-nav-item>
            <b-nav-item
                :active="activeTab === 'password'"
                @click="updateActiveTab('password')"
            >
                Password
            </b-nav-item>
            <b-nav-item
                :active="activeTab === 'cancel'"
                @click="updateActiveTab('cancel')"
            >
                Cancel Account
            </b-nav-item>
        </b-nav>
        <component
            :is="form"
            :user="user"
            :notification-settings="notificationSettings"
        ></component>
    </b-card>
</template>

<script>
import UserInfoForm from "../../Forms/UserForms/UserInfoForm";
import UserPasswordForm from "../../Forms/UserForms/UserPasswordForm";
import UserCancelAccountForm from "../../Forms/UserForms/UserCancelAccountForm";
export default {
    name: "UserSettingsGeneral",
    components: { UserPasswordForm, UserInfoForm, UserCancelAccountForm },
    props: {
        user: {
            type: Object,
            required: true,
        },
        notificationSettings: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            activeTab: "info",
        };
    },
    computed: {
        form() {
            switch (this.activeTab) {
                case "password":
                    return "user-password-form";
                case "cancel":
                    return "user-cancel-account-form";
                default:
                    return "user-info-form";
            }
        },
    },
    methods: {
        updateActiveTab(tab) {
            this.activeTab = tab;
        },
    },
};
</script>

<style scoped>
.nav-link {
    font-size: 20px;
    color: #0e0e0e;
}
.nav-link.active {
    color: #5451de !important;
    font-weight: bold;
}
</style>
