<template>
    <div>
    <b-card
        overlay
        text-variant="white"
        no-body
        class="drop-shadow"
        style="position: relative; z-index: 0;"
    >
        <div
                v-if="!isSearchLeague"
                style="z-index: 1000; width: 30px; height: 40px; text-align: center;"
                class="clickable menu-top-right"
                :id="`popover-target-${league.id}`"
        >
            <i style="color: #fff; margin-top: 10px;" class="fa fas fa-ellipsis-v"></i>
            <b-popover
                    custom-class="menu-popover"
                    :target="`popover-target-${league.id}`"
                    triggers="click"
                    placement="leftbottom"
            >
                <div class="menu-items">
                    <p v-if="isOwner" class="clickable" @click.stop="routeToNotification('/leagues/edit/'+league.id+'?section=member-requests')">({{ league.memberRequests.length }}) New Requests</p>
                    <p v-else >({{ league.memberRequests.length }}) New Requests</p>
                    <p
                            v-if="userId === authId && !league.vaulted"
                            @click.stop="vaultItem(league)"
                            class="clickable"
                    >
                        <i class="fa fas fa-lock"></i> Add to Vault
                    </p>
                    <p
                            v-if="userId === authId && league.vaulted"
                            @click.stop="unvaultItem(league)"
                            class="clickable"
                    >
                        <i class="fa fas fa-lock-open"></i> Remove from the Vault
                    </p>
                    <p v-if="!isSubLeague" class="clickable" @click.stop="openLeagueSubLeague(league)">({{ league.subLeagueCount }}) Sub-leagues</p>
                </div>
            </b-popover>
        </div>

        <div
            @click="checkEmit"
            style="z-index: 2 !important;"
            class="clickable embed-responsive embed-responsive-16by9 league-image"
        >
            <template v-if="league.bannerThumb">
                <b-img-lazy
                    v-if="mediaType === 'image'"
                    :src="league.bannerThumb"
                    class="embed-responsive-item"
                    style="object-fit: cover"
                ></b-img-lazy>
                <video
                    v-if="mediaType === 'video'"
                    autoplay
                    muted
                    loop
                    class="embed-responsive-item video-js"
                    style="object-fit: cover"
                >
                    <source
                        v-if="
                            league.banner
                                .split('.')
                                .slice(-1)[0]
                                .toLowerCase() === 'mp4'
                        "
                        :src="league.banner"
                        type="video/mp4"
                    />
                    <source
                        v-if="
                            league.banner
                                .split('.')
                                .slice(-1)[0]
                                .toLowerCase() === 'webm'
                        "
                        :src="league.banner"
                        type="video/webm"
                    />
                    <source
                        v-if="
                            league.banner
                                .split('.')
                                .slice(-1)[0]
                                .toLowerCase() === 'ogg'
                        "
                        :src="league.banner"
                        type="video/ogg"
                    />
                    <source
                        v-if="
                            league.banner
                                .split('.')
                                .slice(-1)[0]
                                .toLowerCase() === 'm3u8'
                        "
                        :src="league.banner"
                        type="application/x-mpegURL"
                    />
                </video>
            </template>
            <div
                v-else
                class="embed-responsive-item"
                :style="{
                    backgroundImage: background,
                }"
            ></div>
        </div>

        <b-card-body
            class="
                card-img-overlay
                d-flex
                flex-column
                justify-content-end
                bg-gradiant-overlay
            "
        >
            <b-container
                    @click="checkEmit"
                    class="clickable px-0" style="z-index: 3 !important;"
            >
                <div
                    v-if="
                        league.owner === true &&
                        league.enabled === 0 &&
                        league.stripeResponse === 3
                    "
                    style="
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        z-index: 2;
                    "
                >
                    <div class="alert alert-danger">
                        Your league is currently disabled due to personal
                        information not being verified. Click here to verify.
                    </div>
                </div>

                <div
                    v-if="
                        league.owner === true &&
                        league.enabled === 0 &&
                        league.stripeResponse === 1
                    "
                    style="
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        z-index: 2;
                    "
                >
                    <div class="alert alert-warning">
                        Your league is currently disabled while your personal
                        information is being verified.
                    </div>
                </div>

                <b-row dense class="align-items-center">
                    <b-col class="col-md-9">
                        <p class="h6 font-weight-bold mb-0">
                            {{ league.name }}
                            <b-button
                                    style="font-size: .8em;"
                                    pill
                                    variant="danger"
                                    size="sm"
                                    v-if="isOwner && league.memberRequests && league.memberRequests.length > 0"
                                    @click.stop="routeToNotification('/leagues/edit/'+league.id+'?section=member-requests')"
                            >
                                {{league.memberRequests.length }}
                            </b-button>
                        </p>
                    </b-col>
                    <!--<b-col :class="small ? 'col-md-1' : 'col-md-4'" v-if="!small" cols="auto">
                        <p class="mb-0">
                            {{ numberAbbrev(league.members_count) }} Members
                        </p>
                    </b-col>-->
                    <!--<b-col class="col-1 d-flex d-row-reverse justify-content-around">
                        <div
                                v-if="userId === authId && league.vaulted !== true"
                                @click.stop="vaultItem(league)"
                        ><i class="fa fas fa-lock-open"></i
                        ></div>
                        <div
                                v-if="userId === authId && league.vaulted === true"
                                @click.stop="unvaultItem(league)"
                        ><i class="fa fas fa-lock"></i
                        ></div>
                    </b-col>-->
                    <b-col class="col-2 d-flex d-row-reverse justify-content-around">
                        <div class="d-flex">
                            <div class="px-1 mt-1">
                                <i style="font-size: 1.2em;" class="fa fas fa-users"></i>
                            </div>
                            <div class="px-1">
                                <p class="h6 font-weight-bold mb-0 mt-1">
                                    {{ league.subscribers }}
                                </p>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </b-card-body>
    </b-card>
    </div>
</template>

<script>
import { numberAbbreviation } from '../Mixins/numberAbbreviation';

export default {
    name: 'LeagueCard',
    mixins: [numberAbbreviation],
    props: {
        league: {
            type: Object,
            required: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
        userId: {
            type: Number,
            required: false,
        },
        isVaulted: {
            type: Boolean,
            default: false,
            required: false,
        },
        authId: {
            type: Number,
            required: false,
        },
        isOwner: Boolean,
        isSubLeague: {
            type: Boolean,
            default: false,
        },
        isSearchLeague: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        cardHeight() {
            return this.small ? 200 : 275;
        },
        background() {
            return !this.league.banner
                ? 'linear-gradient(193deg, rgba(110,107,248,0.4) 0%, rgba(84,81,222,1) 100%)'
                : null;
        },
        mediaType() {
            const videoExtensions = ['mp4', 'qt', 'mov'];

            if (this.league.banner) {
                const extension = this.league.banner.split('.').pop();

                return videoExtensions.includes(extension) ? 'video' : 'image';
            }
            return null;
        },
        memberRequestsCount() {
            let count = 0;
            if (this.league) {
                const { memberRequests = [] } = this.league;
                count = memberRequests.length;
            }
            return count;
        },
    },
    methods: {
        routeToNotification(url) {
            window.location.href = url;
        },
        checkEmit() {
            if (
                this.league.owner === true &&
                this.league.enabled === 0 &&
                this.league.stripeResponse === 3
            ) {
                const theLocation =
                    '/leagues/edit/' +
                    this.league.id +
                    '?section=payment-account';
                window.location = theLocation;
            } else {
                this.$emit('click', this.league);
            }
        },
        async vaultItem(league) {
            const requestData = {
                league_id: league.id,
            };

            try {
                const response = await axios.post('/vault/store', requestData);

                if (!response.data.success) {
                    if (response.data.hasOwnProperty('action')) {
                        if (response.data.action === 'prompt_password') {
                            const { value: password } = await this.$swal({
                                title: response.data.message,
                                input: 'password',
                                showCancelButton: true,
                                showConfirmButton: true,
                                confirmButtonText: 'Unlock',
                                inputPlaceholder:
                                    'Enter password to unlock vault',
                                timer: 90000,
                            });

                            if (password) {
                                const unlockRequestData = {
                                    vault_password: password,
                                };
                                const unlockResponse = await axios.post(
                                    '/vault/unlock',
                                    unlockRequestData,
                                );

                                if (unlockResponse.data.success) {
                                    setTimeout(function () {
                                        location.reload();
                                    }, 1200);
                                    await this.$swal(
                                        'Success!',
                                        unlockResponse.data.message,
                                        'success',
                                    );
                                } else {
                                    await this.$swal(
                                        'Error!',
                                        unlockResponse.data.message,
                                        'error',
                                    );
                                }
                            }
                        }

                        if (response.data.action === 'set_password') {
                            const { value: password } = await this.$swal({
                                title: response.data.message,
                                input: 'password',
                                showCancelButton: true,
                                showConfirmButton: true,
                                confirmButtonText: 'Set Password',
                                inputPlaceholder: 'Type a new vault password',
                                timer: 90000,
                            });

                            if (password) {
                                const setRequestData = {
                                    vault_password: password,
                                };
                                const setPassResponse = await axios.post(
                                    '/vault/set_password',
                                    setRequestData,
                                );

                                if (setPassResponse.data.success) {
                                    setTimeout(function () {
                                        location.reload();
                                    }, 1200);
                                    await this.$swal(
                                        'Success!',
                                        setPassResponse.data.message,
                                        'success',
                                    );
                                } else {
                                    await this.$swal(
                                        'Error!',
                                        setPassResponse.data.message,
                                        'error',
                                    );
                                }
                            }
                        }
                    }
                } else {
                    league.vaulted = true;
                    await this.$swal(
                        'Success!',
                        response.data.message,
                        'success',
                    );
                }
            } catch (error) {
                await this.$swal(error.response.data.message);
            }
        },
        openLeagueSubLeague(league) {
            let leagueSlug = league.slug
            window.location = "/"+ leagueSlug +"?section=discussion";
        },
        async unvaultItem(league) {
            const requestData = {
                league_id: league.id,
            };

            try {
                const response = await axios.post('/vault/remove', requestData);

                if (!response.data.success) {
                    if (response.data.hasOwnProperty('action')) {
                        if (response.data.action === 'prompt_password') {
                            const { value: password } = await this.$swal({
                                title: response.data.message,
                                input: 'password',
                                showCancelButton: true,
                                showConfirmButton: true,
                                confirmButtonText: 'Unlock',
                                inputPlaceholder:
                                    'Enter password to unlock vault',
                                timer: 90000,
                            });

                            if (password) {
                                const unlockRequestData = {
                                    vault_password: password,
                                };
                                const unlockResponse = await axios.post(
                                    '/vault/unlock',
                                    unlockRequestData,
                                );

                                if (unlockResponse.data.success) {
                                    setTimeout(function () {
                                        location.reload();
                                    }, 1200);
                                    await this.$swal(
                                        'Success!',
                                        unlockResponse.data.message,
                                        'success',
                                    );
                                } else {
                                    await this.$swal(
                                        'Error!',
                                        unlockResponse.data.message,
                                        'error',
                                    );
                                }
                            }
                        } else if (response.data.action === 'set_password') {
                            const { value: password } = await this.$swal({
                                title: response.data.message,
                                input: 'password',
                                showCancelButton: true,
                                showConfirmButton: true,
                                confirmButtonText: 'Set Password',
                                inputPlaceholder: 'Type a new vault password',
                                timer: 90000,
                            });

                            if (password) {
                                const setRequestData = {
                                    vault_password: password,
                                };
                                const setPassResponse = await axios.post(
                                    '/vault/set_password',
                                    setRequestData,
                                );

                                if (setPassResponse.data.success) {
                                    setTimeout(function () {
                                        location.reload();
                                    }, 1200);
                                    await this.$swal(
                                        'Success!',
                                        setPassResponse.data.message,
                                        'success',
                                    );
                                } else {
                                    await this.$swal(
                                        'Error!',
                                        setPassResponse.data.message,
                                        'error',
                                    );
                                }
                            }
                        }
                    } else {
                        await this.$swal(
                            'Error!',
                            response.data.message,
                            'error',
                        );
                    }
                } else {
                    console.log('Response was fine.');
                    league.vaulted = null;
                    await this.$swal(
                        'Success!',
                        response.data.message,
                        'success',
                    );
                }
            } catch (error) {
                await this.$swal(error.response.data.message);
            }
        },
    },
};
</script>
<style>
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
        border-left-color: #353535 !important;
    }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
        right: 3px !important;
    }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
        right : 2px !important;
    }
</style>
<style scoped lang="scss">
    .card {
        overflow: hidden;
        &:hover,
        &:focus {
            opacity: 0.75;
            > .league-image {
                transform: scale(1.05);
            }
        }
        .league-image {
            transition: all 0.15s ease-in-out;
        }
        .fa-users {
            font-size: 1.5em;
        }
        .info-row {
            align-items: center;
            justify-content: space-between;
        }
    }
    .menu-top-right {
        position: absolute;
        right: 15px;
        top: 12px;
        z-index: 10;
    }
    .menu-button {
        color: white;
        font-weight: 700;
        font-size: 35px;
    }
    .menu-popover {
        background: #353535;
        left: 5px;
        border-radius: 15px;
        .arrow,
        .arrow::after {
            display: none;
        }
        :last-child {
            padding-bottom: 0px;
        }
        .menu-items {
            padding: 10px;
            p {
                color: #fff;
                font-weight: 600;
                font-size: 15px;
                i {
                    cursor: pointer;
                }
            }
        }
    }
    .edit-button{
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 3;
        color: #fff;
    }
    .btn-sm, .btn-group-sm > .btn {
        padding: 0.10rem 0.5rem;
    }
    .popover {
        z-index: 1 !important;
    }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
        border-left-color: red !important;
    }
</style>
