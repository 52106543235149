<template>
    <b-card :border-variant="active? 'dark' : null" class="h-100 shadow" @click="$emit('plan-selected', plan.id)">
      <div v-if="plan.trial" class="ribbon ribbon-top-left"><span>First Month Free</span></div>
        <div>
            <b-card-title class="plan-title">{{ plan.name }}</b-card-title>
          <b-card-title :class="priceClass" v-if="plan.trial"><span style="font-size:80%;">${{ plan.price }}/month </span></b-card-title>

          <b-card-title :class="priceClass" v-if="!plan.trial">${{ plan.price }}/month</b-card-title>
            <hr class="mt-0">
            <b-card-sub-title class="mb-3">{{ plan.note }}</b-card-sub-title>
        </div>
        <ul :class="listClass">
            <li v-for="item in plan.items" :key="item" class="my-3">{{ item }}</li>
        </ul>
    </b-card>
</template>

<script>
  export default {
    name: "PlanCard",
    props: {
      active: {
        type: Boolean,
        default: false
      },
      plan: {
        type: Object,
        required: true
      }
    },
    computed: {
      listClass() {
        return this.plan.id === 'standard'? 'standard-plan-items mt-4' : 'elite-plan-items mt-4'
      },
      priceClass() {
        return this.plan.id === 'standard'? 'plan-price text-purple' : 'plan-price text-gold';
      }
    }
  }
</script>

<style scoped>
.card-title {
    text-align: center;
    margin-bottom: .5rem;
}
.plan-title {
    font-weight: 300;
}
.plan-price {
    font-weight: 900;
    font-size: 20px;
}
.card-subtitle {
    text-align: center;
    font-size: 12px;
}
.standard-plan-items {
    margin-left: -1.25rem;
    font-size: 14px;
    list-style-image: url('/images/icons/purple-check.svg')
}
.elite-plan-items {
    margin-left: -1.25rem;
    font-size: 14px;
    list-style-image: url('/images/icons/gold-check.svg')
}



/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font-size:80%;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

/* bottom left*/
.ribbon-bottom-left {
  bottom: -10px;
  left: -10px;
}
.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.ribbon-bottom-left::before {
  bottom: 0;
  right: 0;
}
.ribbon-bottom-left::after {
  top: 0;
  left: 0;
}
.ribbon-bottom-left span {
  right: -25px;
  bottom: 30px;
  transform: rotate(225deg);
}

/* bottom right*/
.ribbon-bottom-right {
  bottom: -10px;
  right: -10px;
}
.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}
.ribbon-bottom-right::after {
  top: 0;
  right: 0;
}
.ribbon-bottom-right span {
  left: -25px;
  bottom: 30px;
  transform: rotate(-225deg);
}
</style>
