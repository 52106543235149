<template>
    <div>
        <!-- Single Product Item -->
        <div
            @click="
                storeProductDetailsModalIsOpen = !storeProductDetailsModalIsOpen
            "
            class="store-item-card"
        >
            <div class="store-item-header">
                <div v-if="isOwner" class="store-item-status">
                    <span class="published" v-if="product.status === 1"
                        >Published</span
                    >
                    <span class="unpublished" v-if="product.status === 0"
                        >Unpublished</span
                    >
                </div>
                <div class="store-item-title">
                    {{ product.name.substring(0, 48) }}
                </div>
            </div>
            <div class="store-item-image" :style="storeItemImageStyles">
                <div v-if="product.label" class="store-item-promo-label">
                    {{ product.label.title }}
                </div>
            </div>

            <div class="position-relative p-4">
                <!-- Stars -->
                <div v-if="showReviews" class="mb-3 text-xs">
                    <span class="font-weight-bold text-orange text-sm">{{
                        averageReview
                    }}</span>
                    <span class="pr-2">
                        <i
                            v-for="index in averageReview"
                            :key="index"
                            class="fas fa-star text-orange text-2xs"
                        ></i>
                    </span>
                    <span class="text-grey"
                        >( {{ product.reviews.length }} )</span
                    >
                </div>

                <div>
                    <!-- Sale Price -->
                    <span
                        class="text-capitalize text-base text-sm text-dark-red font-weight-normal mb-4 mr-2"
                    >
                        {{
                            parseFloat(product.price) === 0
                                ? "Free"
                                : "$" + product.price
                        }}
                    </span>
                    <!-- MSRP Price -->
                    <span
                        class="text-capitalize text-base text-sm text-grey font-weight-normal text-decoration-line-through mb-4"
                    >
                        {{ !product.msrp ? "" : "$" + product.msrp }}
                    </span>
                </div>

                <!-- Type icon -->
                <div
                    class="position-absolute bottom-0 right-0 px-3 py-1 mb-4 mr-4 text-capitalize text-sm bg-light-blue text-dark-blue text-center font-weight-bold"
                    style="z-index: 99; border-radius: 4px"
                >
                    <span v-if="product.type == 'Photo'"
                        ><i class="fas fa-camera fa-lg fa-fw"></i> Photo</span
                    >
                    <span v-else-if="product.type == 'Video'"
                        ><i class="fas fa-film fa-lg fa-fw"></i> Video</span
                    >
                    <span v-else
                        ><i class="fas fa-file fa-lg fa-fw"></i> Digital</span
                    >
                </div>
            </div>
        </div>

        <!-- Open Product Card bigger -->
        <store-product-details-modal
            :store-product-details-modal-is-open="
                storeProductDetailsModalIsOpen
            "
            :product="product"
            :passing-index="passingIndex"
            :is-owner="isOwner"
            @close-modal="handleCloseModal"
            @open-edit-product-modal="openEditProductModal"
            @product-purchased="productPurchased"
            @product-deleted="handleProductDeleted"
            @open-payment-modal="checkPaymentModal"
        >
        </store-product-details-modal>

        <check-payment-modal
            :modal-open.sync="checkPaymentModalOpen"
            @open-payment-modal="checkPaymentModal"
        >
        </check-payment-modal>

    </div>
</template>

<script>
import StoreProductDetailsModal from "../Modals/StoreProductDetailsModal";

export default {
    name: "StoreProductItem",
    components: { StoreProductDetailsModal },
    props: {
        product: {
            type: Object,
            default: null,
        },
        passingIndex: {
            type: Number,
            default: 0,
        },
        isOwner: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            storeProductDetailsModalIsOpen: false,
            checkPaymentModalOpen: false
        };
    },
    computed: {
        storeItemImageStyles() {
            let imageSrc = "";
            // Searching for the Cover image
            for (const key in this.product.media) {
                if (Object.hasOwnProperty.call(this.product.media, key)) {
                    const element = this.product.media[key];
                    if (element.cover == 1) {
                        // If cover is = 1 so then return the location
                        imageSrc = element.location;
                        break;
                    }
                }
            }

            return {
                backgroundImage: `url(${imageSrc})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
            };
        },
        itemsReviewsStars() {
            return this.product?.reviews?.stars;
        },
        averageReview() {
            let add = 0;
            let length = this.product.reviews.length;

            for (let index = 0; index < this.product.reviews.length; index++) {
                add += this.product.reviews[index].stars;
            }
            let total = add / length;
            return total.toFixed(1);
        },
        showReviews() {
            return this.product.reviews.length > 0;
        },
    },
    mounted() {
        // This is the infor for the Download button
        this.$emit("files-info", this.product.files);
        this.$emit("products-id", this.product.id);
    },
    methods: {
        checkPaymentModal(status){
            this.checkPaymentModalOpen = status
        },
        handleCloseModal(status) {
            this.storeProductDetailsModalIsOpen = status;
        },
        openEditProductModal(product) {
            this.$emit("open-edit-product-modal", product);
        },
        productPurchased(p) {
            this.storeProductDetailsModalIsOpen = false;
            this.$emit("product-purchased", p);
        },
        handleProductDeleted(p) {
            this.storeProductDetailsModalIsOpen = false;
            this.$emit("product-deleted", p);
        },
    },
};
</script>

<style scoped lang="scss">
.store-item-card {
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 450px;
    background-color: #f9f9f9;
    border-radius: 20px;
    margin-bottom: 24px;
    border-radius: 16px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    .store-item-header {
        max-height: 100px;
        padding-top: 16px;
        .store-item-status {
            padding-left: 16px;
            padding-right: 16px;
            padding-bottom: 4px;
            > .published {
                color: #aaa;
            }
            > .unpublished {
                color: #5451de;
            }
        }
        .store-item-title {
            font-weight: bold;
            padding-left: 16px;
            padding-right: 16px;
            padding-bottom: 8px;
        }
    }
    .store-item-image {
        position: relative;
        width: 100%;
        height: 280px;
        .store-item-promo-label {
            position: absolute;
            top: 0;
            left: 0;
            padding: 4px;
            background-color: #d28511;
            color: #fff;
        }
    }
}
</style>
