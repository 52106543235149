<template>
    <b-modal
        id="media-item-comments-modal modal-center"
        modal-class="media-item-comments-modal "
        v-model="open"
        size="xl-center"
        centered
        hide-footer
        hide-header
        body-class="m-0 bg-white"
    >
        <b-row class="bg-white">
            <!-- <template v-slot:modal-header="{ close }" > -->
            <!-- Emulate built in modal header close button action -->
            <!-- <b-button size="sm" variant="clear" @click="close()">
                    <i class="fas fa-arrow-left fa-lg"></i>
                </b-button> -->
            <!-- </template> -->
            <b-col
                sm="12"
                v-if="this.$mq === 'sm'"
                class="bg-black fixed-top p-2"
            >
                <b-button
                    size="sm"
                    variant="primary"
                    class="float-right"
                    @click="open = false"
                >
                    <i class="fas fa-times fa-lg"></i>
                </b-button>
            </b-col>
            <!-- Image -->
            <b-col
                sm="12"
                md="7"
                class="modal-body-top p-0 m-0"
                :class="this.$mq === 'sm' ? 'mt-4' : null"
            >
                <!-- Left arrow - Image - Right arrow -->
                <div
                    class="image-wrapper"
                    v-if="[0].indexOf(mediaItem.type) > -1"
                >
                    <img
                        class="img-fluid mx-auto d-block"
                        v-bind:src="`${mediaItem.photo}`"
                        @click="joinLeague"
                        :style="changeImgHeight"
                        @load="getHeight"
                    />
                    <div class="nav-prev">
                        <b-button
                            variant="clear"
                            @click="navigatePrev(mediaItem.id)"
                        >
                            <i class="fas fa-arrow-circle-left fa-lg"></i>
                        </b-button>
                    </div>
                    <div class="nav-next">
                        <b-button
                            variant="clear"
                            @click="navigateNext(mediaItem.id)"
                        >
                            <i class="fas fa-arrow-circle-right fa-lg"></i>
                        </b-button>
                    </div>
                    <div
                        class="d-flex justify-content-between py-2 text-grey bg-black"
                        :class="this.$mq === 'sm' ? 'px-4' : 'px-3'"
                    >
                        <reaction-counter
                            :reaction-types="reactions"
                            :counts="reactionCounts"
                        ></reaction-counter>
                        <span>{{ mediaItem.commentCount }} Comments</span>
                    </div>
                </div>
                <!-- Left arrow - Video - Right arrow -->
                <div
                    class="video-wrapper"
                    v-if="[1, 5].indexOf(mediaItem.type) > -1"
                >
                    <video
                        id="modal-video-player-section"
                        data-v-3dbf5e44=""
                        width="100%"
                        height="100%"
                        autoplay="autoplay"
                        playsinline=""
                        controls
                        muted="muted"
                        style="object-fit: cover"
                        @click="joinLeague"
                        class="video-js vjs-fluid"
                    >
                        <source :src="mediaItem.video" />
                    </video>
                    <div class="nav-prev">
                        <b-button
                            variant="clear"
                            @click="navigatePrev(mediaItem.id)"
                        >
                            <i class="fas fa-arrow-circle-left fa-lg"></i>
                        </b-button>
                    </div>
                    <div class="nav-next">
                        <b-button
                            variant="clear"
                            @click="navigateNext(mediaItem.id)"
                        >
                            <i class="fas fa-arrow-circle-right fa-lg"></i>
                        </b-button>
                    </div>
                </div>
            </b-col>
            <!-- Post Description -->
            <b-col sm="12" md="5" class="mt-3" :style="changePostHeight">
                <!-- Avatar rounded  -->
                <b-row>
                    <b-col
                        cols="12"
                        class="d-flex justify-content-between align-items-start"
                    >
                        <b-media no-body class="d-flex align-items-center">
                            <b-media-aside vertical-align="center">
                                <b-avatar
                                    :src="mediaItem.posted_by.avatar"
                                    variant="grey"
                                    size="45"
                                ></b-avatar>
                            </b-media-aside>
                            <b-media-body class="d-flex flex-column ml-3">
                                <a
                                    v-if="
                                        showLeague &&
                                        mediaItem.posted_by.type !== 'league'
                                    "
                                    class="small text-grey d-lg-none"
                                    :href="`/${mediaItem.leagueSlug}`"
                                    >{{ mediaItem.leagueName }}</a
                                >
                                <a
                                    class="h6 font-weight-bold text-black mb-0"
                                    :href="
                                        mediaItem.posted_by.type === 'league'
                                            ? `/${mediaItem.posted_by.slug}`
                                            : `/u/${mediaItem.posted_by.id}`
                                    "
                                >
                                    {{ mediaItem.posted_by.name }}
                                </a>
                                <small class="text-muted mb-0">{{
                                    mediaItem.created_at | moment("from")
                                }}</small>
                            </b-media-body>
                        </b-media>
                        <div class="d-flex align-items-center">
                            <div
                                v-if="
                                    showLeague &&
                                    mediaItem.posted_by.type !== 'league'
                                "
                                class="d-none d-lg-block"
                            >
                                <a
                                    class="h5 text-grey"
                                    :href="`/${mediaItem.leagueSlug}`"
                                    >{{ mediaItem.leagueName }}</a
                                >
                            </div>
                            <b-btn
                                v-if="!showLeague"
                                variant="ghost-purple"
                                v-b-tooltip.hover
                                title="Pin"
                                :class="pinned ? 'active' : null"
                                @click="togglePin"
                            >
                                <i class="fas fa-thumbtack"></i>
                            </b-btn>
                        </div>
                    </b-col>
                </b-row>
                <div class="post-wrapper">
                    <div
                        class="mb-0 mt-3"
                        :class="this.$mq === 'sm' ? 'px-2' : null"
                        v-html="description"
                    ></div>

                    <div
                        class="nav-prev"
                        v-if="[2].indexOf(mediaItem.type) > -1"
                    >
                        <b-button
                            variant="clear"
                            @click="navigatePrev(mediaItem.id)"
                        >
                            <i class="fas fa-arrow-circle-left fa-lg"></i>
                        </b-button>
                    </div>
                    <div
                        class="nav-next"
                        v-if="[2].indexOf(mediaItem.type) > -1"
                    >
                        <b-button
                            variant="clear"
                            @click="navigateNext(mediaItem.id)"
                        >
                            <i class="fas fa-arrow-circle-right fa-lg"></i>
                        </b-button>
                    </div>
                </div>
                <!-- Show More -->
                <div
                    v-show="isLongPost && !postTextShowMore"
                    class="text-center mb-2"
                >
                    <b-btn variant="link" @click="handlePostTextShowMore(true)"
                        >Show More</b-btn
                    >
                </div>
                <!-- Show Less -->
                <div
                    v-show="isLongPost && postTextShowMore"
                    class="text-center mb-2"
                >
                    <b-btn variant="link" @click="handlePostTextShowMore(false)"
                        >Show Less</b-btn
                    >
                </div>
                <!-- Comment Count -->
                <div
                    v-if="mediaItem.permissions.member"
                    class="modal-body-bottom"
                >
                    <post-action-list
                        :post="mediaItem"
                        :reactions="reactions"
                        :reaction-id="reactionId"
                        @react-to-post="reactToPost"
                        @share-post="sharePost"
                        @show-comments="showComments = true"
                    ></post-action-list>
                    <comments-list
                        :show-comment-form="showComments"
                        parent-type="post"
                        :parent-item="mediaItem"
                        :commentable-type="commentableType"
                        :reactions="reactions"
                        :auth-user="authUser"
                        :league-id="mediaItem.leagueId"
                        @comment-added="mediaItem.commentCount++"
                        @comment-removed="mediaItem.commentCount--"
                    ></comments-list>
                </div>
                <join-league-modal
                    v-if="showJoin"
                    :modal-open.sync="joinModalOpen"
                    :price="mediaItem.leaguePrice"
                    :league-id="mediaItem.leagueId"
                    :league-trial="mediaItem.leagueTrial"
                ></join-league-modal>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import videojs from "video.js";
import MediaItemCommentsCard from "../Cards/MediaItemCommentsCard";
import CommentsList from "../Lists/CommentsList";
import JoinLeagueModal from "../Modals/JoinLeagueModal";

import videojsqualityselector from "videojs-hls-quality-selector";
require("videojs-contrib-quality-levels");
require("videojs-hls-quality-selector");
export default {
    name: "MediaItemCommentsModalRes",
    components: {
        MediaItemCommentsCard,
        CommentsList,
        JoinLeagueModal,
    },
    props: {
        modalOpen: Boolean,
        mediaItem: Object,
        currentMediaItemIndex: Number,
    },
    data() {
        return {
            postEditable: true,
            showLeague: true,
            pinned: false,
            showComments: true,
            reactions: [],
            authUser: {},
            postTextShowMore: false,
            truncatePostChars: 250,
            reactionId: null,
            joinModalOpen: false,
            imgHeight: null,
            videoPlayer: null,
        };
    },
    mounted() {
        this.reactionId = this.mediaItem.reaction;
        this.getReactionList();
    },
    computed: {
        commentableType() {
            return this.mediaItem.type == 2 ? "post" : "media";
        },
        showJoin() {
            return (
                (!this.mediaItem.permissions.member && this.showLeague) ||
                this.joinModalOpen
            );
        },
        open: {
            get() {
                return this.modalOpen;
            },
            set(value) {
                this.$emit("update:modalOpen", value);
            },
        },
        reactionCounts() {
            if (this.mediaItem) {
                return {
                    1: this.mediaItem.like_count,
                    2: this.mediaItem.love_count,
                };
            }
            return null;
        },
        isLongPost() {
            return this.mediaItem.description.length > this.truncatePostChars;
        },
        description() {
            const nChars = this.truncatePostChars;
            if (
                this.mediaItem.description.length > nChars &&
                !this.postTextShowMore
            ) {
                return `${this.mediaItem.description.substring(0, nChars)}...`;
            }

            return this.mediaItem.description;
        },
        changeImgHeight() {
            if (this.$mq === "sm") {
                return "height: auto;";
            }
            if (this.$mq === "md") {
                return "height: auto;";
            } else {
                return "width: 100%; height: 50vh; object-fit: cover";
            }
        },
        changePostHeight() {
            if (this.$mq === "sm") {
                return "overflow-y: hidden";
            }
            if (this.$mq === "md") {
                return `overflow-y: auto; height: ${this.imgHeight}px`;
            } else {
                return "overflow-y: auto; height: 50vh";
            }
        },
    },
    watch: {
        open: function (val, oldVal) {
            if (val === true) {
                setTimeout(() => {
                    if ([1, 5].indexOf(this.mediaItem.type) > -1) {
                        this.createVideoPlayerInstance((vp) => {
                            vp.src(this.mediaItem.video);
                            const type = vp.currentType();
                            if (type === "application/x-mpegURL") {
                                vp.hlsQualitySelector = videojsqualityselector;
                                vp.hlsQualitySelector();
                            }
                        });
                    }
                }, 250);
            }
        },
    },
    methods: {
        getVideoPlayerInstance() {
            return new Promise((resolve) => {
                setTimeout(() => {
                    const videoElm = document.getElementById("modal-video-player");
                    if (videoElm && !this.videoPlayer) {
                        this.videoPlayer = videojs(videoElm);
                    }
                    resolve(this.videoPlayer);
                }, 250);
            });
        },
        closeModal() {
            this.$bvModal.hide("media-item-comments-modal");
            this.open = false;
        },
        navigatePrev() {
            this.$emit("navigate-previous", {
                mediaItem: this.mediaItem,
                currentMediaItemIndex: this.currentMediaItemIndex,
            });

            setTimeout(async () => {
                if ([1, 5].indexOf(this.mediaItem.type) > -1) {
                    const player = await this.getVideoPlayerInstance();
                    player.src(this.mediaItem.video);
                    const type = player.currentType();
                    if (type === "application/x-mpegURL") {
                        player.hlsQualitySelector();
                    }
                }
            }, 250);
        },
        navigateNext() {
            this.$emit("navigate-next", {
                mediaItem: this.mediaItem,
                currentMediaItemIndex: this.currentMediaItemIndex,
            });

            setTimeout(async () => {
                if ([1, 5].indexOf(this.mediaItem.type) > -1) {
                    const player = await this.getVideoPlayerInstance();
                    player.src(this.mediaItem.video);
                    const type = player.currentType();
                    if (type === "application/x-mpegURL") {
                        player.hlsQualitySelector();
                    }
                }
            }, 250);
        },
        async getReactionList() {
            try {
                const response = await axios.post("/leagues/getReactionList");
                this.reactions = response.data;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
        sharePost(type) {
            this.$emit("share-post", type);
        },
        async reactToPost(id) {
            try {
                const data = {
                    leagueId: this.mediaItem.leagueId,
                    reaction: id,
                    reactionableId:
                        this.mediaItem.type === 2
                            ? this.mediaItem.postId
                            : this.mediaItem.mediaId,
                    reactionableType:
                        this.mediaItem.type === 2 ? "post" : "media",
                };
                const response = await axios.post("/leagues/reactPost", data);
                this.reactionId = response.data.reaction;
                this.$emit("reaction-change", {
                    like_count: response.data.like_count,
                    love_count: response.data.love_count,
                });
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
        handlePostTextShowMore(showMore) {
            this.postTextShowMore = showMore;
        },
        joinLeague() {
            if (this.mediaItem.preview) {
                if (this.mediaItem.leaguePrice !== "0.00") {
                    this.joinModalOpen = true;
                    console.log("open the join modal");
                } else {
                    this.joinFreeLeague();
                }
            }
        },
        async joinFreeLeague() {
            try {
                const response = await axios.get(`/leagues/join`, {
                    params: { leagueId: this.mediaItem.leagueId },
                });
                window.location.reload();
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
        getHeight(event) {
            let image = event.target;
            this.imgHeight = image.clientHeight;
        },
    },
};
</script>
<style scoped lang="scss">
.image-wrapper,
.video-wrapper {
    cursor: pointer;
    // margin-top: 10px;
    position: relative;
    img.image {
        width: 100%;
    }
    video {
        width: 100%;
    }
    .nav-prev {
        position: absolute;
        top: 10px;
        left: 3px;
        .btn {
            background-color: black;
            .fas {
                color: white;
            }
        }
    }
    .nav-next {
        position: absolute;
        top: 10px;
        right: 3px;
        .btn {
            background-color: black;
            .fas {
                color: white;
            }
        }
    }
}
.post-wrapper {
    position: relative;
    min-height: 0px;
    .nav-prev {
        position: absolute;
        position: absolute;
        top: 10px;
        left: 3px;
        .btn {
            background-color: black;
            .fas {
                color: white;
            }
        }
    }
    .nav-next {
        position: absolute;
        top: 10px;
        right: 3px;
        .btn {
            background-color: black;
            .fas {
                color: white;
            }
        }
    }
}
</style>
