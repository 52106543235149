<template>
    <b-list-group-item class="py-2">
        <b-media no-body>
            <b-media-aside class="mr-3">
            <a :href="'/u/'+message.participant.id">
                <b-avatar
                    :src="
                        message.participant.avatar
                            ? message.participant.avatar.file
                            : null
                    "   
                   @click="message.participant.id"
                    variant="grey"  
                ></b-avatar>
                </a>
            </b-media-aside>
            <b-media-body style="flex: 0 1 auto">
                <div class="d-flex align-items-center">
                    <h6 class="my-0 text-muted">
                     <a :href="'/u/'+message.participant.id">
                        {{ message.participant.name }}
                     </a>
                        <span class="small ml-2">{{ messageDate }}</span>
                    </h6>
                    <b-dropdown
                        v-if="editable"
                        class="message-menu-dropdown ml-2"
                        variant="link"
                        toggle-class="text-decoration-none"
                        size="sm"
                        no-caret
                        right
                    >
                        <template v-slot:button-content>
                            <i class="fas fa-ellipsis-v"></i
                            ><span class="sr-only">Message Menu</span>
                        </template>
                        <b-dropdown-item @click="toggleEdit"
                            >Edit</b-dropdown-item
                        >
                        <b-dropdown-item @click="deleteMessage"
                            >Delete</b-dropdown-item
                        >
                    </b-dropdown>
                </div>
                <b-card
                    class="message-item-card"
                    v-show="editing || message.media || message.message"
                    no-body
                >
                    <message-form
                        v-if="editing"
                        :conversation-id="conversationId"
                        :message="message"
                        :auth-user-id="authUserId"
                        :rows="3"
                        @message-edited="messageEdited"
                    ></message-form>
                    <b-card-body
                        v-show="!editing && (message.media || message.message)"
                    >
                        <b-row v-if="message.media" no-gutters>
                            <b-col
                                v-if="!message.media.type"
                                cols="auto"
                                class="mx-auto"
                            >
                                <div class="media-card">
                                    <b-img-lazy
                                        :src="message.media.file"
                                        fluid
                                        class="mx-auto"
                                        style="max-height: 400px"
                                    ></b-img-lazy>
                                </div>
                            </b-col>
                            <b-col v-else cols="12" class="px-0">
                                <div
                                    class="media-card"
                                    style="position: relative"
                                >
                                    <video
                                        ref="videoPlayer"
                                        controls
                                        class="video-js"
                                    >
                                        <source
                                            v-if="
                                                message.media.file
                                                    .split('.')
                                                    .slice(-1)[0]
                                                    .toLowerCase() === 'mp4'
                                            "
                                            :src="message.media.file"
                                            type="video/mp4"
                                        />
                                        <source
                                            v-if="
                                                message.media.file
                                                    .split('.')
                                                    .slice(-1)[0]
                                                    .toLowerCase() === 'webm'
                                            "
                                            :src="message.media.file"
                                            type="video/webm"
                                        />
                                        <source
                                            v-if="
                                                message.media.file
                                                    .split('.')
                                                    .slice(-1)[0]
                                                    .toLowerCase() === 'ogg'
                                            "
                                            :src="message.media.file"
                                            type="video/ogg"
                                        />
                                        <source
                                            v-if="
                                                message.media.file
                                                    .split('.')
                                                    .slice(-1)[0]
                                                    .toLowerCase() === 'm3u8'
                                            "
                                            :src="message.media.file"
                                            type="application/x-mpegURL"
                                        />
                                    </video>
                                </div>
                                /
                            </b-col>
                        </b-row>
                        <b-card-text>
                            {{ message.message }}
                        </b-card-text>
                    </b-card-body>
                </b-card>
                <post-card-small
                    v-if="message.post"
                    :post="message.post"
                    style="max-width: 550px"
                ></post-card-small>
            </b-media-body>
        </b-media>
    </b-list-group-item>
</template>

<script>
import MessageForm from "../Forms/MessageForm";
import PostCardSmall from "../Cards/PostCardSmall";
import videojs from "video.js";
export default {
    name: "MessageItem",
    components: { PostCardSmall, MessageForm },
    props: {
        message: {
            type: Object,
            required: true,
        },
        authUserId: {
            type: Number,
            required: true,
        },
        conversationId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            editing: false,
        };
    },
    mounted() {
        if (
            this.message &&
            this.message.hasOwnProperty("media") &&
            this.message.media != null &&
            this.message.media.hasOwnProperty("file")
        ) {
            if (this.$refs.videoPlayer) {
                videojs(this.$refs.videoPlayer);
            }
        }
    },
    computed: {
        editable() {
            return this.message.participant.id === this.authUserId;
        },
        messageDate() {
            const date = this.message.date;

            if (!moment().isAfter(date, "day")) {
                return moment(date).format("h:mm a");
            } else if (!moment().isAfter(date, "year")) {
                return moment(date).format("MMM D, h:mm a");
            } else {
                return moment(date).format("MMM D, YYYY h:mm a");
            }
        },
    },
    methods: {
        toggleEdit() {
            this.editing = !this.editing;
        },
        messageEdited(message) {
            this.$emit("message-edited", message);
            this.editing = false;
        },
        async deleteMessage() {
            try {
                const response = await axios.post("/inbox/messages/delete", {
                    chatMessageId: this.message.id,
                });
                this.$emit("message-deleted", this.message.id);
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
    },
};
</script>

<style scoped lang="scss">
.b-avatar {
    width: 42px;
    height: 42px;
    margin-top: 16px;
}
.card.message-item-card {
    border: none;
    .card-body {
        padding: 8px;
    }
}
.video-js{
    width: 640px;
    height: 268px;
    @media (max-width: 768px) {
        width: calc(100vw - 95px);
    }
}
</style>
