<template>
    <div>
        <a
            class="text-decoration-none"
            :href="`/leagues/${post.league.id}/post/${post.id}`"
        >
            <b-card class="post-card clickable drop-shadow" no-body>
                <b-card-body>
                    <b-row class="post-card-header" no-gutters>
                        <b-col cols="12" lg order="1" order-lg="0">
                            <b-media class="align-items-center">
                                <template v-slot:aside>
                                    <b-avatar
                                        :src="post.posted_by.avatar"
                                        variant="grey"
                                        size="45"
                                    ></b-avatar>
                                </template>
                                <div class="d-flex flex-column">
                                    <a
                                        class="h6 font-weight-bold text-black mb-0"
                                        :href="
                                            post.posted_by.type === 'league'
                                                ? `/${post.posted_by.slug}`
                                                : `/u/${post.posted_by.id}`
                                        "
                                    >
                                        {{ post.posted_by.name }}
                                    </a>
                                    <small class="text-muted mb-0">{{
                                        post.created_at | moment("from")
                                    }}</small>
                                </div>
                            </b-media>
                        </b-col>
                        <b-col
                            v-if="post.posted_by.type !== 'league'"
                            cols="12"
                            lg="auto"
                            order="0"
                            order-lg="1"
                        >
                            <div class="py-2">
                                <a
                                    class="h5 text-grey"
                                    :href="`/${post.league.slug}`"
                                    >{{ post.league.name }}</a
                                >
                            </div>
                        </b-col>
                    </b-row>
                    <div
                        class="d-flex align-items-center justify-content-between mt-1"
                    >
                        <p class="truncate text-dark mb-0 mt-3 pr-3">
                            {{ removeHtmlString }}
                        </p>
                        <img
                            v-if="isFile"
                            :src="post.files[0].file"
                            class="rounded"
                            height="72"
                            width="72"
                            style="object-fit: cover"
                        />
                        <img
                            v-if="post.poster && post.type == 5"
                            :src="post.poster"
                            class="rounded"
                            height="72"
                            width="72"
                            style="object-fit: cover;"
                        />
                    </div>
                </b-card-body>
            </b-card>
        </a>
    </div>
</template>

<script>
import MediaCard from "./MediaCard";

export default {
    name: "PostCardSmall",
    components: {
        MediaCard,
    },
    props: {
        post: {
            type: Object,
            required: true,
        },
    },
    computed: {
        removeHtmlString() {
            return this.post.post.replace(/<[^>]*>?/gm, "");
        },
        isFile() {
            return this.post?.files?.length >= 1 ? true : false;
        },
    },
};
</script>

<style scoped></style>
