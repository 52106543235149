<template>
    <div>
        <b-row class="align-items-center">
            <b-col>
                <h1 id="leagues" class="h4 font-weight-bold">My Leagues</h1>
            </b-col>
            <b-col cols="12" sm="auto">
                <b-btn
                    v-if="authUser.id === user.id && !propVaultLocked"
                    variant="purple"
                    class="btn-padded mb-2"
                    pill
                    @click="lockVault"
                    >Lock Vault</b-btn
                >
                <b-btn
                    v-if="authUser.id === user.id && propVaultLocked"
                    variant="purple"
                    class="btn-padded mb-2"
                    pill
                    @click="unlockVault"
                    >Unlock Vault</b-btn
                >
                <b-btn
                    variant="purple"
                    class="btn-padded mb-2"
                    pill
                    href="/browse-leagues"
                    >Find New Leagues</b-btn
                >

              <b-btn
                  variant="purple"
                  class="btn-padded mb-2"
                  pill
                  href="/leagues/create"
              >Create League</b-btn
              >
            </b-col>
        </b-row>
        <hr />
        <leagues-list :user-id="user.id" :auth-user="authUser" :users-leagues="true"></leagues-list>
        <b-row class="align-items-center mt-5">
            <b-col>
                <h1 id="subscriptions" class="h4 font-weight-bold">My Subscriptions</h1>
            </b-col>
        </b-row>
        <hr />
        <leagues-list :user-id="user.id" :auth-user="authUser" :users-leagues="false"></leagues-list>
        <br />
    </div>
</template>

<script>
import LeaguesList from "../../Lists/LeaguesList";
export default {
    name: "UserProfileLeagues",
    components: { LeaguesList },
    props: {
        authUser: { type: Object, required: false },
        user: { type: Object, required: true },
        propVaultLocked: { type: Boolean, required: true },
    },
    data() {
        return {
            vaultLocked: this.propVaultLocked,
        };
    },
    methods: {
        async unlockVault() {
            // Before unlocking the vault, make sure they have a vault password set
            try {
                const vaultCheckRes = await axios.get("/vault/check");

                if (vaultCheckRes.data.success) {
                    const { value: password } = await this.$swal({
                        title: "Unlocking your vault",
                        html:
                            "If you do not remember your vault password, click " +
                            '<a href="/users/resetVault">here</a> ' +
                            "to reset your vault password.",
                        input: "password",
                        showCancelButton: true,
                        showConfirmButton: true,
                        confirmButtonText: "Unlock",
                        inputPlaceholder: "Enter password to unlock vault",
                        timer: 90000,
                    });

                    if (password) {
                        const unlockRequestData = {
                            vault_password: password,
                        };
                        const unlockResponse = await axios.post(
                            "/vault/unlock",
                            unlockRequestData
                        );

                        if (unlockResponse.data.success) {
                            this.$emit("update-vault-locked", false);
                            setTimeout(function () {
                                location.reload();
                            }, 2000);
                            await this.$swal(
                                "Success!",
                                unlockResponse.data.message,
                                "success"
                            );
                        } else {
                            await this.$swal(
                                "Error!",
                                unlockResponse.data.message,
                                "error"
                            );
                        }
                    }
                } else {
                    const { value: password } = await this.$swal({
                        title: vaultCheckRes.data.message,
                        input: "password",
                        showCancelButton: true,
                        showConfirmButton: true,
                        confirmButtonText: "Set Password",
                        inputPlaceholder: "Type a new vault password",
                        timer: 90000,
                    });

                    if (password) {
                        const setRequestData = {
                            vault_password: password,
                        };
                        const setPassResponse = await axios.post(
                            "/vault/set_password",
                            setRequestData
                        );

                        if (setPassResponse.data.success) {
                            setTimeout(function () {
                                location.reload();
                            }, 2000);
                            await this.$swal(
                                "Success!",
                                setPassResponse.data.message,
                                "success"
                            );
                        } else {
                            await this.$swal(
                                "Error!",
                                setPassResponse.data.message,
                                "error"
                            );
                        }
                    }
                }
            } catch (error) {
                await this.$swal(
                    "Error!",
                    error.response.data.message,
                    "error"
                );
            }
        },
        async lockVault() {
            try {
                const response = await axios.post("/vault/lock");

                if (response.data.success) {
                    this.$emit("update-vault-locked", true);
                    await this.$swal(
                        "Success!",
                        response.data.message,
                        "success"
                    );
                }

                // There is no error response from the controller
            } catch (error) {
                await this.$swal(error.response.data.message);
            }
        },
    },
};
</script>

<style scoped></style>
