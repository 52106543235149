var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{staticClass:"video-popup-custom",attrs:{"id":"video-popup-modal","size":"md","hide-footer":"","hide-header":"","centered":""},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('span',{staticClass:"close-btn",on:{"click":function($event){return _vm.$bvModal.hide('video-popup-modal')}}},[_c('img',{attrs:{"src":"/images/icons/close.svg"}})]),_vm._v(" "),_c('div',{staticClass:"burst-replies-video-inner"},[_c('video',{staticClass:"video-show video-js",attrs:{"autoplay":"","controls":"","disablepictureinpicture":"","controlslist":"nodownload","id":"videoPopup","playsinline":""},domProps:{"muted":_vm.muted}},[(
                    _vm.videoUrl.split('.').slice(-1)[0].toLowerCase() === 'mp4'
                )?_c('source',{attrs:{"src":_vm.videoUrl,"type":"video/mp4"}}):_vm._e(),_vm._v(" "),(
                    _vm.videoUrl.split('.').slice(-1)[0].toLowerCase() ===
                    'webm'
                )?_c('source',{attrs:{"src":_vm.videoUrl,"type":"video/webm"}}):_vm._e(),_vm._v(" "),(
                    _vm.videoUrl.split('.').slice(-1)[0].toLowerCase() === 'ogg'
                )?_c('source',{attrs:{"src":_vm.videoUrl,"type":"video/ogg"}}):_vm._e(),_vm._v(" "),(
                    _vm.videoUrl.split('.').slice(-1)[0].toLowerCase() ===
                    'm3u8'
                )?_c('source',{attrs:{"src":_vm.videoUrl,"type":"application/x-mpegURL"}}):_vm._e()]),_vm._v(" "),_c('a',{staticClass:"user-name",attrs:{"href":'/u/' + _vm.postedBy.id}},[_vm._v(_vm._s(_vm.postedBy.name))]),_vm._v(" "),_c('div',{staticClass:"slider-links"},[(_vm.index > 0)?_c('span',{staticClass:"arrow-previous",on:{"click":function($event){return _vm.openVideo(_vm.index - 1, _vm.index)}}},[_c('img',{attrs:{"src":"/images/icons/previous.png"}})]):_vm._e(),_vm._v(" "),(_vm.total - 1 > _vm.index)?_c('span',{staticClass:"arrow-next",on:{"click":function($event){return _vm.openVideo(_vm.index + 1, _vm.index)}}},[_c('img',{attrs:{"src":"/images/icons/next.png"}})]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }