<template>
    <div>
        <league-price-form
            :league-id="league.id"
            :league-trial="league.trial"
            :price="league.league_price"
            :read-only="false"
            button-text="Submit"
            @price-set="refreshPage"
            :balance="balance"
        ></league-price-form>
    </div>
</template>

<script>
import LeaguePriceForm from "../../Forms/LeagueForms/LeaguePriceForm";
export default {
    name: "LeagueSettingsSubscription",
    components: { LeaguePriceForm },
    props: {
        league: {
            type: Object,
            required: true,
        },
        paymentAccount: {
            type: Object,
            required: true,
        },
      balance: {
          type: Object,
      }
    },
    methods: {
        refreshPage() {
            window.location.reload();
        },
    },
};
</script>

<style scoped></style>
