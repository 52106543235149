<template>
    <div>
        <b-list-group flush>
            <conversation-item
                v-for="conversation in conversations"
                :key="conversation.id"
                :conversation="conversation"
                :active="conversation.id === activeConvoId"
                :list-type="listType"
                @click="$emit('select-convo', conversation.id);$emit('set-disable-message-form', conversation.participants[0].is_disabled);"
                @convo-removed="removeConvo"
            ></conversation-item>
        </b-list-group>
        <div v-if="!conversations.length" class="text-center p-5">
            <span class="h5 text-muted" v-if="!isLoading">No Conversations found</span>
            <span class="h5 text-muted" v-if="isLoading">Loading...</span>
        </div>
    </div>
</template>

<script>
import ConversationItem from "../ListItems/ConversationItem";
import ChatSearchForm from "../Forms/ChatSearchForm";
import UserSearchForm from "../Forms/UserForms/UserSearchForm";
export default {
    name: "ConversationsList",
    components: { ChatSearchForm, ConversationItem },
    props: {
        userId: {
            type: Number,
            required: true,
        },
        activeConvoId: Number,
        conversations: {
            type: Array,
            required: true,
        },
        listType: {
            type: String,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            searchTerm: null,
            perPage: 1000,
            currentPage: 1,
        };
    },
    methods: {
        removeConvo(id) {
            this.conversations.splice(
                this.conversations.findIndex((convo) => convo.id === id),
                1,
            );
        },
    },
};
</script>

<style scoped lang="scss"></style>
