<template>
    <div>
        <user-plan-list
            :plans="plans"
            :selectedPlan="selectedPlan"
            @plan-selected="selectPlan"
            class="mt-5"
        ></user-plan-list>
        <b-form @submit.prevent="submitSelection" class="my-4">
            <!-- Kenyatta requested removal 8/21 -->
            <!--<b-form-group label="Promo Code" :invalid-feedback="couponErrors">
              <b-form-input type="text" v-model.trim="promoCode" class="form-control-black"></b-form-input>
            </b-form-group>-->

          <p>This small fee allows us to keep the lights on without having to sell your data or interrupt you when viewing the content you love with third-party ads.</p>
            <submit-button
                :loading="loading"
                :disabled="selectedPlan === null"
                text="Next"
            ></submit-button>
        </b-form>
    </div>
</template>

<script>
import UserPlanList from "../../Lists/UserPlanList";
import SubmitButton from "../../Buttons/SubmitButton";

export default {
    name: "UserPlanChoiceForm",
    components: { SubmitButton, UserPlanList },
    props: {
        plans: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            selectedPlan: null,
            /*promoCode: undefined,*/
            couponErrors: null,
        };
    },
    methods: {
        selectPlan(id) {
            this.selectedPlan = id;
        },
        /* Kenyatta requested removal 8/21 */
        /*async submitPromoCode() {
            try {
                const response = await axios.post("/register/coupon", {
                    coupon: this.promoCode,
                });
                await Swal.fire({
                    title: "Coupon Applied",
                    text: `${response.data.daysFree} Days Free!`,
                    icon: "success",
                    confirmButtonText: "Continue",
                    confirmButtonColor: "#5451DE",
                    showCancelButton: true,
                    cancelButtonText: "Remove Coupon",
                    reverseButtons: true,
                    toast: false,
                    position: "center",
                }).then((object) => {
                    console.log(object);
                    if (object.isConfirmed) {
                        // Route to next page
                        window.location =
                            "/register/payment?plan=" + this.selectedPlan;
                    } else if (object.dismiss === "cancel") {
                        this.removeCoupon();
                    }
                });
                return true;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        },
        async removeCoupon() {
            try {
                const response = await axios.get("/register/removeCoupon");
                this.promoCode = null;

                await this.$swal({
                    title: "Coupon Removed",
                    icon: "success",
                    toast: false,
                    position: "center",
                });
                this.loading = false;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        },*/
        async submitSelection() {
            this.loading = true;
            if (typeof this.promoCode === "undefined") {
                // Route to next page
                window.location = "/register/payment?plan=" + this.selectedPlan;
            } else {
                await this.submitPromoCode();
            }
        },
    },
};
</script>

<style scoped></style>
