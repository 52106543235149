<template>
    <b-col cols="4">
        <div
            v-if="[0, 1, 3, 5].indexOf(mediaItem.type) > -1"
            class="item item-media"
            v-bind:style="{ backgroundImage: `url(${mediaItem.thumbnail})` }"
            @click="viewItem"
        >
            <div
                v-if="mediaItem.commentCount && !mediaItem.preview"
                class="comment-count-badge"
            >
                <i class="fas fa-comment-alt fa-2x"></i>
                <div class="count">{{ mediaItem.commentCount }}</div>
            </div>
            <div class="icon-play" v-if="mediaItem.type == 1">
                <i class="fas fa-play fa-lg"></i>
            </div>
            <div class="icon-burst" v-if="mediaItem.type == 5">
                <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M4.5002 11.4V18.6C4.5002 19.0945 4.09472 19.5 3.6002 19.5C3.10568 19.5 2.7002 19.0945 2.7002 18.6V11.4C2.7002 10.9055 3.10568 10.5 3.6002 10.5C4.09472 10.5 4.5002 10.9055 4.5002 11.4ZM26.4002 4.5C25.9057 4.5 25.5002 4.90548 25.5002 5.4V24.6C25.5002 25.0945 25.9057 25.5 26.4002 25.5C26.8947 25.5 27.3002 25.0945 27.3002 24.6V5.4C27.3002 4.90548 26.8947 4.5 26.4002 4.5ZM5.7002 17.556L24.3002 23.1903V6.8097L5.7002 12.444V17.556ZM14.9909 21.6237L10.0469 20.1272C9.96604 20.1038 9.88518 20.0909 9.80432 20.0909C9.62737 20.0909 9.45275 20.1448 9.3098 20.2561C9.09417 20.4155 8.97113 20.6581 8.97113 20.9253C8.97113 21.2968 9.20785 21.6178 9.56528 21.7268L14.506 23.2233C14.7638 23.3018 15.031 23.2538 15.2466 23.0944C15.4599 22.935 15.5829 22.6925 15.5829 22.4253C15.5829 22.0538 15.3451 21.7327 14.9911 21.6237H14.9909Z"
                        fill="#FFF"
                    />
                </svg>
            </div>
        </div>

        <div
            v-if="mediaItem.type === 2"
            class="item item-text"
            @click="viewItem"
        >
            <div
                v-if="mediaItem.commentCount && !mediaItem.preview"
                class="comment-count-badge"
            >
                <i class="fas fa-comment-alt fa-2x"></i>
                <div class="count">{{ mediaItem.commentCount }}</div>
            </div>
            <div class="item-text-inner" v-html="itemText"></div>
        </div>
    </b-col>
</template>

<script>
export default {
    name: "MediaThumbnailItem",
    props: {
        mediaItem: Object,
    },
    computed: {
        itemText() {
            const description = this.mediaItem.description;
            let nChars = 150;

            if (window.innerWidth < 600) {
                nChars = 40;
            }

            return description.length > nChars
                ? `${description.substring(0, nChars)}...`
                : description;
        },
    },
    methods: {
        viewItem() {
            this.$emit("view-item");
        },
    },
};
</script>

<style scoped lang="scss">
.item {
    height: 120px;
    margin-bottom: 6px;
    cursor: pointer;
    &:hover {
        box-shadow: 5px 6px 12px 7px rgba(0, 0, 0, 0.25);
    }
    .comment-count-badge {
        position: absolute;
        top: 5px;
        right: 10px;
        .fas {
            color: white;
        }
        .count {
            position: absolute;
            top: 2px;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }

    &.item-media {
        background-size: cover;
        background-position: center;
    }
    &.item-text {
        text-align: center;
        align-items: center;
        padding: 14px 14px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        .comment-count-badge {
            color: white;
            .fas {
                color: black;
            }
        }
    }

    .icon-play {
        position: absolute;
        right: 40%;
        top: 30%;
        border-radius: 50%;
        background-color: black;
        height: 40px;
        width: 40px;
        .fas.fa-play {
            color: white;
            margin-left: 14px;
            margin-top: 12px;
        }
    }
    .icon-burst {
        position: absolute;
        right: 40%;
        top: 30%;
        border-radius: 50%;
        background-color: black;
        height: 36px;
        width: 36px;
    }

    @media (min-width: 768px) {
        height: 200px;
    }
}
</style>
