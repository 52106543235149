<template>
    <b-list-group-item
        class="conversation-item"
        active-class="active"
        :active="active"
        @click="$emit('click')"
    >
        <b-row>
            <b-col cols="auto">
                <!-- If more than one participant -->
                <b-avatar-group
                    v-if="conversation.participants.length > 1"
                    variant="grey"
                    size="50"
                    :overlap="1 - 1 / conversation.participants.length"
                >
                    <b-avatar
                        v-for="(participant,
                        index) in conversation.participants"
                        :key="participant.id"
                        :badge="
                            index === conversation.participants.length - 1
                                ? unreadCount
                                : false
                        "
                        badge-top
                        badge-variant="danger"
                    ></b-avatar>
                </b-avatar-group>
                <!-- If there is only one participant -->
                <b-avatar
                    v-else
                    variant="grey"
                    size="50"
                    :src="
                        conversation.participants[0] && conversation.participants[0].avatar
                            ? conversation.participants[0].avatar.file
                            : null
                    "
                    :badge="unreadCount"
                    badge-top
                    badge-variant="danger"
                ></b-avatar>
            </b-col>
            <b-col class="details-group">
                <div>
                    <span>{{ participantNames }}</span>
                    <small class="text-muted ml-2">{{ lastMessageDate }}</small>
                </div>
                <div
                    v-if="conversation.lastMessage != null"
                    class="last-message"
                >
                    {{
                        conversation.lastMessage.message.length > 70
                            ? conversation.lastMessage.message.substr(0, 70) +
                              "..."
                            : conversation.lastMessage.message
                    }}
                </div>
            </b-col>
            <b-col cols="auto" class="end-group">
                <b-btn
                    v-if="listType != 'deleted'"
                    variant="link"
                    :disabled="loading"
                    class="p-2"
                    @click="leaveConversation($event)"
                >
                    <i v-show="!loading" class="fas fa-times"></i>
                    <b-spinner v-show="loading" small></b-spinner>
                </b-btn>
            </b-col>
        </b-row>
    </b-list-group-item>
</template>

<script>
export default {
    name: "ConversationItem",
    props: {
        conversation: {
            type: Object,
            required: true,
        },
        active: {
            type: Boolean,
            default: false,
        },
        listType: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        participantNames() {
            const names = this.conversation.participants.map((person) => {
                return person.name;
            });

            return names.join(", ");
        },
        lastMessageDate() {
            const date = this.conversation.lastMessageDate;

            if (!moment().isAfter(date, "day")) {
                return moment(date).format("h:mm a");
            } else if (!moment().isAfter(date, "year")) {
                return moment(date).format("MMM D");
            } else {
                return moment(date).format("MMM D, YYYY h:mm a");
            }
        },
        unreadCount() {
            if (this.conversation && this.conversation.unread && this.conversation.unread !== 0) {
                return this.conversation.unread.toString();
            } else {
                return false;
            }
        },
    },
    methods: {
        async leaveConversation(event) {
            event.preventDefault();
            event.stopPropagation();
            this.loading = true;
            try {
                const response = await axios.post("/inbox/hide", {
                    chatId: this.conversation.id,
                });

                this.$emit("convo-removed", this.conversation.id);
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.conversation-item {
    border-bottom: 1px solid rgba(170, 170, 170, 0.17);
    &.active {
        color: #0e0e0e;
        background-color: #e7e7e7;
        border-color: #e7e7e7;
    }
    .details-group {
        .last-message {
            font-size: 14px;
            padding-top: 8px;
        }
    }
    .end-group {
        padding-top: 14px;
    }
}
</style>
