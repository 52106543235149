<template>
  <div class="free-alert-message">
    <h5 class="h4">Experience The Innerleague for free!</h5>

    <p class="small">
      Sign up today to get a 30-day free trial to access your favorite people, groups, and organizations on The Inner League.
      Also take advantage of the free trial period to create a league so people can have access to you too!
    </p>
  </div>
</template>

<script>
export default {
  name: "FreePlanMessage"
}
</script>

<style scoped>

</style>