<template>
    <div>
        <admin-members-list
            :league-id="context.id"
            :auth-id="currentUser.id"
        ></admin-members-list>
    </div>
</template>

<script>
import AdminMembersList from "../../../Lists/Admin/AdminMembersList";

export default {
    name: "LeagueProfileMembers",
    components: { AdminMembersList },
    props: {
        /* Contextual Object that contains league and authUser (including league roles) */
        parentContext: {
            type: Object,
            required: true,
        },
        currentUser:{
            type: Object,
            required: true,
        }
    },
    computed: {
        context() {
            return {
                profileTab: "Members",
                ...this.parentContext,
            };
        },
    },
};
</script>

<style scoped></style>
