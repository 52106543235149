<template>
    <div>
        <h2 class="h4 font-weight-bold">{{ title }} <b-spinner v-show="loading" small class="ml-2"></b-spinner></h2>
        <b-tabs content-class="mt-1" v-if="!loading" class="member-tabs">
            <b-tab title-link-class="mb-2" title="Members" class="member-tab">
                <b-form @submit.prevent="submitFormMembers">
                    <b-form-input
                        v-model="searchTerm"
                        placeholder="Search by name..."
                        class="mb-2"
                    ></b-form-input>
                    <b-tabs pills content-class="mt-1" vertical v-if="!loading" class="invite-tabs">
                        <b-tab title-link-class="btn-ghost-purple" v-for="(inviteItem, leagueName) in inviteList" :title="leagueName" :key="leagueName.replace(/ /g,'_')" class="subscribe-tabs">
                            <b-form-group>   
                            <b-form-checkbox-group :name="leagueName+'-invites'" :ref="leagueName+'-invites'">
                                <b-container>
                                    <b-row class="mb-1">
                                        <b-col cols="8" class="pl-0">
                                        </b-col>
                                        <b-col class="d-flex flex-row justify-content-end">
                                            <b-link
                                                href="#" 
                                                role="presentation" 
                                                @click="selectAll($event, leagueName+'-invites')"
                                                class="mx-1"
                                                size="sm"
                                            >
                                            Select All
                                            </b-link>
                                            <b-link
                                                href="#" 
                                                role="presentation" 
                                                @click="clearAll($event, leagueName+'-invites')"
                                                class="mx-1"
                                                size="sm"
                                            >
                                            Clear All
                                            </b-link>
                                        </b-col>
                                    </b-row>
                                </b-container>
                                <template v-for="(user, userKey) in inviteItem">
                                    <b-form-checkbox
                                        v-if="user.user && !user.user.filtered"
                                        v-model="inviteDataArray"
                                        :key="userKey"
                                        :value="user.user_id"
                                        class="inviteChk col-md-12"
                                        :disabled="checkBlockMember(user.user_id)"
                                    >
                                        <b-media class="align-items-center">
                                            <template v-slot:aside>
                                                <b-avatar
                                                    variant="grey"
                                                    :src="user.user.avatar_path ? user.user.avatar_path : null"
                                                    square
                                                    size="25px"
                                                ></b-avatar>
                                            </template>
                                            <div>
                                                <h6 class="mb-0">
                                                    {{ user.user.name }} 
                                                    <i class="check-block-member-info" v-if="checkBlockMember(user.user_id) && checkByType(user.user_id, 'members')">member since {{memberSince(user.user_id)}}</i>
                                                    <i class="check-block-member-info" v-else-if="checkBlockMember(user.user_id) && checkByType(user.user_id, 'invitation')">pending invite since {{memberSince(user.user_id)}}</i><i v-else></i>
                                                </h6>
                                            </div>
                                        </b-media>
                                    </b-form-checkbox>
                                </template>
                                <p v-if="isEmptyAfterFilter(inviteItem)">
                                    No users to invite after filter term try another tab or clearing search.
                                </p>
                            </b-form-checkbox-group>
                            </b-form-group>
                            <p v-if="inviteItem === null" key="errorKey">
                                No users to invite try another tab.
                            </p>
                        </b-tab>
                    </b-tabs>
                        
                    <submit-button
                        :disabled="disabled === true"
                        :loading="loading"
                        submit-text="Loading..."
                        :text="buttonText"
                        class="mt-5"
                    ></submit-button>
                    <invalid-feedback
                        :errors="formErrors"
                        class="text-center"
                    ></invalid-feedback>
                    <b-form-text
                        v-show="saved"
                        text-variant="success"
                        class="h6 text-center mt-3"
                        >Invites Sent!</b-form-text
                    >
                    <b-btn
                        v-show="showSkip"
                        variant="link"
                        class="text-purple mt-3"
                        pill
                        block
                        :href="`/${leagueSlug}`"
                        >Skip</b-btn
                    >
                </b-form>
            </b-tab>
            <b-tab title-link-class="mb-2" title="Non-members" class="non-member-tab">
                <b-form @submit.prevent="submitFormNonMembers">
                    <b-container>
                        <b-row class="mb-3">
                            <b-col cols="2" class="d-flex align-items-center justify-content-end">
                                <label for="emailTags">Email</label>
                            </b-col>
                            <b-col>
                                <b-form-tags 
                                    input-id="emailTags" 
                                    v-model="externalEmails"
                                    :tag-validator="emailValidator"
                                    placeholder="Enter email addresses"
                                ></b-form-tags>
                            </b-col>
                        </b-row>
                         <b-row class="">
                            <b-col cols="2" class="d-flex align-items-center justify-content-end">
                                <label for="phoneTags">Phone</label>
                            </b-col>
                            <b-col>
                                <b-form-tags 
                                    input-id="phoneTags"
                                    v-model="externalPhones"
                                    :tag-validator="phoneValidator"
                                    placeholder="Enter phone numbers"
                                ></b-form-tags>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <submit-button
                                    :loading="loading"
                                    submit-text="Loading..."
                                    :text="buttonText"
                                    class="mt-5"
                                ></submit-button>
                                <invalid-feedback
                                    :errors="formErrors"
                                    class="text-center"
                                ></invalid-feedback>
                                <b-form-text
                                    v-show="saved"
                                    text-variant="success"
                                    class="h6 text-center mt-3"
                                    >Invites Sent!</b-form-text
                                >
                                <b-btn
                                    v-show="showSkip"
                                    variant="link"
                                    class="text-purple mt-3"
                                    pill
                                    block
                                    :href="`/${leagueSlug}`"
                                    >Skip</b-btn
                                >
                            </b-col>
                        </b-row>
                    </b-container>
                </b-form>
            </b-tab>
        </b-tabs>
        
    </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import InvalidFeedback from "../FormComponents/InvalidFeedback";
import UserLookupForm from "../UserForms/UserLookupForm";
import SubmitButton from "../../Buttons/SubmitButton";

export default {
    name: "LeagueMembersForm",
    components: { SubmitButton, UserLookupForm, InvalidFeedback },
    props: {
        title: {
            type: String,
            default: "Invite members to join your league",
        },
        buttonText: {
            type: String,
            default: "Send Invites",
        },
        showSkip: {
            type: Boolean,
            default: false,
        },
        leagueId: {
            required: true,
        },
        leagueSlug: String,
        inSettings: {
            type: Boolean,
            default: false,
        },

    },
    data() {
        return {
            loading: false,
            saved: false,
            form: {
                members: [],
            },
          disabled: false,
            inviteList:[],
            inviteDataArray:[],
            formErrors:[],
            searchTerm:"",
            externalEmails:[],
            externalPhones:[],
            blockinviteUser:[],
            blockAlreadyMember:[],
        };
    },
    async mounted(){
        let vm = this;
        vm.loading = true;
        await axios.post("/leagues/inviteableUsers", {
            'user': this.user,
            'leagueId': vm.leagueId
        }).then(response => {
            vm.inviteList = response.data.inviteList;
            vm.blockInviteUser = response.data.blockInviteUser;
            vm.blockAlreadyMember = response.data.blockAlreadyMember;
        }).catch(error =>{
            console.log(error);
            vm.$swal(error.response.data.message);
            vm.loading = false;
        });
        this.loading = false;
    },
    validations: {
        form: {
            members: { required },
        },
    },
    watch: {
        searchTerm(){
            //console.log(this.inviteList);
            for (const league in this.inviteList) {
                this.inviteList[league].forEach((invite, index) => {
                    let user = invite.user ?? invite.user2
                    if(!user.name.includes(this.searchTerm)){
                        user.filtered = true
                    }else{
                        user.filtered = false
                    }
                })
            } 
            this.$forceUpdate(); 
        }
    },
    methods: {
        checkBlockMember(id) {
            let checkMember = '';
            if (this.blockAlreadyMember?.length > 0) {
                checkMember = this.blockAlreadyMember.find((item) => item.user_id === id );
                // return ( checkMember && checkMember.id ) ? true : false;
            }
            if ( !checkMember && this.blockInviteUser?.length > 0) {
                checkMember = this.blockInviteUser.find((item) => item.user_id === id );
                // return ( checkMember && checkMember.id ) ? true : false;
            }
            return ( checkMember?.id ) ? true : false;
        },
        checkByType(id,type) {
            if (type === 'members') {
                if (this.blockAlreadyMember?.length > 0) {
                    const checkMember = this.blockAlreadyMember.find((item) => item.user_id === id );
                    return ( checkMember?.id ) ? true : false;
                }
            }
            if (type === 'invitation') {
                if (this.blockInviteUser?.length > 0) {
                    const checkMember = this.blockInviteUser.find((item) => item.user_id === id );
                    return ( checkMember?.id ) ? true : false;
                }
            }
        },
        memberSince(id) {
            let checkMember = '';
            if (this.blockAlreadyMember?.length > 0) {
                checkMember = this.blockAlreadyMember.find((item) => item.user_id === id );
                if (checkMember) {
                    return moment(checkMember.created_at).format("MMMM D, YYYY");
                }
            }
            if ( !checkMember && this.blockInviteUser?.length > 0 ) {
                let check = this.blockInviteUser.find((item) => item.user_id === id );
                if (check) {
                    return moment(check.created_at).format("MMMM D, YYYY");
                }
            }
        },
        emailValidator(tag){
            return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(tag);
        },
        phoneValidator(tag){
            return /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(tag);
        },
        isEmptyAfterFilter(tab){
            let doesntHaveItems = true;
            tab.forEach(item =>{
                if(item.user){
                    if(!item.user.filtered) doesntHaveItems = false;
                }
            })
            return doesntHaveItems
        },
        addMember(user) {
            this.form.members.push(user);
        },
        removeMember(index) {
            this.form.members.splice(index, 1);
        },
        selectAll(whichTab){
            if(whichTab){
                //Grab all non hidden inviteChkDivs
                const tabs = document.querySelectorAll(".inviteChk:not(.hidden)");
                //console.log(this.inviteDataArray);
                tabs.forEach((checkbox, index) => {
                    //push the value of the first child node, in this case its a checkbox
                    this.inviteDataArray.push(checkbox.childNodes[0].value) ;
                })
            }
        },
        clearAll(whichTab){
            if(whichTab){
                let tabs = document.querySelectorAll(".inviteChk:not(.hidden)");
                tabs.forEach((checkbox) => {
                    //skip first 2 the rest are checkboxes
                    const index = this.inviteDataArray.indexOf(checkbox.childNodes[0].value);
                    if (index > -1) {
                        this.inviteDataArray.splice(index, 1);
                    }
                    
                })
            }
        },
        resetForm() {
            this.form = {
                members: [],
            };
            this.$v.form.$reset();
        },
        async submitFormMembers() {
            this.formErrors = [];
            this.inviteDataArray.forEach(userId => {
                let inserted = false;
                for(let leagues in this.inviteList){
                   this.inviteList[leagues].forEach(user=>{
                        if(user.user_id == userId && !inserted){
                            this.form.members.push(user)
                            inserted = true;
                        }
                   })
                }
                return true;
            })
            if(this.form.members.length > 0){
              this.disabled = true;
                try {
                    this.loading = true;
                    const response = await axios.post("/leagues/invite", {
                        leagueId: this.leagueId,
                        ...this.form,
                    });
                    if (this.inSettings) {
                        this.$emit("members-added", response);
                        this.resetForm();
                        this.loading = false;
                        this.saved = true;
                    } else {
                        window.location.href = `/${this.leagueSlug}`;
                    }
                  this.disabled = false;
                } catch (error) {
                    // If there is an error for some reason, show alert
                    await this.$swal(error.response.data.message);
                    this.loading = false;
                  this.disabled = false;
                }
            }else{
                this.formErrors.push("No one to invite!");
              this.disabled = false;
            }
            

        },
        async submitFormNonMembers(){
            if(this.externalPhones || this.externalEmails){
                this.loading = true;
              this.disabled = true;
                try{
                    const response = await axios.post("/leagues/inviteExternal", {
                        leagueId: this.leagueId,
                        externalEmails: this.externalEmails,
                        externalPhones: this.externalPhones
                    });
                    //console.log(response);
                    if (this.inSettings) {
                        this.$emit("members-added", response);
                        this.resetForm();
                        this.loading = false;
                        this.saved = true;
                    } else {
                        window.location.href = `/${this.leagueSlug}`;
                    }
                  this.disabled = false;
                }catch(error){
                    await this.$swal(error.response.data.message);
                    this.loading = false;
                  this.disabled = false;
                }
                
            }else{
                this.formErrors.push("No one to invite!");
              this.disabled = false;
            }
        }
    },
};
</script>
<style>
.invite-tabs
{
    font-size:.8rem;
    
}
.invite-tabs .nav-tabs, .invite-tabs .tab-content{
    max-height: 200px;
    max-width: 100%;
    flex-wrap: nowrap;
}
.invite-tabs .nav-link{
    text-transform: capitalize;
}
.check-block-member-info{
    color: #6c757d; 
    font-size: 14px;
}
</style>
