<template>
    <div>
        <hr />
        <h1 id="sub-leagues" class="h4 font-weight-bold">Sub Leagues</h1>
        <sub-league-list
            :league="parentContext.league"
            :auth-user="parentContext.authUser"
        ></sub-league-list>
    </div>
</template>
<script>
import SubLeagueList from "../../Lists/SubLeagueList";
export default {
    name: "LeagueProfileSubLeagues",
    props: {
        parentContext: {
            type: Object,
            required: true,
        },
    },
}
</script>