<template>
    <b-modal
            id="edit-media-image"
            v-model="open"
            size="lg"
            centered
            hide-footer
            modal-class="edit-media-image"
    >
        <template v-slot:modal-header="{ close }">
            <!-- Emulate built in modal header close button action -->
            <b-button
                    size="sm"
                    variant="black"
                    @click="close()"
                    class="float-right"
            >
                <svg
                        width="42"
                        height="42"
                        viewBox="0 0 42 42"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                            d="M27.6358 12.3066L20.9994 18.9838L14.363 12.3066L12.3057 14.364L18.9828 21.0004L12.3057 27.6368L14.363 29.6941L20.9994 23.017L27.6358 29.6941L29.6932 27.6368L23.016 21.0004L29.6932 14.364L27.6358 12.3066Z"
                            fill="white"
                    />
                    <path
                            d="M20.9998 1.0498C9.99623 1.0498 1.0498 9.99623 1.0498 20.9998C1.0498 32.0034 9.99623 40.9498 20.9998 40.9498C32.0034 40.9498 40.9498 32.0034 40.9498 20.9998C40.9498 9.99623 32.0034 1.0498 20.9998 1.0498ZM20.9998 38.0526C11.5926 38.0526 3.94696 30.4074 3.94696 20.9998C3.94696 11.5922 11.5922 3.94696 20.9998 3.94696C30.4074 3.94696 38.0526 11.5922 38.0526 20.9998C38.0526 30.4074 30.4074 38.0526 20.9998 38.0526Z"
                            fill="white"
                    />
                </svg>
            </b-button>
        </template>
        <b-container class="p-sm-4 pt-0">
            <b-row>
                <b-col class="text-center">
                    <div class="content">
                        <section class="cropper-area">
                            <div class="img-cropper">
                                <vue-cropper
                                        ref="cropper"
                                        :src="$parent.selected_image_media ? $parent.selected_image_media.file : ''"
                                        :zoomable="false"
                                        :movable="false"
                                        :autoCrop="false"
                                />
                            </div>
                            <div class="actions">
                                <b-btn
                                        variant="outline-white"
                                        pill
                                        @click.prevent="rotate(90)"
                                >
                                    <i class="fas fa-redo"></i>
                                </b-btn>
                                <b-btn
                                        variant="outline-white"
                                        pill
                                        @click.prevent="rotate(-90)"
                                >
                                    <i class="fas fa-undo"></i>
                                </b-btn>
                                <b-btn
                                        ref="flipX"
                                        variant="outline-white"
                                        pill
                                        @click.prevent="flipX"
                                >
                                    Flip Horizontal
                                </b-btn>
                                <b-btn
                                        ref="flipY"
                                        variant="outline-white"
                                        pill
                                        @click.prevent="flipY"
                                >
                                    Flip Vertical
                                </b-btn>
                                <b-btn
                                        variant="outline-white"
                                        pill
                                        @click.prevent="reset"
                                >
                                    Reset
                                </b-btn>
                                <b-btn
                                        variant="outline-white"
                                        pill
                                        @click="saveImage()"
                                >
                                    Apply Changes
                                </b-btn>
                            </div>
                        </section>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
    import SubmitButton from "../Buttons/SubmitButton";
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css'

    export default {
        name: "EditMediaImage",
        props: {
            modalOpen: Boolean,
        },
        components: {
            VueCropper,
            SubmitButton,
        },
        data() {
            return {
                imgSrc: '',
                cropedImage: '',
                data: null,
            };
        },
        computed: {
            open: {
                get() {
                    return this.modalOpen;
                },
                set(value) {
                    this.$emit("update:modalOpen", value);
                },
            },
        },
        methods: {
            saveImage() {
                this.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL()
                this.$parent.selected_image_media.file = this.cropedImage
                this.$parent.selected_image_media.edited = true
                this.$parent.editMediaImageOpen = false;
                this.$parent.formHasFiles = this.$parent.mediaItems.length > 0 ? true : false
            },
            flipX() {
                const dom = this.$refs.flipX;
                let scale = dom.getAttribute('data-scale');
                scale = scale ? -scale : -1;
                this.$refs.cropper.scaleX(scale);
                dom.setAttribute('data-scale', scale);
            },
            flipY() {
                const dom = this.$refs.flipY;
                let scale = dom.getAttribute('data-scale');
                scale = scale ? -scale : -1;
                this.$refs.cropper.scaleY(scale);
                dom.setAttribute('data-scale', scale);
            },
            reset() {
                this.$refs.cropper.reset();
            },
            rotate(deg) {
                this.$refs.cropper.rotate(deg);
            },
        },
    };
</script>
<style scoped lang="scss">
    .actions {
        margin: 15px;
    }
</style>
