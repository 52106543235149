<template>
    <b-modal
        id="create-message-modal"
        v-model="open"
        centered
        hide-header
        hide-footer
    >
        <message-form
            :user-id="userId"
            :auth-user-id="authUserId"
            @submit-convo="createChat"
            :skip-is-friends="skipIsFriends"
        ></message-form>
    </b-modal>
</template>

<script>
import MessageForm from "../Forms/MessageForm";
export default {
    name: "CreateChatModal",
    components: { MessageForm },
    props: {
        modalOpen: Boolean,
        authUserId: {
            type: Number,
            required: true,
        },
        userId: {
            type: Number,
            required: true,
        },
        skipIsFriends: {
            type: Boolean,
        }
    },
    computed: {
        open: {
            get() {
                return this.modalOpen;
            },
            set(value) {
                this.$emit("update:modalOpen", value);
            },
        },
    },
    methods: {
        async createChat(form) {
            this.loading = true;
            try {
                const requestData = {
                    ...form,
                    users: [this.userId],
                    skipIsFriends: this.skipIsFriends
                };
                // Attempt to create post
                const response = await axios.post("/inbox/create", requestData);
                this.$bvModal.hide("media-modal");
                this.open = false;
                if (!skipIsFriends) {
                    window.location.href = `/inbox?convo=${response.data.chatId}`;
                }
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped></style>
