<template>
    <div>
        <hr />
        <!-- <media-list
            :league-id="context.league.id"
            :per-page="perPage"
        ></media-list> -->
        <wall-media-list
            v-show="activeItem === 'all'"
            :league-id="context.league.id"
            :listType="'all'"
            :parent-context="context"
            :is-league-profile="true"
        ></wall-media-list>
    </div>
</template>

<script>
// import MediaCard from "../../Cards/MediaCard";
// import MediaList from "../../Lists/MediaList";
import WallMediaList from "../../Lists/WallMediaList";

export default {
    name: "LeagueProfileMedia",
    components: {
        // MediaList,
        // MediaCard,
        WallMediaList,
    },
    props: {
        /* Contextual Object that contains league and authUser (including league roles) */
        parentContext: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            //perPage: 18,
            //currentPage: 1,
            activeItem: "post",
        };
    },
    mounted() {
        setTimeout(() => {
            this.activeItem = "all"; //Activate the spinner
        }, 300);
    },
    computed: {
        context() {
            return {
                profileTab: "Media",
                ...this.parentContext,
            };
        },
    },
};
</script>

<style scoped></style>
