<template>
    <b-modal
        id="welcome-back-modal"
        v-model="open"
        centered
        hide-footer
        :title="'Welcome Back ' + user.firstName + ' ' + user.lastName + '!!'"
    >
        <p>Your account account has been re-enabled.</p>
    </b-modal>
</template>
<script>
export default {
    name: "WelcomeBackModal",
    props: {
        modalOpen: {
            type: Boolean,
            required: true
        },
        user: {
            type: Object,
            required: true
        }
    },
    computed: {
        open: {
            get() {
                return this.modalOpen;
            },
            set(value) {
                this.$emit("update:modalOpen", value);
            },
        },
    }
}
</script>