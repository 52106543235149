<template>
    <div>

        <b-row id="league-list" v-show="notifications.length > 0">
          <b-col cols="6">
            <b-checkbox @change="selectAll" v-model="checkbox">Select All</b-checkbox>
          </b-col>

          <b-col cols="6" style="text-align: right" :class="this.$mq === 'sm' ? 'mb-3 p-0' : 'mb-3' ">
            <button @click="removeItems" class="btn btn-danger btn-sm"><i class="fas fa-trash"></i> Delete selected</button>
          </b-col>

            <b-col cols="12">
                <b-list-group flush>
                    <notification-item
                        v-for="notification in notifications"
                        :key="notification.id"
                        :notification="notification"
                        @remove="removeItem"
                        @updateCheck="updateCheck"
                        @unPin="unPin"
                    ></notification-item>
                </b-list-group>
                <div class="text-center">
                    <b-btn
                        v-show="
                            !loading &&
                            notifications.length < totalNotifications
                        "
                        variant="outline-dark"
                        pill
                        class="btn-padded"
                        @click="getNotifications"
                        >Load More</b-btn
                    >
                </div>
            </b-col>
        </b-row>
        <div
            v-show="!loading && notifications.length === 0"
            class="text-center my-5"
        >
            <span class="h5 text-muted">No Notifications...</span>
        </div>
        <div v-if="loading" class="text-center my-5">
            <b-spinner></b-spinner>
        </div>
    </div>
</template>

<script>
import NotificationItem from "../ListItems/NotificationItem";
export default {
    name: "NotificationsList",
    components: { NotificationItem },
    data() {
        return {
            loading: true,
            perPage: 15,
            currentPage: 1,
            notifications: [],
            totalNotifications: 0,
            checkbox: false,
        };
    },
    mounted() {
        this.getNotifications();
    },
    methods: {
        async getNotifications() {
            this.loading = true;
            const data = {
                per_page: this.perPage,
                page: this.currentPage,
            };

            try {
                const response = await axios.post(`/notifications`, data);

                this.notifications = this.notifications.concat(
                    response.data.notifications
                );
                this.totalNotifications = response.data.notifications_count;
                this.currentPage += 1;
                this.loading = false;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        },

      selectAll() {
        if(this.checkbox) {
          this.notifications = this.notifications.map(obj => {
            obj.checked = false;
            return obj;
          });
        } else {
          this.notifications = this.notifications.map(obj => {
            obj.checked = true;
            return obj;
          });
        }
      },

      updateCheck(notification) {
        let notifications = this.notifications;
        const objIndex = notifications.findIndex(obj => obj.id === notification.id);
        notifications[objIndex] = notification;
        this.notifications = notifications;
      },

      async unPin(notification) {

          try {
            const response = await axios.get('/notifications/unPin?id=' + notification.id);

            notification.pinned = false;
            let notifications = this.notifications;
            const objIndex = notifications.findIndex(obj => obj.id === notification.id);
            notifications[objIndex] = notification;
            this.notifications = notifications;

          } catch (error) {
            await this.$swal(error.response.data.message);
          }

      },

      async removeItems() {
        const notifications = this.notifications.filter(obj => obj.checked === true).map(obj => obj.id);
        const data = {
          notifications: notifications
        };
        if (notifications.length === 0) {
          return;
        }

        try {
          const response = await axios.post('/notifications/deleteBulk', data);
          this.notifications = this.notifications.filter(obj => {
            return obj.checked === false
          });
        } catch (error) {
          await this.$swal(error.response.data.message);
          this.loading = false;
        }
      },

      async removeItem(notification) {
        this.loading = true;
        try {
          const response = await axios.get('/notifications/delete?id=' + notification.id);
          this.notifications = this.notifications.filter(obj => {
            return obj.id !== notification.id;
          });
          this.totalNotifications = this.notifications.length;
          this.loading = false;
        } catch (error) {
          await this.$swal(error.response.data.message);
          this.loading = false;
        }
      },


    },
};
</script>

<style scoped></style>
