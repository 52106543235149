<template>
    <b-list-group-item :class="className" v-if="notification.body !== null">
        <b-row>
          <b-col  col lg="1" md="1" sm="1"  :class="this.$mq === 'sm' ? 'p-0 checkbox-col-sm' : null ">
            <b-checkbox v-model="notification.checked" :class="this.$mq === 'sm' ? 'checkbox-display' : null"  @change="$emit('updateCheck',notification)"></b-checkbox>
            <b-avatar v-if="this.$mq === 'sm'" :size="this.$mq === 'sm'? '30px' : null" badge-variant="danger" :src="notification.image">
                <!--<template #badge><i class="fas fa-video"></i></template>-->
            </b-avatar>
          </b-col>
            <b-col v-if="this.$mq !== 'sm'" col lg="1" md="1" sm="1" :class="this.$mq === 'sm' ? 'p-0 pr-1' : null ">
                <b-avatar :size="this.$mq === 'sm'? '80px' : null" badge-variant="danger" :src="notification.image">
                    <!--<template #badge><i class="fas fa-video"></i></template>-->
                </b-avatar>
            </b-col>
            <b-col col lg="9" md="9" sm="10" :class="this.$mq === 'sm' ? 'p-0' : null ">
                <div :class="this.$mq === 'sm' ? 'notification-content-sm' : null" v-html="notification.body"></div>
                <div style="clear: both; height: 0px; overflow: hidden"></div>
                <small class="text-muted">{{ notificationDate }}</small>
            </b-col>
            <b-col col lg="1" md="1" sm="1" style="text-align:right" :class="this.$mq === 'sm' ? 'p-0 delete-col-sm' : null ">

              <span v-if="notification.pinned" @click="$emit('unPin', notification)"><i class="fas fa-thumbtack"></i></span>

                <span @click="$emit('remove', notification)"
                    ><i class="fas fa-trash"></i
                ></span>
            </b-col>
        </b-row>
    </b-list-group-item>
</template>

<script>
export default {
    name: "NotificationItem",
    props: {
        notification: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            avatarProps: { width: 90, height: 90 },
        };
    },
    computed: {
        notificationDate() {
            return moment(this.notification.date).fromNow();
        },
        className() {
            if (this.notification.pinned) {
                return "pinned mb-2";
            } else {
                // Per Kenyatta, as of 2/23/2021, we no longer want to show a highlight color for
                // new messages.
                return "read mb-2";
            }
        },
    },
};
</script>

<style scoped lang="scss">
.list-group-item {
    //background-color: #dbdbf7;
    //&.read {
    //    background-color: inherit;
    //    opacity: 0.6;
    //}
    &.pinned {
        opacity: 1;
        background: #f8d7da;
    }
}
@media (max-width: 750px) {
    .col-xl,
    .col-xl-auto,
    .col-xl-12,
    .col-xl-11,
    .col-xl-10,
    .col-xl-9,
    .col-xl-8,
    .col-xl-7,
    .col-xl-6,
    .col-xl-5,
    .col-xl-4,
    .col-xl-3,
    .col-xl-2,
    .col-xl-1,
    .col-lg,
    .col-lg-auto,
    .col-lg-12,
    .col-lg-11,
    .col-lg-10,
    .col-lg-9,
    .col-lg-8,
    .col-lg-7,
    .col-lg-6,
    .col-lg-5,
    .col-lg-4,
    .col-lg-3,
    .col-lg-2,
    .col-lg-1,
    .col-md,
    .col-md-auto,
    .col-md-12,
    .col-md-11,
    .col-md-10,
    .col-md-9,
    .col-md-8,
    .col-md-7,
    .col-md-6,
    .col-md-5,
    .col-md-4,
    .col-md-3,
    .col-md-2,
    .col-md-1,
    .col-sm,
    .col-sm-auto,
    .col-sm-12,
    .col-sm-11,
    .col-sm-10,
    .col-sm-9,
    .col-sm-8,
    .col-sm-7,
    .col-sm-6,
    .col-sm-5,
    .col-sm-4,
    .col-sm-3,
    .col-sm-2,
    .col-sm-1,
    .col,
    .col-auto,
    .col-12,
    .col-11,
    .col-10,
    .col-9,
    .col-8,
    .col-7,
    .col-6,
    .col-5,
    .col-4,
    .col-3,
    .col-2,
    .col-1 {
        padding-right: -2px !important;
        padding-left: 3px !important;
    }
    .col {
        flex-basis: 0 !important;
        flex-grow: 1  !important;
        max-width: 100%   !important;
    }
}
.checkbox-display {
    display: inline-block;
}
.checkbox-col-sm {
    flex-grow: .4;
}
.notification-content-sm {
    max-width: 175px;
}
.delete-col-sm {
    flex-grow: 0;
}
</style>
