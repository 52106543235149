<template>
    <div class="d-inline">
        <template v-if="selectedReaction">
            <b-btn
                :ref="generatedId"
                variant="link"
                :size="small ? 'sm' : 'md'"
                :class="reactButtonClass"
                @click="$emit('react', selectedReaction.id)"
            >
                <i v-if="!small" :class="`${selectedReaction.class} fa-fw`"></i>
                {{ selectedReaction.name }}
            </b-btn>
            <b-tooltip :target="() => $refs[generatedId]" triggers="hover" delay="100">
                <div class="d-inline-flex" :style="tooltipStyle">
                        <reaction
                            v-for="reaction in reactions"
                            :key="reaction.id"
                            :reaction="reaction"
                            @react="$emit('react', reaction.id)"
                        >
                        </reaction>
                    </div>
            </b-tooltip>
        </template>
    </div>
</template>

<script>
import Reaction from "./Reaction";
export default {
    name: "ReactionPicker",
    components: { Reaction },
    props: {
        reactions: {
            type: Array,
            required: true,
        },
        reactionId: Number,
        small: {
            type: Boolean,
            default: false,
        },
        parentId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            hasMounted: false
        };
    },
    mounted() {
        this.loading = false;
        this.$nextTick(() => {
            this.hasMounted = true;
        });
    },
    computed: {
        generatedId() {
            return this.small
                ? `comment-${this.parentId}-react`
                : `post-${this.parentId}-react`;
        },
        selectedReaction() {
            if (
                typeof this.reactionId === "undefined" ||
                this.reactionId === null ||
                this.reactionId === 0
            ) {
                return this.reactions[0];
            }
            return this.reactions.find((reaction) => reaction.id === this.reactionId);
        },
        reactButtonClass() {
            if (
                typeof this.reactionId === "undefined" ||
                this.reactionId === null ||
                this.reactionId === 0
            ) {
                return `text-grey`;
            }
            return `text-purple font-weight-bold`;
        },
        tooltipStyle() {
            return {
                width: `${2.75 * this.reactions.length}rem`,
                height: "45px",
            };
        },
    },
    methods: {
        getButtonByRef() {
            return this.$refs["reaction-selector"];
        },
    }
};
</script>

<style scoped>
    .text-purple:hover{
        color:grey !important;
    }
</style>
