<template>
    <b-form @submit.prevent="uploadImage">
        <b-form-row v-if="mediaPreview">
            <b-col v-if="mediaType === 'image'" cols="6" class="mx-auto">
                <b-img :src="mediaPreview" class="img-fluid"></b-img>
            </b-col>
            <b-img-lazy
                v-if="mediaType === 'video' && mediaPreview !== null"
                :src="mediaPreview.src"
                class="img-fluid"
            ></b-img-lazy>
        </b-form-row>
        <b-form-file
            ref="formField"
            v-model="form.file"
            :multiple="multiple"
            placeholder="Choose a file..."
            drop-placeholder="Drop file here..."
            :accept="mediaTypes"
            @change="updatePreview"
            class="my-4"
        ></b-form-file>
        <submit-button
            :loading="loading"
            :disabled="form.file === null"
            :text="buttonText"
            :submit-text="submitText"
        ></submit-button>
    </b-form>
</template>

<script>
import SubmitButton from "../Buttons/SubmitButton";

export default {
    name: "MediaUploadForm",
    components: { SubmitButton },
    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
        leagueId: Number,
        type: {
            type: String,
            required: true,
        },
        buttonText: {
            type: String,
            default: "Submit",
        },
        submitText: {
            type: String,
            default: "Submitting",
        },
        isProductInfo: {
            type: Boolean,
            default: false,
        },
        productId: {
            type: Number,
            default: 0,
        },
        isCoverMedia:{
            type: Number,
            default: null
        },
         description:{
            type: String,
            default: "",
        },
        isDownloadMedia:{
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
            mediaPreview: null,
          fileUpload: null,
            mediaType: null,
            form: {
                file: null,
            },
            chunkSize: 10100462, // Filesize in bytes, 10mb
            chunks: [],
            fileToken: '',
        };
    },
    beforeDestroy(){
        if(this.mediaPreview){
            this.$emit('video-preview-src', this.mediaPreview.src);
        }
    },
    computed: {
        mediaTypes() {
            if (this.type === "all") {
                return "image/*, video/mp4, video/quicktime, video/mp4, video/mov, video/m4v, video/webm";
            }
            if (this.type === "images") {
                return "image/*";
            }
            if (this.type === "videos") {
               
               return "video/mp4, video/quicktime, video/mp4, video/mov, video/m4v, video/webm";
            }
            // ================================
            // Store Allowed File Types:
            // ================================
            // photo ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp']
            // video ['mp4', 'mov', 'm4v', 'webm', 'mpg', 'wmv', 'mkv', 'ogv', 'matroska', 'asf', 'mpeg']
            // excel (csv, xls, xlsx)
            // powerpoint (ppt, pptx)
            // word (doc, docx, rtf, txt)
            // pdf (pdf)
            if (this.type === "store") {
                return "image/jpg, image/jpeg, image/png, image/gif, image/svg, image/webp, video/mp4, video/mov, video/m4v, video/webm, video/mpg, video/wmv, video/mkv, video/agv, video/matroska, video/asf, video/mpeg, xls/*, .ppt, .pptx, .doc, .docx, .rtf, .txt, .pdf,";
            }
        },
    },
    methods: {
        async updatePreview(event) {
            const file = event.target.files[0];
            const reader = new FileReader();
            this.fileUpload = file;

            this.mediaType = file.type.substring(0, 5);
            let _this = this;

            this.$emit('media-type', this.mediaType);

            reader.readAsDataURL(file);
            reader.onload = async function(e) {
                if (_this.mediaType === 'video') {
                    _this.mediaPreview = null;
                    _this.loading = true;
                    getThumbnail(file);
                } else {
                    console.log("Not a video!");
                    this.mediaPreview = e.target.result;
                }

            };



            // Creates a thumbnail for videos to use in the preview (MediaCard)
            async function getThumbnail(file) {
                // Keep track of frame drawn so we only draw the first frame. This is an issue because we may not be
                // able to stop playback during the first frame.
                let hasDrawn = false;
                let thumbnail;
                const url = URL.createObjectURL(file);
                let thumbnailCanvas = document.createElement('canvas');
                let video = document.createElement('video');
                video.autoplay = true;
                video.muted = true;
                video.src = url;
                video.className += "video-js";
                video.preload = "auto";
                let ctx = thumbnailCanvas.getContext('2d');
                
                async function drawFrame(e) {
                    video.pause();
                    if (!hasDrawn) {
                        video.pause();
                        ctx.drawImage(video, 0, 0);
                        thumbnailCanvas.toBlob(setThumb, 'image/jpeg');
                        hasDrawn = true;
                    }

                }

                async function revokeURL(e) {
                    URL.revokeObjectURL(video.src);
                }

                async function setThumb(blob) {
                    thumbnail = new Image();
                    thumbnail.onload = revokeURL;
                    thumbnail.src = URL.createObjectURL(blob);
                    _this.mediaPreview = thumbnail;
                    _this.loading = false;
                    
                }


                video.addEventListener('loadedmetadata', function () {
                    thumbnailCanvas.width = video.videoWidth;
                    thumbnailCanvas.height = video.videoHeight;
                    video.play();
                });

                video.addEventListener('timeupdate', drawFrame, false);
            }
        },
        shouldBeChunked(file) {
            // To be chunked, a file should be greater than our desired chunk size
            return file.size / this.chunkSize > 1;
        },
        createChunks(file) {
            this.chunks = [];
            let chunks = Math.ceil(file.size / this.chunkSize);

            for (let i = 0; i < chunks; i++) {
                this.chunks.push(file.slice(i * this.chunkSize, Math.min(i * this.chunkSize + this.chunkSize, file.size), file.type));
            }

            console.log("Created " + this.chunks.length + " out of " + chunks + " expected chunks");
        },
        async createTicket(file) {
            // When uploads need to be chunked, we need to request permission to store the file first
            // We also send the first chunk with the request, as well as some file metadata
            // We will try this (and all requests) a total of 5 times, with each failure increasing the delay 1 second.

            const firstChunkData = new FormData();
            firstChunkData.set('data', this.chunks[0], '1');

            if (this.leagueId) {
                firstChunkData.append("leagueId", this.leagueId);
            }

            let ticketResponse = null;
            let tries = 0;
            let requestSuccess = false;
            while (tries < 5 || !requestSuccess) {
                try {
                    ticketResponse = await axios({
                        url: "/media/getTicket",
                        method: "POST",
                        headers: {
                            'X-Content-Name': file.name,
                            'X-Content-Total-Length': file.size,
                            'Content-Type': 'application/octet-stream'
                        },
                        data: firstChunkData,
                    });

                    if (ticketResponse.data.success) {
                        requestSuccess = true;
                        this.fileToken = ticketResponse.data.token;
                        return true;
                    } else {
                        // We treat this as a success so we can break the loop and display an error.
                        // The loop itself is for network/server errors.
                        requestSuccess = true;
                        await this.$swal(ticketResponse.data.message);
                        this.loading = false;
                        return false;
                    }
                } catch (error) {
                    tries++;
                    if (tries === 5) {
                        // At 5 tries, display the server error and quit trying to process.
                        await this.$swal(error.response.data.message);
                        this.loading = false;
                        return false;
                    }
                }
            }
        },
        async sendChunk(index) {
            const chunkData = new FormData();
            chunkData.set('data', this.chunks[index], index+1);
            let tries = 0;
            let requestSuccess = false;
            while (tries < 5 && !requestSuccess) {
                try {
                    const uploadChunkResponse = await axios({
                        url: "/media/chunk",
                        method: "POST",
                        headers: {
                            'X-Media-Token': this.fileToken,
                            'X-Media-Chunk-Id': index+1,
                            'X-Content-Name': this.form.file.name,
                            'X-Content-Total-Length': this.form.file.size,
                            'Content-Type': 'application/octet-stream'
                        },
                        data: chunkData,
                    });

                    if (uploadChunkResponse.data.success) {
                        requestSuccess = true;
                        return true;
                    } else {
                        requestSuccess = true;
                        await this.$swal(uploadChunkResponse.data.message);
                        this.loading = false;
                        return false;
                    }
                } catch (error) {
                    tries++;
                    if (tries === 5) {
                        // At 5 tries, display the server error and quit trying to process.
                        await this.$swal(error.response.data.message);
                        this.loading = false;
                        return false;
                    }
                }
            }
            return false;
        },
        async closeTicket() {
            let tries = 0;
            let requestSuccess = false;
            while (tries < 5 && !requestSuccess) {
                try {
                    const closeTicketResponse = await axios({
                        url: "/media/closeTicket",
                        method: "POST",
                        headers: {
                            'X-Media-Token': this.fileToken,
                        },
                    });

                    if (closeTicketResponse.data.success) {
                        requestSuccess = true;
                        return closeTicketResponse.data.media;
                    } else {
                        requestSuccess = true;
                        await this.$swal(closeTicketResponse.data.message);
                        this.loading = false;
                        return false;
                    }
                } catch (error) {
                    tries++;
                    if (tries === 5) {
                        await this.$swal(error.response.data.message);
                        this.loading = false;
                        return false;
                    }

                }
            }
            return false;
        },
        async finalizeMedia(mediaId) {
            let tries = 0;
            let requestSuccess = false;
            while (tries < 5 && !requestSuccess) {
                try {
                    const finalizeMediaRequest = await axios({
                        url: "/media/finalize",
                        method: "POST",
                        data: {
                            mediaId: mediaId,
                        },
                        headers: {
                            'X-Media-Token': this.fileToken,
                        },
                    });

                    if (finalizeMediaRequest.data.success) {
                        requestSuccess = true;
                        return finalizeMediaRequest.data.media;
                    } else {
                        requestSuccess = true;
                        await this.$swal(finalizeMediaRequest.data.message);
                        this.loading = false;
                        return false;
                    }
                } catch (error) {
                    tries++;
                    if (tries === 5) {
                        await this.$swal(error.response.data.message);
                        this.loading = false;
                        return false;
                    }
                }
            }
            return false;
        },
        async uploadImage() {
            this.loading = true;
            if (this.shouldBeChunked(this.form.file)) {
                console.log("should be chunked!");
                this.createChunks(this.form.file);

                if (await this.createTicket(this.form.file)) {
                    let allChunksUploaded = true;
                    for(let i = 1; i < this.chunks.length; i++) {
                        const chunkResult = await this.sendChunk(i);
                        if (chunkResult === false) {
                            console.log("Chunk failed to upload");
                            allChunksUploaded = false;
                            break;
                        }
                    }

                    // Once we have uploaded all the chunks, close the ticket and convert to media
                    if (allChunksUploaded) {
                        console.log("All chunks uploaded");
                        let closeTicketResponse = await this.closeTicket();
                        if (closeTicketResponse) {
                            // Upon success, we need to wait for everything to finish processing
                            let finalizeResponse = await this.finalizeMedia(closeTicketResponse.id);

                            if (finalizeResponse) {
                                console.log("Done finalizing. ", finalizeResponse);
                                this.$emit("media-uploaded", finalizeResponse);
                            }

                        } else {
                            console.log("No ticket response");
                        }

                        // If there are errors, the closeTicket method itself SHOULD handle them properly.
                    }
                }
            } else {
                /** Use formData since we are sending file data **/
                const data = new FormData();
                data.append("file", this.form.file);
                //this.$emit('whole-file', this.form.file)
                if (this.leagueId) {
                    data.append("leagueId", this.leagueId);
                }

                try {

                  let productId = this.productId;
                  let formData = new FormData();
                  formData.append('productId', productId.toString());
                  formData.append('description','This is a test description');
                  formData.append('file', this.form.file);


                  const response = await axios.post('/store/addFile', formData, {
                    headers: {
                      "Content-Type": "multipart/form-data"
                    },
                  });
                  console.log(this.form.file);
                } catch (error) {
                  console.log("There was an error: ", error);
                  // If there is an error for some reason, show alert
                    //await this.$swal(error.response.data.message);
                    //await this.$swal(error.responseFile.data);
                    //this.loading = false;
                }


            }
        },
    },
};
</script>

<style>
#fileInput {
    display: none;
}
</style>
