<template>
    <stripe-payment-form
        :dark="dark"
        :button-text="whiteTheme ? 'Subscribe' : 'Complete Registration'"
        @stripe-submitted="submitForm"
        :white-theme="whiteTheme"
        :is-plan-selected="isPlanSelected"
    >
    </stripe-payment-form>
</template>

<script>
import InvalidFeedback from "../FormComponents/InvalidFeedback";
import StripePaymentForm from "../PaymentForms/StripePaymentForm";

export default {
    name: "UserPlanPaymentForm",
    components: { StripePaymentForm, InvalidFeedback },
    props: {
        plan: {
            type: Object,
            required: true,
        },
        whiteTheme: {
            type: Boolean,
            required: false,
        },
        leagueId: {
            type: Number,
            default: 0,
        },
        isPlanSelected: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dark: true,
        };
    },
    mounted() {
        this.switchTheme();
    },
    computed: {
        switchEndPoint() {
            return this.whiteTheme
                ? "/leagues/subscribeAndJoin"
                : "/register/subscribe";
        },
    },
    methods: {
        async submitForm(token) {
            try {
              let formData = {};

              if( this.whiteTheme ) {
                formData = {
                  token: token.id,
                  plan: this.plan.plans[0].id,
                  leagueId: this.leagueId,
                };
              } else {
                formData = {
                  token: token.id,
                  plan: this.plan.slug,
                }
              }


              const response = await axios.post(this.switchEndPoint, formData);

              if (!this.whiteTheme) {
                    // Redirect once submitted
                    window.location =
                        sessionStorage.getItem("redirectToLeague") ||
                        "/welcome";
                    sessionStorage.removeItem("redirectToLeague");
                } else {
                    this.$emit("last-step", 2);
                }
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
        switchTheme() {
            if (this.whiteTheme) {
                this.dark = false;
            }
        },
    },
};
</script>

<style scoped></style>
