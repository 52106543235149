<template>
    <div v-if="showAlert" class="app-alerts">
        <b-container>
            <h5 class="my-3">{{ alert.title }}</h5>
            <p>{{ alert.content }}</p>
            <div class="text-center">
                <b-btn
                    variant="link"
                    class="text-white"
                    @click="alertHidden = true"
                    >Close</b-btn
                >
            </div>
        </b-container>
    </div>
</template>

<script>
export default {
    name: "AppAlert",
    props: {
        alert: {
            type: Object,
        },
    },
    data() {
        return {
            alertHidden: false,
        };
    },
    computed: {
        showAlert() {
            return this.alert && !this.alertHidden;
        },
    },
};
</script>

<style scoped>
.app-alerts {
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 1rem;
    width: 100%;
}
</style>
