<template>
    <b-modal
        id="delete-post-modal"
        v-model="open"
        centered
        hide-footer
        hide-header
    >
        <b-container class="p-4">
            <h3 class="font-weight-bold mb-4">
                {{message}}
            </h3>
            <b-btn
                variant="purple"
                block
                pill
                @click="deletePost"
                :disabled="loading"
                class="mb-3"
            >
                <i v-show="loading" class="fas fa-spinner fa-pulse"></i>
                <span v-show="!loading">Yes</span>
            </b-btn>
            <b-btn
                variant="outline-purple-purple"
                block
                pill
                @click="$bvModal.hide('delete-post-modal')"
            >
                No
            </b-btn>
        </b-container>
    </b-modal>
</template>

<script>
export default {
    name: "DeletePostModal",
    props: {
        modalOpen: {
            type: Boolean,
        },
        leagueId: Number,
        postId: Number,
        message: {
            type: String,
            default: "Are you sure you want to delete this Post?"
        }
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        open: {
            get() {
                return this.modalOpen;
            },
            set(value) {
                this.$emit("update:modalOpen", value);
            },
        },
    },
    methods: {
        async deletePost() {
            this.loading = true;
            try {
                const response = await axios.post("/leagues/posts/delete", {
                    leagueId: this.leagueId,
                    postId: this.postId,
                });
                this.$emit("post-deleted", this.postId);
                this.$bvModal.hide("delete-post-modal");
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        },
    },
};
</script>
