var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{staticStyle:{"border-radius":"20px"},attrs:{"id":"store-product-details-modal","size":"xl-center","centered":"","hide-header":"","hide-footer":""},model:{value:(_vm.storeProductDetailsModalIsOpenComputed),callback:function ($$v) {_vm.storeProductDetailsModalIsOpenComputed=$$v},expression:"storeProductDetailsModalIsOpenComputed"}},[(this.$mq === 'sm')?_c('div',{staticClass:"close-modal-button",attrs:{"data-dismiss":"modal"},on:{"click":function($event){return _vm.$emit('close-modal', false)}}},[_vm._v("\n            Close\n        ")]):_vm._e(),_vm._v(" "),_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-row',[_c('b-col',{staticClass:"pl-4",attrs:{"sm":"5"}},[_c('b-row',[(this.$mq != 'sm')?_c('b-col',{staticClass:"px-0 px-md-2 pt-3 pt-md-0",attrs:{"sm":"2"}},_vm._l((_vm.productMedia),function(item,index){return _c('img',{key:index,staticClass:"cursor-pointer",staticStyle:{"width":"46px","height":"39px"},style:(_vm.smallImagePreview),attrs:{"src":item.type === 'Video'
                                                ? '/images/VideoThumbnailSmall.jpg'
                                                : item.location,"width":"46","height":"39","alt":""},on:{"click":function($event){return _vm.clickToSwitchImage(index)}}})}),0):_vm._e(),_vm._v(" "),_c('b-col',{staticClass:"pl-0 pt-3 pt-md-0",attrs:{"sm":"10"}},[(_vm.videoPreview.toggle)?_c('div',[_c('video',{ref:"videoPlayer",staticClass:"video-js vjs-fluid",attrs:{"controls":""}},[_c('source',{attrs:{"src":_vm.videoPreview.location,"type":_vm.videoMimeType}})])]):_c('b-avatar',{staticStyle:{"color":"black","width":"100%","margin-left":"10px"},attrs:{"square":"","variant":"grey","src":_vm.switchImage
                                                ? _vm.switchImage.location
                                                : _vm.product.media &&
                                                  typeof _vm.product.media[0]
                                                      .location !==
                                                      'undefined'
                                                ? _vm.product.media[0].location
                                                : '',"size":298}})],1),_vm._v(" "),(this.$mq === 'sm')?_c('b-col',{staticClass:"px-0 px-md-2 pt-3 pt-md-0",attrs:{"sm":"2"}},_vm._l((_vm.product.media),function(item,index){return _c('img',{key:index,staticClass:"cursor-pointer",staticStyle:{"width":"46px","height":"39px"},style:(_vm.smallImagePreview),attrs:{"src":item.type === 'Video'
                                                ? '/images/VideoThumbnailSmall.jpg'
                                                : item.location,"width":"46","height":"39","alt":""},on:{"click":function($event){return _vm.clickToSwitchImage(index)}}})}),0):_vm._e()],1),_vm._v(" "),(this.$mq != 'sm')?_c('b-row',{staticStyle:{"height":"306px","overflow":"auto","overflow-wrap":"break-word"}},[_c('b-col',{attrs:{"sm":"12"}},[_c('header',[_c('div',{staticClass:"font-weight-bolder mb-3 mt-4"},[(
                                                    _vm.product.reviews
                                                        .length >= 1
                                                )?_c('span',[_vm._v("Reviews")]):_vm._e(),_vm._v(" "),(
                                                    _vm.product.reviews
                                                        .length >= 1
                                                )?_c('a',{staticClass:"text-sm font-weight-normal pl-2 text-decoration-underline",attrs:{"href":"#"},on:{"click":function($event){_vm.tabIndex++}}},[_vm._v("\n                                                View All\n                                            ")]):_vm._e(),_vm._v(" "),(false)?_c('a',{staticClass:"text-sm font-weight-normal pl-2 text-decoration-underline",attrs:{"href":"#"},on:{"click":function($event){_vm.writeReview.toggle = !_vm.writeReview.toggle}}},[_vm._v("\n                                                Write Review\n                                            ")]):_vm._e()])]),_vm._v(" "),_vm._l((_vm.product.reviews),function(review){return _c('b-row',{key:review.id},[_c('b-col',{staticClass:"pr-0",attrs:{"sm":"2"}},[_c('b-avatar',{staticClass:"profile-avatar",staticStyle:{"color":"black"},attrs:{"variant":"grey","src":review.user.avatar.file,"size":41}})],1),_vm._v(" "),_c('b-col',{staticClass:"pl-0",attrs:{"sm":"10"}},[(review.stars)?_c('span',{staticClass:"pr-2"},_vm._l((review.stars),function(index){return _c('i',{key:index,staticClass:"fas fa-star text-orange text-xs"})}),0):_vm._e(),_vm._v(" "),_c('span',{staticClass:"text-black text-sm"},[_vm._v(_vm._s(((review.user.firstName) + " " + (review.user.lastName))))]),_vm._v(" "),_c('span',{staticClass:"text-grey text-xs"},[_vm._v(_vm._s(_vm.currentDate(
                                                        review.created_at
                                                    )))])])],1)})],2)],1):_vm._e()],1),_vm._v(" "),(this.$mq != 'sm')?_c('div',{staticStyle:{"border-left":"0.1rem solid #e7e7e7","height":"608px"}}):_vm._e(),_vm._v(" "),_c('b-col',{staticClass:"mx-sm-0 mx-md-4",attrs:{"sm":"6","align-self":"stretch"}},[_c('header',[_c('b-row',[_c('b-col',{staticClass:"col"},[_c('div',{staticClass:"px-3 py-1 mb-3 mr-4 mt-2 mt-md-0 text-capitalize text-sm bg-light-blue text-dark-blue font-weight-bold",staticStyle:{"z-index":"99","border-radius":"4px"}},[(_vm.product.type == '')?_c('span',[_vm._v("Digital")]):_vm._e(),_vm._v(" "),_c('span',{attrs:{"else":""}},[_vm._v(_vm._s(_vm.product.type))])])]),_vm._v(" "),(_vm.isOwner)?_c('b-col',{staticClass:"col"},[_c('ul',[(_vm.isOwner)?_c('li',{staticClass:"list-inline-item"},[_c('a',{staticClass:"btn-link",attrs:{"href":"#"},on:{"click":function($event){return _vm.setProductStatus(
                                                            $event,
                                                            2
                                                        )}}},[_vm._v("Delete")])]):_vm._e(),_vm._v(" "),_c('li',{staticClass:"list-inline-item"},[_c('a',{staticClass:"btn-link",attrs:{"href":"#"},on:{"click":function($event){return _vm.setProductStatus(
                                                            $event,
                                                            _vm.product.status
                                                                ? 0
                                                                : 1
                                                        )}}},[_vm._v(_vm._s(_vm.product.status === 1
                                                            ? "Unpublish"
                                                            : "  Publish"))])]),_vm._v(" "),_c('li',{staticClass:"list-inline-item"},[_c('a',{staticClass:"btn-link",attrs:{"href":"#"},on:{"click":function($event){return _vm.openEditProductModal(
                                                            $event,
                                                            _vm.product
                                                        )}}},[_vm._v("Edit")])])])]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"text-2xl font-weight-bolder",staticStyle:{"overflow-wrap":"break-word"}},[_vm._v("\n                                    "+_vm._s(_vm.product.name)+"\n                                ")]),_vm._v(" "),(_vm.product.reviews.length >= 1)?_c('div',{staticClass:"mb-3 text-xs"},[_c('span',{staticClass:"font-weight-bold text-orange text-sm"},[_vm._v(_vm._s(_vm.averageReview))]),_vm._v(" "),_c('span',{staticClass:"pr-2"},_vm._l((_vm.averageReview),function(index){return _c('i',{key:index,staticClass:"fas fa-star text-orange text-2xs"})}),0),_vm._v(" "),_c('span',{staticClass:"text-grey"},[_vm._v("(\n                                        "+_vm._s(_vm.product.reviews.length)+"\n                                        )")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"text-2xl text-capitalize text-base font-weight-normal mb-4"},[_c('span',{staticClass:"text-dark-red mr-2"},[_vm._v("\n                                        "+_vm._s(parseFloat(_vm.product.price) === 0
                                                ? "Free"
                                                : "$" + _vm.product.price)+"\n                                    ")]),_vm._v(" "),_c('span',{staticClass:"text-grey text-decoration-line-through"},[_vm._v("\n                                        "+_vm._s(!_vm.product.msrp
                                                ? ""
                                                : "$" + _vm.product.msrp)+"\n                                    ")])])],1),_vm._v(" "),_c('main',[_c('p',{staticClass:"text-xs leading-6"},[_vm._v("\n                                    "+_vm._s(_vm.product.description)+"\n                                ")]),_vm._v(" "),(!_vm.purchaseComplete && !_vm.isOwner)?_c('b-col',{staticClass:"text-center mt-4"},[_c('b-button',{staticClass:"w-100",attrs:{"pill":"","disabled":_vm.isOwner,"variant":"red"},on:{"click":_vm.checkPaymentMethod}},[(
                                                !_vm.purchaseComplete &&
                                                !_vm.processingPurchase
                                            )?_c('span',[_vm._v("Buy Now")]):_vm._e(),_vm._v(" "),(
                                                !_vm.purchaseComplete &&
                                                _vm.processingPurchase
                                            )?_c('div',{staticClass:"spinner-border spinner-border-sm text-light",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])]):_vm._e()]),_vm._v(" "),(_vm.isOwner)?_c('div',{staticClass:"mt-1"},[_vm._v("\n                                        Purchasing your own products is not\n                                        currently enabled.\n                                    ")]):_vm._e()],1):(!_vm.isOwner)?_c('b-col',{staticClass:"text-center mt-4"},[_c('b-button',{staticClass:"w-100 text-dark-grey cursor-not-allowed",attrs:{"pill":"","variant":"light-red","disabled":""},on:{"click":function($event){_vm.purchaseComplete = !_vm.purchaseComplete}}},[_vm._v("\n                                        Purchased!\n                                    ")])],1):_vm._e(),_vm._v(" "),(_vm.isOwner)?_c('div',{staticClass:"mt-2"},[_c('a',{attrs:{"target":"_blank"},on:{"click":function($event){return _vm.testProductDownload($event)}}},[_c('b-button',{attrs:{"pill":"","block":"","variant":"purple","type":"button"}},[_vm._v("Test Product\n                                            Download")])],1)]):_vm._e()],1)])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }