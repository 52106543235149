<template>
    <div>
        <b-form @submit.prevent="submitForm">
            <league-profile-header
                small
                editable
                :league-name="form.name"
                :banner-image="bannerImage"
                :profile-photo="profileImage"
                @editBanner="openMediaModal('banner')"
                @editProfile="openMediaModal('profile')"
            ></league-profile-header>
            <b-form-text class="mb-5 mt-md-n3 text-center">
                Please Choose a Banner and Profile Photo
                <div v-if="successMessage" class="text-sm text-red font-weight-bold text-center mb">
                    Click on submit button below to save.
                </div>
            </b-form-text>
            <media-modal-inline
                v-if="toggleMediaModal"
                :modal-open.sync="showMediaModal"
                :user-id="authUser.id"
                type="all"
                @media-chosen="setMedia"
                :media-chosen="mediaKey"
                :open-input-file="openInputFile"
                :league-id="league ? league.id : null"
            ></media-modal-inline>
            <b-form-group
                :label="`What would you like to name your ${subLeague}league?`"
                label-for="name-input"
            >
                <b-form-input
                    id="name-input"
                    type="text"
                    v-model.trim="form.name"
                    :state="validateInput('name')"
                    @change="$v.form.name.$touch()"
                ></b-form-input>
                <invalid-feedback :errors="nameErrors"></invalid-feedback>
            </b-form-group>
            <b-form-group
                :label="`Set your ${subLeague}League's URL`"
                label-for="slug-input"
                :description="baseUrlText"
            >
                <b-input-group :prepend="baseUrlText">
                    <b-form-input
                        id="slug-input"
                        type="text"
                        v-model.trim="form.slug"
                        :state="validateInput('slug')"
                        @change="$v.form.slug.$touch()"
                    ></b-form-input>
                </b-input-group>
                <invalid-feedback :errors="slugErrors"></invalid-feedback>
            </b-form-group>
            <b-form-group
                :label="`Provide a description for your ${subLeague}league`"
                label-for="description-input"
            >
                <b-form-textarea
                    id="description-input"
                    rows="4"
                    v-model.trim="form.description"
                    :state="validateInput('description')"
                    @change="$v.form.description.$touch()"
                ></b-form-textarea>
                <invalid-feedback
                    :errors="descriptionErrors"
                ></invalid-feedback>
            </b-form-group>
            <b-form-group :label="`${subLeague}League Category`" label-for="category-input">
                <v-select
                    label="category"
                    placeholder="Select or Add a Category"
                    taggable
                    v-model="form.category"
                    :options="categories"
                    :reduce="(category) => category.id"
                    :create-option="(category) => ({ category: category })"
                    @search="getCategories"
                    @option:created="createCategory"
                    :class="
                        validateInput('category') === false
                            ? 'v-select-error'
                            : null
                    "
                >
                </v-select>
                <b-form-text v-show="createdCategory"
                >This category is not currently available, it will be
                    submitted for approval once you submit the
                    form.</b-form-text
                >
                <invalid-feedback :errors="categoryErrors"></invalid-feedback>
            </b-form-group>
            <b-form-group
                :label="`${subLeague}League Tags`"
                label-for="tags-input"
                description="Use #hastags #seperated #by #space"
                class="mb-5"
            >
                <b-form-input
                    id="tags-input"
                    type="text"
                    v-model.trim="form.tags"
                ></b-form-input>
            </b-form-group>
            <submit-button
                :loading="loading"
                :text="submitButtonText"
            ></submit-button>
            <b-form-text
                v-show="saved"
                text-variant="success"
                class="h6 text-center mt-3"
            >{{subLeague}}League Saved!</b-form-text
            >
        </b-form>
        <media-modal
            :modal-open.sync="showMediaModal"
            :league-id="league ? league.id : null"
            :user-id="authUser.id"
            type="all"
            @media-chosen="setMedia"
        ></media-modal>
    </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import InvalidFeedback from "../FormComponents/InvalidFeedback";
import LeagueProfileHeader from "../../Headers/LeagueProfileHeader";
import MediaModal from "../../Modals/MediaModal";
import SubmitButton from "../../Buttons/SubmitButton";
import MediaModalInline from "../../Modals/MediaModalInline";

export default {
    name: "LeagueInfoForm",
    components: {
        SubmitButton,
        MediaModal,
        LeagueProfileHeader,
        InvalidFeedback,
        MediaModalInline
    },
    props: {
        authUser: {
            type: Object,
            required: true,
        },
        league: {
            type: Object,
            required: false,
        },
        initialCategories: {
            type: Array,
            required: true,
        },
        parentLeague: {
            type: Object
        }
    },
    data() {
        return {
          successMessage: false,
          toggleMediaModal: false,
            loading: false,
            saved: false,
            showMediaModal: false,
            categories: [],
            createdCategory: false,
            mediaKey: null, // Needed to differentiate between photo uploads
            form: {
                name: "",
                slug: "",
                description: "",
                profile: null,
                banner: null,
                category: null,
                tags: "",
            },
            bannerImage: null,
            profileImage: null,
            slugServerError: null,
            lastCheckedSlug: "",
            successMessage: false,
            toggleMediaModal: false
        };
    },
    validations: {
        form: {
            name: { required },
            slug: {
                required,
                minLength: minLength(3),
                async isAvailable(value) {
                    // if this is a subleague concat the parent league's id and the delimiter plid (see window.plid) plus the given slug
                    let slug = this.parentLeague ? this.parentLeague.id + plid + value : value;
                    const available = await this.checkSlug(slug);
                    return available;
                },
            },
            description: { required },
            category: { required },
        },
    },
    created() {
        // Prevent hitting the server too many times on search
        this.getCategories = _.debounce(this.getCategories, 250);
    },
    mounted() {
        this.categories = this.initialCategories;
        if (this.league) {
            this.setForm();
        }
    },
    computed: {
        submitButtonText() {
            return this.league ? "Submit" : "Next";
        },
        /* Validation Errors */
        nameErrors() {
            const errors = [];
            if (!this.$v.form.name.$dirty) return errors;
            !this.$v.form.name.required && errors.push("Name is required");
            return errors;
        },
        slugErrors() {
            const errors = [];
            if (!this.$v.form.slug.$dirty) return errors;
            !this.$v.form.slug.required && errors.push("URL is required");
            !this.$v.form.slug.minLength &&
            errors.push("URL must be at least 3 characters long");
            !this.$v.form.slug.isAvailable &&
            errors.push(this.slugServerError);
            return errors;
        },
        descriptionErrors() {
            const errors = [];
            if (!this.$v.form.description.$dirty) return errors;
            !this.$v.form.description.required &&
            errors.push("Description is required");
            return errors;
        },
        categoryErrors() {
            const errors = [];
            if (!this.$v.form.category.$dirty) return errors;
            !this.$v.form.category.required &&
            errors.push("Category is required");
            return errors;
        },
        baseUrlText() {
            let baseUrl = "www.theinnerleague.com/";
            return baseUrl + this.getParentSlug();
        },
        subLeague() {
            return this.parentLeague ? 'Sub ' : '';
        },
    },
    methods: {
        async getCategories(search, loading) {
            loading(true);
            const response = await axios.post(`/leagues/categories/search`, {
                search: search,
            });
            this.categories = response.data.categories;
            loading(false);
        },
        async createCategory(newCategory) {
            const response = await axios.post(
                `/leagues/categories/create`,
                newCategory
            );
            this.createdCategory = true;
            this.form.category = response.data.category.id;
            setTimeout(() => {
                this.categories.push(response.data.category);
            }, 500);
        },
        setForm() {
            this.form.name = this.league.name;
            this.form.slug = this.isSubLeague() ? this.league.slug.replace(this.getParentSlug(), '') : this.league.slug;
            this.form.description = this.league.description;
            this.form.tags = this.league.tags;
            this.form.category = this.league.category_id;
            this.form.banner = this.league.banner
                ? this.league.banner.id
                : null;
            this.form.profile = this.league.photo ? this.league.photo.id : null;

            this.bannerImage = this.league.banner
                ? this.league.banner.file
                : null;
            this.profileImage = this.league.photo
                ? this.league.photo.file
                : null;

            if (this.league.leagueCategory) {
                if (
                    !this.categories.some(
                        (category) =>
                            category.id === this.league.leagueCategory.id
                    )
                ) {
                    this.categories.push(this.league.leagueCategory);
                }
            }
        },
        validateInput(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        openMediaModal(formKey) {
            this.mediaKey = formKey;
            this.showMediaModal = true;
        },
        setMedia(media) {
            if (this.mediaKey === "banner") {
                this.form.banner = media.id;
                this.bannerImage = media.file;
            } else {
                this.form.profile = media.id;
                this.profileImage = media.file;
            }
        },
        async checkSlug(slug) {
            // Prevent infinite loop request

            // this will only happen on edit sub league
            if (this.isSubLeague()) {
                // check the league's slug vs this.form
                let subLeagueSlug = this.getParentSlug() + this.form.slug;
                if (this.league?.slug === subLeagueSlug) {
                    return true;
                }
            }

            if (this.league?.slug === this.form.slug || slug === this.lastCheckedSlug) {
                return true;
            }

            this.slugServerError = null;
            this.lastCheckedSlug = slug;

            try {

                console.log("Creating a new request");
                this.slugRequest = await axios.post("/leagues/checkSlug", {
                    slug: slug,
                });
                return this.slugRequest.data.success;
            } catch (error) {
                console.log(error.response.data.message);
                this.slugServerError = error.response.data.message.replace('slug', 'league URL');

            }
            return false;
        },
        async submitForm() {
            this.$v.form.$touch();

            if (!this.$v.form.$anyError) {
                this.loading = true;

                // if parentLeague is provided set the parentLeagueId of the subLeague.
                if (this.parentLeague) {
                    this.form.parentLeagueId = this.parentLeague.id;
                }

                if (this.league) {
                    await this.updateLeague();
                } else {
                    await this.createLeague();
                }
            }
        },
        async createLeague() {
            try {
                // Attempt to create league
                const response = await axios.post("/leagues/create", this.form);
                // Redirect to the next step
                window.location = "/leagues/subscription";
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        },
        async updateLeague() {
            try {
                // Attempt to create league
                const response = await axios.post(
                    `/leagues/edit/${this.league.id}`,
                    this.form
                );
                this.loading = false;
                this.saved = true;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        },
        getParentSlug() {
            let parentSlug = this.parentLeague ? this.parentLeague.slug + "/" : "";
            return parentSlug;
        },
        isSubLeague() {
            return this.league?.parent_league_id ? true : false;
        }
    },
};
</script>