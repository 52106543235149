<template>
    <div>
        <b-form @submit.stop.prevent="submitForm">
            <b-form-group label="Name" label-for="name-input">
                <v-select
                    label="name"
                    taggable
                    v-model="form.user"
                    :options="users"
                    @search="getUsers"
                    :create-option="(name) => ({ name: name, email: null })"
                    :class="
                        validateInput('user') === false
                            ? 'v-select-error'
                            : null
                    "
                >
                    <template #option="{ id, name, avatar }">
                        <b-media class="align-items-center">
                            <template v-slot:aside>
                                <b-avatar
                                    v-if="id > 0"
                                    :src="avatar"
                                    variant="grey"
                                ></b-avatar>
                            </template>
                            <span>{{ name }}</span>
                        </b-media>
                    </template>
                </v-select>
                <invalid-feedback :errors="nameErrors"></invalid-feedback>
            </b-form-group>
            <b-form-group
                v-show="showEmailInput"
                label="Please provide the user's email address"
                label-for="email-input"
            >
                <b-form-input
                    id="email-input"
                    type="text"
                    v-model.trim="form.email"
                    :state="validateInput('email')"
                    @change="$v.form.email.$touch()"
                ></b-form-input>
                <invalid-feedback :errors="emailErrors"></invalid-feedback>
            </b-form-group>
            <div class="text-center">
                <b-btn type="submit" pill variant="link" class="text-purple"
                    >+ Add Subscriber</b-btn
                >
            </div>
        </b-form>
    </div>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import InvalidFeedback from "../FormComponents/InvalidFeedback";

export default {
    name: "UserLookupForm",
    components: { InvalidFeedback },
    data() {
        return {
            users: [],
            form: {
                user: null,
                email: "",
            },
        };
    },
    validations: {
        form: {
            user: { required },
            email: {
                email: requiredIf(function () {
                    return this.form.user && this.form.user.email === null;
                }),
            },
        },
    },
    computed: {
        showEmailInput() {
            if (this.form.user) {
                return this.form.user.email === null;
            }
            return false;
        },
        /* Validation Errors */
        nameErrors() {
            const errors = [];
            if (!this.$v.form.user.$dirty) return errors;
            !this.$v.form.user.required && errors.push("User is required");
            return errors;
        },
        emailErrors() {
            const errors = [];
            if (!this.$v.form.email.$dirty) return errors;
            !this.$v.form.email.email &&
                errors.push("Please provide a valid email address");
            return errors;
        },
    },
    methods: {
        async getUsers(search, loading) {
            loading(true);
            const response = await axios.post(`/leagues/searchUsers`, {
                search: search,
            });
            this.users = response.data.users;
            loading(false);
        },
        validateInput(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        resetForm() {
            this.form = {
                user: null,
                email: "",
            };

            this.$v.form.$reset();
        },
        async submitForm() {
            this.$v.form.$touch();

            if (!this.$v.form.$anyError) {
                const member = this.form.user;

                if (member.email === null) {
                    member.email = this.form.email;
                }

                this.$emit("user-chosen", member);
                this.resetForm();
            }
        },
    },
};
</script>

<style scoped></style>
