<template>
    <div>
        <payment-card
            v-for="subscription in subscriptions"
            :payment="subscription"
            :key="subscription.id"
            subscription
            class="my-2"
            @cancel-membership="cancelMembership"
            @leave-league="leaveLeague"
        ></payment-card>
    </div>
</template>

<script>
import PaymentCard from "../Cards/PaymentCard";
export default {
    name: "SubscriptionsList",
    components: { PaymentCard },
    props: {
        subscriptions: {
            type: Array,
            required: true,
        },
    },
    methods: {

      async leaveLeague(leagueId) {
        const swal = await this.$swal({
          timer: undefined,
          title: "Are you sure?",
          text:
              "Are you sure you want to remove your subscription and access to this league?",
          icon: "error",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonColor: "#d33",
          confirmButtonText: "Yes, cancel league subscription.",
          reverseButtons: true,
        });
        if (swal.isConfirmed) {
          try {
            const response = await axios.post("/account/leaveLeagues", {leagueId: leagueId});
            if (response.data.success) {
              await this.$swal({
                icon: "success",
                title: "Leauge subscription cancelled.",
                text:
                    "You will no longer be billed for this subscription..",
              });
              window.location.reload();
            }
          } catch (error) {
            await this.$swal(error.response.data.message);
          }
        }
      },

        async cancelMembership() {
            const swal = await this.$swal({
                timer: undefined,
                title: "Are you sure?",
                text:
                    "You must close all of the leagues you own before doing this. You will be unsubscribed from all of your leagues and you will lose access to most of the site.",
                icon: "error",
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonColor: "#d33",
                confirmButtonText: "Yes, close it!",
                reverseButtons: true,
            });
            if (swal.isConfirmed) {
                try {
                    await axios.post("/account/leaveLeagues");
                    const response = await axios.post("/account/close");

                    if (response.data.success) {
                        await this.$swal({
                            icon: "success",
                            title: "Account Closed!",
                            text:
                                "Your account has been closed and your membership to The Inner League has been canceled.",
                        });
                        window.location.reload();
                    }
                } catch (error) {
                    await this.$swal(error.response.data.message);
                }
            }
        },
    },
};
</script>

<style scoped></style>
