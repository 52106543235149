<template>
    <div>
        <b-row>
            <b-col cols="12" md="6">
                <h1 class="h4 font-weight-bold">My Friends</h1>
            </b-col>
            <b-col cols="12" md="6">
                <b-input-group class="mb-2">
                    <b-form-input
                        id="search-friend"
                        placeholder="Search..."
                        v-model="searchText"
                        @change="searchFriend()"
                    >
                    </b-form-input>
                </b-input-group>
            </b-col>
        </b-row>
        <hr />
        <friends-list
            :searchText="searchText"
            :user-id="user.id"
            :query="{ status: 2 }"
            ref="friendslist"
        ></friends-list>
    </div>
</template>

<script>
import FriendsList from "../../Lists/FriendsList";
// import _ from "lodash";
export default {
    name: "UserProfileFriends",
    components: { FriendsList },
    props: {
        user: { type: Object, required: true },
    },
    data() {
        return {
            searchText: null,
        };
    },
    methods: {
        searchFriend() {
            setTimeout(function () {
                this.$refs.friendslist.getFriends();
            }, 250);
        },
    },
};
</script>
