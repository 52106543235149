<template>
    <div>
        <b-row>
            <b-col cols="6">
                <b-form-text v-show="this.paginationMeta.first_item">{{ membersCountDisplay }}</b-form-text>
            </b-col>
        </b-row>
        <b-row v-if="members.length > 0">
            <b-col
                v-for="member in members"
                :key="member.id"
                cols="12"
                md="6"
                xl="4"
            >
                <admin-user-card
                    :user="member"
                    remove-text="Remove Member"
                    class="clickable my-3"
                    :editable="editable && member.id !== authId"
                    @nuke-user="openNukeModal(member)"
                    @view-user="showPersonalInfoModal(member)"
                    @make-admin="setAdmin(member)"
                    @remove-admin="removeAdmin(member)"
                    @click="routeToUser(member.id)"
                ></admin-user-card>
            </b-col>
        </b-row>
        <div v-else class="text-center my-5">
            <span class="h6 text-muted">No Members</span>
        </div>
        <b-pagination
            v-if="totalMembers > perPage"
            v-model="currentPage"
            :total-rows="totalMembers"
            :per-page="perPage"
            aria-controls="media-list"
            align="center"
            class="my-3"
        ></b-pagination>
        <admin-remove-user-modal
            :modal-open.sync="removeModalOpen"
            :user-name="userName"
            :league-name="leagueName"
            @remove-user="removeMember"
        >
        </admin-remove-user-modal>
        <admin-nuke-user-modal
            :modal-open.sync="nukeModalOpen"
            :user-name="activeMember.name"
            @nuke-user="nukeMember(activeMember)"
        >
        </admin-nuke-user-modal>
    </div>
</template>

<script>
import AdminUserCard from "../../Cards/Admin/AdminUserCard";
import AdminRemoveUserModal from "../../Modals/Admin/AdminRemoveUserModal";
import AdminNukeUserModal from "../../Modals/Admin/AdminNukeUserModal";
import EventBus from '../../EventBus/EventBus.js';

export default {
    name: "MembersList",
    components: { AdminRemoveUserModal, AdminUserCard, AdminNukeUserModal },
    props: {
        leagueId: {
            type: Number,
            required: true,
        },
        authId: {
            type: Number,
            required: true,
        },
        perPage: {
            type: Number,
            default: 15,
        },
        editable: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            currentPage: 1,
            totalMembers: 0,
            activeMember: {},
            members: [],
            removeModalOpen: false,
            nukeModalOpen: false,
            personalInfoModalIsShown: false,
            reason: '',
            userName: '',
            leagueName: '',
            leagueIdToRemove: '',
            activeMemberToRemove: {},
            paginationMeta: []
        };
    },
    mounted() {
        this.getMembers();
        // set props, member and user data in remove user modal
        EventBus.$on(
            'remove-user', (payload) => {
                this.leagueName = payload.league.league_name
                this.userName = payload.user.name
                this.leagueIdToRemove = payload.league.id
                this.activeMemberToRemove = payload.user
                this.removeModalOpen = true;
            }
        )
    },
    watch: {
        currentPage: function () {
            this.getMembers();
        },
    },
    computed: {
        membersCountDisplay() {
            
            if(this.totalMembers <= 1) {
                return `Showing ${this.totalMembers} Result`;
            }

            if(this.totalMembers > 1 && this.totalMembers < 100) {
               return this.paginationMeta.first_item ? `Showing ${this.paginationMeta.first_item} to ${this.paginationMeta.last_item} of ${this.totalMembers} Results` : '';
            }

            return `About ${this.totalMembers} Results`;
        },
    },
    methods: {
        async getMembers() {
            const data = {
                leagueId: this.leagueId,
                per_page: this.perPage,
                page: this.currentPage,
            };

            try {

                const response = await axios.post("/admin/members", data);
                this.paginationMeta = response.data.meta
                this.members = response.data.members;
                this.totalMembers = response.data.member_count;

            } catch (error) {
                // If there is an error for some reason, show alert
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                ) {
                    await this.$swal(error.response.data.message);
                } else {
                    await this.$swal(error);
                }
            }
        },
        openNukeModal(member) {
            this.activeMember = member;
            this.nukeModalOpen = true;
        },
        async removeMember() {
            
            const requestData = {
                leagueId: this.leagueIdToRemove,
                userId: this.activeMemberToRemove.id,
            };

            try {
                const response = await axios.post(
                    "/leagues/remove",
                    requestData
                );

                location.reload()
                this.removeModalOpen = false;

            } catch (error) {
                // If there is an error for some reason, show alert
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                ) {
                    await this.$swal(error.response.data.message);
                } else {
                    await this.$swal(error);
                }
            }
        },
        async nukeMember() {

             const requestData = {
                leagueId: this.leagueId,
                userId: this.activeMember,
            };

            try {
              
                const response = await axios.post(
                    `/admin/users/${this.activeMember.id}/nuke`,
                    requestData
                );
                await this.getMembers();
                this.nukeModalOpen = false;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error);
            }
        },
        async setAdmin(member) {
            const requestData = {
                leagueId: this.leagueId,
                userId: member.id,
            };

            try {
                const response = await axios.post(
                    "/leagues/members/addAdmin",
                    requestData
                );
                await this.getMembers();
            } catch (error) {
                // If there is an error for some reason, show alert
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                ) {
                    await this.$swal(error.response.data.message);
                } else {
                    await this.$swal(error);
                }
            }
        },
        async removeAdmin(member) {
            const requestData = {
                leagueId: this.leagueId,
                userId: member.id,
            };

            try {
                const response = await axios.post(
                    "/leagues/members/removeAdmin",
                    requestData
                );
                await this.getMembers();
            } catch (error) {
                // If there is an error for some reason, show alert
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                ) {
                    await this.$swal(error.response.data.message);
                } else {
                    await this.$swal(error);
                }
            }
        },
        //open the user page in new tab
        routeToUser(id) {
            
            var url = window.location.origin + `/u/${id}`;
            
            window.open(url,'_blank');

        },

        showPersonalInfoModal(member) {
            this.$refs.datingProfileModal.loadProfileInfo(member);
            this.personalInfoModalIsShown = true;   
        },

    },
};
</script>

<style scoped></style>
