<template>
    <b-modal
        id="join-league-modal"
        v-model="open"
        centered
        hide-footer
        hide-header
    >
        <b-container class="p-4">
            <div v-show="state === 'question'">
                <h3 class="font-weight-bold mb-4">
                    This league has a monthly subscription of
                    <span class="text-purple">
                      <del v-if="leagueTrial">${{ price }}</del>
                      <span v-if="!leagueTrial">${{ price }}</span>
                    </span> to join.
                </h3>

              <p v-if="leagueTrial">
                After the first month, you will be billed ${{ price }} per month.
              </p>

                <p class="mb-5">
                You will be billed monthly until cancelled.   You may cancel at any time.
                </p>
                <b-btn
                    variant="purple"
                    block
                    pill
                    @click="checkPaymentMethod"
                    :disabled="loading"
                >
                    <i v-show="loading" class="fas fa-spinner fa-pulse"></i>
                    <span v-show="!loading">Join League</span>
                </b-btn>
            </div>
            <div v-show="state === 'add-payment'">
                <stripe-payment-form
                    title="Add Payment Method"
                    @stripe-submitted="addPaymentMethod"
                ></stripe-payment-form>
            </div>
            <div v-show="state === 'success'">
                <img src="/images/icons/purple-check.svg" height="60" />
                <h2 class="font-weight-bold my-4">Success!</h2>
                <p class="mb-5">
                  You have successfully joined the league!
                </p>
                <b-btn variant="purple" block pill @click="completeJoin"
                    >Close</b-btn
                >
            </div>
            <div v-show="state === 'error'">
                <h2 class="font-weight-bold my-4">Error!</h2>
                <p v-html="errorMessage"></p>
            </div>
        </b-container>
    </b-modal>
</template>

<script>
import StripePaymentForm from "../Forms/PaymentForms/StripePaymentForm";
export default {
    name: "JoinLeagueModal",
    components: { StripePaymentForm },
    props: {
        modalOpen: {
            type: Boolean,
        },
        price: {
            type: String,
            required: true,
        },
        leagueId: {
            type: Number,
            required: true,
        },
      queued: {
          type: Boolean,
      },
      leagueTrial: {
        type: Number,
        default: 0,
      }
    },
    data() {
        return {
            loading: false,
            state: "question",
            errorMessage: null,
        };
    },
    computed: {
        open: {
            get() {
                return this.modalOpen;
            },
            set(value) {
                this.$emit("update:modalOpen", value);
            },
        },
    },
    methods: {
        async checkPaymentMethod() {
            try {
                this.loading = true;
                const response = await axios.get(`/settings/hasCard`);
                if (response.data.hasCard) {
                    await this.joinLeague();
                } else {
                    this.state = "add-payment";
                    this.loading = false;
                }
            } catch (error) {
                this.state = "error";
                this.errorMessage = error.response.data.message;
                this.loading = false;
            }
        },
        async addPaymentMethod(token) {
            try {
                this.loading = true;
                const response = await axios.post(`/settings/addCard`, {
                    token: token.id,
                });
                await this.joinLeague();
            } catch (error) {
                this.state = "error";
                this.errorMessage = error.response.data.message;
                this.loading = false;
            }
        },
        async joinLeague() {
            try {
                this.loading = true;
                const response = await axios.get(`/leagues/join`, {
                    params: { leagueId: this.leagueId, queued: this.queued },
                });
                this.state = "success";
                this.loading = false;
            } catch (error) {
                this.state = "error";
                this.errorMessage = error.response.data.message;
              if(error.response.data.redirect) {
                setTimeout( function () {
                  window.location = error.response.data.redirect;
                }, 8000 );
              }
                this.loading = false;
            }
        },
        completeJoin() {
            window.location.reload();
        },
    },
};
</script>
