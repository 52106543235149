<template>
    <div>
      <p v-if="balance">
        <span v-if="balance && balance.available">Available Balance: ${{balance.available}} <br></span>
        <span v-if="balance && balance.pending">Pending Balance: ${{balance.pending}} <br></span>

        <b-form @submit.prevent="submitPayout">
          <fieldset>
            <b-form-group
            label="Payout Amount"
            label-for="amount"
            description="Enter how much you would like to withdraw"
            class="mt-5"
            >
            <b-input-group
                prepend="$"
            >
              <b-form-input
                  id="amount"
                  v-model="amount"
                  type="number"
                  step=".01"
              ></b-form-input>
            </b-input-group>
            <invalid-feedback :errors="priceErrors"></invalid-feedback>
            </b-form-group>

            <submit-button
                :loading="loading"
                value="Payout"
                class="mt-5"
            ></submit-button>
          </fieldset>
        </b-form>


      </p>
        <b-form @submit.prevent="submitForm">
            <fieldset :disabled="readOnly">
                <h1 class="h2 font-weight-bold">{{ title }}</h1>
                <p class="text-muted">
                    {{ subtitle }}
                </p>

              <div>
                <p>Would you like to give users a free month trial?</p>
                <b-form-select
                    v-model.number="form.trial"
                    id="trial"
                    @change="$v.form.trial.$touch()"
                >
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </b-form-select>
              </div>



                <b-form-group
                    label="Price"
                    label-for="price-input"
                    description="A $1.99 or 5%, whichever is higher will be added as platform fee"
                    class="mt-5"
                >
                
                    <b-input-group
                        prepend="$"
                        append="/ month"
                        :class="readOnly ? 'disabled' : null"
                    >
                        <b-form-input
                            id="price-input"
                            type="number"
                            step=".01"
                            v-model.number="form.price"
                            :state="validateInput('price')"
                            @change="$v.form.price.$touch()"
                        ></b-form-input>
                    </b-input-group>
                    <invalid-feedback :errors="priceErrors"></invalid-feedback>
                </b-form-group>
                <p v-show="form.price > 0">
                  Total Price: ${{ totalPrice }}
                  </p>

              <!-- <div v-show="price > 0">
               <p>If you change your price, you have the option to grandfather your current subscribers at the current price.</p>
                <p>Would you like to grandfather your users?  You cannot change this option later.</p>
                <b-form-select
                    v-model.number="form.grandfather"
                  id="grandfather"
                    @change="$v.form.grandfather.$touch()"
                >
                  <option value="1"  selected>Yes</option>
                  <option value="0">No</option>
                </b-form-select>
              </div> -->
                <submit-button
                    v-if="!readOnly"
                    :loading="loading"
                    :text="buttonText"
                    class="mt-5"
                ></submit-button>
            </fieldset>
        </b-form>
    </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import InvalidFeedback from "../FormComponents/InvalidFeedback";
import SubmitButton from "../../Buttons/SubmitButton";

export default {
    name: "LeaguePriceForm",
    components: { SubmitButton, InvalidFeedback },
    props: {
        title: {
            type: String,
            default: "Subscription",
        },
        subtitle: {
            type: String,
            default: "The price your members pay per month.",
        },
        leagueId: {
            required: true,
        },
      leagueTrial: {
          type: Number,
        default: 0,
      },
      price: [String, Number],
        readOnly: {
            type: Boolean,
            default: false,
        },
      balance: {
          type: Object
      },
      buttonText: {
            type: String,
            default: "Next",
      },
    },
    data() {
        return {
          amount: '',
            loading: false,
            form: {
                price: null,
              grandfather: 1,
              trial: this.leagueTrial,
            },
        };
    },
    validations: {
        form: {
            price: { 
              required,
            },
          grandfather: {},
          trial: {required},
        },
    },
    mounted() {
        if(this.price){
          this.form.price = this.price.toFixed(2) ?? 0;
        }
        //this.payout.amount = this.balance.available ?? 0;
    },
    computed: {
        totalPrice() {
          
            var price = parseFloat(this.form.price) * .05;
            
            price = parseFloat(price>1.99 ? price : 1.99) + parseFloat(this.form.price)
            
            return new Intl.NumberFormat().format(price);
        },
        /* Validation Errors */
        priceErrors() {
            const errors = [];
            if (!this.$v.form.price.$dirty) return errors;

            !this.$v.form.price.required && errors.push("Price is required");

            return errors;
        },
    },
    methods: {
        validateInput(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },

      validateInput2(name) {
        const { $dirty, $error } = this.$v.payout[name];
        return $dirty ? !$error : null;
      },
        async submitForm() {

          const url = (this.price > 0) ? "/leagues/updatePrice" : "/leagues/setPrice";
            this.$v.form.$touch();

            if (!this.$v.form.$anyError) {
                this.loading = true;
                try {
                    // Attempt to set price
                    const response = await axios.post(url, {
                        leagueId: this.leagueId,
                        ...this.form, 
                    });
                    this.$emit("price-set");
                } catch (error) {
                    // If there is an error for some reason, show alert
                    await this.$swal(error.response.data.message);
                    this.loading = false;
                }
            }
        },

      async submitPayout() {


          this.loading = true;
          try {
            // Attempt to set price
            const response = await axios.post("/leagues/payout", {
              leagueId: this.leagueId,
              amount: this.amount,
            });

            await this.$swal("Your payout has been submitted.");
            this.loading = false;

          } catch (error) {
            // If there is an error for some reason, show alert
            await this.$swal(error.response.data.message);
            this.loading = false;
          }
        }
      },
};
</script>
