<template>
    <b-modal
        id="join-league-modal"
        v-model="open"
        centered
        hide-footer
        hide-header
    >
        <b-container class="p-4">
            <div v-show="state === 'add-payment'">
                <stripe-payment-form
                    title="Add Payment Method"
                    @stripe-submitted="addPaymentMethod"
                ></stripe-payment-form>
            </div>
            <div v-show="state === 'success'">
                <img src="/images/icons/purple-check.svg" height="60" />
                <h2 class="font-weight-bold my-4">Success!</h2>
                <p class="mb-5">
                  Payment Method Has Been Added!
                </p>
                <b-btn variant="purple" block pill @click="completePaymentMethod"
                    >Close</b-btn
                >
            </div>
             <div v-show="state === 'error'">
                <h2 class="font-weight-bold my-4">Error!</h2>
                <p v-html="errorMessage"></p>
            </div>
        </b-container>
    </b-modal>
</template>

<script>
import StripePaymentForm from "../Forms/PaymentForms/StripePaymentForm";
export default {
    name: "JoinLeagueModal",
    components: { StripePaymentForm },
    props: {
        modalOpen: {
            type: Boolean,
        }
    },
    data() {
        return {
            loading: false,
            state: "add-payment",
            errorMessage: null,
        };
    },
    computed: {
        open: {
            get() {
                return this.modalOpen;
            },
            set(value) {
                this.$emit("update:modalOpen", value);
            },
        },
    },
    methods: {
        
        async addPaymentMethod(token) {
            try {
                this.loading = true;
                const response = await axios.post(`/settings/addCard`, {
                    token: token.id,
                });
                this.loading = false
                this.state = 'success'

            } catch (error) {
                this.state = "error";
                this.errorMessage = error.response.data.message;
                this.loading = false;
            }
        },
        completePaymentMethod() {
            this.$emit("open-payment-modal", false);
        },
    },
};
</script>
