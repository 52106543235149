var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length > 0)?_c('div',{staticClass:"lb"},[_c('div',{class:_vm.gridClass},_vm._l((_vm.filteredItems),function(item,i){return _c('div',{key:item.id,staticClass:"lb-item",on:{"click":function($event){return _vm.show(i)}}},[(!item.type && _vm.items.length === 1)?_c('div',{staticClass:"img-center"},[_c('b-img',{staticStyle:{"object-fit":"cover"},attrs:{"src":item.thumbnail,"fluid":""}})],1):(!item.type && _vm.items.length > 1)?_c('b-img',{class:_vm.items.length > 1 ? 'h-100 w-100' : '',staticStyle:{"object-fit":"cover"},attrs:{"src":item.thumbnail,"fluid":""}}):_c('video',{staticClass:"video-js vjs-fill",attrs:{"id":("video-player-" + (item.id)),"controls":"","muted":"","data-setup":"{}","poster":item.poster},domProps:{"muted":true}},[(
                        item.file.split('.').slice(-1)[0].toLowerCase() ===
                        'mp4'
                    )?_c('source',{attrs:{"src":item.file,"type":"video/mp4"}}):_vm._e(),_vm._v(" "),(
                        item.file.split('.').slice(-1)[0].toLowerCase() ===
                        'webm'
                    )?_c('source',{attrs:{"src":item.file,"type":"video/webm"}}):_vm._e(),_vm._v(" "),(
                        item.file.split('.').slice(-1)[0].toLowerCase() ===
                        'ogg'
                    )?_c('source',{attrs:{"src":item.file,"type":"video/ogg"}}):_vm._e(),_vm._v(" "),(
                        item.file.split('.').slice(-1)[0].toLowerCase() ===
                        'm3u8'
                    )?_c('source',{attrs:{"src":item.file,"type":"application/x-mpegURL"}}):_vm._e()]),_vm._v(" "),(i === _vm.cells - 1 && _vm.items.length - _vm.cells > 0)?_c('span',{staticClass:"lb-more"},[_vm._v(_vm._s(_vm.items.length - _vm.cells)+"+")]):_vm._e()],1)}),0),_vm._v(" "),(_vm.openModal)?_c('b-modal',{attrs:{"id":(_vm.postId + "-media-lb"),"size":"xl","hide-header":"","hide-footer":"","centered":"","body-class":"p-0","body-bg-variant":"dark"},on:{"hidden":function($event){_vm.openModal = false}},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[(_vm.index >= 0)?_c('b-carousel',{staticStyle:{"min-height":"200px"},attrs:{"interval":0,"controls":_vm.items.length > 1,"indicators":_vm.items.length > 1},model:{value:(_vm.index),callback:function ($$v) {_vm.index=$$v},expression:"index"}},_vm._l((_vm.items),function(item){return _c('b-carousel-slide',{key:item.id,scopedSlots:_vm._u([{key:"img",fn:function(){return [(!item.type)?_c('b-img',{staticClass:"h-100 w-100",staticStyle:{"object-fit":"cover","max-height":"90vh"},attrs:{"src":item.file,"fluid":""}}):_c('video',{staticClass:"mb-n2 video-js vjs-fluid",attrs:{"id":("video-player-large-" + (item.id)),"width":"100%","height":"auto","controls":"","muted":"","data-setup":"{}","poster":item.poster},domProps:{"muted":true}},[(
                                item.file
                                    .split('.')
                                    .slice(-1)[0]
                                    .toLowerCase() === 'mp4'
                            )?_c('source',{attrs:{"src":item.file,"type":"video/mp4"}}):_vm._e(),_vm._v(" "),(
                                item.file
                                    .split('.')
                                    .slice(-1)[0]
                                    .toLowerCase() === 'webm'
                            )?_c('source',{attrs:{"src":item.file,"type":"video/webm"}}):_vm._e(),_vm._v(" "),(
                                item.file
                                    .split('.')
                                    .slice(-1)[0]
                                    .toLowerCase() === 'ogg'
                            )?_c('source',{attrs:{"src":item.file,"type":"video/ogg"}}):_vm._e(),_vm._v(" "),(
                                item.file
                                    .split('.')
                                    .slice(-1)[0]
                                    .toLowerCase() === 'm3u8'
                            )?_c('source',{attrs:{"src":item.file,"type":"application/x-mpegURL"}}):_vm._e()])]},proxy:true}],null,true)})}),1):_vm._e()],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }