<template>
    <div>
        <b-form @submit.prevent="submitForm">
            <fieldset :disabled="readOnly">
                <h1 v-if="title" class="h2 font-weight-bold">{{ title }}</h1>
                <p v-if="subtitle" class="text-muted">
                    {{ subtitle }}
                </p>
                <b-alert
                    v-if="account"
                    variant="dark"
                    :show="account.pastDue"
                    class="mb-4 redbg"
                    >

                  <p style="font-weight:bold;">Additional verification information is required to enable payout or charge capabilities on this account:</p>
                <ul>
                  <li v-for="message in account.messages">
                    {{ message }}
                  </li>
                </ul>
                </b-alert>

              <slot name="before-fields" />

              <b-form-group class="mt-5">
                    <!-- User Section -->
                    <b-form-row>
                        <!-- Name -->
                        <b-col cols="12" md="6">
                            <b-form-group
                                label="First Name"
                                label-for="first-name-input"
                            >
                                <b-form-input
                                    id="first-name-input"
                                    type="text"
                                    v-model.trim="form.first_name"
                                    :state="validateInput('first_name')"
                                    @change="$v.form.first_name.$touch()"
                                ></b-form-input>
                                <invalid-feedback
                                    :errors="firstNameErrors"
                                ></invalid-feedback>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6">
                            <b-form-group
                                label="Last Name"
                                label-for="last-name-input"
                            >
                                <b-form-input
                                    id="last-name-input"
                                    type="text"
                                    v-model.trim="form.last_name"
                                    :state="validateInput('last_name')"
                                    @change="$v.form.last_name.$touch()"
                                ></b-form-input>
                                <invalid-feedback
                                    :errors="lastNameErrors"
                                ></invalid-feedback>
                            </b-form-group>
                        </b-col>
                    </b-form-row>
                    <b-form-group label="Email" label-for="email-input">
                        <b-form-input
                            id="email-input"
                            type="text"
                            v-model.trim="form.email"
                            :state="validateInput('email')"
                            @change="$v.form.email.$touch()"
                        ></b-form-input>
                        <invalid-feedback
                            :errors="emailErrors"
                        ></invalid-feedback>
                    </b-form-group>
                    <b-form-row class="mb-4">
                        <b-col cols="12" md="4">
                            <b-form-group
                                label="Phone Number"
                                label-for="phone-input"
                            >
                                <b-form-input
                                    id="phone-input"
                                    type="text"
                                    v-mask="'+1 ###-###-####'"
                                    v-model.trim="form.phone"
                                    :state="validateInput('phone')"
                                    @change="$v.form.phone.$touch()"
                                ></b-form-input>
                                <invalid-feedback
                                    :errors="phoneErrors"
                                ></invalid-feedback>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                            <b-form-group
                                label="Date of Birth"
                                label-for="birthday-input"
                            >
                                <b-form-datepicker
                                    id="birthday-input"
                                    v-model.trim="form.dob"
                                    :disabled="readOnly"
                                    :date-format-options="{
                                        year: 'numeric',
                                        month: 'long',
                                        day: '2-digit',
                                    }"
                                    :state="validateInput('dob')"
                                    @change="$v.form.dob.$touch()"
                                    :max="maxDate"
                                    show-decade-nav
                                    no-flip
                                ></b-form-datepicker>
                                <invalid-feedback
                                    :errors="birthdayErrors"
                                ></invalid-feedback>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4" v-show="!readOnly">
                            <b-form-group
                                v-if="!account"
                                label="Last 4 digits of SSN"
                                label-for="ssn4-input"
                            >
                                <b-form-input
                                    id="ssn4-input"
                                    type="text"
                                    maxLength="4"
                                    v-model.trim="form.ssn_last_4"
                                    :state="validateInput('ssn_last_4')"
                                    @change="$v.form.ssn_last_4.$touch()"
                                ></b-form-input>
                                <invalid-feedback
                                    :errors="ssn4Errors"
                                ></invalid-feedback>
                            </b-form-group>
                            <b-form-group
                                v-if="
                                    account &&
                                    account.fields.includes('full_ssn')
                                "
                                label="Social Security Number"
                                label-for="ssn-input"
                            >
                                <b-form-input
                                    id="ssn-input"
                                    type="text"
                                    v-mask="'###-##-####'"
                                    v-model.trim="preTokenForm.full_ssn"
                                    :state="!$v.form.full_ssn.$invalid"
                                    @change="tokenizeSsn('ssn')"
                                ></b-form-input>
                                <invalid-feedback
                                    :errors="ssnErrors"
                                ></invalid-feedback>
                            </b-form-group>
                        </b-col>
                    </b-form-row>
                </b-form-group>
                <!-- Address Section -->
                <b-form-group>
                    <b-form-group label="Address" label-for="address-input">
                        <b-form-input
                            id="address-input"
                            type="text"
                            v-model.trim="form.address"
                            :state="validateInput('address')"
                            @change="$v.form.address.$touch()"
                        ></b-form-input>
                        <invalid-feedback
                            :errors="addressErrors"
                        ></invalid-feedback>
                    </b-form-group>
                    <b-form-row>
                        <b-col cols="8" md="7">
                            <b-form-group label="City" label-for="city-input">
                                <b-form-input
                                    id="city-input"
                                    type="text"
                                    v-model.trim="form.city"
                                    :state="validateInput('city')"
                                    @change="$v.form.city.$touch()"
                                ></b-form-input>
                                <invalid-feedback
                                    :errors="cityErrors"
                                ></invalid-feedback>
                            </b-form-group>
                        </b-col>
                        <b-col cols="4" md="2">
                            <b-form-group label="State" label-for="state-input">
                                <b-form-input
                                    id="state-input"
                                    type="text"
                                    v-model.trim="form.state"
                                    :state="validateInput('state')"
                                    @change="$v.form.state.$touch()"
                                    :formatter="stateFormatter"
                                ></b-form-input>
                                <invalid-feedback
                                    :errors="stateErrors"
                                ></invalid-feedback>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="3">
                            <b-form-group
                                label="Zipcode"
                                label-for="zipcode-input"
                            >
                                <b-form-input
                                    id="zipcode-input"
                                    type="text"
                                    v-model.trim="form.zip"
                                    :state="validateInput('zip')"
                                    @change="$v.form.zip.$touch()"
                                ></b-form-input>
                                <invalid-feedback
                                    :errors="zipcodeErrors"
                                ></invalid-feedback>
                            </b-form-group>
                        </b-col>
                    </b-form-row>
                </b-form-group>
                <!-- Official ID Section -->
                <b-form-group
                    v-if="account && account.fields.includes('official_id')"
                    label="Identifying Document"
                    class="mt-5"
                >
                    <b-row>
                        <b-col>
                            <b-form-file
                                id="document-front-input"
                                v-model="docForm.front"
                                placeholder="Front of document..."
                                drop-placeholder="Drop file here..."
                                :state="!$v.docForm.front.$invalid"
                            ></b-form-file>
                        </b-col>
                        <b-col>
                            <b-form-file
                                id="document-back-input"
                                v-model="docForm.back"
                                placeholder="Back of document..."
                                drop-placeholder="Drop file here..."
                            ></b-form-file>
                        </b-col>
                    </b-row>
                    <invalid-feedback
                        :errors="officialIdErrors"
                    ></invalid-feedback>
                </b-form-group>
                <!--Utility Bill Section -->
                <b-form-group
                    v-if="account && account.fields.includes('utility_bill')"
                    label="Utility Bill"
                    class="mt-5"
                >
                    <b-form-file
                        id="utility-input"
                        v-model="docForm.utility"
                        :state="!$v.docForm.utility.$invalid"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                    ></b-form-file>
                    <invalid-feedback
                        :errors="utilityErrors"
                    ></invalid-feedback>
                </b-form-group>
                <submit-button
                    v-if="!readOnly"
                    :loading="loading"
                    text="Next"
                    class="mt-5"
                ></submit-button>
                <b-form-text class="mt-2">
                    By registering your account, you agree to our
                    <a class="text-purple" href="#">Services Agreement</a> and
                    the
                    <a
                        class="text-purple"
                        href="https://stripe.com/connect-account/legal"
                        target="_blank"
                        >Stripe Connect Account Agreement</a
                    >
                </b-form-text>
            </fieldset>
        </b-form>
    </div>
</template>

<script>
import {
    required,
    email,
    minLength,
    requiredIf,
} from "vuelidate/lib/validators";
import InvalidFeedback from "../FormComponents/InvalidFeedback";
import SubmitButton from "../../Buttons/SubmitButton";

export default {
    name: "PaymentAccountForm",
    components: { SubmitButton, InvalidFeedback },
    props: {
        title: {
            type: String,
            default: "Set up payment account",
        },
        subtitle: {
            type: String,
            default:
                "If you want to be able to charge for your league, you must first setup a payment account.",
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        leagueId: {
            required: true,
        },
        account: Object,
    },
    data() {
      const maxDate = new Date();
      maxDate.setFullYear(maxDate.getFullYear() - 18);
        return {
            loading: false,
            stripeObject: null,
            docForm: {
                front: null,
                back: null,
                utility: null,
            },
            preTokenForm: {
                full_ssn: null,
            },
            form: {
                first_name: null,
                last_name: null,
                address: null,
                city: null,
                state: null,
                zip: null,
                email: null,
                phone: null,
                dob: null,
                ssn_last_4: null,
                full_ssn: null,
            },
          maxDate,
        };
    },
    validations: {
        form: {
            first_name: { required },
            last_name: { required },
            address: { required },
            city: { required },
            state: { required },
            zip: { required },
            email: { required, email },
            phone: { 
                required,
                isPhoneValid(val){
                    const regexStr = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gm;
                    return regexStr.test(val);
                },
                minLength: minLength(10),
             },
            dob: { required },
            ssn_last_4: {
                required: requiredIf(function () {
                    return !this.account;
                }),
                minLength: minLength(4),
            },
            full_ssn: {
                required: requiredIf(function () {
                    return this.account
                        ? this.account.fields.includes("full_ssn")
                        : false;
                }),
                minLength: minLength(11), // So empty validation occurs
            },
        },
        docForm: {
            front: {
                required: requiredIf(function () {
                    return this.account
                        ? this.account.fields.includes("official_id")
                        : false;
                }),
            },
            utility: {
                required: requiredIf(function () {
                    return this.account
                        ? this.account.fields.includes("utility_bill")
                        : false;
                }),
            },
        },
    },
    mounted() {
        // Setup the stripe instance
        this.stripeObject = Stripe(process.env.MIX_STRIPE_KEY);

        if (this.account) {
            this.setForm();
        }
    },
    computed: {
        /** Validation Errors **/
        firstNameErrors() {
            const errors = [];
            if (!this.$v.form.first_name.$dirty) return errors;
            !this.$v.form.first_name.required &&
                errors.push("First name is required");
            return errors;
        },
        lastNameErrors() {
            const errors = [];
            if (!this.$v.form.last_name.$dirty) return errors;
            !this.$v.form.last_name.required &&
                errors.push("Last name is required");
            return errors;
        },
        addressErrors() {
            const errors = [];
            if (!this.$v.form.address.$dirty) return errors;
            !this.$v.form.address.required &&
                errors.push("Address is required");
            if (this.account) {
                this.account.fields.includes("address") &&
                    errors.push(
                        "There was a problem verifying your address, please re-enter your address"
                    );
            }
            return errors;
        },
        cityErrors() {
            const errors = [];
            if (!this.$v.form.city.$dirty) return errors;
            !this.$v.form.city.required && errors.push("City is required");
            return errors;
        },
        stateErrors() {
            const errors = [];
            if (!this.$v.form.state.$dirty) return errors;
            !this.$v.form.state.required && errors.push("State is required");
            return errors;
        },
        zipcodeErrors() {
            const errors = [];
            if (!this.$v.form.zip.$dirty) return errors;
            !this.$v.form.zip.required && errors.push("Zipcode is required");
            return errors;
        },
        emailErrors() {
            const errors = [];
            if (!this.$v.form.email.$dirty) return errors;
            !this.$v.form.email.required && errors.push("Email is required");
            !this.$v.form.email.email &&
                errors.push("Must be a valid email address");
            return errors;
        },
        phoneErrors() {
            const errors = [];
            if (!this.$v.form.phone.$dirty) return errors;
            !this.$v.form.phone.required &&
                errors.push("Phone Number is required");
            !this.$v.form.phone.minLength &&
                errors.push("Phone Number must be 10 numbers");
            !this.$v.form.phone.isPhoneValid &&
                errors.push("Phone Number is not Valid");
            return errors;
        },
        birthdayErrors() {
            const errors = [];
            if (!this.$v.form.dob.$dirty) return errors;
            !this.$v.form.dob.required &&
                errors.push("Date of birth is required");
            return errors;
        },
        ssn4Errors() {
            const errors = [];
            if (!this.$v.form.ssn_last_4.$dirty) return errors;
            !this.$v.form.ssn_last_4.required &&
                errors.push("Last 4 of SSN is required");
            !this.$v.form.phone.minLength && errors.push("Must be 4 numbers");
            return errors;
        },
        ssnErrors() {
            const errors = [];
            !this.$v.form.full_ssn.required &&
                errors.push(
                    "There was a problem verifying the last 4 of your SSN, please enter your full SSN."
                );
            !this.$v.form.full_ssn.minLength &&
                errors.push(
                    "Your SSN must be exactly 9 numerical digits"
                );
            return errors;
        },
        officialIdErrors() {
            const errors = [];
            this.$v.docForm.front.required &&
                errors.push(
                    "There was a problem verifying your information, please upload an image of the front and back of an identifying document"
                );
            return errors;
        },
        utilityErrors() {
            const errors = [];
            this.$v.docForm.utility.required &&
                errors.push(
                    "There was a problem verifying your information, please upload an image of a utility bill."
                );
            return errors;
        },
    },
    methods: {
        setForm() {
            this.form.first_name = this.account.first_name;
            this.form.last_name = this.account.last_name;
            this.form.address = this.account.address;
            this.form.city = this.account.city;
            this.form.state = this.account.state;
            this.form.zip = this.account.zip;
            this.form.email = this.account.email;
            this.form.phone = this.account.phone;
            this.form.dob = this.account.dob;
        },
        stateFormatter(value) {
            return value.toUpperCase();
        },

      buildConnectForm() {
        let dataObj = {leagueId: this.leagueId, ...this.form};

        if( dataObj.phone ) {
          dataObj.phone = dataObj.phone.replace( /[^\d]/g, "" ).substring( 1 );
        }

        return dataObj;
      },
        validateInput(name) {
            const { $dirty, $error } = this.$v.form[name];

            if (this.account) {
                const stripeError = this.account.fields.includes(name);
                if (stripeError) {
                    return false;
                }
            }

            return $dirty ? !$error : null;
        },
        async tokenizeSsn() {
            try {
                // This is kind of weird. But we need to tokenize the ssn for sending to stripe. But only when we
                // have the full ssn and it has been validated.
                this.form.full_ssn = this.preTokenForm.full_ssn;
                // The way we'll handle this is to always update the full_ssn which is tied to vuelidator.
                // This will fire off any validation errors. We will check for validation errors before
                // attempting to tokenize. If there are none, we'll tokenize and assign the token to the field
                // instead.
                // I'll be honest, I'm not sure if assigning the token to the field is the best way to handle it.
                // But the token itself IS longer than our validation rules, so it just happens to work out.
                if (this.ssnErrors.length == 0) {
                    const { token } = await this.stripeObject.createToken("pii", {
                        id_number: this.preTokenForm.full_ssn,
                    });
                    this.form.full_ssn = token.id;
                    this.form.ssn_last_4 = this.preTokenForm.full_ssn.substring(7, 11);
                }

            } catch(e) {
                // Do nothing. This will succeed when it is supposed to succeed
            }

        },
        async submitForm() {
            this.$v.$touch();

            if (!this.$v.$anyError) {
                if (this.account) {
                    await this.submitDocuments();
                    await this.updateAccount();
                } else {
                    await this.createAccount();
                }
            }
        },
        async submitDocuments() {
            this.loading = true;
            // Create Request Data
            const data = new FormData();
            data.append("leagueId", this.leagueId);
            if (this.account.fields.includes("official_id")) {
                data.append("id_front", this.docForm.front);
                data.append("id_back", this.docForm.back);
            }
            if (this.account.fields.includes("utility_bill")) {
                data.append("utility_bill", this.docForm.utility);
            }

            try {
                // Attempt to send files
                const response = await axios.post(
                    "/leagues/updateConnectFiles",
                    data
                );
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        },
        async createAccount() {
            this.loading = true;
            try {

                // Attempt to create connect account
                const response = await axios.post(
                    "/leagues/subscriptionSetup",
                    this.buildConnectForm()
                );
                this.$emit("payment-account-created");
                this.loading = false;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        },
        async updateAccount() {
            this.loading = true;
            try {
                // Attempt to update connect account
                const response = await axios.post("/leagues/updateConnect",
                    this.buildConnectForm()
                );
                this.$emit("payment-account-updated");
                this.loading = false;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        },
    },
};
</script>
