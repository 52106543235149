<template>
    <b-card title="About" class="drop-shadow">
        <hr />
        <b-card-text :class="truncated ? 'truncate mb-0' : 'mb-0'">{{
            content
        }}</b-card-text>
        <div v-show="truncated" class="text-center">
            <b-btn variant="link" @click="truncated = false">Show More</b-btn>
        </div>
    </b-card>
</template>

<script>
export default {
    name: "AboutCard",
    props: {
        content: {
            type: String,
            required: true,
        },
        editable: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            truncated: true,
        };
    },
    mounted() {
        if (this.content.length < 150) {
            this.truncated = false;
        }
    },
};
</script>

<style scoped></style>
