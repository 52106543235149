<template>
    <b-modal
        id="edit-burst-playlist-modal"
        v-model="open"
        centered
        title="Edit Your Burst Playlist Order"
    >
        <div class="container">
            <div class="row">
                <div v-if="post.type == 5" class="col-md-6">
                    <h5>Main Burst</h5>
                    <div
                        class="main-item mt-2"
                        :style="{
                            backgroundImage: `url(${post.files[0].poster})`,
                        }"
                    ></div>
                </div>
                <div class="col-md-6">
                    <h5>Burst Replies</h5>
                    <draggable
                        v-model="burstChildren"
                        group="people"
                        @start="drag = true"
                        @end="drag = false"
                    >
                        <div
                            class="playlist-item mt-2"
                            v-for="childBurst in burstChildren"
                            :key="childBurst.item.id"
                            :style="{
                                backgroundImage: `url(${childBurst.item.files[0].poster})`,
                            }"
                        >
                            <i class="fas fa-grip-lines fa-2x"></i>
                        </div>
                    </draggable>
                </div>
            </div>
        </div>

        <template #modal-footer>
            <div class="w-100">
                <b-button
                    variant="primary"
                    class="float-right"
                    @click="saveChanges"
                >
                    Save Changes
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
export default {
    name: "EditBurstPlaylistModal",
    props: {
        modalOpen: Boolean,
        post: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            burstChildren: this.post.children.map((item, index) => ({
                item,
                order: index + 1,
                fixed: false,
            })),
        };
    },
    computed: {
        open: {
            get() {
                return this.modalOpen;
            },
            set(value) {
                this.$emit("update:modalOpen", value);
            },
        },
    },
    methods: {
        async saveChanges(e) {
            const sortOrder = this.burstChildren.map((c, index) => ({
                ...{
                    id: c.item.id,
                    sortOrder: index + 1,
                    prevSortOrder: c.order,
                },
            }));
            const { status, data } = await axios.post(
                "/leagues/posts/updateBurstPlaylistOrder",
                {
                    sortOrder,
                },
            );

            if (status == 200) {
                window.location.reload();
            }
        },
    },
};
</script>
<style scoped lang="scss">
.main-item {
    background-size: cover;
    width: 100%;
    height: 200px;
    border-radius: 5px;
}
.playlist-item {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100px;
    border-radius: 5px;
    .fa-grip-lines {
        margin-left: 10px;
        margin-top: 36px;
        color: white;
    }
}
</style>
