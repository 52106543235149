<template>
    <div>
        <div
            v-if="variant == 'link'"
            class="burst-reply-action-v1"
            @click="openCreateBurstModal"
        >
            <b-spinner v-if="processingBurstReply" class="mt"></b-spinner>
            <b-btn v-else variant="link" class="text-grey">
                <burst-icon :fill="'#AAAAAA'" /> Burst!
            </b-btn>

            <input
                type="file"
                multiple
                ref="fileInput"
                accept="video/mp4,video/x-m4v,video/*,.mkv"
                class="d-none"
                @change="updateFiles"
            />
            <create-burst-modal
                :modal-open.sync="createBurstModalOpen"
                @upload-burst-video="uploadBurstVideo"
                :league-id="leagueId"
                :is-reply="true"
            ></create-burst-modal>
        </div>
        <div
            v-if="variant == 'listButton'"
            class="burst-reply-action-v2"
            @click="openCreateBurstModal"
        >
            <b-spinner
                v-if="processingBurstReply"
                small
                class="mt-4 spinner-margin"
            ></b-spinner>
            <div v-else>
                
                    <img src="/images/icons/np_plus.svg" class="plus-icon" />
                
                <div class="action-description">
                    Burst it! Add a 20 sec video comment
                </div>
            </div>

            <input
                type="file"
                ref="fileInput"
                accept="video/mp4,video/x-m4v,video/*,.mkv"
                class="d-none"
                @change="updateFiles"
            />
            <create-burst-modal
                :modal-open.sync="createBurstModalOpen"
                @upload-burst-video="uploadBurstVideo"
                :league-id="leagueId"
                :is-reply="true"
            ></create-burst-modal>
        </div>
    </div>
</template>

<script>
import CreateBurstModal from "../Modals/CreateBurstModal";
import { requiredIf, required } from "vuelidate/lib/validators";
import BurstIcon from "../CustomIcons/BurstIcon";
export default {
    name: "CreateBurstReplyItem",
    components: {
        CreateBurstModal,
        BurstIcon,
    },
    props: {
        variant: {
            type: String,

            // Can be link or listButton
            default: "link",
        },
        post: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            // Number of times the upload function has run, prevent duplicate keys
            uploadCount: 1,
            loading: false,
            processingBurstReply: false,
            mediaItems: [],
            form: {
                post: null,
                privacy: 1,
                pinned: 0,
                donation: 0,
                files: [],
                links: [],
                burst: true,
                parent_id: null,
                taggedUserIds: [],
            },
            submitted: false,
            privacyOptions: [{ value: 1, text: "Private Post" }],
            chunkSize: 10100462, // Filesize in bytes, 10mb
            currentFile: null,
            currentFileIndex: 0,
            currentFileChunks: [],
            currentFileToken: "",
            createBurstModalOpen: false,
        };
    },
    computed: {
        leagueId() {
            return this.post.league.id;
        },
        uploadingMedia() {
            let loadingItems = [];
            if (this.mediaItems && this.mediaItems.length) {
                loadingItems = this.mediaItems.filter((item) => item.loading);
            }

            return loadingItems.length > 0;
        },
    },
    methods: {
        shouldBeChunked(file) {
            // To be chunked, a file should be greater than our desired chunk size
            return file.size / this.chunkSize > 1;
        },
        createChunks(file) {
            this.currentFileChunks = [];
            let chunks = Math.ceil(file.size / this.chunkSize);

            for (let i = 0; i < chunks; i++) {
                this.currentFileChunks.push(
                    file.slice(
                        i * this.chunkSize,
                        Math.min(
                            i * this.chunkSize + this.chunkSize,
                            file.size,
                        ),
                        file.type,
                    ),
                );
            }
        },
        async createTicket(file) {
            // When uploads need to be chunked, we need to request permission to store the file first
            // We also send the first chunk with the request, as well as some file metadata
            // We will try this (and all requests) a total of 5 times, with each failure increasing the delay 1 second.

            const firstChunkData = new FormData();
            firstChunkData.set("data", this.currentFileChunks[0], "1");
            firstChunkData.append("leagueId", this.post.league.id);

            let ticketResponse = null;
            let tries = 0;
            let requestSuccess = false;
            while (tries < 5 || !requestSuccess) {
                try {
                    ticketResponse = await axios({
                        url: "/media/getTicket",
                        method: "POST",
                        headers: {
                            "X-Content-Name": file.name,
                            "X-Content-Total-Length": file.size,
                            "Content-Type": "application/octet-stream",
                        },
                        data: firstChunkData,
                    });

                    if (ticketResponse.data.success) {
                        requestSuccess = true;
                        this.currentFileToken = ticketResponse.data.token;
                        return true;
                    } else {
                        // We treat this as a success so we can break the loop and display an error.
                        // The loop itself is for network/server errors.
                        requestSuccess = true;
                        await this.$swal(ticketResponse.data.message);
                        this.loading = false;
                        return false;
                    }
                } catch (error) {
                    tries++;
                    if (tries === 5) {
                        // At 5 tries, display the server error and quit trying to process.
                        await this.$swal(error.response.data.message);
                        this.loading = false;
                        return false;
                    }
                }
            }
        },
        async sendChunk(index) {
            const chunkData = new FormData();
            chunkData.set("data", this.currentFileChunks[index], index + 1);
            let tries = 0;
            let requestSuccess = false;
            while (tries < 5 && !requestSuccess) {
                try {
                    const uploadChunkResponse = await axios({
                        url: "/media/chunk",
                        method: "POST",
                        headers: {
                            "X-Media-Token": this.currentFileToken,
                            "X-Media-Chunk-Id": index + 1,
                            "X-Content-Name": this.currentFile.name,
                            "X-Content-Total-Length": this.currentFile.size,
                            "Content-Type": "application/octet-stream",
                        },
                        data: chunkData,
                    });

                    if (uploadChunkResponse.data.success) {
                        requestSuccess = true;
                        return true;
                    } else {
                        requestSuccess = true;
                        await this.$swal(uploadChunkResponse.data.message);
                        this.loading = false;
                        return false;
                    }
                } catch (error) {
                    tries++;
                    if (tries === 5) {
                        // At 5 tries, display the server error and quit trying to process.
                        await this.$swal(error.response.data.message);
                        this.loading = false;
                        return false;
                    }
                }
            }
            return false;
        },
        async closeTicket() {
            let tries = 0;
            let requestSuccess = false;
            while (tries < 5 && !requestSuccess) {
                try {
                    const closeTicketResponse = await axios({
                        url: "/media/closeTicket",
                        method: "POST",
                        headers: {
                            "X-Media-Token": this.currentFileToken,
                        },
                    });

                    if (closeTicketResponse.data.success) {
                        requestSuccess = true;
                        this.loading = false;
                        return closeTicketResponse.data.media;
                    } else {
                        requestSuccess = true;
                        await this.$swal(closeTicketResponse.data.message);
                        this.loading = false;
                        return false;
                    }
                } catch (error) {
                    await this.$swal(error.response.data.message);
                    this.loading = false;
                }
            }
        },
        async updateFiles(event) {
            
            // Burst reply loading spinner
            this.processingBurstReply = true;

            const files = Array.from(event.target.files);

            files.map(async (file, index) => {
                const tempId = `temp-${index}-${this.uploadCount}`;

                // Display the file as loading
                const reader = new FileReader();
                reader.readAsDataURL(file);

                let _this = this;

                reader.onload = async function (e) {
                    getDefaultThumbnail(e, tempId, file);
                };

                function getDefaultThumbnail(event, id, file) {
                    const defaultThumbnail = new Image();

                    // some purple bg
                    defaultThumbnail.src = "/images/VideoThumbnail.jpg";
                    _this.mediaItems.push({
                        id: id,
                        file: event.target.result,
                        type: file.type.substring(0, 5) === "video",
                        thumbnail: defaultThumbnail,
                        loading: true,
                    });
                }

                // Upload the file
                const media = await this.uploadImage(file);

                if (media && media.hasOwnProperty("id")) {
                    // Add the media id to the files array
                    this.form.files.push(media.id);

                    const mediaIndex = this.mediaItems.findIndex(
                        (item) => item.id === tempId,
                    );

                    this.mediaItems[mediaIndex].id = media.id;
                    this.mediaItems[mediaIndex].file = media.file;
                    this.mediaItems[mediaIndex].loading = false;
                    this.uploadCount += 1;
                } else {
                    // If no id was returned (due to server error or rejection, etc), remove the item
                    const mediaIndex = this.mediaItems.findIndex(
                        (item) => item.id === tempId,
                    );

                    this.mediaItems.splice(mediaIndex, 1);
                }
            });
        },
        async uploadImage(file) {
            this.currentFile = file;
            if (this.shouldBeChunked(file)) {
                this.createChunks(file);

                if (await this.createTicket(file)) {
                    let allChunksUploaded = true;
                    for (let i = 1; i < this.currentFileChunks.length; i++) {
                        const chunkResult = await this.sendChunk(i);
                        if (chunkResult === false) {
                            allChunksUploaded = false;
                            break;
                        }
                    }

                    // Once we have uploaded all the chunks, close the ticket and convert to media
                    if (allChunksUploaded) {
                        let closeTicketResponse = await this.closeTicket();
                        if (closeTicketResponse && closeTicketResponse.id) {
                            setTimeout(() => {
                                this.submitForm();
                            }, 1000);

                            return closeTicketResponse;
                        }

                        // If there are errors, the closeTicket method itself SHOULD handle them properly.
                    }
                }
            } else {
                /** Use formData since we are sending file data **/
                const data = new FormData();
                data.append("file", file);
                if (this.post.league.id) {
                    data.append("leagueId", this.post.league.id);
                }

                try {
                    const response = await axios.post("/media/add", data);
                    if (response.status == 200) {
                        setTimeout(() => {
                            this.submitForm();
                        }, 1000);
                    }
                    return response.data.media;
                } catch (error) {
                    // If there is an error for some reason, show alert
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.message
                    ) {
                        await this.$swal(error.response.data.message);
                    } else {
                        await this.$swal(error);
                    }

                    this.loading = false;
                }
            }
        },
        removeMedia(media) {
            const fileIndex = this.form.files.findIndex(
                (item) => item === media.id,
            );
            this.form.files.splice(fileIndex, 1);

            const mediaIndex = this.mediaItems.findIndex(
                (item) => item.id === media.id,
            );
            this.mediaItems.splice(mediaIndex, 1);
        },
        resetForm() {
            this.$refs.fileInput.value = "";

            this.form = {
                post: null,
                parent_id: null,
                leagueId: null,
                privacy: 1,
                pinned: 0,
                burst: true,
                files: [],
                links: [],
            };
            this.mediaItems = [];
            this.loading = false;
            this.submitted = false;

            // Burst reply loading spinner
            this.processingBurstReply = false;
        },
        async submitForm() {
            this.loading = true;
            try {
                const files = this.form.files;

                const requestData = {
                    privacy: 1,
                    pinned: 0,
                    files: files,
                    postId: null,
                    burst: true,

                    // Burst reply is a child of the main burst
                    parent_id: this.post.id,
                    leagueId: this.post.league.id,
                    taggedUserIds: this.form.taggedUserIds,
                };

                // Attempt to create post
                const response = await axios.post(
                    "/leagues/posts/add",
                    requestData,
                );

                if (response.data.post.active) {
                    this.$emit("post-created", response.data.post);
                } else {
                    this.$root.$emit("video-posted", true);
                    await this.$swal({
                        title: "Video Converting...",
                        text:
                            "Your video needs to be converted before it is posted, we will let you know when it is live.",
                    });
                }
                this.submitted = true;
                this.resetForm();
            } catch (error) {
                console.log("error: ", error);
                // If there is an error for some reason, show alert
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                ) {
                    await this.$swal(error.response.data.message);
                } else {
                    await this.$swal(error);
                }
                this.loading = false;

                // Burst reply loading spinner
                this.processingBurstReply = false;
            }
        },
        addPhotoOrVideo(e) {
            // Reset the burst and parent id flags
            this.form.burst = false;
            this.form.parent_id = null;
            this.$refs.fileInput.click();
        },
        openCreateBurstModal() {
            this.createBurstModalOpen = true;
        },
        openVideoPopUpModal() {
            this.VideoPopUpModal = true;
        },
        uploadBurstVideo(taggedUserIds) {
            this.form.taggedUserIds = taggedUserIds;
            this.form.burst = true;

            // IMPORTANT!!! Set the parent_id to make this a burst reply
            this.form.parent_id = this.post.id;
            this.$refs.fileInput.click();
            this.createBurstModalOpen = false;
        },
    },
};
</script>

<style scoped lang="scss">
.burst-reply-action-v2 {
    cursor: pointer;
    position: relative;
    display: inline-table;
    width: 110px;
    height: 115px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 5px;
    overflow: hidden;
    background-color: #5451de;
    color: white;
    text-align: center;
    padding-top: 1px;
    border-radius:20px;

    @media (max-width:768px){
        margin-left: 5px;
    }
    
    .action-description {
        // color: white;
        // font-size: 13px;
        // text-align: center;
        font-family: "Roboto Condensed", sans-serif;
        font-size: 14px;
        line-height: 16px;
        padding: 23px 26px;
        line-height: 100%;
        text-align: center;
    }
    .plus-icon{
        margin-bottom:-25px;
    }
}
.spinner-margin {
    margin-top: 43px !important;
    height: 25px;
    width: 25px;
}
</style>
