<template>
    <b-form @submit.prevent="routeToSearch">
        <b-input-group
            :class="dark ? 'input-group-dark' : null"
            :size="small ? 'sm' : null"
        >
            <b-form-input
                v-model="searchTerm"
                :class="dark ? 'form-control-black' : null"
                placeholder="Search Leagues..."
            ></b-form-input>
            <template v-slot:append>
                <b-input-group-text class="clickable" @click="routeToSearch">
                    <i v-show="!loading" class="fas fa-search"></i>
                    <i v-show="loading" class="fas fa-spinner fa-pulse"></i>
                </b-input-group-text>
            </template>
        </b-input-group>
    </b-form>
</template>

<script>
export default {
    name: "LeagueSearchForm",
    props: {
        dark: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            searchTerm: "",
            loading: false,
        };
    },
    methods: {
        routeToSearch() {
            this.loading = true;
            if (this.searchTerm) {
                window.location.href = `/browse-leagues?search=${this.searchTerm}`;
            } else {
                window.location.href = `/browse-leagues`;
            }
        },
    },
};
</script>

<style scoped></style>
