<template>
    <div>
        <b-row>
            <b-col sm="12" md="4">
                <div>
                    <div class="text-sm font-weight-bold">Store Earnings</div>
                    <div
                        class="text-purple font-weight-bolded"
                        style="font-size: 40px"
                    >
                        {{ storeEarnings }}
                    </div>
                </div>
            </b-col>
        </b-row>

        <b-row
            v-for="item in leagueOrders"
            :key="item.orderNumber"
            class="transaction-item"
        >
            <b-col class="p-4">
                <b-row class="transaction-item-header">
                    <b-col></b-col>
                    <b-col>Product</b-col>
                    <b-col>Payment</b-col>
                </b-row>

                <b-row class="transaction-item-body">
                    <b-col>
                        <b-avatar
                            v-if="item.coverMedia"
                            square
                            :src="item.coverMedia.location"
                            variant="white"
                            class="pr-2 mr-md-2"
                            size="70"
                            style="width: 86px"
                        ></b-avatar>
                    </b-col>
                    <b-col>
                        {{ item.productName }}
                    </b-col>
                    <b-col> ${{ item.paymentAmount }} </b-col>
                </b-row>
                <b-row class="transaction-item-footer">
                    <b-col>
                        <span>
                            <b>Member:</b>
                            {{ item.firstName }}
                            {{ item.lastName }}
                        </span>
                    </b-col>
                    <b-col>
                        <span>
                            <b>Order#:</b>
                            {{ item.orderNumber }}
                        </span>
                    </b-col>
                </b-row>
                <b-row class="transaction-item-date">
                    <b-col>
                        <div class="date">
                            {{ currentDate(item.date) }}
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import LineChart from "../../Charts/LineChart";

export default {
    name: "LeagueSettingsEarnings",
    components: {
        LineChart,
    },
    props: {
        authUser: {
            type: Object,
            required: true,
        },
        league: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            storeEarnings: "Loading",
            leagueOrders: [],
            selectedYear: null,
            selectedMonth: null,
        };
    },
    mounted() {
        this.getGraphData();
        this.getLeaguOrders();
    },
    computed: {
        coverMediaStyle() {
            return this.$mq === "sm" ? "width:50px" : "width:100px";
        },
    },
    methods: {
        async getGraphData() {
            try {
                const { data, status } = await axios.post("/store/graph", {
                    leagueId: this.league.id,
                    year: this.selectedYear,
                    month: this.selectedMonth,
                });

                if (status === 200) {
                    this.storeEarnings = `$${data.total.toFixed(2)}`;
                }
            } catch (error) {
                await this.$swal(
                    error.response
                        ? error.response.data.message
                        : "Oops! Something went wrong. Please try again.",
                );
            }
        },
        currentDate(date) {
            const monthNames = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ];
            const current = new Date(date);
            return `${
                monthNames[current.getMonth()]
            } ${current.getDate()}, ${current.getFullYear()}`;
        },
        async getLeaguOrders() {
            try {
                const { data, status } = await axios.post(
                    "/store/leagueOrders",
                    {
                        leagueId: this.league.id,
                    },
                );

                if (status === 200) {
                    this.leagueOrders = data.orders.map((element) => {
                        let order = {
                            productId: element.product.id,
                            firstName: element.user.firstName,
                            lastName: element.user.lastName,
                            avatar: element.user.avatar,
                            productName: element.product.name,
                            orderNumber: element.id,
                            paymentAmount: element.product.price,
                            date: element.date,
                        };
                        order.coverMedia = element.product.media.find(
                            (item) => item.cover,
                        );
                        return order;
                    });
                }
            } catch (error) {
                await this.$swal(
                    error.response
                        ? error.response.data.message
                        : "Oops! Something went wrong. Please try again.",
                );
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.transaction-item {
    margin-bottom: 32px;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    .transaction-item-header {
        font-weight: bold;
        color: #aaaaaa;
        border-bottom: 1px solid #e7e7e7;
        padding-bottom: 8px;
    }
    .transaction-item-body {
        padding-top: 16px;
        color: #0e0e0e;
    }
    .transaction-item-footer {
        padding-top: 16px;
        align-items: center;
    }
    .transaction-item-date {
        padding-top: 16px;
        color: #aaaaaa;
        .date: {
            margin-left: auto;
            font-size: 14px;
        }
    }
}
</style>
