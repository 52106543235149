<template>
    <b-modal
        id="user-disable-account"
        v-model="open"
        centered
        title="Disable your account"
        title-class="text-muted"
        ok-title="Continue"
        ok-variant="danger"
        @ok="disableUser"
        :ok-disabled="password.length < 5 || selected.length <= 2"
        cancel-title="Keep Account"
        cancel-variant="outline-secondary"
    >
        <p>Deactivating your account will hide your profile and prevent users from messaging you. To reactivate your account later, simply log in!</p>
        <b-form-group
            label="Reason(s) for deactivation (choose up to 3)"
            label-class="font-weight-bold"
        >
            <b-form-checkbox-group
                v-model="selected"
                :options="options"
                name="deactivate-reasons"
                stacked
            ></b-form-checkbox-group>
        </b-form-group>

        <b-form-group label="Password Required" label-for="password-required">
            <b-form-input
                v-model="password"
                id="password-required"
                type="password"
            ></b-form-input>
        </b-form-group>
    </b-modal>
</template>
<script>
export default {
    name: "UserDisableModal",
    props: {
        modalOpen: Boolean,
        options: {
            type: Array,
            required: true
        }
    },
    computed: {
        open: {
            get() {
                return this.modalOpen;
            },
            set(value) {
                this.$emit("update:modalOpen", value);
            },
        },
    },
    data(){
        return {
            password: "",
            selected: [],
        }
    },
    methods: {
        async disableUser() {
            try {
                const data = {
                    password: this.password,
                    reasons: this.selected,
                };
                const response = await axios.post("/account/disable", data);
                await this.$swal({
                    title: "Your account has been disabled!",
                    icon: "success",
                    type: "success",
                    position: "center",
                }).then(() => {
                    window.location = "/logout";
                });
                this.open = await false;
            } catch (error) {
                console.error(error);
                await this.$swal(error.response?.data.message);
            }
        }
    }
}
</script>