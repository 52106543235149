<template>
    <div>
        <b-form>
            <b-form-row class="align-items-center">
                <b-col cols="12" md="6">
                    <div class="d-inline-flex align-items-baseline">
                        <h1 class="h4 mb-0">Browse Leagues</h1>
                        <b-button
                            variant="link"
                            size="sm"
                            class="ml-3"
                            @click="showCategories"
                            >Browse Categories</b-button
                        >
                    </div>
                </b-col>
                <b-col cols="12" md="6" lg="3" class="ml-lg-auto">
                    <b-input-group>
                        <template v-slot:prepend>
                            <b-input-group-text
                                ><i class="fas fa-search"></i
                            ></b-input-group-text>
                        </template>
                        <b-form-input
                            type="text"
                            v-model="searchValue"
                            placeholder="Search for a league..."
                            @input="getLeagues"
                        ></b-form-input>
                    </b-input-group>
                </b-col>
                <!-- Request removal - 9/30 -->
                <!--<b-col cols="12" md="6" lg="3">
                    <v-select
                        label="category"
                        placeholder="League Category..."
                        v-model="searchForm.category"
                        :options="categories"
                        :reduce="(category) => category.id"
                        @search="getCategories"
                        class="form-control"
                        @input="getLeagues"
                    >
                    </v-select>
                </b-col>-->
            </b-form-row>
            <b-form-row v-if="selectedCategory" class="my-3">
                <b-col cols="6" sm="4" md="3" lg="2">
                    <div
                        class="d-flex bg-black text-white align-items-center justify-content-between rounded pl-3 py-2"
                    >
                        <span>
                            {{ selectedCategory.category }}
                        </span>
                        <b-button
                            variant="text"
                            class="text-white"
                            @click="$emit('remove-category')"
                        >
                            <i class="fas fa-times"></i>
                        </b-button>
                    </div>
                </b-col>
            </b-form-row>
        </b-form>
        <hr class="mb-5" />
        <b-row id="league-list" v-show="leagues.length > 0">
            <b-col cols="12" lg="6" v-for="league in paginatedLeagues" :key="league.id">
                <league-card
                    :league="league"
                    :is-owner="league.owner"
                    :is-sub-league="league.parentLeagueId ? true : false"
                    class="mb-4"
                    :is-search-league="true"
                    @click="routeToLeague"
                ></league-card>
            </b-col>
        </b-row>
        <b-pagination
            @change="onPageChanged"
            v-if="totalLeagues > perPage"
            v-model="currentPage"
            :total-rows="totalLeagues"
            :per-page="perPage"
            aria-controls="league-list"
            align="center"
            class="my-3"
        ></b-pagination>
        <div v-show="leagues.length === 0">
            <h5 class="text-muted text-center">No Leagues Found...</h5>
        </div>
    </div>
</template>

<script>
import LeagueCard from "../../Cards/LeagueCard";

export default {
    name: "LeagueSearch",
    components: { LeagueCard },
    props: {
        search: String,
        selectedCategory: Object,
    },
    data() {
        return {
            loading: true,
            searchValue: "",
            perPage: 20,
            currentPage: 1,
            leagues: [],
            totalLeagues: 0,
            paginatedLeagues: [],
        };
    },
    created() {
        // Prevent hitting the server too many times on search
        this.getLeagues = _.debounce(this.getLeagues, 250);
    },
    mounted() {
        this.searchValue = this.search;
        this.getLeagues();
    },
    watch: {
        selectedCategory(oldValue, newValue) {
            this.getLeagues();
        },
    },
    methods: {
        /* Requested Removal - 9/30 */
        /*async getCategories(search, loading) {
            if (loading) {
                loading(true);
            }
            const response = await axios.post(`/leagues/categories/search`, {
                search: search,
            });
            this.categories = response.data.categories;
            if (loading) {
                loading(false);
            }
        },*/
        paginate(pageSize, pageNumber) {
            // Get all leagues and split them at the intended place.
            let leaguesToParse = this.leagues;
            this.paginatedLeagues = leaguesToParse.slice(
                pageNumber * pageSize,
                (pageNumber + 1) * pageSize
            );
        },
        onPageChanged(page) {
            this.paginate(this.perPage, page - 1);
        },
        async getLeagues() {
            this.loading = true;
            this.updateURL();
            try {
                const response = await axios.get("/leagues/search", {
                    params: {
                        search: this.searchValue,
                        category: this.selectedCategory
                            ? this.selectedCategory.id
                            : null,
                    },
                });

                this.leagues = response.data.leagues;
                this.totalLeagues = response.data.total;
                this.loading = false;
                this.paginate(this.perPage, 0);
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
        routeToLeague(league) {
            window.location.href = `/${league.slug}`;
        },
        updateURL() {
            if (this.searchValue) {
                window.history.pushState(
                    null,
                    "",
                    `?search=${this.searchValue}`
                );
            }
        },
        showCategories() {
            this.leagues = []; // Reseting categories will fix the blinking issue.
            this.$emit("show-categories");
        },
    },
};
</script>

<style scoped></style>
