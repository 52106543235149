<template>
    <div class="user-avatar-container d-flex align-items-center">
        <b-avatar
            :src="user.avatar_path"
            variant="grey"
            class="mr-xl-2"
            :size="size == '' ? '2rem' : size"
            style="border: 1px white solid;"
        ></b-avatar>
        <span class="pr-2 d-none d-xl-block">{{ user.name }}</span>
    </div>
</template>

<script>
export default {
    name: "UserAvatar",
    props: {
        user: {
            type: Object,
            required: true,
        },
        size: {
            type: String,
            default: "",
        },
    },
};
</script>

<style scoped lang="scss">
.user-avatar-container {
    display: flex;
    align-items: center;
    background-color: #3e3e3e;
    border-radius: 60px;
    color: white;
    padding: 0.25rem;
    font-size: 1rem;
    &:hover {
        background-color: #4b4b4b;
    }
}
</style>
