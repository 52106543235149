<template>
    <b-overlay :show="loading" rounded="sm">
        <b-card no-body>
            <b-btn
                v-if="!loading && deletable"
                variant="purple"
                pill
                size="sm"
                class="d-block abs-top-right remove-button"
                v-b-tooltip.hover.left
                title="Remove"
                @click.stop="$emit('remove-link')"
            >
                <i class="fas fa-times-circle"></i>
            </b-btn>
            <div
                v-if="link.image"
                class="embed-responsive embed-responsive-16by9"
            >
                <b-card-img
                    :src="link.image"
                    class="embed-responsive-item og-image"
                ></b-card-img>
            </div>
            <b-card-body>
                <a
                    class="small stretched-link"
                    :href="link.url"
                    target="_blank"
                    >{{ link.url }}</a
                >
                <div class="h6 mt-1 mb-2">{{ link.title }}</div>
                <p class="text-muted mb-0" style="font-size: 14px;">
                    {{ link.description }}
                </p>
            </b-card-body>
        </b-card>
    </b-overlay>
</template>

<script>
export default {
    name: "LinkCard",
    props: {
        link: {
            type: Object,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        deletable: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped>
.og-image {
    object-fit: cover;
    object-position: center;
}
.remove-button {
    z-index: 1021;
}
</style>
