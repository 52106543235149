<template>
    <b-container id="chat-container" fluid class="px-0">
        <b-row no-gutters>
            <b-col class="col-12 col-md-5">
                <div class="chat-header">
                    <div
                        class="chat-top-tabs active"
                    >
                        <div
                            class="chat-top-tab-item"
                            :class="{ active: activeTopTab == 'inbox' }"
                            @click="handleChangeTopTab($event, 'inbox')"
                        >
                            Inbox
                        </div>
                        <div
                            class="chat-top-tab-item"
                            :class="{ active: activeTopTab == 'sent' }"
                            @click="handleChangeTopTab($event, 'sent')"
                        >
                            Sent
                        </div>
                        <div
                            class="chat-top-tab-item"
                            :class="{ active: activeTopTab == 'deleted' }"
                            @click="handleChangeTopTab($event, 'deleted')"
                        >
                            Deleted
                        </div>
                    </div>
                    <div class="filter-compose-group">
                        <div
                            class="chat-actions"
                            :class="{ active: !hideActions }"
                        >
                            <button
                                type="button"
                                class="btn btn-clear filter-btn"
                                @click="handleShowFilterByNamesButton($event)"
                            >
                                <i class="fas fa-filter fa-lg"></i>
                            </button>
                            <div class="filter-by-names-wrapper">
                                <input
                                    v-if="showFilterByNames"
                                    id="filter-by-names"
                                    type="text"
                                    class="form-control"
                                    placeholder="Filter by Name..."
                                    @keyup="searchConversations"
                                />
                            </div>
                            <button
                                type="button"
                                class="btn btn-clear compose-btn"
                                @click="handleComposeBtnClick"
                            >
                                <compose-icon />
                            </button>
                        </div>
                        <div
                            v-if="showComposeNewConversationForm"
                            class="compose-new-conversation-form"
                        >
                            <user-search-form
                                ref="userSearch"
                                @user-chosen="handleUserSelected"
                                search-url="/inbox/users/search"
                            ></user-search-form>
                            <div>
                                <textarea
                                    class="form-control"
                                    placeholder="Type your message here..."
                                    v-model="newConversationMessage"
                                ></textarea>
                                <button
                                    type="button"
                                    class="btn btn-primary btn-block mt-2"
                                    :disabled="
                                        !startConversationButtonIsEnabled
                                    "
                                    @click="startConversation"
                                >
                                    Start Conversation
                                    <i class="fas fa-paper-plane"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col class="col-12 col-md-7">
                <conversations-list
                    v-if="showConversations"
                    ref="conversationList"
                    :isLoading="isLoading"
                    :conversations="filteredConversations"
                    :user-id="authUser.id"
                    :activeConvoId="conversationId"
                    :list-type="activeTopTab"
                    @select-convo="setConversation"
                    @set-participant="setParticipant"
                    @set-disable-message-form="setDisableMessageForm"
                ></conversations-list>
                <chat-conversation
                    v-if="!showConversations"
                    :user-id="authUser.id"
                    :conversation-id="conversationId"
                    :participant-id="participantId"
                    @message-added="updateConvos = true"
                    :disable-message-form="disableMessageForm"
                    @show-conversations-list="handleShowConversationsList"
                ></chat-conversation>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import ConversationsList from "../../Lists/ConversationsList";
import ChatConversation from "./ChatConversation";
import UserSearchForm from "../../Forms/UserForms/UserSearchForm";
import ComposeIcon from "../../CustomIcons/ComposeIcon";
import Bowser from "bowser";
export default {
    name: "ChatPage",
    components: {
        ConversationsList,
        ChatConversation,
        UserSearchForm,
        ComposeIcon,
    },
    props: {
        authUser: {
            type: Object,
            required: true,
        },
        convoId: Number,
    },
    data() {
        return {
            os: null,
            browser: null,
            conversationId: -1,
            updateConvos: false,
            showConversation: true,
            disableMessageForm: false,
            participantId: null,
            conversations: [],
            filteredConversations: [],
            newConversationSelectedUser: null,
            newConversationMessage: "",
            showComposeNewConversationForm: false,
            activeTopTab: "inbox",
            showFilterByNames: false,
            showConversations: false,
            isLoading: false,
        };
    },
    beforeMount() {
        const agent = Bowser.getParser(window.navigator.userAgent);
        this.os = agent.getOS().name.toLowerCase();
        this.browser = agent.getBrowser().name.toLowerCase();
    },
    mounted() {
        if (this.convoId) {
            this.conversationId = this.convoId;
        }

        this.handleChangeTopTab(null, 'inbox')
    },
    computed: {
        startConversationButtonIsEnabled() {
            return (
                !!this.newConversationMessage.length &&
                this.newConversationSelectedUser
            );
        },
        hideActions() {
            return !this.showConversations && window.innerWidth < 768;
        },
    },
    methods: {
        handleComposeBtnClick() {
            this.showComposeNewConversationForm = !this
                .showComposeNewConversationForm;
        },
        searchConversations($event) {
            const query = $event.target.value;
            const results = this.conversations.filter((item) => {
                const participantName = item.participants[0]?.name;
                return query
                    .toLowerCase()
                    .split(" ")
                    .every((v) => participantName.toLowerCase().includes(v));
            });
            this.filteredConversations = results;
        },
        async getInboxChats() {
            try {
                const { data, status } = await axios.post("/inbox/getChats", {
                    is_hidden: 0,
                    // per_page: this.perPage,
                    // page: this.currentPage,
                });
                if (status === 200) {
                    this.conversations = data;
                    this.filteredConversations = data;
                    this.$emit("convos-updated");
                }
                this.isLoading = false;
            } catch (error) {
                console.log('error: ', error);
                // If there is an error for some reason, show alert
                //await this.$swal(error.response.data.message);
                this.isLoading = false;
            }
        },
        async getHiddenChats() {
            try {
                const { data, status } = await axios.post("/inbox/getChats", {
                    is_hidden: 1,
                });
                if (status === 200) {
                    this.conversations = data;
                    this.filteredConversations = data;
                    this.$emit("convos-updated");
                }
                this.isLoading = false;
            } catch (error) {
                console.log('error: ', error);
                // If there is an error for some reason, show alert
                //await this.$swal(error.response.data.message);
                this.isLoading = false;
            }
        },
        async getChatsWithUnreadMessages() {
            try {
                const { data, status } = await axios.post("/inbox/getChats", {
                    is_hidden: 0,
                    unread_only: 1,
                });
                if (status === 200) {
                    this.conversations = data;
                    this.filteredConversations = data;
                    this.$emit("convos-updated");
                }
                this.isLoading = false;
            } catch (error) {
                console.log('error: ', error);
                // If there is an error for some reason, show alert
                // /await this.$swal(error.response.data.message);
                this.isLoading = false;
            }
        },
        async getSentMessages() {
            try {
                const { data, status } = await axios.post("/inbox/getSentMessages", {
                    is_hidden: 0,
                    unread_only: 0,
                });

                if (status === 200) {
                    this.conversations = data;
                    this.filteredConversations = data;
                    this.$emit("convos-updated");
                }
                this.isLoading = false;
                
            } catch (error) {
                console.log('error: ', error);
                // If there is an error for some reason, show alert
                // /await this.$swal(error.response.data.message);
                this.isLoading = false;
            }
        },
        handleUserSelected(user) {
            this.newConversationSelectedUser = user;
        },
        async startConversation() {
            if (!this.newConversationSelectedUser) {
                return alert("Please select a user");
            }

            if (!this.newConversationMessage.length) {
                return alert("Please enter a message");
            }

            // Before creating a new conversation, make sure a conversation with this user
            // does not already exist.
            let found = false;
            for (let i = 0; i < this.conversations.length; i++) {
                if (
                    this.conversations[i]?.participants[0]?.id ===
                    this.newConversationSelectedUser.id
                ) {
                    found = true;
                    this.$emit("select-convo", this.conversations[i].id);
                    break;
                }
            }

            try {
                const { data, status } = await axios.post("/inbox/create", {
                    mediaId: null,
                    message: this.newConversationMessage,
                    skipIsFriends: false,
                    users: [this.newConversationSelectedUser.id],
                });

                if (status === 200 && data.success && data.chatId) {
                    await this.getInboxChats();
                    this.showConversations = false;
                    this.conversationId = data.chatId;
                    this.newConversationSelectedUser = null;
                    this.newConversationMessage = "";
                    this.showComposeNewConversationForm = false;
                    this.$refs.userSearch.resetForm();
                }
            } catch (error) {
                await this.$swal(error.response.data.message);
            }
        },
        setConversation(id) {
            this.showComposeNewConversationForm = false;
            if (id > 0) {
                this.conversationId = id;

                // SHow the chat view
                this.showConversations = false;
                window.history.pushState(null, "", `?convo=${id}`);
            }
        },
        setParticipant(id) {
            this.participantId = id;
        },
        setDisableMessageForm(disable) {
            this.disableMessageForm = !!+disable; // convert string to int with + and convert the result to boolean with !!
        },
        handleShowConversationsList() {
            this.showConversations = true;
            this.conversationId = -1;
            this.participantId = null;
        },
        handleChangeTopTab(e, tabName) {
            this.conversations = [];
            this.filteredConversations = [];
            this.isLoading = true;
            this.activeTopTab = tabName;
            this.conversationId = -1;
            this.participantId = null;
            this.showConversations = true;
            switch (tabName) {
                case "inbox":
                    return this.getInboxChats();
                case "deleted":
                    return this.getHiddenChats();
                case "sent": {
                    //return this.getChatsWithUnreadMessages();
                    return this.getSentMessages();
                }
                default:
                    return [];
            }
        },
        handleShowFilterByNamesButton(event) {
            event.preventDefault();
            this.showFilterByNames = !this.showFilterByNames;
            setTimeout(() => {
                if (document.getElementById("filter-by-names")) {
                    document.getElementById("filter-by-names").focus();
                }
            }, 100);
        },
    },
};
</script>

<style scoped lang="scss">
#chat-container {
    .chat-header {
        display: flex;
        flex-direction: column;
    }
    .chat-top-tabs {
        display: none;
        flex-direction: row;
        &.active {
            display: flex;
        }
        .chat-top-tab-item {
            width: 33%;
            text-align: center;
            padding: 16px;
            color: #757373;
            cursor: pointer;
            &.active {
                border-bottom: 2px solid red;
            }
        }
        @media (min-width: 768px) {
            flex-direction: column;
            order: 2;
            .chat-top-tab-item {
                width: 100%;
                text-align: center;
                // padding: 16px;
                // color: #757373;
                // cursor: pointer;
                &.active {
                    border-bottom: none;
                    background-color: #dedede;
                }
            }
        }
    }
    .chat-actions {
        display: none;
        flex-direction: row;
        align-items: center;
        padding-left: 16px;
        padding-right: 16px;
        margin-top: 8px;
        margin-bottom: 2px;
        &.active {
            display: flex;
        }
        .fas.fa-filter {
            color: #5451de;
        }
        #filter-by-names {
            margin-right: 8px;
        }
        .filter-by-names-wrapper {
            width: 100%;
        }
    }
    .compose-new-conversation-form {
        margin-top: 16px;
        margin-bottom: 16px;
        padding-left: 16px;
        padding-right: 16px;
    }
}
</style>
