<template>
    <b-modal
        id="share-post-modal"
        v-model="open"
        centered
        hide-header
        hide-footer
    >
        <h3 class="mb-3">Share Post</h3>
        <v-select
            id="share-post"
            v-if="type === 'chat'"
            label="name"
            placeholder="Select a friend"
            v-model="friendId"
            :options="friendOptions"
            :reduce="(friend) => friend.id"
            class="form-control my-3"
            multiple
        >
        </v-select>
        <b-form-input
            v-else
            v-model="email"
            placeholder="Enter an email address"
        ></b-form-input>
        <post-card-small :post="post" class="mt-4"></post-card-small>
        <div class="d-flex justify-content-end mt-4">
            <b-btn
                variant="ghost-purple"
                type="close"
                pill
                class="btn-padded"
                @click="open = false"
            >
                Cancel
            </b-btn>
            <b-btn
                variant="purple"
                type="submit"
                pill
                class="btn-padded"
                :disabled="submitDisabled"
                @click="sharePost"
            >
                {{ submitText }}
            </b-btn>
        </div>
    </b-modal>
</template>

<script>
import MessageForm from "../Forms/MessageForm";
import PostCardSmall from "../Cards/PostCardSmall";
export default {
    name: "SharePostModal",
    components: { PostCardSmall, MessageForm },
    props: {
        modalOpen: Boolean,
        authUserId: {
            type: Number,
            required: true,
        },
        post: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            default: "chat",
        },
    },
    data() {
        return {
            loading: false,
            email: null,
            friendId: null,
            friends: [],
        };
    },
    mounted() {
        if (this.type === "chat") {
            this.getFriends();
        }
    },
    computed: {
        open: {
            get() {
                return this.modalOpen;
            },
            set(value) {
                this.$emit("update:modalOpen", value);
            },
        },
        friendOptions() {
            const friendOptions = [];

            this.friends.map((friend) =>
                friendOptions.push({
                    id: friend.user.id,
                    name: `${friend.user.firstName} ${friend.user.lastName}`,
                })
            );

            return friendOptions;
        },
        submitDisabled() {
            if (!this.loading) {
                return this.type === "chat"
                    ? this.friendId === null
                    : this.email === null;
            }
            return true;
        },
        submitText() {
            return this.loading ? "Submitting..." : "Submit";
        },
    },
    methods: {
        async getFriends() {
            const data = {
                userId: this.authUserId,
                per_page: 100,
                page: 1,
            };

            try {
                const response = await axios.post(`/users/friends`, data);
                this.friends = response.data.friends;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
        async sharePost() {
            this.loading = true;
            try {
                const requestData = {
                    postId: this.post.id,
                    leagueId: this.post.league.id,
                    email: this.email,
                    users: this.friendId ? this.friendId : null,
                };
                // Attempt to create a message
                const response = await axios.post(
                    "/leagues/sharePost",
                    requestData
                );
                this.$bvModal.hide("share-post-modal");
                this.open = false;
                //window.location.href = `/inbox?convo=${response.data.chatId}`;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped></style>
