<template>
    <div class="mb-5">
        <div v-if="leagueIsPrivate">
            <h5 class="text-center text-muted my-5">
                This league is private, you must join the league to see their
                store.
            </h5>
        </div>

        <div
            v-if="!leagueIsPrivate"
            id="store-products-list"
            content-class="mt-4"
        >
            <b-row cols="12" class="mb-3">
                <b-col sm="12" md="5">
                    <div class="float-left">
                        <a
                            href="#"
                            @click="storeViewIsActive = !storeViewIsActive"
                            class="text-base text-decoration-underline"
                        >
                            <span
                                v-if="
                                    storeViewIsActive &&
                                    !isOwner
                                "
                            >
                                View Transaction History
                            </span>
                            <span v-if="!storeViewIsActive">
                                Go Back to Store
                            </span>
                        </a>
                    </div>
                </b-col>

                <!-- Most Recent -->
                <b-col cols="6" md="3" v-if="storeViewIsActive">
                    <b-form-group
                        label-for="type-select"
                        class="text-sm font-weight-bold mt-2 mt-md-0"
                    >
                        <b-form-select
                            id="type-select"
                            class="text-xs text-capitalize font-weight-bold"
                            v-model="selectedMediaLabel"
                            :options="mediaLabelOptions"
                        ></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col
                    sm="12"
                    md="4"
                    class="position-relative"
                    v-if="storeViewIsActive"
                >
                    <b-input-group>
                        <b-input-group-prepend>
                            <span class="input-group-text">
                                <i class="fa fa-search text-grey text-xs"></i>
                            </span>
                        </b-input-group-prepend>
                        <b-form-input
                            class="text-black text-xs"
                            v-model="searchQuery"
                            placeholder="Search History"
                            type="search"
                            autocomplete="off"
                        >
                        </b-form-input>
                    </b-input-group>
                </b-col>
            </b-row>

            <b-row
                v-if="storeViewIsActive"
                cols="1"
                cols-sm="1"
                cols-md="2"
                cols-lg="3"
            >
                <!-- Add Digital Item -->
                <b-col v-if="isOwner" class="mb-4">
                    <div
                        @click="createProductModal"
                        class="store-digital-product-card"
                    >
                        <div class="add-item-btn-group">
                            <b-btn variant="clear">
                                <svg
                                    width="76"
                                    height="76"
                                    viewBox="0 0 76 76"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M37.9997 1.58203C17.919 1.58203 1.58203 17.919 1.58203 37.9997C1.58203 58.0804 17.919 74.4174 37.9997 74.4174C58.0804 74.4174 74.4174 58.0804 74.4174 37.9997C74.4174 17.919 58.0804 1.58203 37.9997 1.58203ZM37.9997 70.3681C20.1519 70.3681 5.63131 55.8483 5.63131 37.9997C5.63131 20.1511 20.1511 5.63131 37.9997 5.63131C55.8483 5.63131 70.3681 20.1511 70.3681 37.9997C70.3681 55.8483 55.8483 70.3681 37.9997 70.3681Z"
                                        fill="#AAAAAA"
                                    />
                                    <path
                                        d="M40.025 19.793H35.9756V35.9756H19.793V40.025H35.9756V56.2077H40.025V40.025H56.2077V35.9756H40.025V19.793Z"
                                        fill="#AAAAAA"
                                    />
                                </svg>
                            </b-btn>
                            <div>Add Digital Item</div>
                        </div>
                    </div>
                </b-col>

                <b-col
                    v-for="(item, index) in filteredStoreProductItems"
                    :key="item.id"
                >
                    <store-product-item
                        :product="item"
                        :passing-index="index"
                        :is-owner="isOwner"
                        @files-info="getFilesInfo"
                        @products-id="getProductsId"
                        @open-edit-product-modal="openEditProductModal"
                        @product-purchased="productPurchased"
                        @product-deleted="handleProductDeleted"
                    ></store-product-item>
                </b-col>
            </b-row>
            <b-row v-if="storeHasNoProductsForSaleYet">
                <b-col class="text-center text-weight-bold text-xl mt-5">
                    This league does not currently have any items available for
                    sale.
                </b-col>
            </b-row>

            <!-- View Store Transactions -->
            <b-row v-if="!storeViewIsActive">
                <b-col v-if="filteredStoreProductItems.length >= 1">
                    <b-row
                        v-for="item in orders"
                        :key="item.id"
                        class="transaction-item"
                    >
                        <b-col class="p-4">
                            <b-row class="transaction-item-header">
                                <b-col></b-col>
                                <b-col>Product</b-col>
                                <b-col>Payment</b-col>
                            </b-row>

                            <b-row class="transaction-item-body">
                                <b-col>
                                    <b-avatar
                                        v-if="item.product.media"
                                        square
                                        :src="item.product.media[0].location"
                                        variant="white"
                                        class="pr-2 mr-md-2"
                                        size="70"
                                        style="width: 86px"
                                    ></b-avatar>
                                </b-col>
                                <b-col>
                                    {{ item.product.name }}
                                </b-col>
                                <b-col> ${{ item.product.price }} </b-col>
                            </b-row>
                            <b-row class="transaction-item-footer">
                                <b-col>
                                    <div
                                        v-if="
                                            !item.product.unlimited_downloads &&
                                            item.remainingDownloads !== null
                                        "
                                    >
                                        {{ item.remainingDownloads }} remaining
                                        downloads
                                    </div>
                                    <div
                                        v-if="item.product.unlimited_downloads"
                                    >
                                        Unlimited Downloads
                                    </div>
                                </b-col>
                                <b-col>
                                    <store-download-item
                                        v-if="
                                            (item.product.files &&
                                                item.remainingDownloads !==
                                                    0) ||
                                            item.product.unlimited_downloads
                                        "
                                        :files="item.product.files"
                                        :item-id="item.product.id"
                                        :order-id="item.id"
                                        @product-downloaded="productDownloaded"
                                    ></store-download-item>
                                </b-col>
                            </b-row>
                            <b-row class="transaction-item-date">
                                <b-col>
                                    <div class="date">
                                        {{
                                            currentDate(item.product.created_at)
                                        }}
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col v-else class="text-center text-weight-bold text-xl mt-5">
                    You have not purchased any products yet.
                </b-col>
            </b-row>
        </div>

        <!-- Add Digitail Item  -->
        <create-edit-store-product-modal
            :is-modal-open.sync="createEditStoreProductModalModalIsOpen"
            :league="parentContext.league"
            :edit-product="editProductData"
            @close-modal="isCloseAddDigitalModal"
            @product-creation-complete="productCreationComplete"
        >
        </create-edit-store-product-modal>
        <create-product-modal
            :is-modal-open.sync="createProductModalModalIsOpen"
            :league="parentContext.league"
            @product-creation-complete="productCreationComplete"
        >
        </create-product-modal>
    </div>
</template>

<script>
import CreateEditStoreProductModal from "../Forms/LeagueForms/CreateEditStoreProductModal";
import CreateProductModal from "../Forms/LeagueForms/CreateProductModal";
import StoreSearchForm from "../Forms/LeagueForms/StoreSearchForm";
import StoreDownloadItem from "../ListItems/StoreDownloadItem";

export default {
    name: "StoreProductsList",
    components: {
        CreateEditStoreProductModal,
        CreateProductModal,
        StoreSearchForm,
        StoreDownloadItem,
    },
    props: {
        /* Contextual Object */
        parentContext: {
            type: Object,
            required: true,
        },
        leagueId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            storeViewIsActive: true,
            createEditStoreProductModalModalIsOpen: false,
            createProductModalModalIsOpen: false,

            editable: false,
            mediaLabelOptions: [
                { value: null, text: "All" },
                { value: 1, text: "Just Arrived" },
                { value: 2, text: "Most Popular" },
            ],
            selectedMediaLabel: null,
            productsData: null,
            editProductData: null,
            download: {
                info: null,
                productsId: null,
            },
            searchQuery: null,
            searchItem: null,
            closeSearchSuggestion: true,
            orders: [],
        };
    },
    mounted() {
        // List of products that can be purchased.
        this.getProducts();

        // List of products the current user has purchased.
        this.getOrders();
    },
    computed: {
        context() {
            return {
                ...this.parentContext,
            };
        },
        leagueIsPrivate() {
            if (!this.context.league) {
                return false;
            }
            return (
                this.context.league.permissions.privacy === "Private" &&
                (!this.context.authUser ||
                    !this.context.authUser.leaguePermissions.isMember)
            );
        },
        mediaCoverStyle() {
            return this.$mq === "sm" ? "width:50px" : "width:100px";
        },
        productsDataProducts() {
            return this.productsData?.products;
        },
        isOwner() {
            return this.context?.authUser?.leaguePermissions?.isOwner;
        },
        filteredStoreProductItems() {
            let filteredProducts = this.productsDataProducts;

            if (this.selectedMediaLabel) {
                filteredProducts = filteredProducts.filter((item) => {
                    return item.label?.id === this.selectedMediaLabel;
                });
            }

            if (this.searchQuery) {
                filteredProducts = filteredProducts.filter((item) => {
                    return this.searchQuery
                        .toLowerCase()
                        .split(" ")
                        .every((v) => item.name.toLowerCase().includes(v));
                });
            }

            return filteredProducts;
        },
        storeHasNoProductsForSaleYet() {
            return this.productsData?.products.length === 0;
        },
    },
    methods: {
        currentDate(date) {
            const monthNames = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ];
            const current = new Date(date);
            return `${
                monthNames[current.getMonth()]
            } ${current.getDate()}, ${current.getFullYear()}`;
        },
        isCloseAddDigitalModal(status) {
            this.createEditStoreProductModalModalIsOpen = status;
            this.createProductModalModalIsOpen = status;
            this.editProductData = {};
        },
        createProductModal() {
            this.createEditStoreProductModalModalIsOpen = false;
            this.createProductModalModalIsOpen = true;
        },
        async getProducts() {
            try {
                if (!this.leagueIsPrivate) {
                    const { data, status } = await axios.post(
                        "/store/products",
                        {
                            leagueId: this.parentContext.league.id,
                        },
                    );
                    if (status == 200) {
                        this.productsData = data;
                    }
                }
            } catch (error) {
                console.warn("ERROR: ", error);
                await this.$swal(
                    error.response
                        ? error.response.data.message
                        : "Oops! Something went wrong. Please try again.",
                );
            }
        },
        getFilesInfo(info) {
            this.download.info = info;
        },
        getProductsId(id) {
            this.download.productsId = id;
        },
        editProduct(product) {
            this.editProductData = product;
            this.createEditStoreProductModalModalIsOpen = true;
            this.createProductModalModalIsOpen = false;
        },
        async getOrders() {
            try {
                const { status, data } = await axios.post("/store/orders", {
                    leagueId: this.leagueId,
                });
                if (status == 200) {
                    this.orders = data.orders;
                }
            } catch (error) {
                console.warn("ERROR: ", error);
                await this.$swal(
                    error.response
                        ? error.response.data.message
                        : "Oops! Something went wrong. Please try again.",
                );
            }
        },
        openEditProductModal(product) {
            this.editProduct(product);
        },
        async productPurchased(p) {
            // Refresh orders (aka transactions and switch to transactions view.)
            this.getOrders();
            this.storeViewIsActive = false;
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            await this.$swal(
                "Success!",
                `${p.name} was sucessfully purchased!`,
                "success",
            );
        },
        handleProductDeleted(p) {
            this.getProducts();
        },
        async productCreationComplete(product) {
            this.getProducts();
            this.createEditStoreProductModalModalIsOpen = false;
            this.createProductModalModalIsOpen = false;
            await this.$swal(
                "Success!",
                "Product successfully added to your league store!",
                "success",
            );
        },
        productDownloaded() {
            this.getOrders();
        },
    },
};
</script>

<style scoped lang="scss">
.store-digital-product-card {
    position: relative;
    display: flex;
    background-color: #e7e7e7;
    border-radius: 20px;
    height: 450px;
    &:hover {
        opacity: 100;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 20px;
    }
}
.add-item-btn-group {
    margin: auto;
    color: #aaaaaa;
}
.transaction-item {
    margin-bottom: 32px;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    .transaction-item-header {
        font-weight: bold;
        color: #aaaaaa;
        border-bottom: 1px solid #e7e7e7;
        padding-bottom: 8px;
    }
    .transaction-item-body {
        padding-top: 16px;
        color: #0e0e0e;
    }
    .transaction-item-footer {
        padding-top: 16px;
        align-items: center;
    }
    .transaction-item-date {
        padding-top: 16px;
        color: #aaaaaa;
        .date: {
            margin-left: auto;
            font-size: 14px;
        }
    }
}
</style>
