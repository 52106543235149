<template>
    <div class="burst-reply-action" @click="loginRegister">
        <div class="action-description">Sign up to continue watching!</div>
    </div>
</template>

<script>
export default {
    name: "BurstPreviewSignupItem",
    components: {},
    props: {
        burst: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {};
    },
    computed: {
        leagueId() {
            return this.burst.league.id;
        },
    },
    methods: {
        loginRegister() {
            // store the league's url for redirect after user login or signup
            sessionStorage.setItem("redirectToLeague", window.location.href);
            sessionStorage.setItem("joinLeague", true);
            // redirect to login
            window.location.href = "/";
        }
    },
};
</script>

<style scoped lang="scss">
.burst-reply-action {
    cursor: pointer;
    position: relative;
    display: inline-table;
    width: 90px;
    height: 90px;
    margin-left: 16px;
    border: 1px solid white;
    border-radius: 5px;
    overflow: hidden;
    background-color: #5451de;
    color: white;
    text-align: center;
    padding-top: 1px;
    .plus-icon {
        padding-bottom: 5px;
    }
    .action-description {
        color: white;
        font-size: 13px;
        text-align: center;
        padding: 12px 5px;
    }
}
</style>
