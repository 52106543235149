<template>
    <div class="autocomplete-results" v-bind:style="{ top: offsetTop + 'px' }">
        <ul>
            <li v-for="item in tagPeopleAutocompleteResults" :key="item.name" @click="selectUser($event, item)">
                {{item.name}}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "TagPeopleAutocomplete",
    props: {
        tagPeopleAutocompleteResults: {
            type: Array,
            required: true,
        },
        offsetTop: {
            type: Number,
            required: true,
        }
    },
    data() {
        return {
            loading: true,
        };
    },
    mounted() {
        
    },
    methods: {
        selectUser(event, user) {
            this.$emit("user-selected", {...user});
        }
    }
};
</script>

<style scoped lang="scss">
    .autocomplete-results {
        position: absolute;
        z-index: 1000;
        width: 250px;
        left: 24px;
        background-clip: border-box;
        background: white;
        border: 1px solid rgba(14, 14, 14, 0.125);
        border-radius: 0.25rem;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
        ul {
            list-style: none;
            margin-bottom: 0px;
            padding-left: 0px;
            li {
                overflow: hidden;
                cursor: pointer;
                padding: 16px;
                &:hover {
                    background-color: #eee;
                }
            }
        }
    }
</style>
