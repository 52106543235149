<template>
    <b-card :title="`${numberAbbrev(membersCount)} Members`" class="drop-shadow">
        <hr />
        <b-list-group flush>
            <template v-if="members.length > 0">
                <b-list-group-item v-for="member in members" :key="member.id">
                    <b-media class="align-items-center">
                        <template v-slot:aside>
                            <b-avatar
                                :src="member.avatar"
                                variant="grey"
                                size="45"
                            ></b-avatar>
                        </template>
                        <a class="h6 mb-0" :href="`/u/${member.id}`">{{
                            member.name
                        }}</a>
                    </b-media>
                </b-list-group-item>
            </template>
            <b-list-group-item v-else>
                <h6 class="text-muted text-center">No Members</h6>
            </b-list-group-item>
        </b-list-group>
        <div v-if="showMore" class="text-center">
            <b-btn variant="link" @click="$emit('view-members')"
                >View All Members</b-btn
            >
        </div>
    </b-card>
</template>

<script>
import { numberAbbreviation } from "../Mixins/numberAbbreviation";

export default {
    name: "MembersListCard",
    mixins: [numberAbbreviation],
    props: {
        members: {
            type: Array,
            required: true,
        },
        showMore: Boolean,
        membersCount:{
            type: Number,
            default: 0,
        }
    },
};
</script>

<style scoped>
.list-group-item {
    padding: 0.25rem 0;
    border: 0;
}
</style>
