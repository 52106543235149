<template>
    <b-modal
        id="plan-upgrade-modal"
        v-model="open"
        centered
        hide-footer
        hide-header
    >
        <b-container class="p-4">
            <div v-show="state === 'question'">
                <h2 class="font-weight-bold mb-4">
                    You are currently on the Standard plan.
                </h2>
                <p class="mb-4">
                    Would you like to upgrade to the Pro plan to set a monthly
                    subscription for you members?
                </p>
                <b-btn
                    variant="purple"
                    block
                    pill
                    @click="checkPaymentMethod"
                    class="mb-3"
                    :disabled="loading"
                >
                    <i v-show="loading" class="fas fa-spinner fa-pulse"></i>
                    <span v-show="!loading">Upgrade</span>
                </b-btn>
                <b-btn
                    block
                    pill
                    variant="outline-purple"
                    class="text-purple mb-3"
                    :href="declineRoute"
                    :disabled="loading"
                >
                    No, thank you
                </b-btn>
            </div>
            <div v-show="state === 'add-payment'">
                <stripe-payment-form
                    title="Add Payment Method"
                    @stripe-submitted="addSubscription"
                ></stripe-payment-form>
            </div>
            <div v-show="state === 'success'">
                <img src="/images/icons/purple-check.svg" height="60" />
                <h2 class="font-weight-bold my-4">Success!</h2>
                <p class="mb-4">
                   Your plan has been upgraded successfully!
                </p>
                <b-btn
                    variant="purple"
                    block
                    pill
                    :href="afterUpgradeRoute"
                    class="mb-3"
                    >Close</b-btn
                >
            </div>
            <div v-show="state === 'error'">
                <h2 class="font-weight-bold my-4">Error!</h2>
                <p>{{ errorMessage }}</p>
            </div>
        </b-container>
    </b-modal>
</template>
<script>
import StripePaymentForm from "../Forms/PaymentForms/StripePaymentForm";
export default {
    name: "PlanUpgradeModal",
    components: { StripePaymentForm },
    props: {
        modalOpen: {
            type: Boolean,
        },
        afterUpgradeRoute: {
            type: String,
            default: "/leagues/subscriptionSetup",
        },
        declineRoute: {
            type: String,
            default: "/leagues/permissions",
        },
    },
    data() {
        return {
            loading: false,
            state: "question",
            errorMessage: null,
        };
    },
    computed: {
        open: {
            get() {
                return this.modalOpen;
            },
            set(value) {
                this.$emit("update:modalOpen", value);
            },
        },
    },
    methods: {
        async checkPaymentMethod() {
            try {
                this.loading = true;
                const response = await axios.get(`/settings/hasCard`);
                if (response.data.hasCard) {
                    await this.updatePlan();
                } else {
                    this.state = "add-payment";
                    this.loading = false;
                }
            } catch (error) {
                this.state = "error";
                this.errorMessage = error.response.data.message;
                this.loading = false;
            }
        },
        async addSubscription(token) {
            try {
                this.loading = true;
                const response = await axios.post("/register/subscribe", {
                    token: token.id,
                    plan: "elite",
                });
                this.state = "success";
                this.loading = false;
            } catch (error) {
                this.state = "error";
                this.errorMessage = error.response.data.message;
                this.loading = false;
            }
        },
        async updatePlan() {
            try {
                this.loading = true;
                const response = await axios.post(`/account/upgrade`);
                this.state = "success";
                this.loading = false;
            } catch (error) {
                this.state = "error";
                this.errorMessage = error.response.data.message;
                this.loading = false;
            }
        },
    },
};
</script>
