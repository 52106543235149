<template>
    <div>
        <b-form-invalid-feedback v-for="error in errors" :key="error" force-show>{{error}}</b-form-invalid-feedback>
    </div>
</template>

<script>
import { BFormInvalidFeedback } from 'bootstrap-vue'
export default {
    name: "InvalidFeedback",
    components: {BFormInvalidFeedback},
    props: {
      errors: {
        type: Array,
        required: true
      }
    }
}
</script>
