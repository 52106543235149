<template>
    <div>
        <b-form @submit.prevent="submitForm">
            <b-form-group :label="`${subleague}League Type`" label-for="type-select">
                <b-form-select
                    id="type-select"
                    v-model="form.privacy"
                    :options="permissions.privacy.options"
                ></b-form-select>
                <b-form-text>{{ privacyHelpText }}</b-form-text>
            </b-form-group>
            <b-form-group
                :label="`Can users search for your ${subleague}league?`"
                label-for="search-select"
            >
                <b-form-select
                    id="search-select"
                    v-model="form.searchable"
                    :options="permissions.searchable.options"
                ></b-form-select>
                <b-form-text>{{ searchHelpText }}</b-form-text>
            </b-form-group>
            <b-form-group
                :label="`Who can post to this ${subleague}league?`"
                label-for="post-select"
            >
                <b-form-select
                    id="post-select"
                    v-model="form.post_permission"
                    :options="permissions.posts.options"
                ></b-form-select>
                <b-form-text>{{ postHelpText }}</b-form-text>
            </b-form-group>
            <b-form-group label="Can posts be shared?" label-for="share-select">
                <b-form-select
                    id="share-select"
                    v-model="form.shareable"
                    :options="permissions.shareable.options"
                ></b-form-select>
            </b-form-group>
            <b-form-group label="Are public posts allowed?" label-for="public-posts-select">
                <b-form-select
                    id="public-posts-select"
                    v-model="form.allowPublicPosts"
                    :options="permissions.allowPublicPosts.options"
                ></b-form-select>
            </b-form-group>
            <submit-button
                :loading="loading"
                :text="submitButtonText"
                class="mt-5"
            ></submit-button>
            <b-form-text
                v-show="saved"
                text-variant="success"
                class="h6 text-center mt-3"
                >Permissions Saved!</b-form-text
            >
        </b-form>
    </div>
</template>

<script>
import SubmitButton from "../../Buttons/SubmitButton";

/** There are no validations on this form **/
export default {
    name: "LeaguePermissionsForm",
    components: { SubmitButton },
    props: {
        leagueId: {
            required: true,
        },
        permissions: {
            type: Object,
            required: true,
        },
        editing: {
            type: Boolean,
            default: false,
        },
        isSubleague: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            loading: false,
            saved: false,
            form: {
                privacy: 1, // private vs. public
                searchable: 0, // Who can search
                post_permission: 0, // Who can post
                shareable: 0, // Who can share,
                allowPublicPosts: 0, // Are public posts allowed
            },
        };
    },
    mounted() {
        this.form = {
            privacy: this.permissions.privacy.selected, // private vs. public
            searchable: this.permissions.searchable.selected, // Who can search
            post_permission: this.permissions.posts.selected, // Who can post
            shareable: this.permissions.shareable.selected, // Who can share
            allowPublicPosts: this.permissions.allowPublicPosts.selected,
        };
    },
    computed: {
        privacyHelpText() {
            const option = this.permissions.privacy.options.find(
                (option) => option.value === this.form.privacy
            );
            return option.text === "Public"
                ? "New users can join without approval."
                : "New users must get approval before joining.";
        },
        searchHelpText() {
            return this.form.searchable
                ? "Users can search for this league."
                : "This league will not show in the search, you will have to invite new users.";
        },
        postHelpText() {
            const option = this.permissions.posts.options.find(
                (option) => option.value === this.form.post_permission
            );
            return option.text === "All members"
                ? "All Members can post to this league."
                : "Only the League Owner can post to this league.";
        },
        submitButtonText() {
            return this.editing ? "Submit" : "Next";
        },
        subleague() {
            return this.isSubleague ? 'Sub ' : '';
        },
    },
    methods: {
        async submitForm() {
            try {
                this.loading = true;
                // Attempt to update permissions
                const response = await axios.post("/leagues/permissions", {
                    leagueId: this.leagueId,
                    ...this.form,
                });

                if (this.editing) {
                    this.loading = false;
                    this.saved = true;
                } else {
                    // Redirect to the next step of creation
                    window.location = "/leagues/invite?leagueId=" + this.leagueId;
     
                }
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        },
    },
};
</script>
