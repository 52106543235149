<template>
    <div>
        <b-row v-if="friends.length > 0">
            <b-col
                v-for="friend in friends"
                :key="friend.id"
                cols="12"
                md="6"
                :xl="cols"
            >
                <friend-card
                    :friend="friend"
                    remove-text="Remove Friend"
                    class="clickable my-3"
                    :editable="editable"
                    @remove-friend="openRemoveModal(friend)"
                    @accept-friend="approveFriend"
                    @reject-friend="rejectFriend"
                    @unblock-friend="removeFriend"
                    @click="routeToUser(friend.user.id)"
                ></friend-card>
            </b-col>
        </b-row>
        <div v-else class="text-center my-5">
            <span class="h5 text-muted">No Friends...</span>
        </div>
        <b-pagination
            v-if="totalFriends > perPage"
            v-model="currentPage"
            :total-rows="totalFriends"
            :per-page="perPage"
            aria-controls="media-list"
            align="center"
            class="my-3"
        ></b-pagination>
        <remove-user-modal
            v-if="editable"
            :modal-open.sync="removeModalOpen"
            :user-name="removeFriendName"
            @remove-user="removeFriend(friendToRemove.id)"
        >
        </remove-user-modal>
    </div>
</template>

<script>
import UserCard from "../Cards/UserCard";
import RemoveUserModal from "../Modals/removeUserModal";
import FriendCard from "../Cards/FriendCard";
export default {
    name: "FriendsList",
    components: { FriendCard, RemoveUserModal, UserCard },
    props: {
        userId: {
            type: Number,
            required: true,
        },
        perPage: {
            type: Number,
            default: 15,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        query: Object,
        searchText: String,
        cols: {
            type: Number,
            default: 4,
        },
    },
    data() {
        return {
            currentPage: 1,
            friends: [],
            totalFriends: 0,
            friendToRemove: {},
            removeModalOpen: false,
        };
    },
    mounted() {
        this.getFriends();
    },
    watch: {
        currentPage: function () {
            this.getFriends();
        },
        query: {
            deep: true,
            handler: "getFriends",
        }
    },
    computed: {
        removeFriendName() {
            return this.friendToRemove.user
                ? `${this.friendToRemove.user.firstName} ${this.friendToRemove.user.lastName}`
                : null;
        }
        
    },
    methods: {
        /**
         * We're searching friends by their first or last name
         * Author: mashini.kindamba@thecasebuilding
         */
        async getFriends() {
            const data = {
                ...this.query,
                userId: this.userId,
                search: this.searchText,
                per_page: this.perPage,
                page: this.currentPage,
            };

            try {
                const response = await axios.post(`/users/friends`, data);

                this.friends = response.data.friends;

                this.totalFriends = response.data.friends_count;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                
            }
        },
        routeToUser(id) {
            window.location.href = `/u/${id}`;
        },
        approveFriend(id) {
            this.updateFriendStatus(id, 2);
        },
        rejectFriend(id) {
            this.updateFriendStatus(id, 3);
        },
        async updateFriendStatus(id, status) {
            const requestData = {
                friendId: id,
                status: status,
            };

            try {
                const response = await axios.post(
                    "/users/friends/respond",
                    requestData
                );
                let urlParams = new URLSearchParams(window.location.search);
                if (urlParams.has('status'))
                    location.reload();
                else
                    await this.getFriends();
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
        openRemoveModal(friend) {
            this.friendToRemove = friend;
            this.removeModalOpen = true;
        },
        async removeFriend(id) {
            const requestData = {
                friendId: id,
            };

            try {
                const response = await axios.post(
                    "/users/friends/remove",
                    requestData
                );
                await this.getFriends();
                this.removeModalOpen = false;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
    },
};
</script>

<style scoped></style>
