<template>
    <div>
        <b-card v-if="!editing">
            <b-row class="align-items-center">
                <b-col>
                    <p class="font-weight-bold mb-0">
                        {{ paymentMethod.brand }} ending in
                        {{ paymentMethod.last4 }}
                    </p>
                    <p class="small text-muted mb-0">
                        Expires {{ paymentMethod.expiration }}
                    </p>
                </b-col>
                <b-col v-if="!hideUpdate" cols="auto">
                    <b-btn variant="link" @click="editing = true">Update</b-btn>
                </b-col>
            </b-row>
        </b-card>
        <b-card v-else>
            <stripe-payment-form
                @stripe-submitted="submitCardUpdate"
            ></stripe-payment-form>
        </b-card>
    </div>
</template>

<script>
import StripePaymentForm from "../Forms/PaymentForms/StripePaymentForm";
export default {
    name: "PaymentMethodCard",
    components: { StripePaymentForm },
    props: {
        paymentMethod: {
            type: Object,
            required: true,
        },
        hideUpdate: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            editing: false,
        };
    },
    methods: {
        async submitCardUpdate(token) {
            try {
                // Attempt to update payment method
                const response = await axios.post(
                    "/account/updatePaymentMethod",
                    {
                        token: token.id
                    }
                );
                window.location.reload();
            } catch (error) {
                console.log('error: ', error);
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
    },
};
</script>

<style scoped></style>
