<template>
    <div class="mb-5">
        <div v-show="showPrivateMessage">
            <h5 class="text-center text-muted my-5">
                This league is private, you must join the league to see their
                posts.
            </h5>
        </div>
        <b-row class="grid">
            <media-thumbnail-item
                v-for="(item, index) in mediaItems"
                :mediaItem="item"
                :key="item.id"
                @view-item="viewItem(item, index)"
            ></media-thumbnail-item>
        </b-row>
        <infinite-loading
            direction="bottom"
            @infinite="getMoreWallMediaItems"
            spinner="spiral"
            force-use-infinite-wrapper
        >
            <div slot="no-more">No more items...</div>
            <div slot="no-results"></div>
        </infinite-loading>

        <div v-if="isLeagueProfile">
            <media-item-comments-modal-res
                v-if="mediaItems.length && currentMediaItemIndex > -1"
                :modal-open.sync="showModal"
                :currentMediaItemIndex="currentMediaItemIndex"
                :mediaItem="currentMediaItem"
                @navigate-previous="navigatePrevious"
                @navigate-next="navigateNext"
                @reaction-change="updateReactions"
            ></media-item-comments-modal-res>
        </div>
        <div v-else>
            <media-item-comments-modal
                v-if="mediaItems.length && currentMediaItemIndex > -1"
                :modal-open.sync="showModal"
                :currentMediaItemIndex="currentMediaItemIndex"
                :mediaItem="currentMediaItem"
                @navigate-previous="navigatePrevious"
                @navigate-next="navigateNext"
                @reaction-change="updateReactions"
            ></media-item-comments-modal>
        </div>
    </div>
</template>

<script>
import MediaThumbnailItem from "../ListItems/MediaThumbnailItem";
import MediaItemCommentsModal from "../Modals/MediaItemCommentsModal";
import MediaItemCommentsModalRes from "../Modals/MediaItemCommentsModalRes";

export default {
    name: "WallMediaList",
    components: {
        MediaThumbnailItem,
        MediaItemCommentsModal,
        MediaItemCommentsModalRes,
    },
    props: {
        /* Contextual Object */
        parentContext: {
            type: Object,
            required: true,
        },
        leagueId: {
            type: Number,
            default: null,
        },
        // @see app/Enums/PostTypes.php
        listType: {
            type: String,
            required: true,
        },
        //
        isLeagueProfile: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            mediaItems: [],
            showModal: false,
            perPage: 21,
            currentPage: 1,
            currentMediaItemIndex: 0,
            totalMediaItemsCount: 0,
        };
    },
    beforeMount() {
        this.getWallMediaItems(1, true);
    },
    computed: {
        context() {
            return {
                ...this.parentContext,
            };
        },
        currentMediaItem() {
            return this.mediaItems[this.currentMediaItemIndex];
        },
        showPrivateMessage() {
            if (!this.context.league) {
                return false;
            }
            return (
                this.context.league.permissions.privacy === "Private" &&
                (!this.context.authUser ||
                    !this.context.authUser.leaguePermissions.isMember)
            );
        },
    },
    methods: {
        viewItem(item, index) {
            // Set selected index for the modal
            this.currentMediaItemIndex = index;
            this.showModal = true;
        },
        navigatePrevious(item) {
            this.currentMediaItemIndex =
                item.currentMediaItemIndex > 0
                    ? item.currentMediaItemIndex - 1
                    : 0;
        },
        navigateNext(item) {
            this.currentMediaItemIndex =
                item.currentMediaItemIndex < this.mediaItems.length - 1
                    ? item.currentMediaItemIndex + 1
                    : item.currentMediaItemIndex;
        },
        getPostTypeIdFromListType(listType) {
            switch (listType) {
                case "all":
                    return 4;
                case "videos":
                    return 1;
                case "images":
                    return 0;
                default:
                    return 0;
            }
        },
        async getWallMediaItems(currentPage) {
            this.mediaItems = [];
            try {
                const postType = this.getPostTypeIdFromListType(this.listType);
                const requestData = {
                    leagueId: this.leagueId,
                    type: postType,
                    page: currentPage,
                    per_page: this.perPage,
                };

                const response = await axios.get("/leagues/getPostsByType", {
                    params: requestData,
                });
                if (response.data.items) {
                    this.mediaItems = this.mediaItems.concat(
                        ...response.data.items,
                    );
                    this.totalMediaItemsCount = response.data.total;

                    this.currentPage += 1;
                    this.$emit("media-items", this.mediaItems); //Emitting to MediaList.vue
                    this.$emit(
                        "total-media-items-count",
                        this.totalMediaItemsCount,
                    ); //Emitting to MediaList.vue
                }
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
        async getMoreWallMediaItems($state) {
            if (this.mediaItems.length < this.totalMediaItemsCount) {
                try {
                    const postType = this.getPostTypeIdFromListType(
                        this.listType,
                    );
                    const requestData = {
                        leagueId: this.leagueId,
                        type: postType,
                        page: this.currentPage,
                        per_page: this.perPage,
                    };

                    const response = await axios.get(
                        "/leagues/getPostsByType",
                        {
                            params: requestData,
                        },
                    );

                    if (response.data.items) {
                        this.mediaItems = this.mediaItems.concat(
                            ...response.data.items,
                        );
                        this.totalMediaItemsCount = response.data.total;
                        this.currentPage += 1;
                        this.$emit("media-items", this.mediaItems); //Emitting to MediaList.vue
                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                } catch (error) {
                    // If there is an error for some reason, show alert
                    await this.$swal(error.response.data.message);
                }
            } else {
                $state.complete();
            }
        },
        updateReactions(counts) {
            this.mediaItems[this.currentMediaItemIndex].like_count =
                counts.like_count;
            this.mediaItems[this.currentMediaItemIndex].love_count =
                counts.love_count;
        },
    },
};
</script>

<style scoped lang="scss">
.grid {
    .col-4 {
        padding-right: 3px;
        padding-left: 3px;
    }
}
</style>
