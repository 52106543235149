<template>
    <div>
        <hr />
        <b-row class="pb-4" v-show="showVideoInProcess">
            <b-col cols="12">
                <video-list-card
                    v-if="context.authUser"
                    :league="parentContext.league"
                    :is-store="true"
                    :show-video-in-process.sync="showVideoInProcess"
                ></video-list-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <store-products-list
                    v-show="activeItem === 'all'"
                    :league-id="context.league.id"
                    :listType="'all'"
                    :parent-context="context"
                    :is-league-profile="true"
                ></store-products-list>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import StoreProductsList from "../../Lists/StoreProductsList";
import VideoListCard from "../../../components/Cards/VideoListCard";

export default {
    name: "LeagueProfileStore",
    components: {
        StoreProductsList,
    },
    props: {
        /* Contextual Object that contains league and authUser (including league roles) */
        parentContext: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            activeItem: "post",
            showVideoInProcess: false,
        };
    },
    mounted() {
        setTimeout(() => {
            this.activeItem = "all"; //Activate the spinner
        }, 300);
    },
    computed: {
        context() {
            return {
                profileTab: "Store",
                ...this.parentContext,
            };
        },
    },
};
</script>

<style scoped></style>
