<template>
    <b-form id="stripe-payment-form" @submit.prevent="submitForm">
        <h1 v-show="title" class="h2 font-weight-bold mb-5">{{ title }}</h1>
        <!-- Name -->
        <b-form-group :label="whiteTheme ? null : 'Name on Card'" for="cardName-input">
            <b-input
                id="cardName-input"
                v-model="cardName"
                :state="validateCardName()"
                @change="$v.cardName.$touch()"
                class="form-control pt-2"
                :class="{'form-control-black': this.dark, 'form-control-white': this.whiteTheme}"
                :placeholder="whiteTheme ? 'Name on Card' : null"
            ></b-input>
            <invalid-feedback :errors="cardNameErrors"></invalid-feedback>
        </b-form-group>
        <!-- Card Number -->
        <b-form-group :label="whiteTheme ? null : 'Card Number'" for="number-input">
            <div id="number-input" ref="cardNumber" class="form-control pt-2" :placeholder="whiteTheme ? 'Card Number' : null" :class="{'form-control-black': this.dark, 'form-control-white': this.whiteTheme}"></div>
        </b-form-group>
        <b-form-row>
            <b-col>
                <!-- Exp. Date -->
                <b-form-group :label="whiteTheme ? null : 'Exp. Date'" for="exp-input">
                    <div id="exp-input" ref="cardExp" class="form-control pt-2" :placeholder="whiteTheme ? 'Exp. Date' : null" :class="{'form-control-black': this.dark, 'form-control-white': this.whiteTheme}"></div>
                </b-form-group>
            </b-col>
            <b-col>
                <!-- 3-Digit Code -->
                <b-form-group :label="whiteTheme ? null : 'CVC Code'" for="cvc-input">
                    <div id="cvc-input" ref="cardCvc" class="form-control pt-2" :placeholder="whiteTheme ? 'CVC Code' : null" :class="{'form-control-black': this.dark, 'form-control-white': this.whiteTheme}"></div>
                </b-form-group>
            </b-col>
            <b-col>
                <!-- Zipcode -->
                <b-form-group :label="whiteTheme ? null : 'Zip Code'" for="zip-input">
                    <div id="zip-input" ref="cardZip" class="form-control pt-2" :placeholder="whiteTheme ? 'Zip Code' : null" :class="{'form-control-black': this.dark, 'form-control-white': this.whiteTheme}"></div>
                </b-form-group>
            </b-col>
        </b-form-row>
      <invalid-feedback :errors="stripeError"></invalid-feedback>
        <submit-button
            :loading="loading"
            :text="buttonText"
            class="mt-5"
            :class="whiteTheme ? 'bg-red' : null"
            :style="whiteTheme ? 'box-shadow: 0 6px 6px -6px black;' : null"
        ></submit-button>
    </b-form>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import InvalidFeedback from "../FormComponents/InvalidFeedback";
import SubmitButton from "../../Buttons/SubmitButton";
/** Form used for card payment as well as debit card setup on payment account **/
export default {
    cardName: "StripePaymentForm",
    components: { SubmitButton, InvalidFeedback },
    props: {
        dark: {
            type: Boolean,
            default: false,
        },
        buttonText: {
            type: String,
            default: "Submit",
        },
        title: {
            type: String,
            default: "",
        },
        whiteTheme: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            loading: true,
            stripeObject: undefined,
            stripeError: [],
            cardName: "",
            cardObject: {
                number: undefined,
                exp: undefined,
                cvc: undefined,
                zipcode: undefined,
            },
        };
    },
    validations: {
        cardName: { required },
    },
    mounted() {
        // Setup the stripe instance
        this.stripeObject = Stripe(process.env.MIX_STRIPE_KEY);

        // Input Styles
        const styles = {
            base: {
                fontSize: "16px",
                fontFamily: "Roboto, Sans-Serif",
                //color: this.dark ? "#FFF" : "#000", //This is always true
                color: "#787878",
            },
        };

        // Mount Stripe Input Elements
        const card = this.cardObject;
        const elements = this.stripeObject.elements({
            fonts: [
                {
                    cssSrc:
                        "https://fonts.googleapis.com/css?family=Roboto&display=swap",
                },
            ],
        });

        card.number = elements.create("cardNumber", { style: styles });
        card.number.mount(this.$refs.cardNumber);
        card.exp = elements.create("cardExpiry", { style: styles });
        card.exp.mount(this.$refs.cardExp);
        card.cvc = elements.create("cardCvc", { style: styles });
        card.cvc.mount(this.$refs.cardCvc);
        card.zipcode = elements.create("postalCode", { style: styles });
        card.zipcode.mount(this.$refs.cardZip);

        this.loading = false;
    },
    computed: {
        inputClass() {
            return this.dark
                ? "form-control form-control-black pt-2"
                : "form-control pt-2";
        },
        cardNameErrors() {
            const errors = [];
            if (!this.$v.cardName.$dirty) return errors;
            !this.$v.cardName.required && errors.push("Please Enter a Name");
            return errors;
        },
    },
    methods: {
        validateCardName() {
            const { $dirty, $error } = this.$v.cardName;
            return $dirty ? !$error : null;
        },
        async submitForm() {
            this.$v.$touch();
            this.stripeError = [];

            if (!this.$v.$anyError) {
                this.loading = true;

                let { error, token } = await this.stripeObject.createToken(
                    this.cardObject.number,
                    { name: this.cardName, currency: "usd" }
                );

                if (error) {
                    this.loading = false;
                    this.stripeError = [error.message];
                    this.$forceUpdate();
                    return;
                }

                this.$emit("stripe-submitted", token);
                /* Wait a bit then set loading as false this is to allow the
                   form that contains this one to finish submitting */
                let _this = this;
                setTimeout(() => {
                    _this.loading = false;
                }, 5000);
            }
        },
    },
};
</script>

<style scoped></style>
