<template>
    <div class="pt-n4 pb-4 pl-sm-5 pr-sm-5 pl-0 pr-0">
        <croppie-upload-form
            :league-id="leagueId"
            :type="type"
            :button-text="buttonText"
            :submit-text="submitText"
            @media-uploaded="submitForm"
            :media-chosen="mediaChosen"
            is-media-modal-inline
            :open-input-file="openInputFile"
            :user-id="userId"
        ></croppie-upload-form>
    </div> 
</template>

<script>
import CroppieUploadForm from "../Forms/CroppieUploadForm";

export default {
    name: "MediaModalInline",
    components: { CroppieUploadForm },
    props: {
        leagueId: Number,
        userId: Number,
        modalOpen: Boolean,
        uploadOnly: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: "all",
        },
        buttonText: {
            type: String,
            default: "Submit",
        },
        submitText: {
            type: String,
            default: "Submitting...",
        },
        mediaChosen: {
            type: String,
            default: "",
        },
        openInputFile: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            tabIndex: 0,
            selectedMedia: null, // Used when selecting existing media
        };
    },
    computed: {
        open: {
            get() {
                return this.modalOpen;
            },
            set(value) {
                this.$emit("update:modalOpen", value);
            },
        },
        showChooseMedia() {
            return !this.uploadOnly ? this.open : false;
        },
        showLeagueMedia() {
            return !this.uploadOnly ? this.open && this.leagueId : false;
        },
        selectedMediaId() {
            return this.selectedMedia ? this.selectedMedia.id : null;
        },
    },
    methods: {
        submitExistingMedia() {
            this.submitForm(this.selectedMedia);
        },
        submitForm(media) {
            this.$emit("media-chosen", media);
            this.$bvModal.hide("media-modal");
            this.open = false;
        },
        upperCaseFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    },
};
</script>
