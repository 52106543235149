<template>
    <div>
        <h1 class="h2 font-weight-bold mb-4">
            General Information
        </h1>
        <league-info-form
            :auth-user="authUser"
            :league="league"
            :initial-categories="categories"
            :parent-league="league.parent"
        ></league-info-form>
    </div>
</template>

<script>
import LeagueInfoForm from "../../Forms/LeagueForms/LeagueInfoForm";
export default {
    name: "LeagueSettingsGeneral",
    components: { LeagueInfoForm },
    props: {
        league: {
            type: Object,
            required: true,
        },
        authUser: {
            type: Object,
            required: true,
        },
        categories: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style scoped></style>
