<template>
    <div class="mb-5">
        <post-form
            v-if="userCanPost"
            :is-edit-post-mode="false"
            class="mb-4"
            :league="context.league"
            :auth-user="context.authUser"
            placeholder-text="Share your thoughts..."
            @post-created="addPost"
        ></post-form>
        <div v-show="showPrivateMessage">
            <h5 class="text-center text-muted my-5">
                This league is private, you must join the league to see their
                posts.
            </h5>
        </div>
        <post-item
            v-for="post in postItems"
            :key="post.id"
            :post="post"
            :auth-user="context.authUser"
            :reactions="reactions"
            class="mb-4"
            @post-edited="updatePost"
            @post-deleted="deletePost"
            :showMedia="showMedia"
            :list-type="listType"
        ></post-item>

        <infinite-loading
            direction="bottom"
            @infinite="getMorePosts"
            spinner="spiral"
        >
            <div slot="no-more">No more posts...</div>
            <div slot="no-results" v-show="!postItems || postItems.length == 0">
                This league does not have any posts yet.
            </div>
        </infinite-loading>
    </div>
</template>

<script>
import PostForm from "../Forms/PostForm";
import PostItem from "../ListItems/PostItem";
import DeletePostModal from "../Modals/DeletePostModal";

export default {
    name: "LeaguePostsList",
    components: {
        DeletePostModal,
        PostItem,
        PostForm,
    },
    props: {
        posts: {
            type: Array,
            default: [],
        },
        /* Contextual Object */
        parentContext: {
            type: Object,
            required: true,
        },
        showMedia: {
            type: Boolean,
            required: true,
        },
        listType: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            loading: true,
            perPage: 10,
            currentPage: 2,
            postItems: [],
            totalPostCount: 0,
            reactions: [],
        };
    },
    async mounted() {
        if (this.context.authUser) {
            await this.getReactions();
        }

        this.postItems = this.posts;
        this.totalPostCount = this.context.league.post_count;
        this.loading = false;
    },
    computed: {
        context() {
            return {
                ...this.parentContext,
            };
        },
        showPrivateMessage() {
            return (
                this.context.league.permissions.privacy === "Private" &&
                (!this.context.authUser ||
                    !this.context.authUser.leaguePermissions.isMember)
            );
        },
        userCanPost() {
            if (this.context.authUser) {
                if (this.context.league.permissions.posts === "All members") {
                    return this.context.authUser.leaguePermissions.isMember;
                }
                if (
                    this.context.league.permissions.posts === "Owner and admin"
                ) {
                    return (
                        this.context.authUser.leaguePermissions.isOwner ||
                        this.context.authUser.leaguePermissions.isAdmin
                    );
                }
                return this.context.authUser.leaguePermissions.isOwner;
            }
            return false;
        },
    },
    methods: {
        addPost(post) {
            this.loading = true;
            if (this.postItems.length > 0 && this.postItems[0].pinned) {
                this.postItems.splice(1, 0, post);
            } else {
                this.postItems.unshift(post);
            }

            this.totalPostCount++;
            this.loading = false;
            this.$emit("post-created", post);
        },
        updatePost(post) {
            const index = this.postItems.findIndex((p) => p.id === post.id);
            this.postItems[index] = post;
        },
        deletePost(id) {
            const index = this.postItems.findIndex((p) => p.id === id);
            this.postItems.splice(index, 1);
            this.totalPostCount--;
        },
        async getReactions() {
            this.loading = true;
            try {
                const response = await axios.post("/leagues/getReactionList");
                this.reactions = response.data;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        },
        async getMorePosts($state) {
            if (this.postItems.length < this.totalPostCount) {
                try {
                    const requestData = {
                        leagueId: this.context.league.id,
                        per_page: this.perPage,
                        page: this.currentPage,
                        listType: this.listType
                    };

                    const { data } = await axios.post(
                        "/leagues/posts",
                        requestData
                    );

                    if (data.posts.length) {
                        this.totalPostCount = data.post_count;
                        this.currentPage += 1;
                        this.postItems = this.postItems.concat(...data.posts);
                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                } catch (error) {
                    // If there is an error for some reason, show alert
                    await this.$swal(error.response.data.message);
                }
            } else {
                $state.complete();
            }
        },
    },
};
</script>

<style scoped></style>
