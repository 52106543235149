<template>
    <div>
        <b-form @submit.prevent="submitForm">
            <span class="float-right">SMS</span>
            <span class="float-right mr-5">Email</span>
            <h3 class="h4 font-weight-bold">Notifications</h3>
            <hr class="mt-2 mb-5" />
            <div
                v-if="user.phone === null"
                class="alert alert-info"
                role="alert"
            >
                If you want to enable SMS notifications, you must
                <a href="#" v-on:click="showPhoneModal = true"
                    >add your phone number.</a
                >
            </div>
            <div
                v-for="notification in notificationSettings"
                :key="notification.id"
            >
                <label>{{ notification.title }}</label>
                <div class="form-check form-check-inline float-right">
                    <input
                        class="form-check-input mr-5"
                        type="checkbox"
                        value="1"
                        v-model="form['email_' + notification.id]"
                    />
                    <input
                        class="form-check-input"
                        type="checkbox"
                        value="1"
                        v-model="form['sms_' + notification.id]"
                        :disabled="user.phone === null"
                    />
                </div>
            </div>
            <submit-button
                :loading="loading"
                text="Submit"
                class="mt-5"
            ></submit-button>
            <b-form-text
                v-show="saved"
                text-variant="success"
                class="h6 text-center mt-3"
                >Notifications Saved!</b-form-text
            >
        </b-form>
        <add-phone-number-modal
            :modal-open.sync="showPhoneModal"
            @updatePhone="updatePhone"
        ></add-phone-number-modal>
    </div>
</template>

<script>
import UserSettingsEventBus from "../../Wrappers/UserSettings/UserSettingsEventBus.js";
import SubmitButton from "../../Buttons/SubmitButton";

/** There are no validations on this form **/
export default {
    name: "UserNotificationForm",
    components: { SubmitButton },
    props: {
        user: {
            type: Object,
            required: true,
        },
        notificationSettings: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            saved: false,
            form: {},
            showPhoneModal: false,
        };
    },
    mounted() {
        this.setForm();
    },
    methods: {
        setForm() {
            const form = {};
            this.notificationSettings.map((setting) => {
                form["sms_" + setting.id] = setting.sms;
                form["email_" + setting.id] = setting.email;
            });
            this.form = form;
        },
        generateRequest() {
            const request = [];
            for (const [key, value] of Object.entries(
                this.notificationSettings,
            )) {
                request.push({
                    id: value.id,
                    email: this.form["email_" + value.id] ? 1 : 0,
                    sms: this.form["sms_" + value.id] ? 1 : 0,
                    title: value.title,
                });
            }
            return request;
        },
        async submitForm() {
            try {
                this.loading = true;
                // Attempt to update permissions
                const response = await axios.post(
                    "/settings/updateNotifications",
                    { notifications: this.generateRequest() },
                );

                // Emit the updated notification settings to grandparent UserSettings.vue
                UserSettingsEventBus.$emit(
                    "update-notification-settings",
                    this.generateRequest(),
                );

                this.loading = false;
                this.saved = true;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        },
        updatePhone(value) {
            this.user.phone = value;
        },
    },
};
</script>
