<template>
    <div>
        <!-- If the owner of the league is on the standard plan -->
        <!-- <div v-if="!league.ownerElite">
            <h1 class="h2 font-weight-bold">Payment Account</h1>
            <p class="text-muted mb-4">
                {{ subtitle }}
            </p>

            <b-btn
                v-b-modal:plan-upgrade-modal
                variant="purple"
                block
                pill
                class="mb-3"
            >
                Upgrade your User Subscription Plan to setup a paid league.
            </b-btn> -->
            <!-- After modal route back to same page -->
            <!-- <plan-upgrade-modal
                :afterUpgradeRoute="currentRoute"
                :declineRoute="currentRoute"
            ></plan-upgrade-modal>
        </div> -->
        <!-- If the owner of the league is on the elite plan -->
        <div >
            <b-card style="position: relative;">
                <b-btn
                    v-if="!paymentAccountEditing"
                    variant="link"
                    class="abs-top-right"
                    @click="paymentAccountEditing = true"
                    >
                  Edit
                </b-btn>
                <payment-account-form
                    title="Payment Account"
                    :subtitle="subtitle"
                    :league-id="league.id"
                    :account="paymentAccount"
                    :read-only="!paymentAccountEditing"
                    @payment-account-created="reloadPage"
                    @payment-account-updated="handlePaymentAccountUpdated"
                >
                  <template #before-fields>
                    <!-- If league is current on stripe pending check status, display this info message. -->
                    <b-alert :show="league.pendingStripeConnectCheck" variant="info">
                      Account is currently under verification. This may take at least 5 minutes, notification will be sent to your account.
                    </b-alert>
                  </template>
                </payment-account-form>
            </b-card>
            <b-card v-if="showPayout" class="my-5">
                <b-btn
                    v-if="!payoutEditing"
                    variant="link"
                    class="abs-top-right"
                    @click="payoutEditing = true"
                    >Edit</b-btn
                >
                <h1 class="h2 font-weight-bold">Payout Account</h1>
                <p class="text-muted mb-4">
                    This is the account your money will be sent to.
                </p>
              <b-alert :show="hasPayoutAccountErrors && !hidePayoutErrors" variant="dark" class="mb-4" fade>
                <p class="font-weight-bold">
                  Additional verification information is required to enable payout or charge capabilities on this account.
                </p>
                <ul>
                  <li v-for="error in payoutAccountErrors">
                    {{ error }}
                  </li>
                </ul>
              </b-alert>
                <fieldset :disabled="!payoutEditing">
                    <b-form-group label="Payout Method">
                        <b-form-select
                            v-model="payoutMethod"
                            :options="payoutMethodOptions"
                        >
                        </b-form-select>
                    </b-form-group>
                </fieldset>
                <hr class="my-3" />
                <direct-deposit-form
                    v-if="payoutMethod === 'bank_account'"
                    title=""
                    :account="directDepositInfo"
                    :read-only="!payoutEditing"
                    button-text="Submit"
                    @stripe-submitted="submitBankForm"
                ></direct-deposit-form>
                <div v-if="payoutMethod === 'card'">
                    <stripe-payment-form
                        v-if="payoutEditing"
                        :account="debitCardInfo"
                        @stripe-submitted="submitBankForm"
                    ></stripe-payment-form>
                    <payment-method-card
                        v-else
                        hide-update
                        :payment-method="debitCardInfo"
                    ></payment-method-card>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import LeaguePaymentAccountForm from "../../Forms/LeagueForms/LeaguePaymentAccountForm";
import PaymentAccountForm from "../../Forms/PaymentForms/PaymentAccountForm";
import DirectDepositForm from "../../Forms/PaymentForms/DirectDepositForm";
import StripePaymentForm from "../../Forms/PaymentForms/StripePaymentForm";
import PlanUpgradeModal from "../../Modals/PlanUpgradeModal";
import PaymentMethodCard from "../../Cards/PaymentMethodCard";
export default {
    name: "LeagueSettingsPaymentAccount",
    components: {
        PaymentMethodCard,
        PlanUpgradeModal,
        StripePaymentForm,
        DirectDepositForm,
        PaymentAccountForm,
        LeaguePaymentAccountForm,
    },
    props: {
        league: {
            type: Object,
            required: true,
        },
        paymentAccount: Object,
    },
    data() {
        return {
            payoutEditing: false,
            hidePayoutErrors: false,
            paymentAccountEditing: this.paymentAccount && this.paymentAccount.pastDue,
            payoutMethod: "direct",
            payoutMethodOptions: [
                { value: "bank_account", text: "Direct Deposit" },
                { value: "card", text: "Debit Card" },
            ],
        };
    },
    mounted() {
        if (this.paymentAccount) {
            this.payoutMethod = this.paymentAccount.type;
        }
    },
    computed: {
      getPaymentAccountEditing() {
        console.log("Payment Account: ",this.paymentAccount.pastDue);
        return typeof this.paymentAccount.pastDue !=='undefined';
      },

        showPayout() {
            return this.paymentAccount && !this.accountPastDue;
        },
        accountPastDue() {
            return this.paymentAccount ? this.paymentAccount.pastDue : false;
        },
        subtitle() {
            return this.paymentAccount === null
                ? "If you want to be able to charge for your league, you must first setup a payment account."
                : "Update your details below.";
        },
        currentRoute() {
            return `/leagues/edit/${this.league.id}?section=payment-account`;
        },
        directDepositInfo() {
            if (this.paymentAccount.type === "bank_account") {
                return {
                    accountOwnerName: this.paymentAccount.bank_name,
                    accountNumber: this.paymentAccount.last4,
                    routingNumber: this.paymentAccount.routing,
                };
            }
            return null;
        },
        debitCardInfo() {
            if (this.paymentAccount.type === "card") {
                return {
                    brand: this.paymentAccount.brand,
                    last4: this.paymentAccount.last4,
                    expiration: this.paymentAccount.expiration,
                };
            }
            return null;
        },
      hasPayoutAccountErrors() {
        return this.paymentAccount.fields.includes('payoutAccount');
      },
      payoutAccountErrors() {
        if(!this.hasPayoutAccountErrors) return [];
        return [
            'A bank account or debit card must be on file.'
        ];
      }
    },
    methods: {
        async handlePaymentAccountUpdated() {
          await this.$swal({
            icon: 'success',
            title: 'Successful',
            showConfirmButton: false,
            timer: 1500
          });
          this.reloadPage()
        },
        reloadPage() {
            window.location.reload();
        },
        async submitBankForm(token) {
            this.hidePayoutErrors = true
            try {
                const response = await axios.post("/leagues/updateBank", {
                    token: token.id,
                    leagueId: this.league.id,
                });
                await this.$swal({
                  icon: 'success',
                  title: 'Successful',
                  showConfirmButton: false,
                  timer: 1500
                });
                this.reloadPage();
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped></style>
