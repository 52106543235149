<template>
    <b-row>
        <b-col cols="12" lg="5">
            <login-register-card
                v-if="context.authUser === null"
                :league-name="context.league.name"
                class="mb-4"
                :league-price="leaguePrice"
                :league-id="context.league.id"
                :league-trial="context.league.trial"
            ></login-register-card>
            <about-card
                :content="context.league.description"
                class="mb-4"
            ></about-card>
            <donation-form
                v-if="this.$mq === 'sm'"
                :league-id="context.league.id"
                block
                class="d-md-none mb-3"
            ></donation-form>
            <members-list-card
                :members="context.league.members"
                :show-more="context.authUser !== null"
                class="mb-4"
                @view-members="$emit('change-active-section', 'members')"
                :members-count="parentContext.league.members_count"
            ></members-list-card>
            <video-list-card
                v-if="context.authUser"
                v-show="showVideoInProcess"
                :league="parentContext.league"
                :show-video-in-process.sync="showVideoInProcess"
            ></video-list-card>
            <leagues-list-card
                v-if="parentContext.league.sub_leagues && parentContext.league.sub_leagues.length > 0"
                :leagues="parentContext.league.sub_leagues"
                :user-id="context.authUser ? context.authUser.id : 0"
                :parent-league-id="parentContext.league.id"
                class="d-none d-lg-block mb-3"
            ></leagues-list-card>
        </b-col>
        <b-col cols="12" lg="7">
            <b-row id="wall-navigation" class="mb-4">
                <b-col class="text-center">
                    <b-button
                        variant="clear"
                        :class="activeItem === 'all' ? 'active' : ''"
                        @click="updateActiveItem('all')"
                    >
                        <i class="fas fa-th fa-2x"></i>
                    </b-button>
                </b-col>
                <b-col class="text-center">
                    <b-button
                        variant="clear"
                        :class="activeItem === 'images' ? 'active' : ''"
                        @click="updateActiveItem('images')"
                    >
                        <i class="fas fa-camera-retro fa-2x"></i>
                    </b-button>
                </b-col>
                <b-col class="text-center">
                    <b-button
                        variant="clear"
                        :class="activeItem === 'videos' ? 'active' : ''"
                        @click="updateActiveItem('videos')"
                    >
                        <i class="fas fa-film fa-2x"></i>
                    </b-button>
                </b-col>
                <b-col class="text-center">
                    <b-button
                        variant="clear"
                        :class="activeItem === 'textOnly' ? 'active' : ''"
                        @click="updateActiveItem('textOnly')"
                    >
                        <i class="fas fa-comment-alt fa-2x"></i>
                    </b-button>
                </b-col>
                <b-col class="text-center">
                    <b-button
                        variant="clear"
                        :class="activeItem === 'bursts' ? 'active' : ''"
                        @click="updateActiveItem('bursts')"
                    >
                        <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M4.5002 11.4V18.6C4.5002 19.0945 4.09472 19.5 3.6002 19.5C3.10568 19.5 2.7002 19.0945 2.7002 18.6V11.4C2.7002 10.9055 3.10568 10.5 3.6002 10.5C4.09472 10.5 4.5002 10.9055 4.5002 11.4ZM26.4002 4.5C25.9057 4.5 25.5002 4.90548 25.5002 5.4V24.6C25.5002 25.0945 25.9057 25.5 26.4002 25.5C26.8947 25.5 27.3002 25.0945 27.3002 24.6V5.4C27.3002 4.90548 26.8947 4.5 26.4002 4.5ZM5.7002 17.556L24.3002 23.1903V6.8097L5.7002 12.444V17.556ZM14.9909 21.6237L10.0469 20.1272C9.96604 20.1038 9.88518 20.0909 9.80432 20.0909C9.62737 20.0909 9.45275 20.1448 9.3098 20.2561C9.09417 20.4155 8.97113 20.6581 8.97113 20.9253C8.97113 21.2968 9.20785 21.6178 9.56528 21.7268L14.506 23.2233C14.7638 23.3018 15.031 23.2538 15.2466 23.0944C15.4599 22.935 15.5829 22.6925 15.5829 22.4253C15.5829 22.0538 15.3451 21.7327 14.9911 21.6237H14.9909Z"
                                :fill="
                                    activeItem === 'bursts'
                                        ? '#5451DE'
                                        : '#000000'
                                "
                            />
                        </svg>
                    </b-button>
                </b-col>
                <b-col class="text-center">
                    <b-button
                        variant="clear"
                        :class="activeItem === 'posts' ? 'active' : ''"
                        @click="updateActiveItem('posts')"
                    >
                        <i class="fas fa-list fa-2x"></i>
                    </b-button>
                </b-col>
            </b-row>
            <wall-media-list
                ref="allWallMediaList"
                v-show="activeItem === 'all'"
                :league-id="context.league.id"
                :listType="'all'"
                :parent-context="context"
                :is-league-profile="false"
            ></wall-media-list>
            <wall-media-list
                ref="videoWallMediaList"
                v-show="activeItem === 'videos'"
                :league-id="context.league.id"
                :listType="'videos'"
                :parent-context="context"
            ></wall-media-list>
            <wall-media-list
                ref="imageWallMediaList"
                v-show="activeItem === 'images'"
                :league-id="context.league.id"
                :listType="'images'"
                :parent-context="context"
            ></wall-media-list>
            <league-posts-list
                v-show="activeItem === 'textOnly'"
                :list-type="'textOnly'"
                :posts="parentContext.league.textOnlyPosts"
                :parent-context="context"
                :show-media="false"
                @post-created="updateWallMediaLists"
            ></league-posts-list>
            <league-posts-list
                v-show="activeItem === 'bursts'"
                :list-type="'burstOnly'"
                :posts="parentContext.league.bursts"
                :parent-context="context"
                :show-media="true"
                @post-created="updateWallMediaLists"
            ></league-posts-list>
            <league-posts-list
                v-show="activeItem === 'posts'"
                :list-type="'all'"
                :posts="parentContext.league.posts"
                :parent-context="context"
                :show-media="true"
                @post-created="updateWallMediaLists"
            ></league-posts-list>
        </b-col>
    </b-row>
</template>

<script>
import AboutCard from "../../Cards/AboutCard";
import MembersListCard from "../../Cards/MembersListCard";
import PostCard from "../../Cards/PostCard";
import LoginRegisterCard from "../../Cards/LoginRegisterCard";
import VideoListCard from "../../Cards/VideoListCard";
import PostForm from "../../Forms/PostForm";
import LeaguePostsList from "../../Lists/LeaguePostsList";
import WallMediaList from "../../Lists/WallMediaList";
import DonationForm from "../../Forms/DonationForm";
import LeaguesListCard from "../../Cards/LeaguesListCard";

export default {
    name: "LeagueProfileDiscussion",
    components: {
        DonationForm,
        WallMediaList,
        LeaguePostsList,
        PostForm,
        PostCard,
        AboutCard,
        MembersListCard,
        LoginRegisterCard,
        VideoListCard,
        LeaguesListCard,
    },
    props: {
        /* Contextual Object that contains league and authUser (including league roles) */
        parentContext: {
            type: Object,
            required: true,
        },
    },
    computed: {
        context() {
            return {
                profileTab: "Discussion",
                ...this.parentContext,
            };
        },
        leaguePrice() {
            return this.parentContext?.league?.price
        }
    },
    data() {
        return {
            activeItem: "posts",
            showVideoInProcess: false,
        };
    },
    beforeMount() {},
    mounted() {},
    methods: {
        updateActiveItem(newActiveItem) {
            this.activeItem = newActiveItem;
        },
        updateWallMediaLists(post) {
            this.$refs.allWallMediaList.getWallMediaItems(1);
            this.$refs.videoWallMediaList.getWallMediaItems(1);
            this.$refs.imageWallMediaList.getWallMediaItems(1);
        },
    },
};
</script>

<style scoped lang="scss">
#wall-navigation {
    .col {
        padding: 0px !important;
    }
    .btn.btn-clear {
        &.active {
            i.fas {
                color: blue;
            }
        }
    }
}
</style>
