<template>
    <b-modal
        id="video-popup-modal"
        v-model="open"
        size="md"
        class="video-popup-custom"
        hide-footer
        hide-header
        centered
    >
        <span class="close-btn" @click="$bvModal.hide('video-popup-modal')"
            ><img src="/images/icons/close.svg"
        /></span>
        <div class="burst-replies-video-inner">
            <video
                autoplay
                :muted="muted"
                class="video-show video-js"
                controls
                disablepictureinpicture
                controlslist="nodownload"
                id="videoPopup"
                playsinline
            >
                <source
                    v-if="
                        videoUrl.split('.').slice(-1)[0].toLowerCase() === 'mp4'
                    "
                    :src="videoUrl"
                    type="video/mp4"
                />
                <source
                    v-if="
                        videoUrl.split('.').slice(-1)[0].toLowerCase() ===
                        'webm'
                    "
                    :src="videoUrl"
                    type="video/webm"
                />
                <source
                    v-if="
                        videoUrl.split('.').slice(-1)[0].toLowerCase() === 'ogg'
                    "
                    :src="videoUrl"
                    type="video/ogg"
                />
                <source
                    v-if="
                        videoUrl.split('.').slice(-1)[0].toLowerCase() ===
                        'm3u8'
                    "
                    :src="videoUrl"
                    type="application/x-mpegURL"
                />
            </video>
            <a :href="'/u/' + postedBy.id" class="user-name">{{
                postedBy.name
            }}</a>
            <div class="slider-links">
                <span
                    v-if="index > 0"
                    class="arrow-previous"
                    @click="openVideo(index - 1, index)"
                >
                    <img src="/images/icons/previous.png" />
                </span>
                <span
                    v-if="total - 1 > index"
                    class="arrow-next"
                    @click="openVideo(index + 1, index)"
                >
                    <img src="/images/icons/next.png" />
                </span>
            </div>
        </div>
    </b-modal>
</template>

<script>
import videojs from "video.js";
require("videojs-playlist");
require("videojs-contrib-quality-levels");
require("videojs-hls-quality-selector");

export default {
    name: "VideoPopupModal",
    props: {
        modalOpen: {
            type: Boolean,
        },
        videoUrl: String,
        postedBy: Object,
        index: Number,
        total: Number,
        currentTime: {
            default: 0,
            type: Number,
        },
        muted: {
            default: true,
            type: Boolean,
        },
    },
    data() {
        return {
            video: this.videoUrl,
            player: null,
        };
    },
    computed: {
        open: {
            get() {
                try {
                    setTimeout(() => {
                        const videoPlayer = document.getElementById("videoPopup");
                        if (videoPlayer) {
                            this.player = videojs("videoPopup", {
                                preload: true,
                                autoplay: true,
                                controls: true,
                                controlBar: {
                                    playToggle: true,
                                    captionsButton: false,
                                    chaptersButton: false,
                                    subtitlesButton: false,
                                    remainingTimeDisplay: false,
                                    progressControl: {
                                        seekBar: false,
                                    },
                                    fullscreenToggle: false,
                                    playbackRateMenuButton: false,
                                    pictureInPictureToggle: false,
                                },
                                userActions: {
                                    doubleClick: false,
                                },
                            });

                            this.player.pause();
                            const ext = this.videoUrl.split(".").pop().toLowerCase();
                            switch (ext) {
                                case "mp4":
                                    this.player.src([
                                        {
                                            type: "video/mp4",
                                            src: this.videoUrl,
                                        },
                                    ]);
                                    break;
                                case "webm":
                                    this.player.src([
                                        {
                                            type: "video/webm",
                                            src: this.videoUrl,
                                        },
                                    ]);
                                    break;
                                case "ogg":
                                    this.player.src([
                                        {
                                            type: "video/ogg",
                                            src: this.videoUrl,
                                        },
                                    ]);
                                    break;
                                case "m3u8":
                                    this.player.src([
                                        {
                                            type: "application/x-mpegURL",
                                            src: this.videoUrl,
                                        },
                                    ]);
                                    break;
                            }
                            this.player.currentTime(this.currentTime);
                        }
                    }, 100);
                } catch (e) {
                    console.warn("VIDEOJS ERROR: ", e);
                }
                return this.modalOpen;
            },
            set(open) {
                this.$emit("update:modalOpen", open);

                if(!open) {
                    this.$emit("update:currentTime", this.player.currentTime());
                }
            },
        },
    },
    methods: {
        openVideo: function (index, oldIndex) {
            this.$emit('open-video-pop-up-modal', {
                index,
                oldIndex: oldIndex,
                currentTime: this.player.currentTime()
            });
        }
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose();
        }
    },
};
</script>
<style scoped lang="scss">
.arrow-previous {
    position: absolute;
    right: 483px;
    top: calc(50% - 36px);
    cursor: pointer;
    left: -50px;
}
.arrow-next {
    position: absolute;
    right: -33px;
    top: calc(50% - 35px);
    cursor: pointer;
}
.user-name {
    position: relative;
    left: 200px;
    bottom: 15px;
    color: white;
}
::v-deep .modal-body {
    padding: 0 !important;
}
.v-modal-custom {
    padding: 0 !important;
}
::v-deep .modal-content {
    background: #262626 !important;
    padding: 0;
    margin: 0;
    border: 0;
}
.close-btn {
    position: absolute;
    z-index: 9;
    right: -20px;
    top: 0px;
}
.video-show {
    max-height: 495px;
    max-width: 100%;
    width: 100%;
    min-height: 495px;
}
.burst-replies-video-inner {
    text-align: center;
    line-height: 0px;
}

//video tag customize
::v-deep .vjs-control-bar {
    background-color: transparent;
}
audio::-webkit-media-controls-current-time-display,
video::-webkit-media-controls-current-time-display,
audio::-webkit-media-controls-time-remaining-display,
video::-webkit-media-controls-time-remaining-display {
    display: none !important;
}
audio::-webkit-media-controls-timeline,
video::-webkit-media-controls-timeline {
    display: none;
}
audio::-webkit-media-controls-mute-button,
video::-webkit-media-controls-mute-button {
    margin-right: 400px !important;
}
video::-webkit-media-controls-fullscreen-button {
    display: none;
}
video::-webkit-media-controls-play-button {
    margin-right: 50px !important;
    position: absolute;
}
@media (max-width: 576px) {
    .video-show[data-v-41ce721e] {
        max-height: 100vh;
        max-width: 100%;
        min-height: unset;
    }
    .close-btn {
        right: 5px;
        top: 0px;
    }
    ::v-deep .modal-dialog.modal-md.modal-dialog-centered {
        margin: 0px;
    }
    ::v-deep .modal-body {
        height: 100vh;
        align-items: center;
        display: flex;
    }
    .video-js .vjs-tech {
        position: unset;
    }
    .user-name {
        right: 15px !important;
        left: unset;
    }
    .burst-replies-video-inner {
        text-align: right;
    }
}
@media (max-width: 569px) {
     .video-show {
        max-height: 100vh;
        max-width: 100%;
        min-height: unset;
    }
    .arrow-previous {
        position: absolute;
        right: 483px;
        top: calc(50% - 36px);
        cursor: pointer;
        left: -5px;
    }
    .arrow-next {
        position: absolute;
        right: -3px;
        top: calc(50% - 35px);
        cursor: pointer;
    }
}
</style>
