<template>
    <div>
        <b-card title="Videos in Process" class="drop-shadow" v-show="showComponent">
            <hr />
            <b-list-group>
                <b-list-group-item
                    v-for="video in videos"
                    :key="video.id"
                >
                    <b-row class="text-purple">
                        <b-col>{{video.name}}</b-col>
                        <b-col sm="2"><i class="fas fa-spinner fa-pulse"></i></b-col>
                    </b-row>

                </b-list-group-item>
            </b-list-group>
        </b-card>

        <!-- Refresh Modal -->
        <b-modal hide-header-close
                 header-bg-variant="primary text-white"
                 no-close-on-backdrop
                 no-close-on-esc
                 title="Video Update"
                 v-model="openModal"
                 centered
                 @ok="pageReload"
                 @cancel="handleCancel"
                 ok-title="Yes, Refresh this page"
                 ok-variant="success"
                 cancel-title="No, I'll do it later"
                 cancel-variant="danger">
            <div class="d-block">
                <h5>The system has finished processing all of the videos. Refresh this page?</h5>
                <p><small><em>Note: Make sure you don't have any pending post.</em></small></p>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "VideoListCard",
    props: {
    },
    data() {
        return {
            videos: [],
            currentVideoCount: 0,
            reFetchVideos: null,
            showComponent: false,
            openModal: false,
            maxRetry: 10,
            retryCount: 0,
        };
    },
    mounted(){
        this.getVideosInProcess();

        this.$root.$on('video-posted', (value) => {
            this.getVideosInProcess(value);
        });
    },
    methods:{
        async getVideosInProcess(newVideoPosted) {
            try {
                const response = await axios.get("/leagues/posts/videos/in-process-all");

                if (newVideoPosted) console.log('New video has been posted');

                // set videos
                this.videos    = response?.data?.data;
                let videoCount = this.videos?.length;

                // check if there are video(s) in process
                if (videoCount > 0) {
                    // display video list if there's any
                    this.showComponent = true;

                    // refetch videos
                    if(!this.reFetchVideos) {
                        console.log("set refetch setInterval");
                        this.reFetchVideos = setInterval(this.getVideosInProcess, 5000);
                    }
                } else {
                    // cancel interval after a max retry has been reached
                    if (this.reFetchVideos && this.maxRetry === this.retryCount) {
                        this.reFetchVideos = clearInterval(this.reFetchVideos);
                        this.retryCount = 0;
                    }
                }

                // incase no result but there was a new video posted set the interval for refetching
                if(!this.reFetchVideos && newVideoPosted) {
                    console.log("new videos posted refetch setInterval");
                    this.reFetchVideos = setInterval(this.getVideosInProcess, 5000);
                }

                // show modal to refresh page
                if (!this.openModal) {
                    // if the video count is less meaning a new video has been processed.
                    this.openModal = videoCount == 0 && this.currentVideoCount > 0;
                }

                // set the currentVideo count
                this.currentVideoCount = videoCount;
                if (this.currentVideoCount == 0 && videoCount == 0) {
                    this.showComponent = false;
                    this.retryCount++;
                }
            } catch (error) {
                // If there is an error for some reason, show alert
                console.error(error)
                let message = error?.response?.data?.message;
                    message = !message || message === "" ? "Something went wrong! Failed to fetch videos." : message;
                await this.$swal(message);
            }
        },
        pageReload() {
            window.location.reload();
        },
        handleCancel() {
            this.openModal = false;
        }
    },
}
</script>