<template>
    <div v-if="errors.length > 0" id="error-container">
            <b-alert
                variant="dark"
                v-for="error in errors"
                show
                :key="error"
                dismissible
                >{{ error }}</b-alert
            >
    </div>
</template>

<script>
export default {
    name: "AppErrors",
    props: {
        errors: {
            type: Array,
        },
    },
};
</script>

<style scoped></style>
