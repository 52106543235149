<template>
    <div>
        <app-alert v-if="newLeague" :alert="newLeagueMessage"></app-alert>
        <div class="profile-header-container">
            <league-profile-header
                :league-name="league.name"
                :profile-photo="league.photo"
                :banner-image="league.banner"
                :zoom-controls="league.banner_zoom"
            >
            </league-profile-header>
        </div>
        <b-container>
            <!-- Profile Navigation -->
            <b-row class="mb-3">
                <b-col>
                    <button
                        :disabled="!context.authUser"
                        @click="changeActiveSection('store')"
                        type="button"
                        class="btn btn-primary btn-block mt-2 mb-2 d-md-none"
                    >Store <span class="float-right">{{storeProductsCount}}</span></button>
                    <league-profile-nav
                        :active-item="activeSection"
                        :auth-user="context.authUser"
                        :paid-league="paidLeague"
                        :league-id="league.id"
                        :cancel-date="cancelDate"
                        :is-admin="isAdmin"
                        :is-owner="isOwner"
                        :league="league"
                        :public-paid="
                            league.permissions.privacy === 'Public' &&
                            paidLeague
                        "
                        :following="following"
                        @change-active-item="changeActiveSection"
                        :subscriber-count="numberAbbrev(league.members_count)"
                        :has-pending-request="hasPendingRequest"
                        :member-requests="memberRequests"
                        :store-products-count="storeProductsCount"
                        @open-contact-window="openContactWindow"
                        :is-parent-league="!league.parent_league_id"
                    ></league-profile-nav>
                </b-col>
            </b-row>
            <!-- Profile Content -->
            <transition name="fade" mode="out-in">
                <component
                    :is="activeSectionComponent"
                    :parent-context="context"
                    @change-active-section="changeActiveSection"
                ></component>
            </transition>
        </b-container>
        <join-league-modal
            :price="league.price"
            :league-id="league.id"
            :queued="getQueued"
            :league-trial="league.trial"
        ></join-league-modal>
        <contact-card
            ref="contactCard"
            v-if="!league.owner"
            :league="league"
            :auth-user="context.authUser"
        >

        </contact-card>
    </div>
</template>

<script>
import LeagueProfileHeader from "../../Headers/LeagueProfileHeader";
import LeagueProfileNav from "../../Navigation/LeagueProfileNav";
import AboutCard from "../../Cards/AboutCard";
import MembersListCard from "../../Cards/MembersListCard";
import PostCard from "../../Cards/PostCard";
import LeagueProfileDiscussion from "./LeagueProfileDiscussion";
import LeagueProfileMembers from "./LeagueProfileMembers";
import LeagueProfileSubLeagues from "./LeagueProfileSubLeagues";
import JoinLeagueModal from "../../Modals/JoinLeagueModal";
import MediaModal from "../../Modals/MediaModal";
import AppAlert from "../../Alerts/AppAlert";
import { numberAbbreviation } from "../../Mixins/numberAbbreviation";
import ContactCard from "../../Cards/ContactCard";

export default {
    name: "LeagueProfile",
    components: {
        AppAlert,
        MediaModal,
        JoinLeagueModal,
        LeagueProfileDiscussion,
        LeagueProfileMembers,
        PostCard,
        AboutCard,
        MembersListCard,
        LeagueProfileNav,
        LeagueProfileHeader,
        LeagueProfileSubLeagues,
    },
    mixins: [numberAbbreviation],
    props: {
        league: {
            type: Object,
            required: true,
        },
        currentUser: Object,
        section: {
            type: String,
            default: "discussion",
        },
        isOwner: Boolean,
        isAdmin: Boolean,
        isMember: Boolean,
        cancelDate: [Number, String],
        newLeague: {
            type: Boolean,
            default: false,
        },
        following: {
            type: Boolean,
            default: false,
        },
        queued: {
            type: Boolean,
        },
        hasPendingRequest: Boolean,
        memberRequests: Array,
        storeProductsCount: {
            type: Number,
        }
    },
    data() {
        return {
            activeSection: null,
            newLeagueMessage: {
                title: "Congratulations!",
                content:
                    "Your new league has been created.",
            },
        };
    },
    mounted() {
        this.activeSection = this.section;
    },
    computed: {
      getQueued() {
        return !!this.cancelDate;
      },
        context() {
            return {
                page: "LeagueProfile",
                league: this.league,
                authUser: this.currentUser
                    ? {
                          ...this.currentUser,
                          leaguePermissions: {
                              isOwner: this.isOwner,
                              isAdmin: this.isAdmin,
                              isMember: this.isMember,
                          },
                      }
                    : null,
            };
        },
        activeSectionComponent() {
            switch (this.activeSection) {
                case "discussion":
                    return "league-profile-discussion";
                case "members":
                    return "league-profile-members";
                case "media":
                    return "league-profile-media";
                case "store":
                    return "league-profile-store";
                case "sub-leagues":
                    return "league-profile-sub-leagues";
                default:
                    return null;
            }
        },
        paidLeague() {
            return this.league.price !== "0.00";
        },
        subleague() {
            return this.league.parent_league_id ? 'Sub ' : '';
        }
    },
    methods: {
        changeActiveSection(value) {
            this.activeSection = value;

            // Change url to retain location on reload (but dont reload page currently)
            window.history.pushState(null, "", `?section=${value}`);
        },
        openContactWindow() {
            this.$refs.contactCard.showWindow(true);
        }
    },
};
</script>

<style scoped>
.ignore-top-padding {
    margin-top: -5rem;
}
</style>
