<template>
    <b-container>
        <b-row>
            <b-col cols="12" lg="5">
                <about-me-card :user="user" class="mb-4"></about-me-card>
                <leagues-list-card
                    :leagues="leagues"
                    :user-id="user.id"
                    class="d-none d-lg-block mb-3"
                ></leagues-list-card>
                <b-button
                    variant="outline-purple-purple"
                    block
                    pill
                    class="mb-3"
                    href="/leagues/create"
                >
                    Create a League
                </b-button>
                <leagues-list-card
                    :leagues="subscriptions"
                    :user-id="user.id"
                    subscriptions
                    class="d-none d-lg-block mb-3"
                ></leagues-list-card>
                <b-button
                    variant="outline-purple-purple"
                    block
                    pill
                    class="mb-3"
                    href="/browse-leagues"
                >
                    Browse More Leagues
                </b-button>
                <home-video-process
                    v-if="user.id"
                ></home-video-process>
            </b-col>
            <b-col cols="12" lg="7">
                <b-card
                    v-if="leagues.length === 0"
                    class="text-center drop-shadow p-5 mb-4"
                >
                    <h4 class="font-weight-bold mb-5">
                        Join Leagues to see posts in your feed.
                    </h4>
                    <b-btn
                        variant="purple"
                        pill
                        block
                        class="mb-3"
                        href="/browse-leagues"
                        >Find Leagues</b-btn
                    >
                    <b-btn
                        variant="link"
                        pill
                        block
                        class="text-muted"
                        href="/leagues/create"
                        >Want to start your own league? Click here.</b-btn
                    >
                </b-card>
                <div class="text-center text-grey mb-4">
                    <h5 class="h3 font-weight-bold">My Feed</h5>
                    <p>
                        Posts from the leagues that you are a member of will
                        show here.
                    </p>
                    <hr />
                </div>
                <b-row id="wall-navigation" class="mb-4">
                    <b-col class="text-center">
                        <b-button
                            variant="clear"
                            :class="activeItem === 'all' ? 'active' : ''"
                            @click="updateActiveItem('all')"
                        >
                            <i class="fas fa-th fa-2x"></i>
                        </b-button>
                    </b-col>
                    <b-col class="text-center">
                        <b-button
                            variant="clear"
                            :class="activeItem === 'images' ? 'active' : ''"
                            @click="updateActiveItem('images')"
                        >
                            <i class="fas fa-camera-retro fa-2x"></i>
                        </b-button>
                    </b-col>
                    <b-col class="text-center">
                        <b-button
                            variant="clear"
                            :class="activeItem === 'videos' ? 'active' : ''"
                            @click="updateActiveItem('videos')"
                        >
                            <i class="fas fa-film fa-2x"></i>
                        </b-button>
                    </b-col>
                    <b-col class="text-center">
                        <b-button
                            variant="clear"
                            :class="activeItem === 'textOnly' ? 'active' : ''"
                            @click="updateActiveItem('textOnly')"
                        >
                            <i class="fas fa-comment-alt fa-2x"></i>
                        </b-button>
                    </b-col>
                    <b-col class="text-center">
                        <b-button
                            variant="clear"
                            :class="activeItem === 'bursts' ? 'active' : ''"
                            @click="updateActiveItem('bursts')"
                        >
                            <svg
                                width="30"
                                height="30"
                                viewBox="0 0 30 30"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M4.5002 11.4V18.6C4.5002 19.0945 4.09472 19.5 3.6002 19.5C3.10568 19.5 2.7002 19.0945 2.7002 18.6V11.4C2.7002 10.9055 3.10568 10.5 3.6002 10.5C4.09472 10.5 4.5002 10.9055 4.5002 11.4ZM26.4002 4.5C25.9057 4.5 25.5002 4.90548 25.5002 5.4V24.6C25.5002 25.0945 25.9057 25.5 26.4002 25.5C26.8947 25.5 27.3002 25.0945 27.3002 24.6V5.4C27.3002 4.90548 26.8947 4.5 26.4002 4.5ZM5.7002 17.556L24.3002 23.1903V6.8097L5.7002 12.444V17.556ZM14.9909 21.6237L10.0469 20.1272C9.96604 20.1038 9.88518 20.0909 9.80432 20.0909C9.62737 20.0909 9.45275 20.1448 9.3098 20.2561C9.09417 20.4155 8.97113 20.6581 8.97113 20.9253C8.97113 21.2968 9.20785 21.6178 9.56528 21.7268L14.506 23.2233C14.7638 23.3018 15.031 23.2538 15.2466 23.0944C15.4599 22.935 15.5829 22.6925 15.5829 22.4253C15.5829 22.0538 15.3451 21.7327 14.9911 21.6237H14.9909Z"
                                    :fill="
                                        activeItem === 'bursts'
                                            ? '#5451DE'
                                            : '#000000'
                                    "
                                />
                            </svg>
                        </b-button>
                    </b-col>
                    <b-col class="text-center">
                        <b-button
                            variant="clear"
                            :class="activeItem === 'posts' ? 'active' : ''"
                            @click="updateActiveItem('posts')"
                        >
                            <i class="fas fa-list fa-2x"></i>
                        </b-button>
                    </b-col>
                </b-row>
                <wall-media-list
                    v-show="activeItem === 'all'"
                    :listType="'all'"
                    :parent-context="context"
                ></wall-media-list>
                <wall-media-list
                    v-show="activeItem === 'videos'"
                    :listType="'videos'"
                    :parent-context="context"
                ></wall-media-list>
                <wall-media-list
                    v-show="activeItem === 'images'"
                    :listType="'images'"
                    :parent-context="context"
                ></wall-media-list>
                <home-posts-list
                    v-show="activeItem === 'textOnly'"
                    :list-type="'textOnly'"
                    :posts="textOnlyPosts"
                    :total-posts="totalTextOnlyPosts"
                    :auth-user="user"
                    :show-media="false"
                ></home-posts-list>
                <home-posts-list
                    v-show="activeItem === 'bursts'"
                    :list-type="'burstOnly'"
                    :posts="bursts"
                    :total-posts="totalBursts"
                    :auth-user="user"
                    :show-media="false"
                ></home-posts-list>
                <home-posts-list
                    v-show="activeItem === 'posts'"
                    :list-type="'all'"
                    :posts="posts"
                    :total-posts="totalPosts"
                    :auth-user="user"
                    :show-media="true"
                ></home-posts-list>
            </b-col>
        </b-row>
        <welcome-back-modal
            v-if="user.account_reenabled"
            :modal-open="user.account_reenabled"
            :user="user"
        ></welcome-back-modal>
    </b-container>
</template>

<script>
import HomePostsList from "../Lists/HomePostsList";
import WallMediaList from "../Lists/WallMediaList";
import AboutMeCard from "../Cards/AboutMeCard";
import LeaguesListCard from "../Cards/LeaguesListCard";
import WelcomeBackModal from "../Modals/WelcomeBackModal"
import HomeVideoProcess from "../Cards/HomeVideoProcess";

export default {
    name: "HomePage",
    components: {
        LeaguesListCard,
        AboutMeCard,
        HomePostsList,
        WallMediaList,
        WelcomeBackModal,
        HomeVideoProcess
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
        leagues: {
            type: Array,
            required: true,
        },
        subscriptions: {
            type: Array,
            required: true,
        },
        posts: {
            type: Array,
            required: true,
        },
        bursts: {
            type: Array,
            required: true,
        },
        textOnlyPosts: {
            type: Array,
            required: true,
        },
        totalPosts: Number,
        totalTextOnlyPosts: Number,
        totalBursts: Number,
    },
    data() {
        return {
            activeItem: "posts",
        };
    },
    computed: {
        context() {
            return {};
        },
    },
    mounted(){
    },
    methods: {
        updateActiveItem(newActiveItem) {
            this.activeItem = newActiveItem;
        },
    },
};
</script>

<style scoped lang="scss">
#wall-navigation {
    .col {
        padding: 0px !important;
    }
    .btn.btn-clear {
        &.active {
            i.fas {
                color: blue;
            }
        }
    }
}
</style>
