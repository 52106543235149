<template>
    <b-modal
        id="add-phone-number-modal"
        v-model="open"
        title="Adding Phone Number"
        centered
        hide-footer
    >
        <b-container class="p-4">
            <b-form @submit.prevent="submitForm">
                <div class="form-group">
                    <label for="newPhoneNumber">Phone Number</label>
                    <b-form-input
                        id="newPhoneNumber"
                        type="text"
                        v-mask="'###-###-####'"
                        v-model.trim="phone"
                    ></b-form-input>
                </div>
                <submit-button
                    :loading="loading"
                    text="Submit"
                    class="mt-5"
                ></submit-button>
            </b-form>
        </b-container>
    </b-modal>
</template>

<script>
export default {
    name: "AddPhoneNumberModal",
    props: {
        modalOpen: {
            type: Boolean,
        },
    },
    data() {
        return {
            loading: false,
            errorMessage: null,
            phone: null
        };
    },
    computed: {
        open: {
            get() {
                return this.modalOpen;
            },
            set(value) {
                this.$emit("update:modalOpen", value);
            },
        },
    },
    methods: {
        async submitForm() {
            try {
                this.loading = true;
                // Attempt to update permissions
                const response = await axios.post(
                    "/settings/updatePhone",
                    { phone: this.phone }
                );

                this.loading = false;
                this.saved = true;

                this.$emit("updatePhone", response.data.phone);
                this.open = false;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        }
    },
};
</script>
