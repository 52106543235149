<template>
  <div>
  <div class="profile-header-container">
    <league-profile-header
        :league-name="league.name"
        :profile-photo="league.photo"
        :banner-image="league.banner"
    >
    </league-profile-header>
  </div>

    <b-container>
    <b-row>

      <b-col cols="12" lg="8" class="mt-3 mb-3">
      <p>The league you are subscribed to has changed their price to ${{ league.price }}. If you accept the new price,
        click the continue subscription button. If you do not agree with the new price, your subscription will end on
        {{subscription.expiration_date}}</p>
      <b-container>
        <b-button
            v-b-modal.join-league-modal>
          Continue Subscription
        </b-button>
        <join-league-modal
            :price="league.price"
            :league-id="league.id"
            :queued="true"
        ></join-league-modal>
      </b-container>
      </b-col>
    </b-row>
    </b-container>
  </div>
</template>

<script>
import JoinLeagueModal from "../../Modals/JoinLeagueModal";
import LeagueProfileHeader from "../../Headers/LeagueProfileHeader";

export default {
  name: "QueueJoin",
  components: {
    JoinLeagueModal,
    LeagueProfileHeader
  },
  props: {
    league: {
      type: Object,
      required: true,
    },
    currentUser: Object,
    isOwner: Boolean,
    isAdmin: Boolean,
    isMember: Boolean,
    subscription: {
      type: Object,
    }
  },
  computed: {

    paidLeague() {
      return this.league.price !== "0.00";
    },

    context() {
      return {
        league: this.league,
        authUser: this.currentUser
            ? {
              ...this.currentUser,
              leaguePermissions: {
                isOwner: this.isOwner,
                isAdmin: this.isAdmin,
                isMember: this.isMember,
              },
            }
            : null,
      };
    },
  }
}
</script>