<template>
    <div v-if="!loading" class="d-flex flex-column">
        <div class="chat-header">
            <div class="back-button-group">
                <div
                    class="back-button"
                    @click="goBackToConversations"
                >
                    <i class="fas fa-arrow-left fa-lg"></i>
                </div>
            </div>
            <div class="avatar-group">
            <a :href="'/u/'+participant.id">
                <b-avatar
                    :src="participant.avatar ? participant.avatar.file : null"
                    variant="grey"
                ></b-avatar>
                <div class="participant-name">{{ participant.name }}</div>
            </a>
            </div>
            <div></div>
        </div>
        <messages-list
            :conversationId="conversationId"
            :initial-messages="conversation.messages"
            :message-count="conversation.messages_total"
            :auth-user-id="userId"
        ></messages-list>

        <chat-message-form
            v-if="!disableMessageForm && !blockUser"
            :auth-user-id="userId"
            :conversationId="conversationId"
            @message-created="addMessage"
            @create-chat="createChat"
            class="border-top"
        ></chat-message-form>
    </div>
</template>

<script>
import ChatMessageForm from "../../Forms/ChatMessageForm";
import MessagesList from "../../Lists/MessagesList";
export default {
    name: "ChatConversation",
    components: { MessagesList, ChatMessageForm },
    props: {
        userId: {
            type: Number,
            required: true,
        },
        conversationId: {
            type: Number,
            required: true,
        },
        disableMessageForm: {
            type: Boolean,
            default: false
        },
        participantId: {
            type: Number,
        },
    },
    data() {
        return {
            loading: true,
            conversation: null,
            newMessages: [],
            blockUser: false,
            participant: null,
        };
    },
    mounted() {
        this.getConversation();
    },
    watch: {
        conversationId: function () {
            this.getConversation();
        },
    },
    methods: {
        async getConversation() {
            try {
                if (this.conversationId !== -1) {
                    const response = await axios.post("/inbox/getChat", {
                        chatId: this.conversationId,
                    });

                    this.conversation = response.data;
                    this.blockUser = this.conversation.participants.length > 0 && this.conversation.participants[0].friend ? true : false;
                    this.loading = false;
                    // We know that right now, participants array ONLY includes users other than the currently
                    // logged in user. This may change in the future.
                    this.participant = response.data.participants[0];
                } else {
                    this.conversation = {
                        messages: [],
                        messages_total: 0,
                    };
                    this.loading = false;
                }
            } catch (error) {
                // If there is an error for some reason, show alert
                if (error?.data?.message) {
                    await this.$swal(error.data.message);
                }

            }
        },
        addMessage() {
            this.getConversation();
            this.$emit("message-added");
        },
        async createChat(message) {
            try {
                const response = await axios.post("/inbox/create", {
                    mediaId: null,
                    message: message,
                    skipIsFriends: false,
                    users: [this.participantId],
                });

                this.$emit("realize-convo", response.data.chatId);
            } catch (error) {
                await this.$swal(error.response.data.message);
            }
        },
        goBackToConversations() {
            this.$emit("show-conversations-list");
        },
    },
};
</script>

<style scoped lang="scss">
.chat-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 8px;
    border-bottom: 1px solid #aaa;
    .back-button-group {
        width: 33%;
        .back-button {
            padding-left: 16px;
            color: #aaa;
        }
    }
    .avatar-group {
        width: 33%;
        text-align: center;
        .b-avatar {
            width: 40px;
        }
    }
    .end-group {
        width: 33%;
    }
}
</style>
