<template>
    <b-modal
        id="membership-plan"
        v-model="open"
        :size="resizeModal"
        centered
        hide-header
        hide-footer
    >
        <b-container class="p-sm-4#">
            <div 
                v-if="this.$mq === 'sm'"
                @click="isModalOpen = false"
                class="position-absolute float-right top-0 right-0 mt-3 mr-3 text-decoration-underline"
                style="z-index:99;"
            >
            Close
            </div>
            <b-tabs id="membership-tabs" content-class="mt-3" align="center" v-model="tabIndex">
                
                <!-- Register your new account -->
                <b-tab title="First" :title-link-class="disabledTab" active>
                    <b-col class="text-center">
                        <user-registration-form
                            white-theme
                            :email="email"
                            @next-step="getNextStep"
                            @plans-object="getPlansObject"
                        ></user-registration-form>
                    </b-col>
                </b-tab>

                <!-- Choose your Plan -->
                <b-tab title="Second" :title-link-class="disabledTab">
                    <b-row >
                        <b-col cols="12" md="8" class="px-sm-5">
                          <p v-if="leaguePrice > 0">{{ leagueName }} implemented a fee for members to join this league. The fee is <span class="text-purple">{{ leaguePriceCost }} per month</span><span v-if="leagueTrial"> with the first month free</span>.</p>
                            
                            <h6 class="mb-2 mt-4 font-weight-bolder">Please choose a membership plan:</h6>
                            <div v-if="isPlansObject" :class="this.$mq === 'sm' ? null : 'd-flex'">
                                <!-- Plans BASIC OR PREMIUM -->
                                <b-col v-for="(plan, index) in isPlansObject" :key="index" cols="12" md="6" 
                                    class="position-relative mr-4 px-3 py-3 mb-4 text-white" 
                                    :class="plan.id === 'standard' ? 'bg-red-fancy' : 'bg-purple-fancy'"
                                    style="border-radius:15px">
                                  <div v-if="plan.trial" class="ribbon ribbon-top-left"><span>First Month Free</span></div>
                                    <label class="w-100">
                                        <h4 class="text-center"> {{ plan.name }} </h4>
                                        <h1 class="text-center font-weight-bolder">
                                          <span>${{ plan.price }}</span>
                                        </h1>
                                        <h5 class="text-center mt-n2">Monthly</h5>
                                        
                                        <div
                                            v-if="isMediaQuerySmall"
                                            class="py-4 pb-3 text-center"
                                            @click="showPlanDetails = !showPlanDetails"
                                        >
                                            Show details<i class="fas fa-chevron-down pl-2"></i>
                                        </div>
                                        <div v-if="isMediaQuerySmall ? showPlanDetails : !showPlanDetails" class="py-2 pb-5" style="font-size:12px">
                                            <p><i class="fas fa-check pr-2"></i>{{ plan.items[0] }}</p>
                                            <p><i class="fas fa-check pr-2"></i>{{ plan.items[1] }}</p>
                                            <p><i class="fas fa-check pr-2"></i>{{ plan.items[2] }}</p>
                                        </div>
                                        <div 
                                            style="position:absolute; top:90%; left:50%; transform:translate(-50%, -50%); white-space:nowrap;"
                                        >
                                            <b-button 
                                                pill 
                                                variant="black" 
                                                class="px-4 mb-4"
                                                @click="selectPlan(plan)"
                                            >
                                                <span>Select Plan</span>
                                            </b-button>
                                        </div>
                                    </label>
                                </b-col>
                            </div>
                          <transition name="scale" mode="out-in">
                            <free-plan-message v-show="trial"/>
                          </transition>
                          <p>This small fee allows us to keep the lights on without having to sell your data or interrupt you when viewing the content you love with third-party ads.</p>
                        </b-col>
                        <b-col cols="12" md="4" class="px-sm-5">
                            <h6 class="mb-2 font-weight-bolder">We Accept</h6>
                            <div class="d-flex mb-4">
                                <i class="fab fa-cc-amex fa-2x pl-2"></i>
                                <i class="fab fa-cc-visa fa-2x pl-2"></i>
                                <i class="fab fa-cc-mastercard fa-2x pl-2"></i>
                                <i class="fab fa-cc-discover fa-2x pl-2"></i>
                                <i class="fab fa-cc-stripe fa-2x pl-2"></i>
                            </div>

                            <h6 class="mb-2 mt-4 border-1 border-bottom text-grey">Order Summary</h6>
                            <div class="text-xs mt-2" >
                                <div v-if="leaguePrice > 0" class="d-flex justify-content-between align-items-center font-weight-bolder text-sm">
                                    {{ leagueName }}
                                    <span v-if="!leagueTrial">${{ leaguePrice }} per month</span>
                                  <span v-if="leagueTrial">Free month</span>
                                </div>
                                <p v-if="leaguePrice > 0" class="text-xs mt-2">You will be billed {{ leaguePriceCost }} monthly starting {{currentDate}}. Cancel anytime.</p>
                            </div>
                            
                            <div v-if="planSelectedRed">
                                <div class="d-flex justify-content-between align-items-center font-weight-bolder text-sm">
                                    Platform Fee
                                  <span v-if="this.trial">Free month</span>
                                    <span v-if="!this.trial">${{ this.plansObject.data.plans[0].price }} per month</span>
                                </div>
                                <p class="text-xs mt-2">You will be billed ${{ this.plansObject.data.plans[0].price }} monthly starting {{currentDate}}. Cancel anytime.</p>
                            </div>
                            <div v-if="planSelectedPurple">
                                <div class="d-flex justify-content-between align-items-center font-weight-bolder text-sm">
                                    Platform Fee
                                  <span v-if="this.trial">Free month</span>
                                    <span v-if="!this.trial">${{ this.plansObject.data.plans[1].price }} per month</span>
                                </div>
                                <p class="text-xs mt-2">You will be billed ${{ this.plansObject.data.plans[1].price }} monthly starting {{currentDate}}. Cancel anytime.</p>
                            </div>

                            <hr class="mb-n1">
                            <div class="d-flex justify-content-between align-items-center font-weight-bolder text-sm mt-2">
                                <span class="text-grey">Today's Charge</span>
                              <span class="text-purple"><span v-if="showSubtotal !='Free'">$</span>{{ showSubtotal }}</span>
                            </div>
                            <user-plan-payment-form
                                v-if="tabIndex == 1" 
                                white-theme 
                                class="mt-4" 
                                :plan="plansObject.data"
                                @last-step="getLastStep"
                                :league-id="leagueId"
                                :is-plan-selected="planSelectedRed || planSelectedPurple"
                            ></user-plan-payment-form>
                        </b-col>
                    </b-row>
                </b-tab>

                <!-- Confirmation -->
                <b-tab title="Third" :title-link-class="disabledTab">
                    <b-col class="text-center">
                        <i class="fas fa-check-circle fa-3x text-purple"></i>
                        <h6 class="mb-2 mt-4 font-weight-bolder">Purchase successful!</h6>
                        <b-button pill class="btn-red" @click="reloadPage">Close</b-button>
                    </b-col>
                </b-tab>

                <!-- Control buttons-->
                <!-- <b-button-group class="mt-2 text-center">
                    <b-button @click="tabIndex--">Previous</b-button>
                    <b-button @click="tabIndex++">Next</b-button>
                </b-button-group>
                Current Tab: {{ tabIndex }} -->
            </b-tabs>
        </b-container>
    </b-modal>
</template>

<script>
import MediaList from "../Lists/MediaList";
import SubmitButton from "../Buttons/SubmitButton";
import UserRegistrationForm from "../Forms/UserForms/UserRegistrationForm"
import UserPlanPaymentForm from "../Forms/UserForms/UserPlanPaymentForm"
import UserPlanChoiceForm from "../Forms/UserForms/UserPlanChoiceForm"

export default {
    name: "MembershipPlanModal",
    components: { SubmitButton, MediaList, UserRegistrationForm, UserPlanPaymentForm, UserPlanChoiceForm },
    props: {
        isModalOpen:{
            type: Boolean,
            default: false,
        },
        leagueId: {
            type: Number,
            default: 0,
        },
        leagueName: {
            type: String,
            default: "",
        },
        leaguePrice: {
            type: String,
            default: "",
        },
      leagueTrial: {
          type: Number,
          default: 0,
      }
    },
    data() {
        return {
            tabIndex: 0,
            selectedMedia: null, // Used when selecting existing media
            isFilled: true,
            isOpen: false,
            trial: 0,
            disabledTab: 'd-none',
            planSelectedRed: false,
            planSelectedPurple: false,
            subtotal: 0,
            plansObject: {},
            plans: null,
            showPlanDetails: false,
            leaguePriceSelected: 0,
            email: null
        };
    },
    computed: {
        open: {
            get() {
                return this.isModalOpen;
            },
            set(value) {
                this.$emit("update:isModalOpen", value);
            },
        },
        showChooseMedia() {
            return !this.uploadOnly ? this.open : false;
        },
        showLeagueMedia() {
            return !this.uploadOnly ? this.open && this.leagueId : false;
        },
      planPrice() {
        if( this.planSelectedRed ) {
          return this.plansObject.data.plans[0].price;
        } else if( this.planSelectedPurple ) {
          return this.plansObject.data.plans[1].price;
        } else {
          return 0.00;
        }
      },
        resizeModal() {
            if (this.tabIndex == 0){
                return 'md';
            } if(this.tabIndex == 1){
                return 'xl-center';
            } else {
                return 'sm';
            }
        },
        currentDate(){
            const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            let current = new Date();
            current.setMonth(current.getMonth()+1);
            return `${monthNames[current.getMonth()]} ${current.getDate()}, ${current.getFullYear()}`;
        },
        isPlansObject(){
            return this.plansObject?.data?.plans;
        },
        isMediaQuerySmall(){
            if(this.$mq === 'sm'){
                return true;
            } else {
                return false;
            }
        },
        leaguePriceCost(){
            if(this.leaguePrice <= 0){
                return "Free";
            } else {
                this.leaguePriceSelected = Number(this.leaguePrice);
                return `$${this.leaguePrice}`;
            }
        },
        showSubtotal() {

          if( this.leagueTrial && this.trial ) {
            return 0.00;
          }

          if( this.leagueTrial ) {

            return this.planPrice;
          }
          if( this.trial ) {
            return this.leaguePrice;
          }
          return this.subtotal.toFixed( 2 );
        }
    },
    methods: {
        async submitForm() {
            this.$v.form.$touch();

            if (!this.$v.form.$anyError) {
                this.loading = true;
                try {
                    // Attempt to login
                    const response = await axios.post(
                        "/register/submit",
                        this.form
                    );
                    // Redirect once logged in
                    window.location = "/register/plan";
                } catch (error) {
                    // If there is an error for some reason, show alert
                    await this.$swal(error.response.data.message);
                    this.loading = false;
                }
            }
        },
        selectPlan(selected){
            if(selected.id === 'standard'){ //Basic Plan
                this.planSelectedRed = !this.planSelectedRed;
                this.planSelectedPurple = false;
                !this.planSelectedRed ? this.subtotal = this.leaguePriceCost : this.subtotal = this.leaguePriceSelected + this.plansObject.data.plans[0].price;
                this.trial = this.plansObject.data.plans[0].trial;
            } else { //Premium Plan
                this.planSelectedPurple = !this.planSelectedPurple; 
                this.planSelectedRed = false;
                !this.planSelectedPurple ? this.subtotal = this.leaguePriceCost : this.subtotal = this.leaguePriceSelected + this.plansObject.data.plans[1].price;
              this.trial = this.plansObject.data.plans[1].trial;
            }
        },
        getNextStep(next){
            this.tabIndex = next;
        },
        getLastStep(last){
            this.tabIndex = last;
        },
        getPlansObject(plans){
            this.plansObject = plans;
        },
        reloadPage(){
            if(this.tabIndex == 2){
                window.location.reload()
            }
        },
      setEmail(email) {
          this.email = email
      }
    },
};
</script>

<style scoped lang="scss">
.nav-tabs {
    border-bottom: 0px solid #dee2e6!important;
}
.plan-selected {
    box-shadow: 0 4px 15px rgb(42 42 42);
    border: 1px solid #ffffff;
}




.box {
  position: relative;
  max-width: 600px;
  width: 90%;
  height: 400px;
  background: #fff;
  box-shadow: 0 0 15px rgba(0,0,0,.1);
}

/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
font-size: 80%;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

/* bottom left*/
.ribbon-bottom-left {
  bottom: -10px;
  left: -10px;
}
.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.ribbon-bottom-left::before {
  bottom: 0;
  right: 0;
}
.ribbon-bottom-left::after {
  top: 0;
  left: 0;
}
.ribbon-bottom-left span {
  right: -25px;
  bottom: 30px;
  transform: rotate(225deg);
}

/* bottom right*/
.ribbon-bottom-right {
  bottom: -10px;
  right: -10px;
}
.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}
.ribbon-bottom-right::after {
  top: 0;
  right: 0;
}
.ribbon-bottom-right span {
  left: -25px;
  bottom: 30px;
  transform: rotate(-225deg);
}

.scale-enter {
  opacity: 0;
  transform: scale(0.9);
}

.scale-enter-active,
.scale-leave-active {
  transition: all 0.2s ease !important;;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
</style>