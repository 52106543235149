<template>
    <div>
        <template class="text-center">
            <!-- Disabled for now
            <input type="file" @change="croppie" /> -->

            <div v-show="isImgCropped" class="text-center">
                <div v-if="mediaChosen === 'profile'">
                    <vue-croppie
                        ref="croppieRef"
                        :enableOrientation="false"
                        :enableResize="false"
                        :boundary="{ width: 250, height: 250 }"
                        :viewport="{ width: 250, height: 250, type: 'circle' }"
                    >
                    </vue-croppie>
                </div>
                <!-- mediaChosen === 'banner' -->
                <div v-else> 
                    <vue-croppie
                        ref="croppieRef"
                        :enableOrientation="false"
                        :enableResize="false"
                        :boundary="{ width: 550, height: 200 }"
                        :viewport="{ width: 540, height: 190, type: 'square' }"
                    >
                    </vue-croppie>
                </div>
                <div
                    v-if="this.$mq != 'sm'"
                    class="row justify-content-between mt-n5"
                    style="z-index:-1; margin-left:18%; margin-right:15%;"
                >
                    <div>
                        <i class="fa fas fa-image fa-sm mt-2"></i>
                    </div>
                    <div>
                        <i class="fa fas fa-image fa-2x"></i>
                    </div>
                </div>
                <b-button
                    class="px-3 mb-3 text-center"
                    :variant="cropped ? 'success' : 'primary'"
                    @click="crop"
                >
                    <i class="fas fa-crop-alt"></i>
                    <span v-if="cropped">Cropped</span>
                    <span v-else>Crop</span>
                </b-button>
            </div>

            <!-- Image Cropped - result -->
            <!-- <img :src="cropped" /> -->
        </template>
    </div>
</template>

<script>
import VueCroppie from "vue-croppie";
import "croppie/croppie.css"; // import the croppie css manually

export default {
    name: "MediaCroppie",
    props:{
        mediaChosen: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            croppieImage: "",
            cropped: null,
            isImgCropped: false,
        };
    },
    mounted() {
        this.$emit("change-croppie", this.croppie); //Sending this to MediaUploadForm.vue
    },
    methods: {
        croppie(e) {
            var files = e.target.files || e.dataTransfer.files;

            if (!files.length) return;

            var reader = new FileReader();
            reader.onload = (e) => {
                this.$refs.croppieRef.bind({
                    url: e.target.result,
                });
                this.isImgCropped = true;
                this.$emit("media-type", "media"); // feed MediaUploadForm.vue
            };

            reader.readAsDataURL(files[0]);
        },
        crop() {
            // Options can be updated.
            // Current option will return a base64 version of the uploaded image with a size of 600px X 450px.
            
            let profile = { width: 250, height: 250 };
            let banner = { width: 550, height: 200 };

            let options = {
                type: "base64",
                size: `${this.mediaChosen === 'banner' ? banner : profile}`,
                format: "jpeg",
            };
            this.$refs.croppieRef.result(options, (output) => {
                this.cropped = this.croppieImage = output;
                this.$emit("cropped", this.cropped);
            });
        },
    },
};
</script>

<style>
div.cr-slider-wrap input.cr-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 10px;
    border-radius: 0%;
    background: #5451de;
    margin-top: -6px;
}
div.cr-slider-wrap input.cr-slider::-ms-fill-lower {
    background: #5451de;
    border-radius: 10px;
}
div.cr-slider-wrap input.cr-slider::-ms-fill-upper {
    background: #ccc;
    border-radius: 10px;
}
div.cr-slider-wrap input.cr-slider::-ms-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #ddd;
    margin-top: 1px;
}
div.cr-slider-wrap input.cr-slider:focus::-ms-fill-lower {
    background: #5451de;
}
div.cr-slider-wrap input.cr-slider:focus::-ms-fill-upper {
    background: #ccc;
}
</style>
