<template>
    <div>
        <b-card class="post-card drop-shadow" no-body>
            <b-card-body>
                <b-row class="post-card-header" no-gutters>
                    <b-col
                        cols="12"
                        class="d-flex justify-content-between align-items-start"
                    >
                        <b-media no-body class="d-flex align-items-center">
                            <b-media-aside vertical-align="center">
                                <b-avatar
                                    v-show="hasAuthUser() || post.privacy === 0"
                                    :src="post.posted_by.avatar"
                                    variant="grey"
                                    size="45"
                                ></b-avatar>
                            </b-media-aside>
                            <b-media-body
                                class="d-flex flex-column ml-3 user-title"
                            >
                                <a
                                    v-if="
                                        showLeague &&
                                        post.posted_by.type !== 'league'
                                    "
                                    class="small text-grey d-lg-none"
                                    :href="`/${post.league.slug}`"
                                    >{{ post.league.name }}</a
                                >
                                <a
                                    @mouseover="startHover"
                                    @mouseleave="stopHover"
                                    class="h6 font-weight-bold text-black mb-0"
                                    :href="
                                        post.posted_by.type === 'league'
                                            ? `/${post.posted_by.slug}`
                                            : `/u/${post.posted_by.id}`
                                    "
                                >
                                    {{
                                        !hasAuthUser() ||
                                        (hasAuthUser() && showJoin)
                                            ? post.league.name + " member"
                                            : post.posted_by.name
                                    }}
                                </a>
                                <div
                                    v-if="hover"
                                    class="drop-shadow"
                                    style="
                                        position: absolute;
                                        top: 20px;
                                        left: 20px;
                                        padding: 16px;
                                        z-index: 999;
                                        background: white;
                                    "
                                    @mouseover="startHover"
                                    @mouseleave="stopHover"
                                >
                                    <div v-if="userDetails">
                                        <p>
                                            <b-avatar
                                                v-show="
                                                    hasAuthUser() ||
                                                    post.privacy === 0
                                                "
                                                :src="post.posted_by.avatar"
                                                variant="grey"
                                                size="45"
                                            ></b-avatar>
                                            <span
                                                style="
                                                    font-size: 150%;
                                                    font-weight: bold;
                                                "
                                                >{{
                                                    !hasAuthUser() ||
                                                    (hasAuthUser() && showJoin)
                                                        ? post.league.name +
                                                          " member"
                                                        : post.posted_by.name
                                                }}
                                            </span>
                                        </p>
                                        <p
                                            align="center"
                                            v-if="
                                                userDetails &&
                                                !userDetails.friends &&
                                                !requested
                                            "
                                        >
                                            <button
                                                class="btn btn-secondary mt-1"
                                                @click="sendMessage"
                                            >
                                                <i
                                                    class="
                                                        fas
                                                        fa-envelope fa-fw fa-lg
                                                    "
                                                ></i>
                                                Send Message
                                            </button>

                                            <button
                                                class="btn btn-secondary mt-1"
                                                @click="requestFriendship"
                                            >
                                                <i
                                                    class="
                                                        fas
                                                        fa-user-plus fa-fw fa-lg
                                                    "
                                                ></i>
                                                Add Friend
                                            </button>
                                        </p>

                                        <p
                                            align="center"
                                            v-if="
                                                userDetails &&
                                                !userDetails.friends &&
                                                requested
                                            "
                                        >
                                            <button
                                                class="btn btn-secondary mt-1"
                                                @click="sendMessage"
                                            >
                                                <i
                                                    class="
                                                        fas
                                                        fa-envelope fa-fw fa-lg
                                                    "
                                                ></i>
                                                Send Message
                                            </button>

                                            <button
                                                class="btn btn-secondary mt-1"
                                                disabled
                                            >
                                                <i
                                                    class="
                                                        fas
                                                        fa-user-plus fa-fw fa-lg
                                                    "
                                                ></i>
                                                Pending Friend Request
                                            </button>
                                        </p>

                                        <p
                                            align="center"
                                            v-if="
                                                userDetails &&
                                                userDetails.friends
                                            "
                                        >
                                            <button
                                                class="btn btn-secondary mt-1"
                                                @click="sendMessage"
                                            >
                                                <i
                                                    class="
                                                        fas
                                                        fa-envelope fa-fw fa-lg
                                                    "
                                                ></i>
                                                Send Message
                                            </button>

                                            <button
                                                class="btn btn-secondary mt-1"
                                                @click="openRemoveModal"
                                            >
                                                <i
                                                    class="
                                                        fas
                                                        fa-user-minus
                                                        fa-fw
                                                        fa-lg
                                                    "
                                                ></i>
                                                Remove Friend
                                            </button>
                                        </p>
                                    </div>

                                    <div v-if="!userDetails">Loading</div>
                                </div>
                                <small class="text-muted mb-0">{{
                                    post.created_at | moment("from")
                                }}</small>
                            </b-media-body>
                        </b-media>
                        <div class="d-flex align-items-center">
                            <div
                                v-if="
                                    showLeague &&
                                    post.posted_by.type !== 'league'
                                "
                                class="d-none d-lg-block"
                            >
                                <a
                                    class="h5 text-grey"
                                    :href="`/${post.league.slug}`"
                                    >{{ post.league.name }}</a
                                >
                            </div>
                            <div v-if="postEditable">
                                <b-btn
                                    v-if="!showLeague"
                                    variant="ghost-purple"
                                    v-b-tooltip.hover
                                    title="Pin"
                                    :class="pinned ? 'active' : null"
                                    @click="togglePin"
                                >
                                    <i class="fas fa-thumbtack"></i>
                                </b-btn>
                                <b-dropdown
                                    class="post-menu-dropdown"
                                    variant="ghost-purple"
                                    toggle-class="text-decoration-none"
                                    right
                                    no-caret
                                >
                                    <template v-slot:button-content>
                                        <i class="fas fa-ellipsis-v"></i
                                        ><span class="sr-only">Post Menu</span>
                                    </template>
                                    <b-dropdown-item
                                        v-if="showJoin"
                                        @click="joinLeague"
                                        >Join League</b-dropdown-item
                                    >
                                    <b-dropdown-item
                                        v-if="postEditable"
                                        @click="$emit('edit-post')"
                                        >Edit Post</b-dropdown-item
                                    >
                                    <b-dropdown-item
                                        v-if="postEditable"
                                        @click="$emit('delete-post')"
                                        >Delete Post</b-dropdown-item
                                    >
                                    <b-dropdown-item
                                        v-if="
                                            postEditable &&
                                            post.children &&
                                            post.children.length
                                        "
                                        @click="openEditBurstPlaylistModal"
                                        >Edit Playlist</b-dropdown-item
                                    >
                                </b-dropdown>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <!-- Modal -->

                <p
                    v-show="hasAuthUser() || post.privacy === 0"
                    v-bind:ref="post.id"
                    class="mb-0 mt-3 post-description"
                    v-html="post.post"
                    :class="postTruncated ? 'truncate-text' : null"
                ></p>
                <div v-show="postTruncated" class="text-center">
                    <b-btn variant="link" @click="handleShowMore(true)"
                        >Show More</b-btn
                    >
                </div>
                <div v-show="showMoreHidden" class="text-center">
                    <b-btn variant="link" @click="handleShowMore(false)"
                        >Show Less</b-btn
                    >
                </div>
                <donation-form
                    v-if="post.donation"
                    :post-id="post.id"
                    :league-id="post.league.id"
                    :donated="post.userTotalDonation"
                    class="mt-3"
                ></donation-form>
                <link-card
                    v-if="post.links && post.links.length > 0"
                    v-for="link in post.links"
                    :key="link.id"
                    :link="link"
                    :loading="link.loading"
                    class="mt-2"
                ></link-card>
            </b-card-body>

            <!-- Media Display -->
            <template v-if="post.files">
                <media-lightbox
                    :items="post.files"
                    :postId="post.id"
                ></media-lightbox>
            </template>

            <div class="p-2" v-if="authUser == null">
                <b-btn
                    variant="outline-purple-purple"
                    @click="joinLeague"
                    class="text-nowrap btn-block"
                    :class="this.$mq === 'sm' ? 'text-xs' : null"
                >
                    Click to login or signup to see more and participate
                </b-btn>
            </div>

            <section
                v-show="
                    (hasAuthUser() && post.permissions.leagueOwner === 1) ||
                    post.permissions.member === 1 ||
                    post.privacy === 0
                "
            >
                <div
                    class="burst-replies-wrapper"
                    v-show="hasAuthUser() || post.privacy === 0"
                >
                    <div class="burst-replies" v-dragscroll>
                        <burst-preview-signup-item
                            v-if="authUser == null"
                            :burst="post"
                        ></burst-preview-signup-item>
                        <create-burst-reply-item
                            v-if="authUser != null"
                            :post="post"
                            :variant="'listButton'"
                        ></create-burst-reply-item>
                        <div
                            class="slider-center burst-replies"
                            id="content"
                            ref="content"
                            v-dragscroll
                        >
                            <burst-reply-item
                                v-for="(reply, index) in burstReplies"
                                :ref="`replyItem${index}`"
                                :key="index"
                                :index="index"
                                :parent-burst="post"
                                :current-burst="currentPost"
                                :burst-reply-index="index"
                                :burst-reply="reply"
                                @play-reply="playReply"
                                @reply-deleted="removeReply"
                                @open-video-pop-up-modal="openVideoPopUpModal"
                                :class="{
                                    'is-active-slide':
                                        current_slide_number === index,
                                }"
                                @play-next-video-inline="playNextVideoInline"
                                @video-mute-toggle="toggleMuteAll"
                            ></burst-reply-item>
                        </div>

                        <div class="slider-links">
                            <span
                                class="arrow-previous"
                                v-if="burstCount > 2 && arrowLeft"
                                @click="swipeLeft"
                            >
                                <i class="fas fa-chevron-circle-left"></i>
                            </span>

                            <span
                                v-if="burstCount > 2 && arrowRight"
                                class="arrow-next"
                                @click="swipeRight"
                            >
                                <i class="fas fa-chevron-circle-right"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </section>

            <div
                v-if="!post.preview"
                class="
                    d-flex
                    justify-content-between
                    px-3
                    py-2
                    text-grey
                    bg-black
                    post-status-counter
                "
            >
                <div v-if="dateCreated !== dateUpdated">
                    <p>Post Edited</p>
                </div>
                <reaction-counter
                    :reactions="post"
                    :reaction-types="reactions"
                    :counts="reactionCounts"
                ></reaction-counter>
                <div>
                    <span class="burst-count">
                        {{ numberAbbrev(burstCount) }} Bursts
                    </span>
                    <span>{{ numberAbbrev(commentCount) }} Comments</span>
                </div>
            </div>

            <b-card-body v-if="post.permissions.member" class="py-0">
                <post-action-list
                    :show-burst-reply-btn="showBurstReplies"
                    :post="currentPost"
                    :reactions="reactions"
                    :reaction-id="currentPost.reactionId"
                    @react-to-post="reactToPost"
                    @share-post="sharePost"
                ></post-action-list>
                <comments-list
                    :key="`post-${currentPost.id}`"
                    :show-comment-form="commentFormVisible"
                    parent-type="post"
                    :parent-item="currentPost"
                    :commentable-type="'post'"
                    :reactions="reactions"
                    :auth-user="authUser"
                    :league-id="currentPost.league.id"
                    @comment-added="newCommentAdded"
                    @comment-removed="commentRemoved"
                    :comment-level-count="0"
                    @toggle-main-comment-form="toggleMainCommentForm"
                ></comments-list>
            </b-card-body>
        </b-card>
        <membership-plan-modal
            :is-modal-open.sync="isMembershipPlanModalOpen"
            :league-id="leagueId"
            :league-name="leagueName"
            :league-price="leaguePrice"
        ></membership-plan-modal>
        <join-league-modal
            v-if="showJoin"
            :modal-open.sync="joinModalOpen"
            :price="post.league.price"
            :league-id="post.league.id"
            :league-trial="post.league.trial"
        ></join-league-modal>
        <edit-burst-playlist-modal
            :modal-open.sync="editBurstPlaylistOpen"
            :post="post"
        ></edit-burst-playlist-modal>
        <video-popup-modal
            v-if="videoPopUpModalOpen"
            :modal-open.sync="videoPopUpModalOpen"
            :league-id="burstReply.league.id"
            :video-url="burstReply.files[0].file"
            :posted-by="burstReply.posted_by"
            :index.sync="burstReplyIndex"
            :total="burstReplies.length"
            :muted="burstContainerMuted"
            :currentTime.sync="videoPosition"
            @open-video-pop-up-modal="openVideoPopUpModal"
        ></video-popup-modal>
        <create-chat-modal
            :modal-open.sync="messageModalOpen"
            :auth-user-id="authUser ? authUser.id : 0"
            :user-id="this.post.posted_by.id"
        ></create-chat-modal>
        <remove-user-modal
            :modal-open.sync="removeModalOpen"
            :user-name="this.post.posted_by.name"
            @remove-user="removeFriend"
        ></remove-user-modal>
    </div>
</template>

<script>
import CommentsList from "../Lists/CommentsList";
import DonationForm from "../Forms/DonationForm";
import JoinLeagueModal from "../Modals/JoinLeagueModal";
import ReactionCounter from "../Forms/FormComponents/ReactionCounter";
import MediaLightbox from "../Lists/MediaLightbox";
import PostActionList from "../Lists/PostActionList";
import LinkCard from "./LinkCard";
import { numberAbbreviation } from "../Mixins/numberAbbreviation";
import MembershipPlanModal from "../Modals/MembershipPlanModal";
import BurstReplyItem from "../ListItems/BurstReplyItem";
import CreateBurstReplyItem from "../ListItems/CreateBurstReplyItem";
import EditBurstPlaylistModal from "../Modals/EditBurstPlaylistModal";
import VideoPopupModal from "../Modals/VideoPopupModal";
import Bowser from "bowser";
import videojs from "video.js";
import { dragscroll } from "vue-dragscroll";
import draggable from "vuedraggable";
import CreateChatModal from "../Modals/CreateChatModal";
import RemoveUserModal from "../Modals/removeUserModal";
require("videojs-playlist");
require("videojs-contrib-quality-levels");
require("videojs-hls-quality-selector");

export default {
    name: "PostCard",
    mixins: [numberAbbreviation],
    directives: {
        dragscroll: dragscroll,
    },
    components: {
        LinkCard,
        PostActionList,
        MediaLightbox,
        DonationForm,
        ReactionCounter,
        JoinLeagueModal,
        CommentsList,
        MembershipPlanModal,
        BurstReplyItem,
        CreateBurstReplyItem,
        EditBurstPlaylistModal,
        draggable,
        VideoPopupModal,
        CreateChatModal,
        RemoveUserModal,
    },
    props: {
        post: {
            type: Object,
            required: true,
        },
        reactions: {
            type: Array,
            required: true,
        },
        showLeague: {
            type: Boolean,
            default: false,
        },
        authUser: {
            type: Object,
            required: false,
        },
        showMedia: {
            type: Boolean,
            default: true,
        },
        listType: {
            type: String,
            required: true,
        },
        cells: {
            type: Number,
            default: 5,
        },
    },
    data() {
        return {
            postTruncated: false,
            pinned: false,
            joinModalOpen: false,
            joinPreview: false,
            showMoreHidden: false,
            burstReplies: [],
            currentPost: null,
            os: "",
            mimeType: "",
            browser: "",
            uid: null,
            player: null,
            editBurstPlaylistOpen: false,
            isMembershipPlanModalOpen: false,
            posterDims: [],
            commentFormVisible: true,
            currentImg: 0,
            isSlidingToPrevious: false,
            current_slide_number: 0,
            videoPopUpModalOpen: false,
            burstReplyIndex: -1,
            burstReply: {},
            arrowLeft: false,
            arrowRight: true,
            burstContainerMuted: true,
            videoPosition: 0,
            hover: false,
            hoverTimer: false,
            userDetails: null,
            checkingDetails: false,
            messageModalOpen: false,
            removeModalOpen: false,
            requested: false,
        };
    },
    watch: {
        videoPopUpModalOpen: function (open) {
            if (!open) {
                // Set the inline player to the updated play position on modal close
                const player =
                    this.$refs[`replyItem${this.burstReplyIndex}`][0];
                player.setCurrentTime(this.videoPosition);
            }
        },
    },
    beforeMount() {
        // Generate a good enough kinda uid
        this.uid = `${this.post.id}-${new Date().getTime()}`;

        const agent = Bowser.getParser(window.navigator.userAgent);
        this.mimeType = this.getMimeType(
            agent.getOS().name.toLowerCase(),
            agent.getBrowser().name.toLowerCase(),
        );

        this.currentPost = this.post;
    },
    mounted() {
        this.pinned = this.post.pinned;

        if (this.$refs[this.post.id]?.clientHeight > 120) {
            this.postTruncated = true;
        }

        // Do not run burst setup code for previews
        if (!this.post.preview) {
            this.initializeBurst();
        }
    },
    computed: {
        gridClass() {
            const cls =
                this.post?.files?.length > this.cells
                    ? "lb-grid-" + this.cells
                    : this.post?.files?.length === 1 && !this.post.files[0].type
                    ? "lb-one-img"
                    : "lb-grid-" + this.post?.files?.length;

            return `lb-grid ${cls}`;
        },

        mainPostIsSelected() {
            return this.currentPost && this.currentPost.id == this.post.id;
        },
        showMediaFilesViewer() {
            return (
                (this.post.type != 5 && this.showMedia && this.post.files) ||
                this.post.preview
            );
        },
        postEditable() {
            return (
                this.post.permissions.ownPost ||
                this.post.permissions.leagueOwner ||
                this.authUser?.leaguePermissions?.isAdmin
            );
        },
        showJoin() {
            return (
                (!this.post.permissions.member && this.showLeague) ||
                this.joinModalOpen
            );
        },
        reactionCounts() {
            if (this.currentPost) {
                return {
                    1: this.currentPost.like_count,
                    2: this.currentPost.love_count,
                };
            }
            return null;
        },
        commentCount() {
            return this.currentPost.comment_count;
        },
        burstCount() {
            return this.currentPost.childrenCount;
        },
        showBurstReplies() {
            return this.listType != "textOnly" && !this.post.preview;
        },
        leagueId() {
            return this.post?.league?.id;
        },
        leagueName() {
            return this.post?.league?.name;
        },
        leaguePrice() {
            return this.post?.league?.price;
        },
        dateCreated: function () {
            return moment(this.post.created_at).format(
                "MMMM Do YYYY, h:mm:ss a",
            );
        },
        dateUpdated: function () {
            return moment(this.post.updated_at).format(
                "MMMM Do YYYY, h:mm:ss a",
            );
        },
    },
    methods: {
        show(i) {
            if (i >= this.post.files.length) {
                i = 0;
            }
            if (i < 0) {
                i = this.post.files.length - 1;
            }
            this.index = i;
            this.$bvModal.show(`${this.postId}-media-lb`);
            this.closeModal = !this.closeModal;
        },
        scrollTo(element, scrollPixels, duration) {
            const scrollPos = element.scrollLeft;
            // Condition to check if scrolling is required
            if (
                !(
                    (scrollPos === 0 || scrollPixels > 0) &&
                    (element.clientWidth + scrollPos === element.scrollWidth ||
                        scrollPixels < 0)
                )
            ) {
                // Get the start timestamp
                const startTime =
                    "now" in window.performance
                        ? performance.now()
                        : new Date().getTime();

                const me = this;
                function scroll(timestamp) {
                    //Calculate the timeelapsed
                    const timeElapsed = timestamp - startTime;
                    //Calculate progress
                    const progress = Math.min(timeElapsed / duration, 1);
                    //Set the scrolleft
                    element.scrollLeft = scrollPos + scrollPixels * progress;
                    //Check if elapsed time is less then duration then call the requestAnimation, otherwise exit
                    if (timeElapsed < duration) {
                        //Request for animation
                        window.requestAnimationFrame(scroll);
                    }

                    const { clientWidth, scrollLeft, scrollWidth } = element;
                    me.arrowRight = !(scrollLeft + clientWidth >= scrollWidth);
                    //console.log(arrowRight)
                }

                //Call requestAnimationFrame on scroll function first time
                window.requestAnimationFrame(scroll);
            }
        },
        swipeLeft() {
            const content = this.$refs.content;
            this.scrollTo(content, -300, 800);
            this.arrowLeft = false;
        },
        swipeRight() {
            const content = this.$refs.content;
            this.scrollTo(content, 300, 800);
            this.arrowLeft = true;
        },
        async openVideoPopUpModal({ index, oldIndex, currentTime = 0 }) {
            // pause current inline video
            this.videoPopUpModalOpen = false;
            if (oldIndex != undefined) {
                this.$refs[`replyItem${oldIndex}`][0].pauseVideo();
            }
            this.burstReplyIndex = index;
            this.burstReply = this.burstReplies[index];
            this.videoPosition = currentTime;

            await this.$nextTick();
            this.videoPopUpModalOpen = true;
        },
        nextSlide() {
            this.current_slide_number++;
        },
        prevSlide() {
            this.current_slide_number--;
        },
        nextImg(id) {
            const conent = document.querySelector("#burst-replies-" + id);
            conent.scrollLeft += 300;
            // this.isSlidingToPrevious = false
            // if(this.currentImg == this.burstReplies.length-1){
            //     this.currentImg = 0;
            // }else{
            //     this.currentImg += 1;
            // }
        },
        //previous button is clicked
        prevImg() {
            this.isSlidingToPrevious = true;
            if (this.currentImg == 0) {
                this.currentImg = this.burstReplies.length - 1;
            } else {
                this.currentImg -= 1;
            }
        },
        initializeBurst() {
            const mimeType = this.mimeType;
            const formatForPlaylist = (p) => ({
                avatar: p.posted_by.avatar,
                poster: p.files[0].poster,
                sources: [
                    {
                        src: p.files[0].file,
                        type: mimeType,
                    },
                ],
            });

            let playlistReplies = [];
            let burstReplies = [];
            let playlistItems = [];
            if (this.post.children?.length) {
                playlistReplies = this.post.children.map((p) =>
                    formatForPlaylist(p),
                );

                burstReplies = [...this.post.children];
            }

            // Check if this is a burst post. Otherwise it can only have burst replies
            if (this.post.type == 5) {
                this.burstReplies = [...this.post.children];
                playlistItems = [
                    formatForPlaylist(this.post),
                    ...playlistReplies,
                ];
            } else {
                this.burstReplies = burstReplies;
                playlistItems = [...playlistReplies];
            }

            try {
                const videoJsPlayerId = `burst-video-${this.uid}`;
                if (document.getElementById(videoJsPlayerId)) {
                    this.player = videojs(videoJsPlayerId, {
                        controls: true,
                    });

                    this.player.hlsQualitySelector();
                    this.player.playlist(playlistItems);

                    // Play through the playlist automatically.
                    this.player.playlist.autoadvance(0);

                    const videoElement = document.getElementById(
                        `${videoJsPlayerId}_html5_api`,
                    );
                    const videoJsWrapperElem =
                        document.getElementById(videoJsPlayerId);
                    if (playlistItems.length) {
                        this.getPosterDimensions(playlistItems).then((dims) => {
                            // Save the dims to look up later.
                            this.posterDims = dims;

                            // Set layout for first item
                            this.updateVideoLayout(
                                videoJsWrapperElem,
                                dims[0].posterUrl,
                            );
                        });
                    }

                    // Update layout when a new video is loaded
                    videoElement.addEventListener(
                        "loadedmetadata",
                        (e) => {
                            this.updateVideoLayout(
                                videoJsWrapperElem,
                                videoElement.getAttribute("poster"),
                            );
                        },
                        false,
                    );
                }
            } catch (e) {
                console.warn("VIDEOJS ERROR: ", e);
            }
        },
        updateVideoLayout(videoJsWrapperElem, currentPosterUrl) {
            const currentPosterDims = this.posterDims.find((item) => {
                return item.posterUrl == currentPosterUrl;
            });
            if (
                currentPosterDims &&
                currentPosterDims.w > currentPosterDims.h
            ) {
                videoJsWrapperElem.classList.add("vjs-fluid");
                videoJsWrapperElem.classList.remove("vjs-portrait-mode");
                videoJsWrapperElem.classList.remove("vjs-square-mode");
            } else if (
                currentPosterDims &&
                currentPosterDims.w == currentPosterDims.h
            ) {
                videoJsWrapperElem.classList.add("vjs-square-mode");
                videoJsWrapperElem.classList.remove("vjs-fluid");
                videoJsWrapperElem.classList.remove("vjs-portrait-mode");
            } else {
                videoJsWrapperElem.classList.add("vjs-portrait-mode");
                videoJsWrapperElem.classList.remove("vjs-fluid");
                videoJsWrapperElem.classList.remove("vjs-square-mode");
            }
        },
        async getPosterDimensions(list) {
            function Deferred() {
                var self = this;
                this.promise = new Promise((resolve, reject) => {
                    self.reject = reject;
                    self.resolve = resolve;
                });
            }
            const extractPosterDims = (item) => {
                let deferred = new Deferred();
                const imgElem = document.createElement("img");
                imgElem.src = item.poster;
                imgElem.onload = () => {
                    deferred.resolve({
                        w: imgElem.width,
                        h: imgElem.height,
                        posterUrl: item.poster,
                    });
                };
                return deferred.promise;
            };
            return Promise.all(list.map((item) => extractPosterDims(item)));
        },
        getMimeType(os, browser) {
            if (os === "macos" || os === "ios" || browser === "safari") {
                return "application/vnd.apple.mpegurl";
            }

            return "application/x-mpegURL";
        },
        getRandomSize(min, max) {
            return Math.round(Math.random() * (max - min) + min);
        },
        sharePost(type) {
            this.$emit("share-post", type);
        },
        async reactToPost(id) {
            try {
                const data = {
                    reactionableId: this.currentPost.id,
                    reactionableType: "post",
                    leagueId: this.currentPost.league.id,
                    reaction: id,
                };
                const response = await axios.post("/leagues/reactPost", data);
                this.currentPost.reactionId = response.data.reaction;
                this.currentPost.like_count = response.data.like_count;
                this.currentPost.love_count = response.data.love_count;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
        async togglePin() {
            if (this.pinned) {
                await this.unpinPost();
            } else {
                await this.pinPost();
            }
        },
        async pinPost() {
            try {
                const data = {
                    postId: this.post.id,
                    leagueId: this.post.league.id,
                };
                const response = await axios.post("/leagues/posts/pin", data);

                this.pinned = 1;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
        async unpinPost() {
            try {
                const data = {
                    postId: this.post.id,
                    leagueId: this.post.league.id,
                };
                const response = await axios.post("/leagues/posts/unpin", data);

                this.pinned = 0;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
        joinLeague() {
            // redirect to home if user is not logged in
            if (!this.authUser) {
                this.isMembershipPlanModalOpen =
                    !this.isMembershipPlanModalOpen;

                return;
            }

            if (this.post.preview) {
                if (this.post.league.price !== "0.00") {
                    this.joinModalOpen = true;
                } else {
                    this.joinFreeLeague();
                }
            }
        },
        async joinFreeLeague() {
            try {
                const response = await axios.get(`/leagues/join`, {
                    params: { leagueId: this.post.league.id },
                });
                const {
                    data: { success, message },
                } = response;
                if (success === 1 && message?.length > 0) {
                    await this.$swal(message);
                    this.joinLoading = false;
                } else {
                    window.location.reload();
                }
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
        handleShowMore(value) {
            this.postTruncated = !value;
            this.showMoreHidden = value;
        },
        hasAuthUser() {
            let result =
                this.authUser && Object.keys(this.authUser).length > 0
                    ? true
                    : false;
            return result;
        },
        playReply(index) {
            const playerRefs = Object.keys(this.$refs)
                .filter((x) => x.includes("replyItem"))
                .filter((x) => !x.includes(`replyItem${index}`));

            playerRefs.forEach((ref) => this.$refs[ref][0].pauseVideo());
        },
        removeReply(id) {
            // Set parent burst as selected
            // Set parent as selected in playlist
            this.currentPost = this.burstReplies[0];
            this.player.playlist.currentItem(0);

            // Remove the reply from videojs list
            // Remove reply from burst.children
            this.burstReplies = this.burstReplies.filter(
                (item) => item.id != id,
            );

            // Remove item from the playlist and reset the playlist
            this.player.playlist(
                this.player.playlist().filter((item) => item != id),
            );
        },
        nextButton(id) {
            this.currentPost = this.burstReplies[0];
            console.log("next button call");
        },
        previousButton(id) {
            this.currentPost = this.burstReplies[0];
        },
        newCommentAdded() {
            this.currentPost.comment_count++;
        },
        commentRemoved() {
            this.currentPost.comment_count--;
        },
        openEditBurstPlaylistModal() {
            this.editBurstPlaylistOpen = true;
        },
        loginRegister() {
            // store the league's url for redirect after user login or signup
            sessionStorage.setItem("redirectToLeague", window.location.href);
            sessionStorage.setItem("joinLeague", true);
            this.isMembershipPlanModalOpen = !this.isMembershipPlanModalOpen;
        },
        toggleMainCommentForm() {
            this.commentFormVisible = !this.commentFormVisible;
        },
        playNextVideoInline(index) {
            if (this.videoPopUpModalOpen === false) {
                if (this.$refs[`replyItem${index}`]) {
                    const content = this.$refs.content;
                    this.scrollTo(content, 180, 800);
                    this.arrowLeft = true;
                    setTimeout(() => {
                        this.$refs[`replyItem${index}`][0].startVideo();
                    }, 800);
                }
            }
        },
        toggleMuteAll(mute) {
            this.burstContainerMuted = mute;
            const videos = Object.keys(this.$refs).filter((x) =>
                x.includes("replyItem"),
            );
            videos.forEach((v) => this.$refs[v][0].setMute(mute));
        },
        openRemoveModal() {
            this.removeModalOpen = true;
        },
        async requestFriendship() {
            try {
                const response = await axios.post(`/users/friends/add`, {
                    userId: this.post.posted_by.id,
                });
                this.requested = true;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
        async removeFriend() {
            const requestData = {
                friendId: this.userDetails.friends,
            };
            this.loading = true;
            try {
                const response = await axios.post(
                    "/users/friends/remove",
                    requestData,
                );
                this.loading = false;
                this.removeModalOpen = false;
                this.localFriendStatus = 0;
                this.canFriend = true;
                this.userDetails = null;
                await this.getUserDetails();
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        },
        sendMessage() {
            this.messageModalOpen = true;
        },
        startHover() {
            if (
                this.post.posted_by.type !== "league" &&
                this.authUser.id !== this.post.posted_by.id
            ) {
                this.hover = true;
                clearTimeout(this.hoverTimer);
                this.getUserDetails();
            }
        },
        stopHover() {
            if (
                this.post.posted_by.type !== "league" &&
                this.authUser.id !== this.post.posted_by.id
            ) {
                let that = this;
                this.hoverTimer = setTimeout(function () {
                    that.hover = false;
                }, 500);
            }
        },
        async getUserDetails() {
            // Only grab details if we do not already have it.
            if (!this.userDetails && !this.checkingDetails) {
                this.checkingDetails = true;
                try {
                    const data = {
                        userId: this.post.posted_by.id,
                    };
                    const response = await axios.post(
                        "/users/getUserDetails",
                        data,
                    );

                    this.userDetails = response.data;

                    this.checkingDetails = false;
                } catch (e) {
                    await this.$swal(error.response.data.message);
                    this.checkingDetails = false;
                }
            }
        },
        // allMuteVideo(videoMute) {
        //     console.log("get video");
        //     console.log(videoMute);
        //     this.videoMute = !this.videoMute;
        //     //this.videoPlayer.muted(this.videoMute);
        // },
        // playOneVideoInline(index) {
        //     console.log(this.$refs[`replyItem${index}`][index].startVideo());
        //     if (this.$refs[`replyItem${index}`][index].startVideo()) {

        //     }
        // },
    },
};
</script>

<style scoped lang="scss">
$Poppins: "Poppins", sans-serif;

.burst-replies-add-video {
    // width: 22%;
    margin-right: 6px;
    float: left;
}
.slider-center {
    width: 76% !important;
    height: 200px;
    overflow: hidden;
    white-space: nowrap;
    border-radius: 4px 4px;
}
.post-card {
    .user-title {
        font-family: $Poppins;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;
    }
    .post-description {
        //font-family:$font-family-sans-serif;
        font-weight: normal;
        font-size: 15px;
        line-height: 175.69%;
        color: #0e0e0e;
    }
}
.truncate-text {
    max-height: 117px;
    overflow-y: hidden;
}

.burst-replies-video-wrapper {
    background-color: #222;
    .burst-replies-video-inner {
        width: 100%;
    }
}
.post-status-counter {
    background-color: #0e0e0e !important;
    font-size: 14px;
    .burst-count {
        margin-right: 15px;
    }
}
.burst-replies-wrapper {
    padding-top: 8px;
    height: 98px;
    width: 100%;
    cursor: pointer;
    position: relative;
    background-color: #0e0e0e;
    .arrow-previous {
        position: absolute;
        top: calc(50% - 35px);
        cursor: pointer;
        left: 117px;
        border-radius: 50%;
    }
    .arrow-next {
        position: absolute;
        top: calc(50% - 35px);
        cursor: pointer;
        right: 1px;
        border-radius: 50%;
    }
    .burst-replies {
        width: 100%;
        height: 90px;
        overflow-y: hidden;
        overflow-x: hidden;
        display: flex;
        cursor: pointer;
        .burst-replies-main-post-btn {
            cursor: pointer;
            position: relative;
            display: inline-table;
            width: 90px;
            height: 90px;
            margin-left: 16px;
            // border: 1px solid white;
            border-radius: 5px;
            overflow: hidden;
            background-color: #5451de;
            color: white;
            text-align: center;
            padding-top: 12px;
            &.is-selected {
                border: 3px solid #ffa500;
                margin-top: 14px;
                margin-right: 5px;
                margin-left: 0px;
            }
            .action-description {
                color: white;
                font-size: 13px;
                text-align: center;
                padding: 0px 5px;
            }
            .fas.fa-comment {
                color: #fff;
            }
        }
    }

    .fa-chevron-circle-left,
    .fa-chevron-circle-right {
        background-image: radial-gradient(
            at center,
            white 40%,
            transparent 40%
        );
        font-size: 40px !important;
    }
    @media (min-width: 768px) {
        height: 144px;
        padding-top: 5px;
        border: 1px solid #2c2c2c;
        border-top: none;
        .burst-replies {
            height: 140px;
            padding-top: 0px;
        }
    }
    @media (max-width: 768px) {
        .burst-reply {
            width: 141px !important;
            margin-right: 5px;
        }
    }
}
@media (max-width: 768px) {
    .burst-replies-wrapper {
        height: unset !important;
        display: flex;
    }
    .burst-replies {
        height: unset !important;
    }
    // .burst-arrow{
    //     display: none;
    // }
    // .arrow-previous{
    //     display:none;
    // }
    // .arrow-next{
    //     display:none;
    // }
    .burst-replies-add-video {
        // width: 117px;
        float: left;
    }
    .slider-center {
        width: 75% !important;
        height: 200px;
        overflow: hidden;
        white-space: nowrap;
        margin-left: 2px !important;
    }
}
@media (max-width: 535px) {
    .slider-center {
        width: 66% !important;
    }
}
@media (max-width: 400px) {
    .slider-center {
        width: 65% !important;
    }
}
</style>
