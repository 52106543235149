<template>
    <b-container>
        <league-search
            v-show="state === 'league-search'"
            :search="search"
            :selected-category="selectedCategory"
            @show-categories="sendCategories"
            @remove-category="removeCategory"
        >
        </league-search>
        <category-search
            v-show="state === 'category-search'"
            :initial-categories="categories"
            @category-selected="addCategory"
            @show-leagues="state = 'league-search'"
        ></category-search>
    </b-container>
</template>

<script>
import LeagueSearch from "./LeagueSearch";
import CategorySearch from "./CategorySearch";

export default {
    name: "SearchPage",
    components: { CategorySearch, LeagueSearch },
    props: {
        categories: {
            type: Array,
            required: true,
        },
        search: String,
    },
    data() {
        return {
            state: "league-search",
            selectedCategory: null,
        };
    },
    methods: {
        addCategory(category) {
            this.selectedCategory = category;
            this.state = "league-search";
        },
        removeCategory() {
            this.selectedCategory = null;
        },
        sendCategories() {
            this.state = "category-search";
        },
    },
};
</script>

<style scoped></style>
