<template>
    <div>
        <b-form @submit.prevent="submitForm">
            <fieldset :disabled="readOnly">
                <h1 v-show="title" class="h2 font-weight-bold mb-5">
                    {{ title }}
                </h1>
                <b-form-group
                    label="Account Owner's Name"
                    label-for="account-name-input"
                >
                    <b-form-input
                        id="account-name-input"
                        type="text"
                        v-model.trim="form.accountName"
                        :state="validateInput('accountName')"
                        @change="$v.form.accountName.$touch()"
                    ></b-form-input>
                    <invalid-feedback
                        :errors="accountNameErrors"
                    ></invalid-feedback>
                </b-form-group>
                <b-form-group
                    label="Routing Number"
                    label-for="routing-number-input"
                >
                    <b-form-input
                        id="routing-number-input"
                        type="text"
                        v-model.trim="form.routingNumber"
                        :state="validateInput('routingNumber')"
                        @change="$v.form.routingNumber.$touch()"
                    ></b-form-input>
                    <invalid-feedback
                        :errors="routingNumberErrors"
                    ></invalid-feedback>
                </b-form-group>
                <b-form-group
                    label="Account Number"
                    label-for="account-number-input"
                >
                    <b-form-input
                        id="account-number-input"
                        type="text"
                        v-model.trim="form.accountNumber"
                        :state="validateInput('accountNumber')"
                        @change="$v.form.accountNumber.$touch()"
                    ></b-form-input>
                    <invalid-feedback
                        :errors="accountNumberErrors"
                    ></invalid-feedback>
                </b-form-group>
                <submit-button
                    v-if="!readOnly"
                    :loading="loading"
                    :text="buttonText"
                    class="mt-5"
                ></submit-button>
            </fieldset>
        </b-form>
    </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import InvalidFeedback from "../FormComponents/InvalidFeedback";
import SubmitButton from "../../Buttons/SubmitButton";

export default {
    name: "DirectDepositForm",
    components: { SubmitButton, InvalidFeedback },
    props: {
        title: {
            type: String,
            default: "Direct Deposit",
        },
        buttonText: {
            type: String,
            default: "Next",
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        account: Object,
    },
    data() {
        return {
            loading: true,
            stripeObject: undefined,
            form: {
                accountName: null,
                routingNumber: null,
                accountNumber: null,
            },
        };
    },
    validations: {
        form: {
            accountName: { required },
            routingNumber: { required },
            accountNumber: { required },
        },
    },
    mounted() {
        // Setup the stripe instance
        this.stripeObject = Stripe(process.env.MIX_STRIPE_KEY);
        if (this.account) {
            this.setForm();
        }
        this.loading = false;
    },
    computed: {
        /** Validation Errors **/
        accountNameErrors() {
            const errors = [];
            if (!this.$v.form.accountName.$dirty) return errors;
            !this.$v.form.accountName.required &&
                errors.push("Account Owner's Name is required");
            return errors;
        },
        accountNumberErrors() {
            const errors = [];
            if (!this.$v.form.accountNumber.$dirty) return errors;
            !this.$v.form.accountNumber.required &&
                errors.push("Account Number is required");
            return errors;
        },
        routingNumberErrors() {
            const errors = [];
            if (!this.$v.form.routingNumber.$dirty) return errors;
            !this.$v.form.routingNumber.required &&
                errors.push("Routing Number is required");
            return errors;
        },
    },
    methods: {
        setForm() {
            this.form.accountName = this.account.accountOwnerName;
            this.form.routingNumber = this.account.routingNumber;
            this.form.accountNumber = this.account.accountNumber
                ? `XXXXXXXX${this.account.accountNumber}`
                : null;
        },
        validateInput(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        async submitForm() {
            this.$v.$touch();

            if (!this.$v.$anyError) {
                this.loading = true;
                let { error, token } = await this.stripeObject.createToken(
                    "bank_account",
                    {
                        country: "US",
                        currency: "usd",
                        routing_number: this.form.routingNumber,
                        account_number: this.form.accountNumber,
                        account_holder_name: this.form.accountName,
                    }
                );

                if (error) {
                    this.$forceUpdate();
                    this.loading = false;
                    return;
                }

                this.$emit("stripe-submitted", token);
            }
        },
    },
};
</script>
