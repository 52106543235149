<template>
  <div class="donation-form-dialog">
    <modal v-model="modalVisible" :title="title" centered hide-footer @shown="checkIfUserHasCard()" :loading="loading">
      <b-alert :show="!!donationSuccessMessage" variant="success">
        {{ donationSuccessMessage }}
      </b-alert>
      <donation-form ref="donationForm" :league-id="leagueId" @success="handleFormSuccess" class="donation-form"/>
    </modal>
  </div>
</template>

<script>
import DonationForm from '../Forms/DonationForm'
import Modal from "../Modals/Modal";

export default {
  name: "DonationFormDialog",
  props: {
    leagueId: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: 'Donate to League'
    }
  },
  data: () => ({
    loading: false,
    modalVisible: false,
    donationSuccessMessage: ''
  }),
  components: {
    Modal,
    DonationForm
  },
  methods: {
    toggle() {
      this.modalVisible = !this.modalVisible
    },
    handleFormSuccess() {
      this.donationSuccessMessage = 'Thank you!'
      setTimeout(() => {
        this.modalVisible = false
        this.donationSuccessMessage = ''
      }, 1500)
    },
    async checkIfUserHasCard() {
      this.loading = true
      try {
        await this.$refs.donationForm.checkPaymentMethod()
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.donation-form {
  padding: 15px 0;

  ::v-deep {
    .form-row {
      & > .mb-2 {
        width: 100%;

        &:last-of-type {
          text-align: right;
        }
      }

      select.custom-select,
      input.form-control {
        width: 100%;
        max-width: 100% !important;
        margin-bottom: 1.2rem;
      }
    }
  }
}
</style>