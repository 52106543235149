<template>
    <b-modal
        id="user-disable-account"
        v-model="open"
        centered
        :title="title"
        :title-class="titleClass"
        :header-bg-variant="headerBgVariant"
        :ok-title="okTitle"
        :ok-variant="okVariant"
        @ok="onConfirm"
        :cancel-title="cancelTitle"
        :cancel-variant="cancelVariant"
    >
        <slot></slot>
    </b-modal>
</template>
<script>
export default {
    name: "ConfirmationModal",
    props: {
        modalOpen: Boolean,
        title: {
            type: String,
            default: "Are you sure?",
        },
        okTitle: {
            type: String,
            default: "Yes",
        },
        okVariant: {
            type: String,
            default: "success",
        },
        cancelTitle: {
            type: String,
            default: "No",
        },
        cancelVariant: {
            type: String,
            default: "danger",
        },
        onConfirm: {
            type: Function,
            required: true
        },
        titleClass: {
            type: String,
            default: 'text-muted'
        },
        headerBgVariant: String,
    },
    computed: {
        open: {
            get() {
                return this.modalOpen;
            },
            set(value) {
                this.$emit("update:modalOpen", value);
            },
        },
    },
}
</script>