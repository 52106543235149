<template>
    <div>
        <post-item
            v-for="post in postItems"
            :key="post.id"
            :post="post"
            :auth-user="authUser"
            :reactions="reactions"
            class="mb-4"
            show-league
            @post-edited="updatePost"
            @post-deleted="deletePost"
            :showMedia="showMedia"
            :list-type="listType"
        ></post-item>
        <infinite-loading
            direction="bottom"
            @infinite="getMorePosts"
            spinner="spiral"
        >
            <div slot="no-more">No more posts...</div>
            <div slot="no-results">No posts yet...</div>
        </infinite-loading>
    </div>
</template>

<script>
import PostCard from "../Cards/PostCard";
import PostForm from "../Forms/PostForm";
import PostItem from "../ListItems/PostItem";
import DeletePostModal from "../Modals/DeletePostModal";

export default {
    name: "HomePostsList",
    components: { DeletePostModal, PostItem, PostForm, PostCard },
    props: {
        posts: {
            type: Array,
            required: true,
        },
        totalPosts: {
            type: Number,
            required: true,
        },
        authUser: {
            type: Object,
            required: true,
        },
        showMedia: {
            type: Boolean,
            required: true,
        },
        listType: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: true,
            perPage: 10,
            currentPage: 2,
            postItems: [],
            totalPostCount: 0,
            reactions: [],
        };
    },
    mounted() {
        this.getReactions();
        this.postItems = this.posts;
        this.totalPostCount = this.totalPosts;
        this.loading = false;
    },
    methods: {
        addPost(post) {
            this.loading = true;
            this.postItems.unshift(post);
            this.totalPostCount++;
            this.loading = false;
        },
        updatePost(post) {
            const index = this.postItems.findIndex((p) => p.id === post.id);
            this.postItems[index] = post;
        },
        deletePost(id) {
            const index = this.postItems.findIndex((p) => p.id === id);
            this.postItems.splice(index, 1);
            this.totalPostCount--;
        },
        async getReactions() {
            this.loading = true;
            try {
                const response = await axios.post("/leagues/getReactionList");
                this.reactions = response.data;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        },
        async getMorePosts($state) {
            if (this.postItems.length < this.totalPostCount) {
                try {
                    const requestData = {
                        per_page: this.perPage,
                        page: this.currentPage,
                        listType: this.listType
                    };

                    const { data } = await axios.post(
                        "/home/posts",
                        requestData
                    );

                    if (data.posts.length) {
                        this.totalPostCount = data.total;
                        this.currentPage += 1;
                        this.postItems = this.postItems.concat(...data.posts);
                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                } catch (error) {
                    // If there is an error for some reason, show alert
                    await this.$swal(error.response.data.message);
                }
            } else {
                $state.complete();
            }
        },
    },
};
</script>

<style scoped></style>
