<template>
    <div>
        <b-modal
            id="store-product-details-modal"
            v-model="storeProductDetailsModalIsOpenComputed"
            size="xl-center"
            centered
            hide-header
            hide-footer
            style="border-radius: 20px"
        >
            <div
                v-if="this.$mq === 'sm'"
                data-dismiss="modal"
                @click="$emit('close-modal', false)"
                class="close-modal-button"
            >
                Close
            </div>
            <b-form>
                <b-row>
                    <b-col sm="12">
                        <b-row>
                            <b-col sm="5" class="pl-4">
                                <b-row>
                                    <b-col
                                        v-if="this.$mq != 'sm'"
                                        sm="2"
                                        class="px-0 px-md-2 pt-3 pt-md-0"
                                    >
                                        <img
                                            v-for="(item,
                                            index) in productMedia"
                                            :key="index"
                                            :src="
                                                item.type === 'Video'
                                                    ? '/images/VideoThumbnailSmall.jpg'
                                                    : item.location
                                            "
                                            width="46"
                                            height="39"
                                            alt=""
                                            @click="clickToSwitchImage(index)"
                                            :style="smallImagePreview"
                                            style="width: 46px; height: 39px"
                                            class="cursor-pointer"
                                        />
                                    </b-col>
                                    <b-col sm="10" class="pl-0 pt-3 pt-md-0">
                                        <div v-if="videoPreview.toggle">
                                            <video
                                                ref="videoPlayer"
                                                controls
                                                class="video-js vjs-fluid"
                                            >
                                                <source
                                                    :src="videoPreview.location"
                                                    :type="videoMimeType"
                                                />
                                            </video>
                                        </div>
                                        <b-avatar
                                            v-else
                                            square
                                            variant="grey"
                                            :src="
                                                switchImage
                                                    ? switchImage.location
                                                    : product.media &&
                                                      typeof product.media[0]
                                                          .location !==
                                                          'undefined'
                                                    ? product.media[0].location
                                                    : ''
                                            "
                                            :size="298"
                                            style="color: black; width: 100%;margin-left:10px;"
                                        ></b-avatar>
                                        
                                    </b-col>
                                    <!-- IF MOBILE is true, so then switch to the bottom position -->
                                    <b-col
                                        v-if="this.$mq === 'sm'"
                                        sm="2"
                                        class="px-0 px-md-2 pt-3 pt-md-0"
                                    >
                                        <img
                                            v-for="(item,
                                            index) in product.media"
                                            :key="index"
                                            :src="
                                                item.type === 'Video'
                                                    ? '/images/VideoThumbnailSmall.jpg'
                                                    : item.location
                                            "
                                            width="46"
                                            height="39"
                                            alt=""
                                            @click="clickToSwitchImage(index)"
                                            :style="smallImagePreview"
                                            style="width: 46px; height: 39px"
                                            class="cursor-pointer"
                                        />
                                    </b-col>
                                </b-row>

                                <!-- Reviews -->
                                <b-row
                                    v-if="this.$mq != 'sm'"
                                    style="
                                        height: 306px;
                                        overflow: auto;
                                        overflow-wrap: break-word;
                                    "
                                >
                                    <b-col sm="12">
                                        <header>
                                            <div
                                                class="font-weight-bolder mb-3 mt-4"
                                            >
                                                <span
                                                    v-if="
                                                        product.reviews
                                                            .length >= 1
                                                    "
                                                    >Reviews</span
                                                >
                                                <a
                                                    v-if="
                                                        product.reviews
                                                            .length >= 1
                                                    "
                                                    @click="tabIndex++"
                                                    href="#"
                                                    class="text-sm font-weight-normal pl-2 text-decoration-underline"
                                                >
                                                    View All
                                                </a>
                                                <!-- Owner cannot write a review -->
                                                <a
                                                    v-if="false"
                                                    href="#"
                                                    @click="
                                                        writeReview.toggle = !writeReview.toggle
                                                    "
                                                    class="text-sm font-weight-normal pl-2 text-decoration-underline"
                                                >
                                                    Write Review
                                                </a>
                                            </div>
                                        </header>

                                        <b-row
                                            v-for="review in product.reviews"
                                            :key="review.id"
                                        >
                                            <b-col sm="2" class="pr-0">
                                                <!-- Avatar -->
                                                <b-avatar
                                                    variant="grey"
                                                    :src="
                                                        review.user.avatar.file
                                                    "
                                                    :size="41"
                                                    class="profile-avatar"
                                                    style="color: black"
                                                ></b-avatar>
                                            </b-col>
                                            <b-col sm="10" class="pl-0">
                                                <!-- Reviews -->
                                                <span
                                                    v-if="review.stars"
                                                    class="pr-2"
                                                >
                                                    <i
                                                        v-for="index in review.stars"
                                                        :key="index"
                                                        class="fas fa-star text-orange text-xs"
                                                    ></i>
                                                </span>

                                                <!-- Author -->
                                                <span
                                                    class="text-black text-sm"
                                                    >{{
                                                        `${review.user.firstName} ${review.user.lastName}`
                                                    }}</span
                                                >

                                                <!-- Date -->
                                                <span
                                                    class="text-grey text-xs"
                                                    >{{
                                                        currentDate(
                                                            review.created_at,
                                                        )
                                                    }}</span
                                                >
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </b-col>

                            <div
                                v-if="this.$mq != 'sm'"
                                style="
                                    border-left: 0.1rem solid #e7e7e7;
                                    height: 608px;
                                "
                            ></div>

                            <!-- Descriptions -->
                            <b-col
                                sm="6"
                                align-self="stretch"
                                class="mx-sm-0 mx-md-4"
                            >
                                <header>
                                    <!-- Type File -->
                                    <b-row>
                                        <b-col class="col">
                                            <div
                                                class="px-3 py-1 mb-3 mr-4 mt-2 mt-md-0 text-capitalize text-sm bg-light-blue text-dark-blue font-weight-bold"
                                                style="
                                                    z-index: 99;
                                                    border-radius: 4px;
                                                "
                                            >
                                                <span v-if="product.type == ''"
                                                    >Digital</span
                                                >
                                                <span else>{{
                                                    product.type
                                                }}</span>
                                            </div>
                                        </b-col>
                                        <b-col class="col" v-if="isOwner">
                                            <ul 
                                              
                                            >
                                                <li
                                                    class="list-inline-item"
                                                    v-if="isOwner"
                                                >
                                                    <a
                                                        href="#"
                                                        class="btn-link"
                                                        @click="
                                                            setProductStatus(
                                                                $event,
                                                                2,
                                                            )
                                                        "
                                                        >Delete</a
                                                    >
                                                </li>
                                                <li class="list-inline-item">
                                                    <a
                                                        href="#"
                                                        class="btn-link"
                                                        @click="
                                                            setProductStatus(
                                                                $event,
                                                                product.status
                                                                    ? 0
                                                                    : 1,
                                                            )
                                                        "
                                                        >{{
                                                            product.status === 1
                                                                ? "Unpublish"
                                                                : "  Publish"
                                                        }}</a
                                                    >
                                                </li>
                                                <li class="list-inline-item">
                                                    <a
                                                        href="#"
                                                        class="btn-link"
                                                        @click="
                                                            openEditProductModal(
                                                                $event,
                                                                product,
                                                            )
                                                        "
                                                        >Edit</a
                                                    >
                                                </li>
                                            </ul>
                                        </b-col>
                                    </b-row>

                                    <!-- Title -->
                                    <div
                                        class="text-2xl font-weight-bolder"
                                        style="overflow-wrap: break-word"
                                    >
                                        {{ product.name }}
                                    </div>

                                    <!-- Stars -->
                                    <div
                                        v-if="product.reviews.length >= 1"
                                        class="mb-3 text-xs"
                                    >
                                        <span
                                            class="font-weight-bold text-orange text-sm"
                                            >{{ averageReview }}</span
                                        >
                                        <!-- Stars  -->
                                        <span class="pr-2">
                                            <i
                                                v-for="index in averageReview"
                                                :key="index"
                                                class="fas fa-star text-orange text-2xs"
                                            ></i>
                                        </span>
                                        <span class="text-grey"
                                            >(
                                            {{ product.reviews.length }}
                                            )</span
                                        >
                                    </div>
                                    <!-- Price -->
                                    <div
                                        class="text-2xl text-capitalize text-base font-weight-normal mb-4"
                                    >
                                        <!-- Sale Price -->
                                        <span class="text-dark-red mr-2">
                                            {{
                                                parseFloat(product.price) === 0
                                                    ? "Free"
                                                    : "$" + product.price
                                            }}
                                        </span>
                                        <!-- MSRP Price -->
                                        <span
                                            class="text-grey text-decoration-line-through"
                                        >
                                            {{
                                                !product.msrp
                                                    ? ""
                                                    : "$" + product.msrp
                                            }}
                                        </span>
                                    </div>
                                </header>
                                <main>
                                    <p class="text-xs leading-6">
                                        {{ product.description }}
                                    </p>
                        
                                    <!-- BUY BUTTON -->
                                    <b-col
                                        v-if="!purchaseComplete && !isOwner"
                                        class="text-center mt-4"
                                    >
                                        <b-button
                                            pill
                                            :disabled="isOwner"
                                            variant="red"
                                            @click="checkPaymentMethod"
                                            class="w-100"
                                        >
                                            <span
                                                v-if="
                                                    !purchaseComplete &&
                                                    !processingPurchase
                                                "
                                                >Buy Now</span
                                            >
                                            <div
                                                v-if="
                                                    !purchaseComplete &&
                                                    processingPurchase
                                                "
                                                class="spinner-border spinner-border-sm text-light"
                                                role="status"
                                            >
                                                <span class="sr-only"
                                                    >Loading...</span
                                                >
                                            </div>
                                        </b-button>
                                        <div v-if="isOwner" class="mt-1">
                                            Purchasing your own products is not
                                            currently enabled.
                                        </div>
                                    </b-col>
                                    <!-- PURCHASED BUTTON -->
                                    <b-col v-else-if="!isOwner" class="text-center mt-4">
                                        <b-button
                                            pill
                                            variant="light-red"
                                            @click="
                                                purchaseComplete = !purchaseComplete
                                            "
                                            disabled
                                            class="w-100 text-dark-grey cursor-not-allowed"
                                        >
                                            Purchased!
                                        </b-button>
                                    </b-col>
                                    <div class="mt-2" v-if="isOwner">
                                        <a
                                            @click="
                                                testProductDownload($event)
                                            "
                                            target="_blank"
                                        >
                                            <b-button
                                                pill
                                                block
                                                variant="purple"
                                                type="button"
                                                >Test Product
                                                Download</b-button
                                            >
                                        </a>
                                    </div>
                                </main>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
import SubmitButton from "../Buttons/SubmitButton";
import ReviewToggleListItem from "../ListItems/ReviewToggleListItem";
import StoreSearchForm from "../Forms/LeagueForms/StoreSearchForm";
import videojs from "video.js";
require("videojs-playlist");
require("videojs-contrib-quality-levels");
require("videojs-hls-quality-selector");
import Bowser from "bowser";

export default {
    name: "StoreProductDetailsModal",
    components: {
        SubmitButton,
        ReviewToggleListItem,
        StoreSearchForm,
    },
    props: {
        modalOpen: Boolean,
        storeProductDetailsModalIsOpen: {
            type: Boolean,
            default: false,
        },
        product: {
            type: Object,
        },
        isOwner: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            os: "",
            videoMimeType: "",
            browser: "",
            tabIndex: 0,
            disabledTab: "d-none",
            loading: false,
            saved: false,
            switchIndex: 0, // Switch images
            switchImage: null, // Switch images
            selectAllRatingsOptions: [
                { value: null, text: "All Ratins" },
                { value: 1, text: "1 Start" },
                { value: 2, text: "2 Starts" },
                { value: 3, text: "3 Starts" },
                { value: 4, text: "4 Starts" },
                { value: 5, text: "5 Starts" },
            ],
            selectedAllRatings: null,
            videoPreview: {
                toggle: false,
                location: null,
            },
            purchaseComplete: false,
            writeReview: {
                toggle: false,
                starRatings: [
                    // { value:null, text: "How many starts" },
                    { value: 1, text: "1 Start" },
                    { value: 2, text: "2 Starts" },
                    { value: 3, text: "3 Starts" },
                    { value: 4, text: "4 Starts" },
                    { value: 5, text: "5 Starts" },
                ],
                starRatingsSelected: "",
                starRatingsDescription: "",
            },
            processingPurchase: false,
        };
    },
    beforeMount() {
        const agent = Bowser.getParser(window.navigator.userAgent);
        this.videoMimeType = this.getMimeType(
            agent.getOS().name.toLowerCase(),
            agent.getBrowser().name.toLowerCase(),
        );
    },
    mounted() {
        this.clickToSwitchImage();
    },
    computed: {
        productMedia() {
            if (!this.product.media) {
                return [];
            }
            return this.product.media.filter(
                (item) => item.processing_status != 1,
            );
        },
        storeProductDetailsModalIsOpenComputed: {
            get() {
                return this.storeProductDetailsModalIsOpen;
            },
            set(value) {
                this.$emit("update:modalOpen", value);
            },
        },
        smallImagePreview() {
            return this.$mq != "sm"
                ? "margin-bottom:13px;"
                : "margin-bottom:13px; margin-right:10px;";
        },
        averageReview() {
            let add = 0;
            let length = this.product.reviews.length;

            for (let index = 0; index < this.product.reviews.length; index++) {
                add += this.product.reviews[index].stars;
            }
            let total = add / length;
            return total.toFixed(1);
        },
        itemsId() {
            return this.product?.id;
        },
    },
    methods: {
        reloadPage() {
            if (this.tabIndex == 2) {
                window.location.reload();
            }
        },
        clickToSwitchImage(index) {
            if (!this.product.media) {
                return;
            }

            this.switchImage = this.product.media[index];
            this.switchIndex =
                (this.switchIndex + 1) % this.product.media.length;

            // If VIDEO is true, switch to <b-embed></b-embed>
            if (this.product.media[index]?.type === "Video") {
                this.videoPreview.location = this.product.media[index].location;
                this.videoPreview.toggle = true;
                setTimeout(() => {
                    videojs(this.$refs.videoPlayer);
                }, 200);
            } else {
                this.videoPreview.toggle = false;
            }
        },
        currentDate(date) {
            const monthNames = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ];
            const current = new Date(date);
            return `${
                monthNames[current.getMonth()]
            } ${current.getDate()}, ${current.getFullYear()}`;
        },
        async submitBuyNow() {

            try {
                this.processingPurchase = true;
                const { data, status } = await axios.post("/store/purchase", {
                    productId: this.product.id,
                });

                if (status == 200) {
                    this.processingPurchase = false;
                    this.purchaseComplete = true;
                    this.$emit("product-purchased", this.product);
                }
            } catch (error) {
                this.processingPurchase = false;
                this.loading = false;
                await this.$swal(error.response.data.message);
            }
        },
        async checkPaymentMethod() {
            try {
                this.processingPurchase = true;
                const response = await axios.get(`/settings/hasCard`);
                if (response.data.hasCard) {
                    this.submitBuyNow()
                } else {
                    this.processingPurchase = false;
                    this.$emit("open-payment-modal", true);
                }
            } catch (error) {
                this.state = "error";
                this.errorMessage = error.response.data.message;
                this.processingPurchase = false;
            }
        },
        async addReviews() {
            try {
                const response = await axios.post("/store/reviews/add", {
                    productId: this.product.id,
                    stars: this.writeReview.starRatingsSelected,
                    description: this.writeReview.starRatingsDescription,
                });
                this.writeReview.toggle = false;
            } catch (error) {
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        },
        async setProductStatus(event, productStatus) {
            event.preventDefault();
            if (!this.isOwner) {
                return;
            }

            if (productStatus === 2) {
                let { isConfirmed } = await this.$swal({
                    title: "Delete Product",
                    html: "Are you sure you want to delete this product?",
                    showCancelButton: true,
                    showConfirmButton: true,
                    confirmButtonText: "Delete",
                    timer: 10000,
                });
                
                if (!isConfirmed) {
                    return;
                }
            }

            this.loading = true;
            try {
                const { data, status } = await axios.post(
                    "/store/setProductStatus",
                    {
                        id: this.product.id,
                        status: productStatus,
                    },
                );
                if (status == 200) {
                    this.product.status = productStatus;

                    let message = "";
                    if (productStatus === 0 || productStatus === 1) {
                        message = `Product (${
                            this.product.name
                        }) status was set to ${
                            productStatus === 1 ? "Publish" : "Unpublish"
                        }`;
                    }

                    if (productStatus === 2) {
                        message = `Product (${this.product.name}) has been deleted.`;
                    }

                    this.$emit("close-modal", false);
                    this.$emit("product-deleted", this.product);
                    await this.$swal("Success!", message, "success");
                }
            } catch (error) {
                await this.$swal(error.response.data.message);
            }
        },
        openEditProductModal(event, product) {
            event.preventDefault();
            this.$emit("open-edit-product-modal", product);
        },
        getMimeType(os, browser) {
            if (os === "macos" || os === "ios" || browser === "safari") {
                return "application/vnd.apple.mpegurl";
            }

            return "application/x-mpegURL";
        },
        testProductDownload($event) {
            $event.preventDefault();
            window.location = `/store/testDownload?productId=${this.product.id}`;
        },
    },
};
</script>

<style scoped lang="scss">
@media(max-width: 450px) {
    .button-custom {
    display: grid;
    margin-top :-50px;
    }
}
.button-custom {
     display: grid;
}
.close-modal-button {
    position: absolute;
    float: right;
    top: 5px;
    right: 20px;
    z-index: 99;
}
</style>
