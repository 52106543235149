<template>
    <svg
        width="32"
        height="32"
        viewBox="0 0 22 22"
        fill="#AAAAAA"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.49976 10.2144H5.48859C5.0546 10.2144 4.70312 10.5658 4.70312 10.9998C4.70312 11.4347 5.05462 11.7853 5.48859 11.7853H5.50062H16.4989H16.51C16.944 11.7853 17.2955 11.4338 17.2955 10.9998C17.2955 10.565 16.944 10.2144 16.51 10.2144H16.498H5.49976Z"
            fill="#AAAAAA"
        />
        <path
            d="M11.7858 5.50025V5.48908C11.7858 5.05509 11.4343 4.70361 11.0003 4.70361C10.5655 4.70361 10.2148 5.05511 10.2148 5.48908V5.50111V5.50025V16.4994V16.5105C10.2148 16.9445 10.5663 17.296 11.0003 17.296C11.4352 17.296 11.7858 16.9445 11.7858 16.5105V16.4985V16.4994V5.50025Z"
            fill="#AAAAAA"
        />
        <path
            d="M11.0011 0C4.93567 0 0.00195312 4.9346 0.00195312 10.9991C0.00195312 17.0645 4.93655 21.9982 11.0011 21.9982C17.0665 21.9982 22.0002 17.0636 22.0002 10.9991C22.0002 4.93372 17.0656 0 11.0011 0ZM11.0011 1.57179C16.2175 1.57179 20.4292 5.78435 20.4292 10.9999C20.4292 16.2163 16.2173 20.428 11.0011 20.428C5.78487 20.428 1.57297 16.2161 1.57297 10.9999C1.57297 5.78369 5.78487 1.57179 11.0011 1.57179Z"
            fill="#AAAAAA"
        />
    </svg>
</template>

<script>
export default {
    name: "PlusIcon",
    props: {
        fill: {
            type: String,
            default: "#AAAAAA",
        },
    },
};
</script>

<style scoped></style>