<template>
    <div class="lb" v-if="items.length > 0">
        <div :class="gridClass">
            <div
                class="lb-item"
                v-for="(item, i) in filteredItems"
                :key="item.id"
                @click="show(i)"
            >
                <div
                    v-if="!item.type && items.length === 1"
                    class="img-center"
                >
                    <b-img
                        :src="item.thumbnail"
                        fluid
                        style="object-fit: cover;"
                    ></b-img>
                </div>
                <b-img
                    v-else-if="!item.type && items.length > 1"
                    :src="item.thumbnail"
                    fluid
                    :class="items.length > 1 ? 'h-100 w-100' : ''"
                    style="object-fit: cover"
                ></b-img>
                <video
                    :id="`video-player-${item.id}`"
                    class="video-js vjs-fill"
                    v-else
                    controls
                    muted
                    data-setup="{}"
                    :poster="item.poster"
                >
                    <source
                        v-if="
                            item.file.split('.').slice(-1)[0].toLowerCase() ===
                            'mp4'
                        "
                        :src="item.file"
                        type="video/mp4"
                    />
                    <source
                        v-if="
                            item.file.split('.').slice(-1)[0].toLowerCase() ===
                            'webm'
                        "
                        :src="item.file"
                        type="video/webm"
                    />
                    <source
                        v-if="
                            item.file.split('.').slice(-1)[0].toLowerCase() ===
                            'ogg'
                        "
                        :src="item.file"
                        type="video/ogg"
                    />
                    <source
                        v-if="
                            item.file.split('.').slice(-1)[0].toLowerCase() ===
                            'm3u8'
                        "
                        :src="item.file"
                        type="application/x-mpegURL"
                    />
                </video>
                <span
                    class="lb-more"
                    v-if="i === cells - 1 && items.length - cells > 0"
                    >{{ items.length - cells }}+</span
                >
            </div>
        </div>
        <b-modal
            v-if="openModal"
            :id="`${postId}-media-lb`"
            size="xl"
            hide-header
            hide-footer
            centered
            body-class="p-0"
            body-bg-variant="dark"
            @hidden="openModal = false"
            v-model="open"
        >
            <b-carousel
                v-if="index >= 0"
                v-model="index"
                style="min-height: 200px;"
                :interval="0"
                :controls="items.length > 1"
                :indicators="items.length > 1"
            >
                <b-carousel-slide v-for="item in items" :key="item.id">
                    <template v-slot:img>
                        <b-img
                            v-if="!item.type"
                            :src="item.file"
                            fluid
                            class="h-100 w-100"
                            style="object-fit: cover; max-height: 90vh"
                        ></b-img>
                        <video
                            :id="`video-player-large-${item.id}`"
                            v-else
                            width="100%"
                            height="auto"
                            controls
                            muted
                            data-setup="{}"
                            :poster="item.poster"
                            class="mb-n2 video-js vjs-fluid"
                        >
                            <source
                                v-if="
                                    item.file
                                        .split('.')
                                        .slice(-1)[0]
                                        .toLowerCase() === 'mp4'
                                "
                                :src="item.file"
                                type="video/mp4"
                            />
                            <source
                                v-if="
                                    item.file
                                        .split('.')
                                        .slice(-1)[0]
                                        .toLowerCase() === 'webm'
                                "
                                :src="item.file"
                                type="video/webm"
                            />
                            <source
                                v-if="
                                    item.file
                                        .split('.')
                                        .slice(-1)[0]
                                        .toLowerCase() === 'ogg'
                                "
                                :src="item.file"
                                type="video/ogg"
                            />
                            <source
                                v-if="
                                    item.file
                                        .split('.')
                                        .slice(-1)[0]
                                        .toLowerCase() === 'm3u8'
                                "
                                :src="item.file"
                                type="application/x-mpegURL"
                            />
                        </video>
                    </template>
                </b-carousel-slide>
            </b-carousel>
        </b-modal>
    </div>
</template>

<script>
import videojs from 'video.js';
require('videojs-contrib-quality-levels');
require('videojs-hls-quality-selector');
export default {
    name: "MediaLightbox",
    props: {
        items: {
            type: Array,
            required: true,
        },
        cells: {
            type: Number,
            default: 5,
        },
        postId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            index: -1,
            openModal: false,
            showMask: false,
            open: false,
        };
    },
    computed: {
        filteredItems() {
            return this.items.slice(0, 5);
        },
        gridClass() {
            const cls = this.items.length > this.cells
                    ? 'lb-grid-' + this.cells
                    : this.items.length === 1 && !this.items[0].type
                       ? 'lb-one-img'
                       : 'lb-grid-' + this.items.length;

            return `lb-grid ${cls}`;
        }
    },
    watch: {
        open: function (val, oldVal) {

            setTimeout(() => {
                let modalElementID = this.postId + '-media-lb';
                if (this.items.length > 1) {
                    let mainElement = document.getElementById(modalElementID);
                    if (mainElement) {
                        mainElement.getElementsByClassName("carousel-control-prev")[0].style.top = "20%";
                        mainElement.getElementsByClassName("carousel-control-next")[0].style.top = "20%";
                        mainElement.getElementsByClassName("carousel-control-prev")[0].style.bottom = "20%";
                        mainElement.getElementsByClassName("carousel-control-next")[0].style.bottom = "20%";
                    }
                }
                if (this.items.length > 0) {
                    for (let i = 0; i < this.items.length; i++) {

                        // Do the same for the large modal players
                        let lgSelector = "#video-player-large-" + this.items[i].id;
                        const lgElem = document.querySelector(lgSelector);
                        if (lgElem) {
                            let playerLg = videojs(document.querySelector(lgSelector));
                            const type = playerLg.currentType();
                            if (type === "application/x-mpegURL") {
                                console.log("adding playlist selector");
                                playerLg.hlsQualitySelector();
                            }
                        }
                    }
                }
            }, 250);
        },
    },
    mounted: function() {

        // Once mounted, loop through each item and create a player instance we can configure
        if (this.items.length > 0) {
            for (let i = 0; i < this.items.length; i++) {
                let selector = "#video-player-" + this.items[i].id;
                const elem = document.querySelector(selector);
                if (elem) {
                    let player = videojs(document.querySelector(selector), { responsive: true});
                    const type = player.currentType();

                    if (type === "application/x-mpegURL") {
                        console.log("adding playlist selector");
                        player.hlsQualitySelector();
                    }
                }
                // Do the same for the large modal players
                let lgSelector = "#video-player-large-" + this.items[i].id;
                const lgElem = document.querySelector(lgSelector);
                if (lgElem) {
                    let playerLg = videojs(document.querySelector(lgSelector), { responsive: true});
                    const type = playerLg.currentType();
                    if (type === "application/x-mpegURL") {
                        console.log("adding playlist selector");
                        playerLg.hlsQualitySelector();
                    }
                }
            }
        }
    },
    methods: {
        async show(i) {
            if (i >= this.items.length) {
                i = 0;
            }
            if (i < 0) {
                i = this.items.length - 1;
            }
            this.index = i;
            this.openModal = true;
            await Vue.nextTick();
            this.$bvModal.show(`${this.postId}-media-lb`);
        },
    },
};
</script>

<style scoped>
.img-full{
    width:100% !important;
}
.lb-grid {
    height: 219px;
    position: relative;
    display: block;
}
@media (min-width: 768px) {
    .lb-grid {
        height: 389px;
    }
}
@media (min-width: 992px) {
    .lb-grid {
        height: 411px;
    }
}

.lb-item {
    position: absolute;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top: solid 2px #fff;
    border-right: solid 2px #fff;
    cursor: pointer;
    /*overflow-y: hidden;*/
}
/*
.lb-item .video-js {
    position: absolute;
    bottom: 0;
}*/

.lb-grid-1 .lb-item {
    width: 100%;
    height: 100%;
}

.lb-grid-2 .lb-item,
.lb-grid-3 .lb-item,
.lb-grid-4 .lb-item,
.lb-grid-5 .lb-item {
    width: 50%;
}

.lb-grid-2 .lb-item {
    height: 60%;
}

.lb-grid-3 .lb-item:nth-child(1),
.lb-grid-4 .lb-item:nth-child(1) {
    height: 100%;
}

.lb-grid-3 .lb-item:nth-child(2),
.lb-grid-3 .lb-item:nth-child(3) {
    height: 60%;
}

.lb-item:last-child,
.lb-grid-2 .lb-item:nth-child(2),
.lb-grid-3 .lb-item:nth-child(2),
.lb-grid-3 .lb-item:nth-child(3),
.lb-grid-4 .lb-item:nth-child(2),
.lb-grid-4 .lb-item:nth-child(3),
.lb-grid-4 .lb-item:nth-child(4) {
    left: auto;
    right: 0;
    border-right: 0;
}

.lb-grid-3 .lb-item:nth-child(3),
.lb-grid-4 .lb-item:nth-child(4) {
    bottom: 0;
    top: auto;
}

.lb-grid-4 .lb-item:nth-child(3) {
    top: 33.333333333333336%;
}

.lb-grid-4 .lb-item:nth-child(2),
.lb-grid-4 .lb-item:nth-child(3),
.lb-grid-4 .lb-item:nth-child(4) {
    height: 33.333333333333336%;
}

.lb-grid-5 .lb-item:nth-child(1),
.lb-grid-5 .lb-item:nth-child(2) {
    height: 50%;
    left: 0;
    top: 0;
}
.lb-grid-5 .lb-item:nth-child(2) {
    top: 50%;
}

.lb-grid-5 .lb-item:nth-child(3),
.lb-grid-5 .lb-item:nth-child(4),
.lb-grid-5 .lb-item:nth-child(5) {
    height: 33.333333333333336%;
    right: 0;
    top: 0;
}

.lb-grid-5 .lb-item:nth-child(4) {
    top: 33.333333333333336%
}

.lb-grid-5 .lb-item:nth-child(5) {
    top: 66.666666666667%
}

.lb-more {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    color: #fff;
    font-size: 3rem;
    background-color: rgba(0, 0, 0, 0.4);
}

.lb-more:before {
    display: inline-block;
    content: "";
    vertical-align: middle;
    height: 100%;
}

.lb-one-img {
    height: max-content !important;
}

.lb-one-img > .lb-item {
    position: relative;
}

.lb-one-img > .lb-item > .img-center{
    display: flex;
    justify-content: center;
    background: black;
}
</style>
