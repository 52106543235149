<template>
    <!-- <div
        class="burst-reply"
        @click="
            $emit('play-reply', {
                burstReply,
                burstReplyIndex,
            })
        "
        :style="{ backgroundImage: `url(${burstReply.posted_by.avatar})` }"
        :class="{ 'is-selected': isSelected }"
    > -->

    <div style="display: flex; flex-direction: column" class="item-select">
        <div class="position-relative">
            <div
                class="burst-reply"
                :style="{
                    backgroundImage: burstReply.files[0].poster
                        ? `url(${burstReply.files[0].poster})`
                        : `url('/images/backgrounds/default_avtar.png')`,
                }"
            >
                <!-- <div class="reactions-count-badge">
                <div class="count-badge-inner">
                    <i class="fas fa-heart fa-2x"></i>
                    <div class="count">{{ reactionsCount }}</div>
                </div>
            </div> -->
                <!-- <div class="comment-count-badge">
                <div class="count-badge-inner">
                    <i class="fas fa-comment fa-2x"></i>
                    <div class="count">{{ burstReply.comment_count }}</div>
                </div>
            </div> -->
                <video
                    muted
                    ref="burstVideo"
                    class="video-show video-js d-none"
                    playsinline
                    @ended="videoEnded"
                >
                    <source
                        v-if="
                            burstReply.files[0].file
                                .split('.')
                                .slice(-1)[0]
                                .toLowerCase() === 'mp4'
                        "
                        :src="burstReply.files[0].file"
                        type="video/mp4"
                    />
                    <source
                        v-if="
                            burstReply.files[0].file
                                .split('.')
                                .slice(-1)[0]
                                .toLowerCase() === 'webm'
                        "
                        :src="burstReply.files[0].file"
                        type="video/webm"
                    />
                    <source
                        v-if="
                            burstReply.files[0].file
                                .split('.')
                                .slice(-1)[0]
                                .toLowerCase() === 'ogg'
                        "
                        :src="burstReply.files[0].file"
                        type="video/ogg"
                    />
                    <source
                        v-if="
                            burstReply.files[0].file
                                .split('.')
                                .slice(-1)[0]
                                .toLowerCase() === 'm3u8'
                        "
                        :src="burstReply.files[0].file"
                        type="application/x-mpegURL"
                    />
                </video>
                <div class="hover-thumnail">
                    <div class="video-control">
                        <span @click="videoMuteToggle">
                            <img
                                :src="`/images/icons/${
                                    videoMute ? 'mute.png' : 'unmute.png'
                                }`"
                            />
                        </span>
                        <span
                            @click="openVideoPopUpModal"
                            :class="{ 'is-selected': isSelected }"
                        >
                            <img src="/images/icons/fullscreen.png" />
                        </span>
                    </div>
                    <div class="show-button">
                        <img
                           v-if="!videoPlay"
                            src="/images/icons/play.png"
                            @click="startVideo"
                        />

                        <img
                            v-if="videoPlay"
                            src="/images/icons/pause.png"
                            @click="pauseVideo"
                        />
                    </div>
                </div>
                <div
                    v-if="showDeleteBtn"
                    class="delete-btn"
                    @click="handleShowDeleteModal"
                >
                    <i class="fas fa-times-circle fa-lg"></i>
                </div>
            </div>
            <a
                :href="'/u/' + burstReply.posted_by.id"
                class="user-name"
                style="color: white; display: flex; justify-content: center"
                >{{ burstReply.posted_by.name }}</a
            >
        </div>

        <delete-post-modal
            v-if="deleteModalIsOpen"
            :message="'Are you sure you want to delete this Burst reply?'"
            :modal-open.sync="deleteModalIsOpen"
            :league-id="burstReply.league.id"
            :post-id="burstReply.id"
            @post-deleted="burstReplyDeleted"
        ></delete-post-modal>
    </div>
</template>

<script>
import DeletePostModal from "../Modals/DeletePostModal";
import VideoPopupModal from "../Modals/VideoPopupModal";
import videojs from "video.js";
require("videojs-playlist");
require("videojs-contrib-quality-levels");
require("videojs-hls-quality-selector");
export default {
    name: "BurstReplyItem",
    components: {
        DeletePostModal,
        VideoPopupModal,
    },
    props: {
        parentBurst: {
            type: Object,
            required: true,
        },
        currentBurst: {
            type: Object,
            default: null,
        },
        burstReplyIndex: {
            type: Number,
            required: true,
        },
        burstReply: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
        },
    },
    data() {
        return {
            deleteModalIsOpen: false,
            videoPopUpModalOpen: false,
            videoPlay: false,
            videoPlayer: null,
            videoMute: true,
        };
    },
    mounted() {
        try {
            const videoPlayer = this.$refs.burstVideo;
            if (videoPlayer) {
                this.videoPlayer = videojs(videoPlayer, {
                    controls: false,
                });
            }
        } catch (e) {
            console.warn("VIDEOJS ERROR: ", e);
        }
    },
    computed: {
        reactionsCount() {
            return this.burstReply.like_count + this.burstReply.love_count;
        },
        isSelected() {
            return (
                this.currentBurst && this.currentBurst.id == this.burstReply.id
            );
        },
        showDeleteBtn() {
            return (
                ((this.parentBurst.permissions.ownPost ||
                    this.burstReply.permissions.ownPost) &&
                    !(this.parentBurst.id == this.burstReply.id)) ||
                this.parentBurst.id == this.burstReply.id
            );
        },
    },
    methods: {
        handleShowDeleteModal(e) {
            e.preventDefault();
            e.stopPropagation();
            this.deleteModalIsOpen = true;
        },
        burstReplyDeleted(id) {
            this.$emit("reply-deleted", id);
        },
        openVideoPopUpModal() {
            const currentTime = this.videoPlayer.currentTime();
            this.pauseVideo();
            this.$emit('open-video-pop-up-modal', {index: this.index, currentTime});
        },
        startVideo() {
            this.videoPlay = true;
            this.videoPlayer.play();
            this.videoPlayer.removeClass("d-none");
            this.$emit("play-reply", this.index);
        },
        pauseVideo() {
            if(this.videoPlay) {
                this.videoPlay = false;
                this.videoPlayer.pause();
                this.videoPlayer.addClass("d-none");
            }
        },
        setMute(mute) {
            this.videoMute = mute;
            this.videoPlayer.muted(mute);
        },
        videoMuteToggle() {
            this.videoMute = !this.videoMute;
            this.videoPlayer.muted(this.videoMute);
            this.$emit("video-mute-toggle", this.videoMute);
        },
        videoEnded() {
            this.videoPlay = false;
            this.videoPlayer.currentTime(0);
            this.videoPlayer.addClass("d-none");
            this.$emit("play-next-video-inline", this.index + 1);
        },
        setCurrentTime(time) {
            this.videoPlayer.currentTime(time);
        },
    },
};
</script>

<style scoped lang="scss">
.burst-reply {
    background-color: #000;
    background-size: cover;
    position: relative;
    // display: inline-table;
    width: 178px;
    height: 115px;
    margin-right: 5px;
    // border: 1px solid white;
    border-radius: 14px;
    overflow: hidden;
    background-position: center center;
    background-repeat: no-repeat;
    @media (max-width: 768px) {
        width: 141px !important;
        margin-right: 5px;
    }
    img {
        overflow: hidden;
    }

    &.is-selected {
        border: 3px solid #ffa500;
    }

    .comment-count-badge {
        position: absolute;
        top: 2px;
        right: 2px;
        .count-badge-inner {
            position: relative;
            text-align: center;
            .fas.fa-comment {
                color: #5451de;
            }
            .count {
                position: absolute;
                top: 40%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: white;
                font-size: 12px;
            }
        }
    }

    .reactions-count-badge {
        position: absolute;
        top: 2px;
        left: 2px;
        .count-badge-inner {
            position: relative;
            text-align: center;
            .fas.fa-heart {
                color: red;
            }
            .count {
                position: absolute;
                top: 40%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: white;
                font-size: 12px;
            }
        }
    }
    .user-name {
        color: white;
        position: absolute;
        bottom: 0px;
        right: 0px;
        padding: -5px;
    }

    .delete-btn {
        color: white;
        position: absolute;
        bottom: 0px;
        right: 0px;
        padding: -5px;
        cursor: pointer;
        .fas.fa-times-circle {
            color: white;
            background: black;
            border: 1px solid black;
            padding: 2px 0;
            border-radius: 15px;
        }
    }
    .hover-thumnail {
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 0;
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.8),
            rgba(0, 0, 0, 0.8)
        );
        display: none;
        .video-control {
            display: flex;
            justify-content: space-between;
            margin: 0 5px;
        }
        .show-button {
            display: flex;
            justify-content: center;
            margin-top: 15px;
        }
    }
    .video-show {
        width: 100%;
        height: 100%;
        position: absolute;
        background: black;
        border-radius: 14px;
    }
}
.item-select {
    &:hover {
        .hover-thumnail {
            display: block !important;
        }
    }
}

//video tag customize

audio::-webkit-media-controls-current-time-display,
video::-webkit-media-controls-current-time-display,
audio::-webkit-media-controls-time-remaining-display,
video::-webkit-media-controls-time-remaining-display {
    display: none !important;
}
audio::-webkit-media-controls-timeline,
video::-webkit-media-controls-timeline {
    display: none;
}
audio::-webkit-media-controls-mute-button,
video::-webkit-media-controls-mute-button {
    display: none;
}
video::-webkit-media-controls-fullscreen-button {
    display: none;
}
video::-webkit-media-controls-play-button {
    display: none;
}
</style>
