<template>
    <b-container>
        <b-row>
            <b-col cols="12" class="mb-3">
                <div class="d-flex align-items-center justify-content-between">
                    <h1 class="h3 mb-0 font-weight-bold">My Settings</h1>
                    <b-dropdown
                        variant="outline-purple-purple"
                        class="d-lg-none"
                    >
                        <template v-slot:button-content>
                            <span class="text-capitalize mr-2">{{
                                activeSection
                            }}</span>
                        </template>
                        <b-dropdown-item
                            :active="activeSection === 'general'"
                            @click="updateActiveSection('general')"
                            >General</b-dropdown-item
                        >
                        <b-dropdown-item
                            :active="activeSection === 'friends'"
                            @click="updateActiveSection('friends')"
                            >Friends</b-dropdown-item
                        >
                        <b-dropdown-item
                            :active="activeSection === 'notifications'"
                            @click="updateActiveSection('notifications')"
                            >Notifications</b-dropdown-item
                        >
                        <b-dropdown-item
                            :active="activeSection === 'payment'"
                            @click="updateActiveSection('payment')"
                            >Payment</b-dropdown-item
                        >
                        <b-dropdown-item
                            :active="activeSection === 'privacy'"
                            @click="updateActiveSection('privacy')"
                            >Privacy</b-dropdown-item
                        >
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item href="/logout">Logout</b-dropdown-item>
                    </b-dropdown>
                </div>
                <hr />
            </b-col>
        </b-row>
        <b-row>
            <b-col md="3" class="d-none d-lg-block mb-5">
                <b-nav vertical>
                    <b-nav-item
                        :active="activeSection === 'general'"
                        @click="updateActiveSection('general')"
                    >
                        General
                    </b-nav-item>
                    <b-nav-item
                        :active="activeSection === 'friends'"
                        @click="updateActiveSection('friends')"
                    >
                        Friends
                    </b-nav-item>
                    <b-nav-item
                        :active="activeSection === 'notifications'"
                        @click="updateActiveSection('notifications')"
                    >
                        Notifications
                    </b-nav-item>
                    <b-nav-item
                        :active="activeSection === 'payment'"
                        @click="updateActiveSection('payment')"
                    >
                        Payment
                    </b-nav-item>
                    <b-nav-item
                        :active="activeSection === 'privacy'"
                        @click="updateActiveSection('privacy')"
                    >
                        Privacy
                    </b-nav-item>
                    <b-nav-item class="d-lg-none" href="/logout">
                        Logout
                    </b-nav-item>
                </b-nav>
                <hr />
                <a href="/logout" class="d-none d-lg-block logout-link"
                    >Logout</a
                >
            </b-col>
            <b-col>
                <component
                    :is="activeSectionComponent"
                    :user="user"
                    :subscriptions="subscriptions"
                    :payments="payments"
                    :payment-method="paymentMethod"
                    :privacy-settings="privacy"
                    :notification-settings="notificationSettingsData"
                ></component>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import UserSettingsEventBus from "./UserSettingsEventBus.js";
import UserSettingsGeneral from "./UserSettingsGeneral";
import UserSettingsPrivacy from "./UserSettingsPrivacy";
import UserSettingsPayment from "./UserSettingsPayment";
import UserSettingsNotifications from "./UserSettingsNotifications";
export default {
    name: "UserSettings",
    components: {
        UserSettingsNotifications,
        UserSettingsPayment,
        UserSettingsPrivacy,
        UserSettingsGeneral,
    },
    props: {
        section: {
            type: String,
            default: "general",
        },
        user: {
            type: Object,
            required: true,
        },
        subscriptions: {
            type: Array,
            required: true,
        },
        payments: {
            type: Array,
            required: true,
        },
        privacy: {
            type: Object,
            required: true,
        },
        paymentMethod: {
            type: Object,
            required: true,
        },
        notificationSettings: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            activeSection: "general",
            notificationSettingsData: [], // pass the props to notificationSettingsData so we can update the value
        };
    },
    mounted() {
        this.activeSection = this.section;
        this.notificationSettingsData = this.notificationSettings;
        UserSettingsEventBus.$on(
            "update-notification-settings",
            (UpdatedNotificationSettingsData) => {
                this.notificationSettingsData = UpdatedNotificationSettingsData;
            },
        );
    },
    computed: {
        activeSectionComponent() {
            switch (this.activeSection) {
                case "general":
                    return "user-settings-general";
                case "friends":
                    return "user-settings-friends";
                case "notifications":
                    return "user-settings-notifications";
                case "payment":
                    return "user-settings-payment";
                case "privacy":
                    return "user-settings-privacy";
                default:
                    return null;
            }
        },
    },
    methods: {
        updateActiveSection(newSection) {
            this.activeSection = newSection;

            // Change url to retain location on reload (but dont reload page currently)
            window.history.pushState(null, "", `?section=${newSection}`);
        },
    },
};
</script>

<style scoped>
.logout-link {
    font-size: 20px;
    color: #0e0e0e;
    padding: 0.75rem 0.25rem;
    text-decoration: none;
}
.nav-link {
    font-size: 20px;
    color: #0e0e0e;
    padding: 0.75rem 0.25rem;
}
.nav-link.active {
    color: #5451de !important;
    font-weight: bold;
}
</style>
