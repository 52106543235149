<template>
    <div>
        <b-card class="post-card drop-shadow" no-body>
            <b-card-body>
                <h1>card body</h1>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import CommentsList from "../Lists/CommentsList";

export default {
    name: "MediaItemCommentsCard",
    components: {
        CommentsList,
    },
    props: {},
    data() {
        return {};
    },
    mounted() {},
    computed: {},
    methods: {},
};
</script>
