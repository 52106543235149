<template>
    <div class="league-profile-header">
        <div class="banner-container">
            <div v-if="!bannerIsVideo">
                <div
                    class="banner-image"
                    :style="bannerStyles"
                ></div>
                <!-- Small Banner -->
                <div
                    v-show="!bannerIsVideo && !isVisible"
                    class="banner-image position-fixed left-0 right-0 top-0 sticky-top w-100"
                    :style="smallBannerStyles"
                >
                    <div class="container">
                        <div class="py-2">
                            <b-avatar
                                variant="grey"
                                :src="profilePhoto"
                                size="50px"
                                class="profile-avatar"
                            ></b-avatar>
                            <span
                                class="position-absolute top-0 text-white ml-3 mt-3"
                            >
                                <h5>{{ leagueName }}</h5>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-else
                class="d-flex position-relative" 
                style="overflow:hidden; width:100%; align-content:center;"
                :style="this.$mq === 'sm' ? 'height:207px' : 'height:350px'"
            >
                <video
                    v-if="bannerIsVideo"
                    :style="videoBannerStyles"
                    muted
                    autoplay
                    playsinline
                    loop
                    class="position-absolute"
                    height="auto"
                >
                    <source v-if="bannerImage.split('.').slice(-1)[0].toLowerCase() === 'mp4'" :src="bannerImage" type="video/mp4" />
                    <source v-if="bannerImage.split('.').slice(-1)[0].toLowerCase() === 'webm'"  :src="bannerImage" type="video/webm" />
                    <source v-if="bannerImage.split('.').slice(-1)[0].toLowerCase() === 'ogg'" :src="bannerImage" type="video/ogg" />
                    <source v-if="bannerImage.split('.').slice(-1)[0].toLowerCase() === 'm3u8'"  :src="bannerImage" type="application/x-mpegURL" />
                </video>
            </div>
            <div v-show="editable" class="banner-overlay">
                <b-btn
                    class="wh-center btn-padded"
                    variant="outline-light"
                    pill
                    @click="$emit('editBanner')"
                    >Change Banner Photo</b-btn
                >
            </div>
        </div>
        <b-container>
            <b-media
                class="header-content align-items-center"
                :style="{ marginTop: profilePhotoMargin }"
            >
                <!-- This is the Visibility breakpoint -->
                <div v-b-visible="handleVisibility">
                    &nbsp;
                </div>
                <template v-slot:aside>
                    <div class="profile-container">
                        <b-avatar
                            variant="grey"
                            :src="profilePhoto"
                            :size="profilePhotoHeight"
                            class="profile-avatar"
                        ></b-avatar>
                        <div v-show="editable" class="profile-overlay">
                            <b-btn
                                class="wh-center"
                                variant="outline-light"
                                pill
                                @click="$emit('editProfile')"
                            >
                                <i class="fas fa-plus"></i>
                            </b-btn>
                        </div>
                    </div>
                </template>
                <div class="responsive-size text-white pb-5 pb-md-0">
                    {{ leagueName }}
                </div>
            </b-media>
        </b-container>
    </div>
</template>

<script>
export default {
    name: "LeagueProfileHeader",
    data() {
        return {
            isVisible: false,
        };
    },
    props: {
        leagueName: {
            type: String,
        },
        profilePhoto: {
            type: String,
            default: null,
        },
        bannerImage: {
            type: String,
        },
        small: {
            type: Boolean,
            default: false,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        zoomControls:{ 
            type: Number,
            default: 0,
        },
        isLeagueInfoForm:{
            type: Boolean,
            default: false,
        },
    },
    methods: {
        handleVisibility(isVisible) {
            this.isVisible = isVisible;
        },
    },
    computed: {
        bannerIsVideo() {
            if (this.bannerImage) {
                const videoExtensions = ["mp4", "qt", "mov"];
                const extension = this.bannerImage.split(".").pop();

                return videoExtensions.includes(extension);
            }
            return null;
        },
        banner() {
            if (this.bannerImage) {
                return this.bannerImage;
            }
            if (this.editable) {
                return "/images/backgrounds/default-banner@2x.jpg";
            }
            return null;
        },
        bannerHeight() {
            return this.small ? "200px" : "350px";
        },
        bannerStyles() {
            let background = [];

            if (this.bannerImage) {
                background.push(
                    "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.41) 100%)"
                );
            }

            if (this.banner === null) {
                background.push(
                    "linear-gradient(193deg, rgba(110,107,248,0.4) 0%, rgba(84,81,222,1) 100%)"
                );
            } else {
                background.push(`url(${this.banner})`);
            }

            return {
                borderRadius: this.small ? "3px" : 0,
                height: this.bannerHeight,
                backgroundImage: background.join(", "),
            };
        },
        smallBannerStyles() {
            let background = [];

            if (this.bannerImage) {
                background.push(
                    "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.41) 100%)"
                );
            }

            if (this.banner === null) {
                background.push(
                    "linear-gradient(193deg, rgba(110,107,248,0.4) 0%, rgba(84,81,222,1) 100%)"
                );
            } else {
                background.push(`url(${this.banner})`);
            }

            return {
                borderRadius: this.small ? "3px" : 0,
                height: "50px",
                backgroundImage: background.join(", "),
                marginTop: "57px",
            };
        },
        topProperty(){
            let top = 0;
            //When the banner's width is full-screen
            if(this.zoomControls >= 130 && !this.isLeagueInfoForm){
                return top = (window.innerWidth / 15) * this.zoomControls / 100; // top ranges from 0 at 130% to 50 at 200%
            } 
            if(this.zoomControls <= 120 && !this.isLeagueInfoForm) {
                if(this.$mq === 'sm'){
                    return this.zoomControls === 120 ? top = 20 : top = 0; // Mobile top ranges from 0 at 100% to 20 at 200%
                } if(this.$mq === 'md'){
                    if(window.innerWidth >= 1024 && window.innerWidth <= 1069 ){
                        return this.zoomControls === 120 ? top = 55 : top = 35; //iPad PRO ranges from 35 to 55 at 200%
                    } else {
                        return this.zoomControls === 120 ? top = 30 : top = 10; //iPad ranges from 10 to 30 at 200%
                    }
                } if(this.$mq === 'lg'){
                    if(window.innerWidth >= 1070 && window.innerWidth <= 1418 ){
                        return this.zoomControls === 120 ? top = 70 : top = 50; //Laptop ranges from 50 to 75 at 200%
                    } else {
                        return this.zoomControls === 120 ? top = 140 : top = 100; //Desktop ranges from 100 to 140 at 200%
                    }
                }
            }
            //When the banner's width is fixed (783 x 350)
            if(this.zoomControls >= 130 && this.isLeagueInfoForm){ 
                if(this.$mq === 'lg') return top = ((this.zoomControls - 100) / 10 ) * 10; // top ranges from 30 at 130% to 100 at 200%
                if(this.$mq === 'md') return top = ((this.zoomControls - 100) / 10 ) * 7.33; // top ranges from 22 at 130% to 73 at 200%
                if(this.$mq === 'sm') return top = ((this.zoomControls - 100) / 10 ) * 4.5;  //  top ranges from 13 at 130% to 45 at 200%
            } if(this.zoomControls <= 120 && this.isLeagueInfoForm){ 
                if(this.$mq === 'lg') return top = 15; else return top = 0; // Only lg screens require 'top:-15%'
            } 
        },
        videoBannerStyles() {
            let right = w => Math.round((w - 100) / 10) * 5;
            return {
                // height: this.bannerHeight,
                // width: "100%",
                // "object-fit": "cover",
                width: `${this.zoomControls}%`, 
                right: `-${right(this.zoomControls)}%`, 
                top: `-${this.topProperty}%`,
            };
        },
        profilePhotoHeight() {
            return this.small ? "100px" : "150px";
        },
        profilePhotoMargin() {
            return this.small ? "-4.5rem" : "-7.5rem";
        },
    },
};
</script>

<style scoped lang="scss">
div.responsive-size {
    font-size: 1.7rem;
    @media (min-width: 768px) {
        font-size: 2.5rem;
        padding-bottom: 20px!important;
    }
}
</style>
