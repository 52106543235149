<template>
    <div>
        <div class="profile-header-container">
            <user-profile-header
                :active-tab="activeSection"
                :show-edit="authUser.id === user.id"
                :profile-photo="user.avatar"
                :banner-image="user.banner"
                :prop-vault-locked="propVaultLocked"
                @change-active-tab="changeActiveSection"
                @block-user="blockUser"
                :zoom-controls="user.banner_zoom"
                @open-block-user-modal="openBlockUserModal"
            ></user-profile-header>
        </div>
        <b-container class="mt-5">
            <component
                :is="activeSectionComponent"
                :auth-user="authUser"
                :user="user"
                :league-options="invitableLeagues"
                :friend-status="friendStatus"
                :friend-to-delete="friendToDelete"
                :can-invite="canInvite"
                :can-friend="canFriend"
                :prop-vault-locked="propVaultLocked"
                @update-vault-locked="onUpdatePropVaultLocked"
            ></component>
        </b-container>
        
        <block-user-modal
            :modal-open.sync="blockUserModalOpen"
            :user-name="user.name"
            @block-user="blockUser"
        >
        </block-user-modal>
    </div>
</template>

<script>
import UserProfileHeader from "../../Headers/UserProfileHeader";
import UserProfileAbout from "./UserProfileAbout";
import UserProfileFriends from "./UserProfileFriends";
import UserProfileLeagues from "./UserProfileLeagues";
import BlockUserModal from "../../Modals/BlockUserModal";

export default {
    name: "UserProfile",
    components: {
        BlockUserModal,
        UserProfileLeagues,
        UserProfileFriends,
        UserProfileAbout,
        UserProfileHeader,
    },
    props: {
        authUser: { type: Object, required: true },
        user: { type: Object, required: true },
        section: { type: String, required: true },
        invitableLeagues: { type: Array, required: true },
        friendToDelete: { type: Number, required: true },
        friendStatus: {
            type: Number,
            required: true,
        },
        canInvite: {
            type: Boolean,
            required: true,
        },
        canFriend: {
            type: Boolean,
            required: true,
        },
        vaultLocked: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            blockUserModalOpen: false,
            activeSection: "about",
            propVaultLocked: this.vaultLocked,
        };
    },
    mounted() {
        this.activeSection = this.section;
    },
    computed: {
        activeSectionComponent() {
            switch (this.activeSection) {
                case "about":
                    return "user-profile-about";
                case "leagues":
                    return "user-profile-leagues";
                case "media":
                    return "user-profile-media";
                case "friends":
                    return "user-profile-friends";
                default:
                    return null;
            }
        },
    },
    methods: {
        changeActiveSection(value) {
            this.activeSection = value;
            window.history.pushState(null, "", `?section=${value}`);
        },
        openBlockUserModal() {
            this.blockUserModalOpen = true;
        },
        async blockUser() {
            try {
                const response = await axios.post("/users/friends/block", {
                    userId: this.user.id,
                });
                window.location.href = `/home`;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
        onUpdatePropVaultLocked(newValue) {
            console.log("Vault value changing from " + this.propVaultLocked + " to " + newValue);
            this.propVaultLocked = newValue;
        },
    },
};
</script>

<style scoped>
.ignore-top-padding {
    margin-top: -5rem;
}
</style>
