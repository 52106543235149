<template>
    <div>
        <b-row align-h="center">
            <store-upload-file-thumbnail-item
                :title="'Item for Sale'"
                :is-required="true"
                :thumbnail-image-url="mainFileThumbnail"
                :loading="mainFileLoading"
                @click="openDeviceFileExplorer('mainFileRef')"
            ></store-upload-file-thumbnail-item>
        </b-row>
        <b-row align-v="center" align-h="around">
            <store-upload-file-thumbnail-item
                :title="'Cover Image'"
                :is-required="true"
                :thumbnail-image-url="mediaFile1Thumbnail"
                :loading="mediaFile1Loading"
                :position="1"
                @click="openDeviceFileExplorer('mediaFile1Ref')"
            ></store-upload-file-thumbnail-item>
            <store-upload-file-thumbnail-item
                :title="'Image 1'"
                :is-required="false"
                :thumbnail-image-url="mediaFile2Thumbnail"
                :loading="mediaFile2Loading"
                :media-item-id="mediaFile2Id"
                :can-delete="true"
                :position="2"
                @click="openDeviceFileExplorer('mediaFile2Ref')"
                @delete-media-item="handleDeleteMediaItem"
            ></store-upload-file-thumbnail-item>
            <store-upload-file-thumbnail-item
                :title="'Image 2'"
                :is-required="false"
                :thumbnail-image-url="mediaFile3Thumbnail"
                :loading="mediaFile3Loading"
                :media-item-id="mediaFile3Id"
                :can-delete="true"
                :position="3"
                @click="openDeviceFileExplorer('mediaFile3Ref')"
                @delete-media-item="handleDeleteMediaItem"
            ></store-upload-file-thumbnail-item>
        </b-row>
        <b-row align-v="center" align-h="around">
            <store-upload-file-thumbnail-item
                :title="'Image 3'"
                :is-required="false"
                :thumbnail-image-url="mediaFile4Thumbnail"
                :loading="mediaFile4Loading"
                :media-item-id="mediaFile4Id"
                :can-delete="true"
                :position="4"
                @click="openDeviceFileExplorer('mediaFile4Ref')"
                @delete-media-item="handleDeleteMediaItem"
            ></store-upload-file-thumbnail-item>
            <store-upload-file-thumbnail-item
                :title="'Image 4'"
                :is-required="false"
                :thumbnail-image-url="mediaFile5Thumbnail"
                :loading="mediaFile5Loading"
                :media-item-id="mediaFile5Id"
                :can-delete="true"
                :position="5"
                @click="openDeviceFileExplorer('mediaFile5Ref')"
                @delete-media-item="handleDeleteMediaItem"
            ></store-upload-file-thumbnail-item>
            <store-upload-file-thumbnail-item
                :title="'Video'"
                :is-required="false"
                :thumbnail-image-url="mediaFile6Thumbnail"
                :loading="mediaFile6Loading"
                :media-item-id="mediaFile6Id"
                :can-delete="true"
                :position="6"
                @click="openDeviceFileExplorer('mediaFile6Ref')"
                @delete-media-item="handleDeleteMediaItem"
            ></store-upload-file-thumbnail-item>
        </b-row>
        <input
            type="file"
            id="main-file"
            ref="mainFileRef"
            @change="uploadProductFile()"
            style="display: none"
        />
        <input
            type="file"
            id="media-file-1"
            ref="mediaFile1Ref"
            @change="uploadAdditionalMedia(1)"
            style="display: none"
            accept="image/*"
        />
        <input
            type="file"
            id="media-file-2"
            ref="mediaFile2Ref"
            @change="uploadAdditionalMedia(2)"
            style="display: none"
            accept="image/*"
        />
        <input
            type="file"
            id="media-file-3"
            ref="mediaFile3Ref"
            @change="uploadAdditionalMedia(3)"
            style="display: none"
            accept="image/*"
        />
        <input
            type="file"
            id="media-file-4"
            ref="mediaFile4Ref"
            @change="uploadAdditionalMedia(4)"
            style="display: none"
            accept="image/*"
        />
        <input
            type="file"
            id="media-file-5"
            ref="mediaFile5Ref"
            @change="uploadAdditionalMedia(5)"
            style="display: none"
            accept="image/*"
        />
        <input
            type="file"
            id="media-file-6"
            ref="mediaFile6Ref"
            @change="uploadVideoMedia(6)"
            style="display: none"
            accept="video/mp4,video/x-m4v,video/*,.mkv"
        />
    </div>
</template>

<script>
import StoreUploadFileThumbnailItem from "../../ListItems/StoreUploadFileThumbnailItem";

export default {
    name: "StoreProductFileUploader",
    components: {
        StoreUploadFileThumbnailItem,
    },
    props: {
        productId: {
            type: Number,
        },
        leagueId: {
            type: Number,
        },
        product: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            mainFileThumbnail: null,
            mediaFile1Thumbnail: null,
            mediaFile2Thumbnail: null,
            mediaFile3Thumbnail: null,
            mediaFile4Thumbnail: null,
            mediaFile5Thumbnail: null,
            mediaFile6Thumbnail: null,

            // File ids to send to server when editing
            mainFileId: null,
            mediaFile1Id: null,
            mediaFile2Id: null,
            mediaFile3Id: null,
            mediaFile4Id: null,
            mediaFile5Id: null,
            mediaFile6Id: null,

            mainFileLoading: false,
            mediaFile1Loading: false,
            mediaFile2Loading: false,
            mediaFile3Loading: false,
            mediaFile4Loading: false,
            mediaFile5Loading: false,
            mediaFile6Loading: false,

            // Video chunking
            chunkSize: 10100462, // Filesize in bytes, 10mb
            currentVideoFile: null,
            currentVideoFileIndex: 0,
            currentVideoFileChunks: [],
            currentVideoFileToken: "",
        };
    },
    mounted() {
        // product will be set when editing a product.
        if (this.product) {
            this.populateFiles(this.product);
        }
    },
    methods: {
        populateFiles(product) {
            if (product.files && product.files[0]) {
                const file = product.files[0];
                this.mainFileId = file.id;
                if (file.type === "Photo") {
                    this.mainFileThumbnail = file.location;
                } else {
                    this.mainFileThumbnail = this.getThumbnailForFileGroup(
                        file.type,
                        null,
                    );
                }
            }
            if (!product.media) {
                return;
            }
            product.media.forEach((item) => {
                this[`mediaFile${item.position}Thumbnail`] =
                    item.type === "Photo"
                        ? item.location
                        : "/images/VideoThumbnail.jpg";

                this[`mediaFile${item.position}Id`] = item.id;
            });
        },
        openDeviceFileExplorer(ref) {
            this.$refs[ref].click();
        },
        getFileTypeGroupFromExtension(fileExtension) {
            if (
                ["jpg", "png", "jpeg", "jfif", "gif", "svg", "webp"].includes(
                    fileExtension,
                )
            ) {
                return "Photo";
            }
            if (fileExtension === "pdf") {
                return "PDF";
            }
            if (
                [
                    "mp4",
                    "mov",
                    "m4v",
                    "webm",
                    "mpg",
                    "wmv",
                    "mkv",
                    "ogv",
                    "matroska",
                    "asf",
                    "mpeg",
                ].includes(fileExtension)
            ) {
                return "Video";
            }
            if (["csv", "xls", "xlsx"].includes(fileExtension)) {
                return "Excel";
            }
            if (["ppt", "pptx"].includes(fileExtension)) {
                return "PowerPoint";
            }
            if (["doc", "docx", "rtf", "txt"].includes(fileExtension)) {
                return "Document";
            }
            return "";
        },
        getThumbnailForFileGroup(fileGroupType, file) {
            if (fileGroupType === "Photo") {
                return URL.createObjectURL(file);
            }
            if (fileGroupType === "PDF") {
                return "/images/PdfThumbnail.png";
            }
            if (fileGroupType === "Video") {
                return "/images/VideoThumbnail.jpg";
            }
            if (fileGroupType === "Excel") {
                return "/images/ExcelThumbnail.jpg";
            }
            if (fileGroupType === "PowerPoint") {
                return "/images/PowerPointThumbnail.png";
            }
            if (fileGroupType === "Document") {
                return "/images/GenericDocumentThumbnail.png";
            }
            return "/images/GenericDocumentThumbnail.png";
        },
        async uploadProductFile() {
            try {
                let productId = this.productId;
                let formData = new FormData();
                let file = document.getElementById("main-file").files[0];

                // If you open the file explore and close it without selecting a file
                // then file will be null. So just return as we don't want to do anything.
                // In that case.
                if (!file) {
                    return;
                }
                let description = null;
                const fileExtension = file.name.split(".").pop().toLowerCase();

                formData.append("id", this.mainFileId);
                formData.append("productId", productId.toString());
                formData.append("file", file);
                formData.append("description", description);

                this.mainFileThumbnail = null;
                this.mainFileLoading = true;
                const { data, status } = await axios.post(
                    "/store/addFile",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                );
                this.mainFileLoading = false;
                if (status == 200) {
                    this.mainFileThumbnail = this.getThumbnailForFileGroup(
                        this.getFileTypeGroupFromExtension(fileExtension),
                        file,
                    );
                    this[`mainFileId`] = data.id;
                }
            } catch (error) {
                this.productIcon = "plus";
                document.getElementById("main-file").value = null;
                await this.$swal(
                    error.response
                        ? error.response.data.message
                        : "Oops! Something went wrong. Please try again.",
                );
            }
        },
        async uploadAdditionalMedia(position) {
            try {
                this[`mediaFile${position}Thumbnail`] = null;
                this[`mediaFile${position}Loading`] = true;

                let productId = this.productId;
                let formData = new FormData();
                let file = document.getElementById(`media-file-${position}`)
                    .files[0];
                const fileExtension = file.name.split(".").pop().toLowerCase();

                formData.append("id", this[`mediaFile${position}Id`]);
                formData.append("productId", productId.toString());
                formData.append("file", file);
                formData.append("cover", position === 1 ? 1 : null);
                formData.append("position", position);

                const { data, status } = await axios.post(
                    "/store/addMedia",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                );
                this[`mediaFile${position}Loading`] = false;
                if (status == 200) {
                    this[
                        `mediaFile${position}Thumbnail`
                    ] = this.getThumbnailForFileGroup(
                        this.getFileTypeGroupFromExtension(fileExtension),
                        file,
                    );

                    // Set id in case we change the file multiple times.
                    this[`mediaFile${position}Id`] = data.id;
                }
            } catch (error) {
                console.warn("There was an error: ", error);
            }
        },
        async uploadVideoMedia(position) {
            this[`mediaFile${position}Loading`] = true;

            let file = document.getElementById(`media-file-${position}`)
                .files[0];
            await this.startChunking(file);
        },
        async completeVideoUploadOnCloseTicket(mediaId) {
            const position = 6;
            let productId = this.productId;
            let formData = new FormData();
            formData.append("id", this[`mediaFile${position}Id`]);
            formData.append("productId", productId.toString());
            formData.append("mediaId", mediaId.toString());
            formData.append("position", 6);

            this[`mediaFile${position}Thumbnail`] = null;
            const { data, status } = await axios.post(
                "/store/addVideoMedia",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            );
            this[`mediaFile${position}Loading`] = false;
            if (status == 200) {
                this[
                    `mediaFile${position}Thumbnail`
                ] = this.getThumbnailForFileGroup("Video", null);

                // Set id in case we change the file multiple times.
                this[`mediaFile${position}Id`] = data.id;
            }
        },
        async uploadVideoMedia(position) {
            if (position === 6) {
                this.mediaFile6Loading = true;
            }
            let file = document.getElementById(`media-file-${position}`)
                .files[0];
            await this.startChunking(file);
        },
        shouldBeChunked(file) {
            // To be chunked, a file should be greater than our desired chunk size
            return file.size / this.chunkSize > 1;
        },
        createChunks(file) {
            this.currentVideoFileChunks = [];
            let chunks = Math.ceil(file.size / this.chunkSize);

            for (let i = 0; i < chunks; i++) {
                this.currentVideoFileChunks.push(
                    file.slice(
                        i * this.chunkSize,
                        Math.min(
                            i * this.chunkSize + this.chunkSize,
                            file.size,
                        ),
                        file.type,
                    ),
                );
            }
        },
        async createTicket(file) {
            // When uploads need to be chunked, we need to request permission to store the file first
            // We also send the first chunk with the request, as well as some file metadata
            // We will try this (and all requests) a total of 5 times, with each failure increasing the delay 1 second.

            const firstChunkData = new FormData();
            firstChunkData.set("data", this.currentVideoFileChunks[0], "1");
            firstChunkData.append("leagueId", this.leagueId);

            let ticketResponse = null;
            let tries = 0;
            let requestSuccess = false;
            while (tries < 5 || !requestSuccess) {
                try {
                    ticketResponse = await axios({
                        url: "/media/getTicket",
                        method: "POST",
                        headers: {
                            "X-Content-Name": file.name,
                            "X-Content-Total-Length": file.size,
                            "Content-Type": "application/octet-stream",
                        },
                        data: firstChunkData,
                    });

                    if (ticketResponse.data.success) {
                        requestSuccess = true;
                        this.currentVideoFileToken = ticketResponse.data.token;
                        return true;
                    } else {
                        // We treat this as a success so we can break the loop and display an error.
                        // The loop itself is for network/server errors.
                        requestSuccess = true;
                        await this.$swal(ticketResponse.data.message);
                        this.loading = false;
                        return false;
                    }
                } catch (error) {
                    tries++;
                    if (tries === 5) {
                        // At 5 tries, display the server error and quit trying to process.
                        await this.$swal(error.response.data.message);
                        this.loading = false;
                        return false;
                    }
                }
            }
        },
        async sendChunk(index) {
            const chunkData = new FormData();
            chunkData.set(
                "data",
                this.currentVideoFileChunks[index],
                index + 1,
            );
            let tries = 0;
            let requestSuccess = false;
            while (tries < 5 && !requestSuccess) {
                try {
                    const uploadChunkResponse = await axios({
                        url: "/media/chunk",
                        method: "POST",
                        headers: {
                            "X-Media-Token": this.currentVideoFileToken,
                            "X-Media-Chunk-Id": index + 1,
                            "X-Content-Name": this.currentVideoFile.name,
                            "X-Content-Total-Length": this.currentVideoFile
                                .size,
                            "Content-Type": "application/octet-stream",
                        },
                        data: chunkData,
                    });

                    if (uploadChunkResponse.data.success) {
                        requestSuccess = true;
                        return true;
                    } else {
                        requestSuccess = true;
                        await this.$swal(uploadChunkResponse.data.message);
                        this.loading = false;
                        return false;
                    }
                } catch (error) {
                    tries++;
                    if (tries === 5) {
                        // At 5 tries, display the server error and quit trying to process.
                        await this.$swal(error.response.data.message);
                        this.loading = false;
                        return false;
                    }
                }
            }
            return false;
        },
        async closeTicket() {
            let tries = 0;
            let closeTicketSuccess = false;
            while (tries < 5 && !closeTicketSuccess) {
                try {
                    const { data, status } = await axios({
                        url: "/media/closeTicket",
                        method: "POST",
                        headers: {
                            "X-Media-Token": this.currentVideoFileToken,
                        },
                    });
                    closeTicketSuccess = true;
                    if (status == 200) {
                        this.completeVideoUploadOnCloseTicket(data.media.id);
                    }
                } catch (error) {
                    await this.$swal(error.response.data.message);
                }
            }
        },
        async startChunking(file) {
            this.currentVideoFile = file;
            this.createChunks(file);

            if (await this.createTicket(file)) {
                let allChunksUploaded = true;
                for (let i = 1; i < this.currentVideoFileChunks.length; i++) {
                    const chunkResult = await this.sendChunk(i);
                    if (chunkResult === false) {
                        allChunksUploaded = false;
                        break;
                    }
                }

                // Once we have uploaded all the chunks, close the ticket and convert to media
                if (allChunksUploaded) {
                    return await this.closeTicket();
                }
            }
        },
        async handleDeleteMediaItem(id, position) {
            const { data, status } = await axios.post("/store/deleteMedia", {
                mediaId: id,
                position: position,
            });

            if (status == 200) {
                this[`mediaFile${position}Thumbnail`] = null;
                this[`mediaFile${position}Id`] = null;
                await this.$swal("Success!", "Product deleted", "success");
            }
        },
    },
};
</script>

<style scoped lang="scss"></style>
