<template>
    <div>
        <b-form @submit.prevent="submitForm">
            <user-profile-header
                small
                editable
                :banner-image="bannerImage"
                :profile-photo="avatarImage"
                @editBanner="openMediaModal('banner')"
                @editProfile="openMediaModal('profile')"
                :user-id="user.id"
            ></user-profile-header>
            <b-form-text class="mb-5 mt-md-n3 text-center"
                >Please Choose a Banner and Profile Photo</b-form-text
            >
            Please Choose a Banner and Profile Photo
            <div
                v-if="successMessage"
                class="text-sm text-red font-weight-bold text-center mb"
            >
                Click on submit button below to save.
            </div>

            <!--
                :modal-open.sync="showMediaModal"
             -->
            <media-modal-inline
                v-if="toggleMediaModal"
                :modal-open.sync="showMediaModal"
                :user-id="user.id"
                type="images"
                @media-chosen="setMedia"
                :media-chosen="mediaKey"
                :open-input-file="openInputFile"
            ></media-modal-inline>

            <b-form-row>
                <b-col>
                    <b-form-group label="First Name" label-for="name-input">
                        <b-form-input
                            id="first-name-input"
                            type="text"
                            v-model.trim="form.firstName"
                            :state="validateInput('firstName')"
                            @change="$v.form.firstName.$touch()"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Last Name" label-for="name-input">
                        <b-form-input
                            id="last-name-input"
                            type="text"
                            v-model.trim="form.lastName"
                            :state="validateInput('lastName')"
                            @change="$v.form.lastName.$touch()"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-group label="Birthday" label-for="birthday-input">
                <b-form-datepicker
                    id="birthday-input"
                    v-model.trim="form.birthDate"
                    :state="validateInput('birthDate')"
                    @change="$v.form.birthDate.$touch()"
                    show-decade-nav
                    no-flip
                    :max="maxDate"
                ></b-form-datepicker>
            </b-form-group>
            <b-form-group label="Email" label-for="email-input">
                <b-form-input
                    id="email-input"
                    type="text"
                    v-model.trim="form.email"
                    :state="validateInput('email')"
                    @change="$v.form.email.$touch()"
                ></b-form-input>
            </b-form-group>
            <b-form-group label="Phone" label-for="phone-input">
                <b-form-input
                    id="phone-input"
                    type="text"
                    :state="validateInput('phone')"
                    v-mask="'###-###-####'"
                    v-model.trim="form.phone"
                ></b-form-input>
            </b-form-group>
            <b-form-group label="About you" label-for="description-input">
                <b-form-textarea
                    id="description-input"
                    rows="4"
                    v-model.trim="form.about"
                ></b-form-textarea>
            </b-form-group>
            <submit-button :loading="loading" class="mt-5"></submit-button>
            <b-form-text
                v-show="saved"
                text-variant="success"
                class="h6 text-center mt-3"
                >Saved!</b-form-text
            >
        </b-form>
        <media-modal
            :modal-open.sync="showMediaModal"
            :user-id="user.id"
            type="images"
            @media-chosen="setMedia"
        ></media-modal>
    </div>
</template>

<script>
import MediaModalInline from "../../Modals/MediaModalInline";
import SubmitButton from "../../Buttons/SubmitButton";
import UserProfileHeader from "../../Headers/UserProfileHeader";
import { email, minLength, required, sameAs } from "vuelidate/lib/validators";

export default {
    name: "UserInfoForm",
    components: {
        UserProfileHeader,
        SubmitButton,
        MediaModalInline,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
        notificationSettings: {
            type: Array,
            required: true,
        },
    },
    data() {
        const maxDate = new Date();
        maxDate.setFullYear(maxDate.getFullYear() - 18);
        return {
            successMessage: false,
            toggleMediaModal: false,
            loading: false,
            saved: false,
            showMediaModal: false,
            mediaKey: null, // Needed to differentiate between photo uploads
            form: {
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                about: "",
                birthDate: null,
                avatar: null,
                banner: null,
            },
            bannerImage: null,
            avatarImage: null,
            maxDate,
        };
    },
    validations() {
        if (this.hasSmsNotif) {
            return {
                form: {
                    firstName: { required },
                    lastName: { required },
                    email: { required, email },
                    birthDate: { required },
                    phone: { required }
                },
            };
        } else {
            return {
                form: {
                    firstName: { required },
                    lastName: { required },
                    email: { required, email },
                    birthDate: { required },
                },
            };
        }
    },
    mounted() {
        this.setForm();
    },
    computed: {
        hasSmsNotif() {
            return this.notificationSettings.some((notifsetting) => {
                return notifsetting.sms == 1;
            });
        },
        submitButtonText() {
            return this.league ? "Submit" : "Next";
        },
        /* Validation Errors */
        firstNameErrors() {
            const errors = [];
            if (!this.$v.form.firstName.$dirty) return errors;
            !this.$v.form.firstName.required &&
                errors.push("First Name is required");
            return errors;
        },
        lastNameErrors() {
            const errors = [];
            if (!this.$v.form.lastName.$dirty) return errors;
            !this.$v.form.lastName.required &&
                errors.push("Last Name is required");
        },
        emailErrors() {
            const errors = [];
            if (!this.$v.form.email.$dirty) return errors;
            !this.$v.form.email.required && errors.push("Email is required");
            !this.$v.form.email.email &&
                errors.push("Should be an Email Address");
            return errors;
        },
    },
    methods: {
        checkPhoneError(){
            if(this.$v.form.phone == undefined) return false
            if(this.$v.form.phone.$anyError){
                this.$swal('Phone number is required if SMS Notifications are checked');
            }
        },
        validateInput(name) {
            if (name == "phone" && this.$v.form[name] == undefined) {
                return null;
            }
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        setForm() {
            this.form.firstName = this.user.firstName;
            this.form.lastName = this.user.lastName;
            this.form.email = this.user.email;
            this.form.phone = this.user.phone;
            this.form.about = this.user.about;
            this.form.birthDate = this.user.birthDate;
            this.form.banner = this.user.banner ? this.user.banner.id : null;
            this.form.avatar = this.user.avatar ? this.user.avatar.id : null;

            this.bannerImage = this.user.banner ? this.user.banner.file : null;
            this.avatarImage = this.user.avatar ? this.user.avatar.file : null;
        },
        openMediaModal(formKey) {
            this.mediaKey = formKey;
            this.showMediaModal = true;
        },
        setMedia(media) {
            if (this.mediaKey === "banner") {
                this.form.banner = media.id;
                this.bannerImage = media.file;
            } else {
                this.form.avatar = media.id;
                this.avatarImage = media.file;
            }
        },
        async submitForm() {
            this.$v.form.$touch();
            this.checkPhoneError()


            if (!this.$v.form.$anyError) {
                this.loading = true;

                try {
                    // Attempt to update user info
                    const response = await axios.post(
                        `/settings/update`,
                        this.form,
                    );
                    this.loading = false;
                    this.saved = true;
                } catch (error) {
                    // If there is an error for some reason, show alert
                    await this.$swal(error.response.data.message);
                    this.loading = false;
                }
            }
        },
    },
};
</script>
