<template>
    <b-card @click="$emit('click')" class="drop-shadow">
        <b-media class="align-items-center">
            <template v-slot:aside>
                <b-avatar
                    variant="grey"
                    :src="friend.user.avatar ? friend.user.avatar.file : null"
                    square
                    size="100px"
                ></b-avatar>
            </template>
            <div>
                <h5 class="mb-0">
                    {{ friend.user.firstName }} {{ friend.user.lastName }}
                </h5>
                <p class="small text-muted mb-0">{{ friendStatus }}</p>
                <div v-if="editable" class="d-flex flex-column mt-2">
                    <b-link
                        v-if="showRemove"
                        @click.stop="$emit('remove-friend', friend.id)"
                        class="ml-0"
                        >{{ removeText }}</b-link
                    >
                    <b-link
                        v-if="showAccept"
                        @click.stop="$emit('accept-friend', friend.id)"
                        class="ml-0"
                        >Accept Request</b-link
                    >
                    <b-link
                        v-if="showReject"
                        @click.stop="$emit('reject-friend', friend.id)"
                        class="ml-0"
                        >Reject Request</b-link
                    >
                    <b-link
                        v-if="showRemoveBlock"
                        @click.stop="$emit('unblock-friend', friend.id)"
                        class="ml-0"
                        >Remove Block</b-link
                    >
                </div>
            </div>
        </b-media>
    </b-card>
</template>

<script>
export default {
    name: "FriendCard",
    props: {
        friend: {
            type: Object,
            required: true,
        },
        editable: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        friendStatus() {
            switch (this.friend.status) {
                case 1: // Pending
                    return "Request Pending";
                case 2: // Accepted
                    return "Friends";
                case 3: // Rejected
                    return "Request Rejected";
                case 4: // Blocked
                    return "Blocked";
                default:
                    return "Not Friends";
            }
        },
        removeText() {
            return this.friend.status === 1 && this.friend.initiated
                ? "Cancel Request"
                : "Remove Friend";
        },
        showReject() {
            return this.friend.status === 1 && !this.friend.initiated;
        },
        showAccept() {
            return this.friend.status === 1 && !this.friend.initiated;
        },
        showRemove() {
            return (
                (this.friend.status === 1 && this.friend.initiated) ||
                this.friend.status === 2
            );
        },
        showRemoveBlock() {
            return this.friend.status === 4 && this.friend.initiated;
        },
    },
};
</script>

<style scoped></style>
