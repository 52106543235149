<template>
    <b-row id="league-list" >
        <b-col
            cols="12"
            md="6"
            lg="4"
            v-for="league in subLeagues"
            :key="league.id"
            v-show="subLeagues.length > 0"
        >
            <league-card
                :league="league"
                :user-id="(userId === null ? null : userId)"
                :auth-id="(authUser === null ? null : authUser.id)"
                :is-owner="league.owner"
                class="my-3"
                small
                @click="routeToLeague"
            ></league-card>
        </b-col>
        <b-col v-show="subLeagues.length === 0" class="text-center my-5">
            <span class="h5 text-muted"> {{ league.name }} does not have any sub leagues</span>
        </b-col>
    </b-row>
</template>

<script>
import LeagueCard from "../Cards/LeagueCard";
import LeagueMembersFormVue from '../Forms/LeagueForms/LeagueMembersForm.vue';

export default {
    name: "SubLeagueList",
    components: { LeagueCard },
    props: {
        userId: {
            type: Number,
            required: false,
        },
        authUser: {
            type: Object,
            required: false,
        },
        league: {
            type: Object,
        }
    },
    data() {
        return {
            loading: true,
            perPage: 12,
            currentPage: 1,
            subLeagues: [],
            totalLeagues: 0,
        };
    },
    mounted() {
        this.getSubLeagues();
    },
    methods: {
        async getSubLeagues() {
            this.loading = true;
            try {
                const response = await axios.get(`/leagues/sub-leagues/get`, {
                    params: { parentLeagueId: this.league.id },
                });
                this.subLeagues = response.data.subLeagues;
                this.loading = false;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
        routeToLeague(league) {
            window.location.href = `/${league.slug}`;
        },
    },
};
</script>

<style scoped></style>
