<template>
    <div>
        <b-form @submit.prevent="submitForm">
            <b-form-group label-for="name-input">
                <v-select
                    label="name"
                    v-model="form.user"
                    :options="users"
                    @search="getUsers"
                    @input="selectUser"
                    :placeholder="'Who would you like to message?'"
                    :create-option="(name) => ({ name: name, email: null })"
                    :class="
                        validateInput('user') === false
                            ? 'v-select-error'
                            : null
                    "
                >
                    <template #option="{ id, name, avatar }">
                        <b-media class="align-items-center">
                            <template v-slot:aside>
                                <b-avatar
                                    v-if="id > 0"
                                    :src="avatar"
                                    variant="grey"
                                ></b-avatar>
                            </template>
                            <span>{{ name }}</span>
                        </b-media>
                    </template>
                </v-select>
                <invalid-feedback :errors="nameErrors"></invalid-feedback>
            </b-form-group>
        </b-form>
    </div>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import InvalidFeedback from "../FormComponents/InvalidFeedback";

export default {
    name: "UserSearchForm",
    components: { InvalidFeedback },
    props: {
        searchUrl: {
            type: String,
            default: "leagues/searchUsers",
        },
        label: {
            default: "Compose a new message",
        },
    },
    data() {
        return {
            users: [],
            form: {
                user: null,
            },
        };
    },
    validations: {
        form: {
            user: { required },
            email: {
                email: requiredIf(function () {
                    return this.form.user && this.form.user.email === null;
                }),
            },
        },
    },
    // watch: {
    //     'form.user': function() {
    //         console.log(this.form.user);
    //         console.log("Attempting to open a chat with this user");
    //     }
    // },
    computed: {
        showEmailInput() {
            if (this.form.user) {
                return this.form.user.email === null;
            }
            return false;
        },
        /* Validation Errors */
        nameErrors() {
            const errors = [];
            if (!this.$v.form.user.$dirty) return errors;
            !this.$v.form.user.required && errors.push("User is required");
            return errors;
        },
        emailErrors() {
            const errors = [];
            if (!this.$v.form.email.$dirty) return errors;
            !this.$v.form.email.email &&
                errors.push("Please provide a valid email address");
            return errors;
        },
    },
    methods: {
        async selectUser() {
            // Prevent creating of a null chat upon deselection
            if (this.form.user !== null) {
                this.$emit("user-chosen", { ...this.form.user });
            }
        },
        async getUsers(search, loading) {
            if (search.length > 0) {
                loading(true);
                const response = await axios.post(this.searchUrl, {
                    search: search,
                });
                this.users = response.data.users;
                loading(false);
            }
        },
        validateInput(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        resetForm() {
            this.form = {
                user: null,
                email: "",
            };

            this.$v.form.$reset();
        },
        async submitForm() {
            this.$v.form.$touch();

            if (!this.$v.form.$anyError) {
                const member = this.form.user;

                if (member.email === null) {
                    member.email = this.form.email;
                }

                this.$emit("user-chosen", member);
                this.resetForm();
            }
        },
    },
};
</script>

<style scoped></style>
