<template>
  <div>
    <b-row>
      <b-col v-for="plan in plans" :key="plan.id" cols="12" md="6" class="my-3">
        <plan-card
            :plan="plan"
            :active="plan.id === selectedPlan"
            @plan-selected="$emit('plan-selected', plan.id)">
        </plan-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import PlanCard from "../Cards/PlanCard";
  export default {
    name: "UserPlanList",
    components: {PlanCard},
    props: {
      plans: {
        type: Array,
        required: true
      },
      selectedPlan: {
        type: String
      }
    }
  }
</script>

<style scoped>

</style>
