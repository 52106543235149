<template>
    <b-modal
        id="media-item-comments-modal"
        modal-class="media-item-comments-modal"
        v-model="open"
        size="full"
        centered
        hide-footer
    >
        <template v-slot:modal-header="{ close }">
            <!-- Emulate built in modal header close button action -->
            <b-button
                size="sm"
                variant="clear"
                @click="close()"
                class="ml-auto"
            >
                <i class="fas fa-arrow-left fa-lg"></i>
            </b-button>
            <div class="w-100"></div>
        </template>
        <div class="modal-body-top">
            <!-- Avatar rounded  -->
            <b-row no-gutters>
                <b-col
                    cols="12"
                    class="d-flex justify-content-between align-items-start"
                >
                    <b-media no-body class="d-flex align-items-center">
                        <b-media-aside vertical-align="center">
                            <b-avatar
                                :src="mediaItem.posted_by.avatar"
                                variant="grey"
                                size="45"
                            ></b-avatar>
                        </b-media-aside>
                        <b-media-body class="d-flex flex-column ml-3">
                            <a
                                v-if="
                                    showLeague &&
                                    mediaItem.posted_by.type !== 'league'
                                "
                                class="small text-grey d-lg-none"
                                :href="`/${mediaItem.leagueSlug}`"
                                >{{ mediaItem.leagueName }}</a
                            >
                            <a
                                class="h6 font-weight-bold text-black mb-0"
                                :href="
                                    mediaItem.posted_by.type === 'league'
                                        ? `/${mediaItem.posted_by.slug}`
                                        : `/u/${mediaItem.posted_by.id}`
                                "
                            >
                                {{ mediaItem.posted_by.name }}
                            </a>
                            <small class="text-muted mb-0">{{
                                mediaItem.created_at | moment("from")
                            }}</small>
                        </b-media-body>
                    </b-media>
                    <div class="d-flex align-items-center">
                        <div
                            v-if="
                                showLeague &&
                                mediaItem.posted_by.type !== 'league'
                            "
                            class="d-none d-lg-block"
                        >
                            <a
                                class="h5 text-grey"
                                :href="`/${mediaItem.leagueSlug}`"
                                >{{ mediaItem.leagueName }}</a
                            >
                        </div>
                        <b-btn
                            v-if="!showLeague"
                            variant="ghost-purple"
                            v-b-tooltip.hover
                            title="Pin"
                            :class="pinned ? 'active' : null"
                            @click="togglePin"
                        >
                            <i class="fas fa-thumbtack"></i>
                        </b-btn>
                    </div>
                </b-col>
            </b-row>
            <div class="aspect-ratio-wrapper">

                <div class="image-wrapper" v-show="[0].indexOf(mediaItem.type) > -1">
                    <img
                        class="image"
                        v-bind:src="`${mediaItem.photo}`"
                        @click="joinLeague"
                    />
                    <div class="nav-prev">
                        <b-button
                            variant="clear"
                            @click="navigatePrev()"
                        >
                        <i class="fas fa-arrow-circle-left fa-lg"></i>
                        </b-button>
                    </div>
                    <div class="nav-next">
                        <b-button
                            variant="clear"
                            @click="navigateNext()"
                        >
                            <i class="fas fa-arrow-circle-right fa-lg"></i>
                        </b-button>
                    </div>
                </div>

                <div
                    class="video-wrapper modal-video-wrapper"
                    v-if="[1, 5].indexOf(mediaItem.type) > -1 && isVideoLoaded"
                >
                    <video
                        id="modal-video-player"
                        width="100%"
                        height="100%"
                        playsinline
                        controls
                        @click="joinLeague"
                        class="video-js vjs-16-9 vjs-big-play-centered"
                    >

                        <source
                            v-if="
                                mediaItem.video
                                    .split('.')
                                    .slice(-1)[0]
                                    .toLowerCase() === 'mp4'
                            "
                            :src="mediaItem.video"
                            type="video/mp4"
                        />
                        <source
                            v-if="
                                mediaItem.video
                                    .split('.')
                                    .slice(-1)[0]
                                    .toLowerCase() === 'webm'
                            "
                            :src="mediaItem.video"
                            type="video/webm"
                        />
                        <source
                            v-if="
                                mediaItem.video
                                    .split('.')
                                    .slice(-1)[0]
                                    .toLowerCase() === 'ogg'
                            "
                            :src="mediaItem.video"
                            type="video/ogg"
                        />
                        <source
                            v-if="
                                mediaItem.video
                                    .split('.')
                                    .slice(-1)[0]
                                    .toLowerCase() === 'm3u8'
                            "
                            :src="mediaItem.video"
                            type="application/x-mpegURL"
                        />
                    </video>
                    <div class="nav-prev">
                        <b-button
                            variant="clear"
                            @click="navigatePrev()"
                        >
                            <i class="fas fa-arrow-circle-left fa-lg"></i>
                        </b-button>
                    </div>
                    <div class="nav-next">
                        <b-button
                            variant="clear"
                            @click="navigateNext()"
                        >
                            <i class="fas fa-arrow-circle-right fa-lg"></i>
                        </b-button>
                    </div>
                </div>
                <div class="aspect-post-wrapper post-wrapper" v-if="mediaItem.type == 2">
                    <div
                        class="mb-0 mt-3"
                        v-html="description"
                        :class="{ 'px-5': true }"
                    ></div>

                    <div class="nav-prev" v-if="[2].indexOf(mediaItem.type) > -1">
                        <b-button variant="clear" @click="navigatePrev()">
                            <i class="fas fa-arrow-circle-left fa-lg"></i>
                        </b-button>
                    </div>
                    <div class="nav-next" v-if="[2].indexOf(mediaItem.type) > -1">
                        <b-button variant="clear" @click="navigateNext()">
                            <i class="fas fa-arrow-circle-right fa-lg"></i>
                        </b-button>
                    </div>
                </div>
            </div>

        </div>
        <div class="post-wrapper" v-if="mediaItem.type != 3 && mediaItem.type != 2">
            <div
                class="mb-0 mt-3"
                v-html="description"
                :class="{ 'px-5': true }"
            ></div>

            <div class="nav-prev" v-if="[2].indexOf(mediaItem.type) > -1">
                <b-button variant="clear" @click="navigatePrev()">
                    <i class="fas fa-arrow-circle-left fa-lg"></i>
                </b-button>
            </div>
            <div class="nav-next" v-if="[2].indexOf(mediaItem.type) > -1">
                <b-button variant="clear" @click="navigateNext()">
                    <i class="fas fa-arrow-circle-right fa-lg"></i>
                </b-button>
            </div>
        </div>
        <!-- Show More -->
        <div v-show="isLongPost && !postTextShowMore" class="text-center mb-2">
            <b-btn variant="link" @click="handlePostTextShowMore(true)"
                >Show More</b-btn
            >
        </div>
        <!-- Show Less -->
        <div v-show="isLongPost && postTextShowMore" class="text-center mb-2">
            <b-btn variant="link" @click="handlePostTextShowMore(false)"
                >Show Less</b-btn
            >
        </div>
        <!-- Comment Count -->
        <div v-if="mediaItem.permissions.member" class="modal-body-bottom">
            <div
                class="d-flex justify-content-between px-3 py-2 text-grey bg-black"
            >
                <reaction-counter
                    :reaction-types="reactions"
                    :counts="reactionCounts"
                ></reaction-counter>
                <span>{{ mediaItem.commentCount }} Comments</span>
            </div>
            <post-action-list
                :post="mediaItem"
                :reactions="reactions"
                :reaction-id="reactionId"
                @react-to-post="reactToPost"
                @share-post="sharePost"
                @show-comments="showComments = true"
            ></post-action-list>
            <comments-list
                :show-comment-form="showComments"
                parent-type="post"
                :parent-item="mediaItem"
                :commentable-type="commentableType"
                :reactions="reactions"
                :auth-user="authUser"
                :league-id="mediaItem.leagueId"
                @comment-added="mediaItem.commentCount++"
                @comment-removed="mediaItem.commentCount--"
            ></comments-list>
        </div>
        <join-league-modal
            v-if="showJoin"
            :modal-open.sync="joinModalOpen"
            :price="mediaItem.leaguePrice"
            :league-id="mediaItem.leagueId"
            :league-trial="mediaItem.leagueTrial"
        ></join-league-modal>
    </b-modal>
</template>

<script>
import MediaItemCommentsCard from "../Cards/MediaItemCommentsCard";
import CommentsList from "../Lists/CommentsList";
import JoinLeagueModal from "../Modals/JoinLeagueModal";
import CreateBurstModal from "../Modals/CreateBurstModal";
import videojs from "video.js";
import videojsqualityselector from "videojs-hls-quality-selector";
require("videojs-contrib-quality-levels");
require("videojs-hls-quality-selector");
export default {
    name: "MediaItemCommentsModal",
    components: {
        MediaItemCommentsCard,
        CommentsList,
        JoinLeagueModal,
        CreateBurstModal,
    },
    props: {
        modalOpen: Boolean,
        mediaItem: Object,
        currentMediaItemIndex: Number,
    },
    data() {
        return {
            postEditable: true,
            showLeague: true,
            pinned: false,
            showComments: true,
            reactions: [],
            authUser: {},
            postTextShowMore: false,
            truncatePostChars: 250,
            reactionId: null,
            joinModalOpen: false,
            showRepliesGridView: false,
            videoPlayer: null,
            isVideoLoaded: true
        };
    },
    mounted() {
        this.reactionId = this.mediaItem.reaction;
        this.getReactionList();
    },
    computed: {
        commentableType() {
            switch (this.mediaItem.type) {
                case 2:
                    return "post";
                case 5:
                    return "post";
                default:
                    return "media";
            }
        },
        showJoin() {
            return (
                (!this.mediaItem.permissions.member && this.showLeague) ||
                this.joinModalOpen
            );
        },
        open: {
            get() {
                return this.modalOpen;
            },
            set(value) {
                this.$emit("update:modalOpen", value);
            },
        },
        reactionCounts() {
            if (this.mediaItem) {
                return {
                    1: this.mediaItem.like_count,
                    2: this.mediaItem.love_count,
                };
            }
            return null;
        },
        isLongPost() {
            return this.mediaItem.description.length > this.truncatePostChars;
        },
        description() {
            const nChars = this.truncatePostChars;
            if (
                this.mediaItem.description.length > nChars &&
                !this.postTextShowMore
            ) {
                return `${this.mediaItem.description.substring(0, nChars)}...`;
            }
            return this.mediaItem.description;
        },
    },
    watch: {
        open: function (val, oldVal) {
            if (val === true) {
                setTimeout(() => {
                    if ([1, 5].indexOf(this.mediaItem.type) > -1) {
                        this.createVideoPlayerInstance((vp) => {
                            vp.src(this.mediaItem.video);
                            const type = vp.currentType();
                            if (type === "application/x-mpegURL") {
                               this.loadQualitySelector(vp)
                            }
                        });
                    }
                }, 250);

            }
        },
    },
    methods: {
        createVideoPlayerInstance(cb) {
            setTimeout(() => {
                const videoElm = document.getElementById("modal-video-player");
                if (videoElm) {
                    const player = videojs(videoElm);
                    this.videoPlayer = player;
                    cb(this.videoPlayer);
                }
            }, 250);
        },
        closeModal() {
            this.$bvModal.hide("media-item-comments-modal");
            this.open = false;
        },
        async navigatePrev() {

            await this.reRenderVideoJs();

            this.$emit("navigate-previous", {
                mediaItem: this.mediaItem,
                currentMediaItemIndex: this.currentMediaItemIndex,
            });
            setTimeout(() => {

                if ([1, 5].indexOf(this.mediaItem.type) > -1) {
                    this.createVideoPlayerInstance((vp) => {
                        vp.src(this.mediaItem.video);
                        const type = vp.currentType();
                        if(type === "application/x-mpegURL"){
                            this.loadQualitySelector(vp)
                        }
                    });
                }
            }, 250);

        },
        async navigateNext() {

            await this.reRenderVideoJs();

            this.$emit("navigate-next", {
                mediaItem: this.mediaItem,
                currentMediaItemIndex: this.currentMediaItemIndex,
            });

            setTimeout(() => {

                if ([1, 5].indexOf(this.mediaItem.type) > -1) {
                    this.createVideoPlayerInstance((vp) => {
                        vp.src(this.mediaItem.video);
                        const type = vp.currentType();
                        if(type === "application/x-mpegURL"){
                            this.loadQualitySelector(vp)
                        }
                    });
               }
            }, 250);

        },

        async reRenderVideoJs(){

            //got prob with v-if rerendering before videoPlayer is created, not the best solution.

            //dispose video player element
            const videoElm = document.getElementById("modal-video-player");
            if (videoElm) {
                videojs(videoElm).dispose()
            }

            this.isVideoLoaded = false // retrigger v-if in dom
            await this.$nextTick() //wait for dom changes
            this.isVideoLoaded = true // retrigger v-if in dom
            await this.$nextTick() //wait for dom changes

        },

        loadQualitySelector(vp){
            vp.hlsQualitySelector = videojsqualityselector
            vp.hlsQualitySelector()
        },
        async getReactionList() {
            try {
                const response = await axios.post("/leagues/getReactionList");
                this.reactions = response.data;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
        sharePost(type) {
            this.$emit("share-post", type);
        },
        async reactToPost(id) {
            try {
                const data = {
                    leagueId: this.mediaItem.leagueId,
                    reaction: id,
                    reactionableId:
                        this.mediaItem.type === 2
                            ? this.mediaItem.postId
                            : this.mediaItem.mediaId,
                    reactionableType:
                        this.mediaItem.type === 2 ? "post" : "media",
                };
                const response = await axios.post("/leagues/reactPost", data);
                this.reactionId = response.data.reaction;
                this.$emit("reaction-change", {
                    like_count: response.data.like_count,
                    love_count: response.data.love_count,
                });
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
        handlePostTextShowMore(showMore) {
            this.postTextShowMore = showMore;
        },
        joinLeague() {
            if (this.mediaItem.preview) {
                if (this.mediaItem.leaguePrice !== "0.00") {
                    this.joinModalOpen = true;
                } else {
                    this.joinFreeLeague();
                }
            }
        },
        async joinFreeLeague() {
            try {
                const response = await axios.get(`/leagues/join`, {
                    params: { leagueId: this.mediaItem.leagueId },
                });
                window.location.reload();
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
    },
};
</script>
<style scoped lang="scss">
div#media-item-comments-modal {
    div.modal-dialog {
        margin: 0px; //Fixes left side gap
    }
}
.image-wrapper,
.video-wrapper,
.burst-wrapper {
    cursor: pointer;
    margin-top: 10px;
    position: relative;
    img.image {
        max-width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    video {
        width: 100%;
    }
    .nav-prev {
        position: absolute;
        top: 10px;
        left: 3px;
        .btn {
            background-color: black;
            .fas {
                color: white;
            }
        }
    }
    .nav-next {
        position: absolute;
        top: 10px;
        right: 3px;
        .btn {
            background-color: black;
            .fas {
                color: white;
            }
        }
    }
}
.post-wrapper {
    position: relative;
    min-height: 100px;
    .nav-prev {
        position: absolute;
        position: absolute;
        top: 10px;
        left: 3px;
        .btn {
            background-color: black;
            .fas {
                color: white;
            }
        }
    }
    .nav-next {
        position: absolute;
        top: 10px;
        right: 3px;
        .btn {
            background-color: black;
            .fas {
                color: white;
            }
        }
    }
}
.aspect-ratio-wrapper{
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
}

.image-wrapper{
    background-color: black;
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
}
.video-wrapper, .aspect-post-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

</style>
