<template>
    <div>
        <b-row v-if="members.length > 0">
            <b-col
                v-for="member in members"
                :key="member.id"
                cols="12"
                md="6"
                xl="4"
            >
                <user-card
                    :user="member"
                    remove-text="Remove Member"
                    class="clickable my-3"
                    :editable="editable && member.id !== authId"
                    @remove-user="openRemoveModal(member)"
                    @make-admin="setAdmin(member)"
                    @remove-admin="removeAdmin(member)"
                    @click="routeToUser(member.id)"
                ></user-card>
            </b-col>
        </b-row>
        <div v-else class="text-center my-5">
            <span class="h6 text-muted">No Members</span>
        </div>
        <b-pagination
            v-if="totalMembers > perPage"
            v-model="currentPage"
            :total-rows="totalMembers"
            :per-page="perPage"
            aria-controls="media-list"
            align="center"
            class="my-3"
        ></b-pagination>
        <remove-user-modal
            v-if="editable"
            :modal-open.sync="removeModalOpen"
            :user-name="activeMember.name"
            @remove-user="removeMember(activeMember)"
        >
        </remove-user-modal>
    </div>
</template>

<script>
import UserCard from "../Cards/UserCard";
import RemoveUserModal from "../Modals/removeUserModal";
export default {
    name: "MembersList",
    components: { RemoveUserModal, UserCard },
    props: {
        leagueId: {
            type: Number,
            required: true,
        },
        authId: {
            type: Number,
            required: true,
        },
        perPage: {
            type: Number,
            default: 15,
        },
        editable: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            currentPage: 1,
            members: [],
            totalMembers: 0,
            activeMember: {},
            removeModalOpen: false,
        };
    },
    mounted() {
        this.getMembers();
    },
    watch: {
        currentPage: function () {
            this.getMembers();
        },
    },
    methods: {
        async getMembers() {
            const data = {
                leagueId: this.leagueId,
                per_page: this.perPage,
                page: this.currentPage,
            };

            try {
                const response = await axios.post("/leagues/members", data);

                this.members = response.data.members;
                this.totalMembers = response.data.member_count;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
        openRemoveModal(member) {
            this.activeMember = member;
            this.removeModalOpen = true;
        },
        async removeMember() {
            const requestData = {
                leagueId: this.leagueId,
                userId: this.activeMember.id,
            };

            try {
                const response = await axios.post(
                    "/leagues/remove",
                    requestData
                );
                await this.getMembers();
                this.removeModalOpen = false;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
        async setAdmin(member) {
            const requestData = {
                leagueId: this.leagueId,
                userId: member.id,
            };

            try {
                const response = await axios.post(
                    "/leagues/members/addAdmin",
                    requestData
                );
                await this.getMembers();
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
        async removeAdmin(member) {
            const requestData = {
                leagueId: this.leagueId,
                userId: member.id,
            };

            try {
                const response = await axios.post(
                    "/leagues/members/removeAdmin",
                    requestData
                );
                await this.getMembers();
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
        routeToUser(id) {
            window.location.href = `/u/${id}`;
        },
    },
};
</script>

<style scoped></style>
