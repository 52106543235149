var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-list-group-item',{staticClass:"conversation-item",attrs:{"active-class":"active","active":_vm.active},on:{"click":function($event){return _vm.$emit('click')}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"auto"}},[(_vm.conversation.participants.length > 1)?_c('b-avatar-group',{attrs:{"variant":"grey","size":"50","overlap":1 - 1 / _vm.conversation.participants.length}},_vm._l((_vm.conversation.participants),function(participant,index){return _c('b-avatar',{key:participant.id,attrs:{"badge":index === _vm.conversation.participants.length - 1
                            ? _vm.unreadCount
                            : false,"badge-top":"","badge-variant":"danger"}})}),1):_c('b-avatar',{attrs:{"variant":"grey","size":"50","src":_vm.conversation.participants[0] && _vm.conversation.participants[0].avatar
                        ? _vm.conversation.participants[0].avatar.file
                        : null,"badge":_vm.unreadCount,"badge-top":"","badge-variant":"danger"}})],1),_vm._v(" "),_c('b-col',{staticClass:"details-group"},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.participantNames))]),_vm._v(" "),_c('small',{staticClass:"text-muted ml-2"},[_vm._v(_vm._s(_vm.lastMessageDate))])]),_vm._v(" "),(_vm.conversation.lastMessage != null)?_c('div',{staticClass:"last-message"},[_vm._v("\n                "+_vm._s(_vm.conversation.lastMessage.message.length > 70
                        ? _vm.conversation.lastMessage.message.substr(0, 70) +
                          "..."
                        : _vm.conversation.lastMessage.message)+"\n            ")]):_vm._e()]),_vm._v(" "),_c('b-col',{staticClass:"end-group",attrs:{"cols":"auto"}},[(_vm.listType != 'deleted')?_c('b-btn',{staticClass:"p-2",attrs:{"variant":"link","disabled":_vm.loading},on:{"click":function($event){return _vm.leaveConversation($event)}}},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"fas fa-times"}),_vm._v(" "),_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"small":""}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }