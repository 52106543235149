<template>
    <b-modal
        id="add-payment-method-modal"
        v-model="open"
        centered
        hide-footer
        hide-header
    >
        <b-container class="p-4">
            <div v-show="state === 'form'">
                <stripe-payment-form
                    title="Add Payment Method"
                    @stripe-submitted="addPaymentMethod"
                ></stripe-payment-form>
            </div>
            <div v-show="state === 'error'">
                <h2 class="font-weight-bold my-4">Error!</h2>
                <p>{{ errorMessage }}</p>
            </div>
        </b-container>
    </b-modal>
</template>

<script>
import StripePaymentForm from "../Forms/PaymentForms/StripePaymentForm";
export default {
    name: "AddPaymentMethodModal",
    components: { StripePaymentForm },
    props: {
        modalOpen: {
            type: Boolean,
        },
    },
    data() {
        return {
            state: "form",
            errorMessage: null,
        };
    },
    computed: {
        open: {
            get() {
                return this.modalOpen;
            },
            set(value) {
                this.$emit("update:modalOpen", value);
            },
        },
    },
    methods: {
        async addPaymentMethod(token) {
            try {
                this.loading = true;
                const response = await axios.post(`/settings/addCard`, {
                    token: token.id,
                });
                this.$emit("payment-method-added");
                this.open = false;
            } catch (error) {
                this.state = "error";
                this.errorMessage = error.response.data.message;
                this.loading = false;
            }
        },
    },
};
</script>
