<template>
    <div v-if="!loading">
        <post-card
            v-if="!editing"
            :post="postItem"
            :show-league="showLeague"
            :reactions="reactions"
            :auth-user="authUser"
            @edit-post="editing = true"
            @delete-post="showDelete = true"
            @share-post="openShareModal"
            @reaction-change="updateReactions"
            :showMedia="showMedia"
            :list-type="listType"
        ></post-card>
        <post-form
            v-else
            :is-edit-post-mode="true"
            :post="postItem"
            :auth-user="authUser"
            :league="postItem.league"
            @post-edited="postEdited"
            @cancel-edit="editing = false"
        ></post-form>
        <delete-post-modal
            v-if="showDelete"
            :modal-open.sync="showDelete"
            :league-id="postItem.league.id"
            :post-id="postItem.id"
            @post-deleted="postDeleted"
        ></delete-post-modal>
        <share-post-modal
            v-if="showShare"
            :modal-open.sync="showShare"
            :auth-user-id="authUser.id"
            :post="postItem"
            :type="shareType"
        ></share-post-modal>
    </div>
</template>

<script>
/* TODO: Refactor and clean up this component and its children */

import PostCard from "../Cards/PostCard";
import PostForm from "../Forms/PostForm";
import DeletePostModal from "../Modals/DeletePostModal";
import SharePostModal from "../Modals/SharePostModal";
export default {
    name: "PostItem",
    components: {
        SharePostModal,
        DeletePostModal,
        PostForm,
        PostCard,
    },
    props: {
        post: {
            type: Object,
            required: true,
        },
        showLeague: {
            type: Boolean,
            default: false,
        },
        authUser: {
            type: Object,
            required: false,
        },
        reactions: {
            type: Array,
            required: true,
        },
        showMedia: {
            type: Boolean,
            default: true,
        },
        listType: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            editing: false,
            showDelete: false,
            showShare: false,
            shareType: "chat",
            postItem: {},
        };
    },
    mounted() {
        this.postItem = this.post;
        this.loading = false;
    },
    methods: {
        openShareModal(type) {
            this.shareType = type;
            this.showShare = true;
        },
        postEdited(post) {
            this.postItem = post;
            this.editing = false;
            this.$emit("post-edited", post);
        },
        postDeleted(id) {
            this.$emit("post-deleted", id);
        },
        updateReactions(counts) {
            this.postItem.like_count = counts.like_count;
            this.postItem.love_count = counts.love_count;
        },
    },
};
</script>

<style scoped></style>
