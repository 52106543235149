<template>
    <div class="reaction" @click="$emit('react')">
        <span>{{ reaction.name }}</span>
        <i :class="`${reaction.class} fa-fw`"></i>
    </div>
</template>

<script>
export default {
    name: "Reaction",
    props: {
        reaction: {
            type: Object,
            required: true,
        },
    },
};
</script>
