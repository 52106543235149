<template>
    <b-list-group ref="messages" flush class="messages-list">
        <infinite-loading
            direction="top"
            @infinite="getMoreMessages"
            spinner="spiral"
        >
            <div slot="no-more"></div>
            <div slot="no-results"></div>
        </infinite-loading>
        <message-item
            v-for="message in messages"
            :key="message.id"
            :message="message"
            :auth-user-id="authUserId"
            :conversation-id="conversationId"
            @message-edited="messageEdited"
            @message-deleted="messageDeleted"
        ></message-item>
    </b-list-group>
</template>

<script>
import MessageItem from "../ListItems/MessageItem";
import MessageForm from "../Forms/MessageForm";
import InfiniteLoading from "vue-infinite-loading";

export default {
    name: "MessagesList",
    components: { InfiniteLoading, MessageForm, MessageItem },
    props: {
        conversationId: {
            type: Number,
            required: true,
        },
        initialMessages: {
            type: Array,
            required: true,
        },
        messageCount: {
            type: Number,
            required: true,
        },
        authUserId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            messages: [],
            totalMessages: 0,
            currentPage: 2,
            perPage: 10,
            messageInterval: null
        };
    },
    mounted() {
        this.messages = this.initialMessages;
        this.totalMessages = this.messageCount;
        this.scrollToEnd();

        // Make sure we don't already have an interval running.
        if (!this.messageInterval) {
          this.messageInterval = setInterval(this.getNewMessages, 12000);
        }

    },

  beforeDestroy() {
      // Make sure we stop the interval from continuing to run after it is destroyed.
      clearInterval(this.messageInterval);
  },
  watch: {
        initialMessages: {
            deep: true,
            handler: function () {
                this.messages = this.initialMessages;
                this.scrollToEnd();
            },
        },
        messageCount: function () {
            this.totalMessages = this.messageCount;
        },
    },
    methods: {
        scrollToEnd() {
            const content = this.$refs.messages;
            setTimeout(() => {
                window.scrollTo(0,document.body.scrollHeight);
            }, 100);
        },
        messageEdited(updatedMessage) {
            // Find message item
            const index = this.messages.findIndex(
                (message) => message.id === updatedMessage.id,
            );
            // force a rerender because vue will not notice if we change a child of an array otherwise
            Vue.set(this.messages, index, updatedMessage);
        },
        messageDeleted(id) {
            // Find message item
            const index = this.messages.findIndex(
                (message) => message.id === id,
            );
            this.messages.splice(index, 1);
        },
        async getMoreMessages($state) {
            if (this.messages.length < this.totalMessages) {
                try {
                    const requestData = {
                        chatId: this.conversationId,
                        per_page: this.perPage,
                        page: this.currentPage,
                    };
                    const { data } = await axios.post(
                        "/inbox/messages",
                        requestData,
                    );

                    if (data.messages.length) {

                        this.totalMessages = data.total;
                        this.currentPage += 1;

                        let newMessages = this.messages;

                        // Loop through the messages to make sure we only add new messages.
                        data.messages.map((message) => {
                          const obj = newMessages.find(o => o.id === message.id);
                          if (!obj) {
                            newMessages.unshift(message);
                          }
                        });

                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                } catch (error) {
                    // If there is an error for some reason, show alert
                    await this.$swal(error.response.data.message);
                }
            } else {
                $state.complete();
            }
        },
        async getNewMessages() {

            const requestData = {
                chatId: this.conversationId,
                per_page: 1000, // Need to get ALL messages when we check for new ones
                page: 0,
                newer: true
            };

            const { data } = await axios.post(
                "/inbox/messages",
                requestData
            );


            if (data.messages.length) {


              let newMessages = this.messages;

              // Loop through the messages to make sure we only add new messages.
              data.messages.map((message) => {
                let obj = newMessages.find(o => o.id === message.id);
                if (!obj) {
                  newMessages.push(message);
                }
              });


                this.totalMessages = data.total;



                this.scrollToEnd();
            }

            // No failure catch here, we can just do nothing if this request fails.
        }
    },
};
</script>

<style scoped>
.messages-list {
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 160px;
}
</style>
