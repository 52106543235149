<template>
    <b-card>
        <b-nav tabs justified class="mb-5">
            <b-nav-item
                :active="activeTab === 'subscriptions'"
                @click="updateActiveTab('subscriptions')"
            >
                Active Subscriptions
            </b-nav-item>
            <b-nav-item
                :active="activeTab === 'history'"
                @click="updateActiveTab('history')"
            >
                Billing History
            </b-nav-item>
            <b-nav-item
                :active="activeTab === 'payment'"
                @click="updateActiveTab('payment')"
            >
                Payment Method
            </b-nav-item>
        </b-nav>
        <component
            :is="componentName"
            :payment-method="paymentMethod"
            :subscriptions="subscriptions"
            :payments="payments"
        ></component>
    </b-card>
</template>

<script>
import PaymentMethodCard from "../../Cards/PaymentMethodCard";
import SubscriptionsList from "../../Lists/SubscriptionsList";
import BillingHistoryList from "../../Lists/BillingHistoryList";
export default {
    name: "UserSettingsPayment",
    components: { BillingHistoryList, SubscriptionsList, PaymentMethodCard },
    props: {
        paymentMethod: { type: Object, required: true },
        subscriptions: { type: Array, required: true },
        payments: { type: Array, required: true },
    },
    data() {
        return {
            activeTab: "subscriptions",
        };
    },
    computed: {
        componentName() {
            switch (this.activeTab) {
                case "subscriptions":
                    return "subscriptions-list";
                case "history":
                    return "billing-history-list";
                case "payment":
                    return "payment-method-card";
            }
        },
    },
    methods: {
        updateActiveTab(tab) {
            this.activeTab = tab;
        },
    },
};
</script>

<style scoped>
.nav-link {
    font-size: 20px;
    color: #0e0e0e;
}
.nav-link.active {
    color: #5451de !important;
    font-weight: bold;
}
</style>
