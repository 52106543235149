/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import CommentsList from "./components/Lists/CommentsList";
import FreePlanMessage from "./components/Messages/FreePlanMessage";

require("./bootstrap");

import Vuelidate from "vuelidate";
import BootstrapVue from "bootstrap-vue";
import vSelect from "vue-select";
import infiniteScroll from "vue-infinite-scroll";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueMask from "v-mask";
import _ from "lodash";
import Clipboard from "v-clipboard";
import VueMq from "vue-mq";
import VueCharts from "vue-chartjs";


import videojs from 'video.js';
require('videojs-contrib-quality-levels');
require('videojs-hls-quality-selector');
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

window.Vue = require("vue");

Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(require("vue-moment"));
Vue.use(infiniteScroll);
Vue.use(VueMask);
Vue.use(Clipboard);
Vue.use(VueMq);
Vue.use(VueCharts);

const swalOptions = {
    icon: "warning",
    timer: 5000,
    showConfirmButton: false,
};

Vue.use(VueSweetalert2, swalOptions);

Vue.prototype.$_ = _;

Vue.component("v-select", vSelect);
Vue.component("CommentsList", CommentsList);
Vue.component("FreePlanMessage", FreePlanMessage);

window.moment = require("moment");

window.plid = "___PLID___";

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context("./", true, /\.vue$/i);
files
    .keys()
    .map((key) =>
        Vue.component(key.split("/").pop().split(".")[0], files(key).default)
    );

// add sentry config if it's on
if (process.env.MIX_TURN_ON_SENTRY == 1) {
    // init sentry.io
    Sentry.init({
        Vue,
        dsn: "https://ca4a88ea753b46529fc069cf1c0b2251@o862639.ingest.sentry.io/5821975",
        integrations: [new Integrations.BrowserTracing()],
        logErrors: true,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: "#app",
});
