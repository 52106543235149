<template>
    <b-card>
        <b-row class="align-items-center m-auto">
            <b-col cols="12" md="3">
                <h4 class="font-weight-bold">Friends</h4>
            </b-col>
            <b-col cols="12"  md="5" class="mb-2">
                <b-input-group class="mb-2">
                    <b-form-input
                        id="search-friend"
                        placeholder="Search..."
                        v-model="searchText"
                        @change="searchFriend()"
                    >
                    </b-form-input>
                </b-input-group>
            </b-col>
            <b-col cols="12" md="4">
                <b-form-group
                    label="Status"
                    label-for="status-select"
                    label-class="sr-only"
                >
                    <b-form-select
                        id="status-select"
                        v-model="form.status"
                        :options="statusOptions"
                    ></b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
        <hr class="mt-2" />
        <friends-list
            :user-id="user.id"
            :query="form"
            :searchText="searchText"
            :cols="6"
            editable
            ref="friendslist"
        ></friends-list>
    </b-card>
</template>

<script>
import FriendsList from "../../Lists/FriendsList";
export default {
    name: "UserSettingsFriends",
    components: { FriendsList },
    props: {
        user: { type: Object, required: true },
    },
    mounted() {
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('status')) {
            this.form.status = urlParams.get('status');
        }
    },
    data() {
        return {
            statusOptions: [
                { value: null, text: "All Friends" },
                { value: 1, text: "Pending" },
                { value: 2, text: "Accepted" },
                { value: 3, text: "Rejected" },
                { value: 4, text: "Blocked" },
            ],
            form: {
                status: null,
            },
            searchText: null,
            
        };
    },
    methods: {
        searchFriend() {
            this.$refs.friendslist.getFriends();
        }
    }
};
</script>
