<template>
    <div>
        <b-row v-if="mediaItems.length > 0">
            <b-col
                v-for="media in mediaItems"
                :key="media.id"
                cols="12"
                md="6"
                xl="4"
            >
                <media-card
                    :media="media"
                    :selected="selectedMediaId === media.id"
                    :deletable="editable"
                    small
                    class="my-3"
                    @click="selectMedia"
                    @delete-media="openDeleteMediaModal"
                >
                </media-card>
            </b-col>
        </b-row>
        <div v-else class="text-center my-5">
            <span class="h5 text-muted">No Media Items</span>
        </div>
        <b-pagination
            v-if="totalMediaItems > perPage"
            v-model="currentPage"
            :total-rows="totalMediaItems"
            :per-page="perPage"
            aria-controls="media-list"
            align="center"
            class="my-3"
        ></b-pagination>
        <delete-media-modal @delete-media="deleteMedia"></delete-media-modal>
    </div>
</template>

<script>
import MediaCard from "../Cards/MediaCard";
import DeleteMediaModal from "../Modals/DeleteMediaModal";

export default {
    name: "MediaList",
    components: { DeleteMediaModal, MediaCard },
    props: {
        leagueId: Number,
        userId: Number,
        selectedMediaId: {
            type: Number,
        },
        perPage: {
            type: Number,
            default: 6,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        mediaType: {
            type: String,
            default: "all",
        },
    },
    data() {
        return {
            mediaToDelete: null, // Used if deleting media
            currentPage: 1,
            mediaItems: [],
            totalMediaItems: 0,
        };
    },
    mounted() {
        this.getMedia();
    },
    watch: {
        currentPage: function () {
            this.getMedia();
        },
    },
    computed: {
        type() {
            if (this.mediaType === "all") {
                return null;
            } else {
                return this.mediaType === "videos" ? 1 : 0;
            }
        },
    },
    methods: {
        async getMedia() {
            try {
                const data = {
                    leagueId: this.leagueId,
                    userId: this.userId,
                    mediaType: this.type,
                    per_page: this.perPage,
                    page: this.currentPage,
                };
                const response = await axios.post("/media/get", data);

                this.mediaItems = response.data.files;
                this.totalMediaItems = response.data.total;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
        selectMedia(media) {
            this.$emit("media-clicked", media);
        },
        openDeleteMediaModal(media) {
            this.mediaToDelete = media;
            this.$bvModal.show("delete-media-modal");
        },
        async deleteMedia() {
            try {
                const response = await axios.post("/media/delete", {
                    mediaId: this.mediaToDelete.id,
                });
                await this.getMedia();
                this.$bvModal.hide("delete-media-modal");
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
    },
};
</script>

<style scoped></style>
