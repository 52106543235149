<template>
    <div>
        <b-modal
            id="media-modal"
            v-model="open"
            size="lg"
            centered
            hide-header
            hide-footer
        >
            <b-container class="p-sm-4">
                <h3 class="text-center">Choose Media</h3>
                <b-tabs v-model="tabIndex" justified content-class="mt-4">
                    <b-tab title="Upload">
                        <media-upload-form
                            :league-id="leagueId"
                            :type="type"
                            :button-text="buttonText"
                            :submit-text="submitText"
                            @media-uploaded="submitForm"
                            :is-profile-img="mediaChosen"
                        ></media-upload-form>
                    </b-tab>
                </b-tabs>
            </b-container>
        </b-modal>
    </div>
</template>

<script>
import MediaUploadForm from "../Forms/MediaUploadForm";
import MediaList from "../Lists/MediaList";
import SubmitButton from "../Buttons/SubmitButton";
export default {
    name: "MediaModal",
    components: { SubmitButton, MediaList, MediaUploadForm },
    props: {
        leagueId: Number,
        userId: Number,
        modalOpen: Boolean,
        uploadOnly: {
            type: Boolean,
            default: false,
        },
        canEditLeagueMedia: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: "all",
        },
        buttonText: {
            type: String,
            default: "Submit",
        },
        submitText: {
            type: String,
            default: "Submitting...",
        },
        mediaChosen: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            tabIndex: 0,
            selectedMedia: null, // Used when selecting existing media
            controls:{},
            zoomControls: null,
        };
    },
    computed: {
        open: {
            get() {
                return this.modalOpen;
            },
            set(value) {
                this.$emit("update:modalOpen", value);
            },
        },
        showChooseMedia() {
            return !this.uploadOnly ? this.open : false;
        },
        showLeagueMedia() {
            return !this.uploadOnly ? this.open && this.leagueId : false;
        },
        selectedMediaId() {
            return this.selectedMedia ? this.selectedMedia.id : null;
        },
    },
    methods: {
        selectMedia(media) {
            this.selectedMedia = media;
        },
        submitExistingMedia() {
            this.submitForm(this.selectedMedia);
        },
        submitForm(media) {
            this.$emit("media-chosen", media);
            this.$bvModal.hide("media-modal");
            this.open = false;
            this.$emit('zoom-controls', this.zoomControls)
        },
        getZoomControls(zoom){
            this.zoomControls = zoom
        }
    },
};
</script>