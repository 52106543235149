<template>
  <b-modal
      :id="modalId"
      :title="title"
      v-bind="$attrs"
      @shown="$emit('shown')">
    <slot name="modal-title">
      <template #modal-title/>
    </slot>
    <slot name="modal-header">
      <template #modal-header/>
    </slot>
    <slot name="modal-footer">
      <template #modal-footer/>
    </slot>
    <slot name="modal-ok">
      <template #modal-ok/>
    </slot>
    <slot name="modal-cancel">
      <template #modal-cancel/>
    </slot>
    <slot name="modal-header-close">
      <template #modal-header-close/>
    </slot>
    <slot name="activator" :on="{ click: toggle }"/>
    <div class="loader-wrap">
      <div class="loader" v-show="loading"></div>
    </div>
    <slot/>
  </b-modal>
</template>

<script>
export default {
  name: "Modal",
  data: () => ({
    modalId: 'simple-modal',
    visible: false
  }),
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  created() {
    this.visible = Boolean(this.value)
    this.modalId = this.modalId + '-' + Math.floor(Math.random() * 1000)
  },
  watch: {
    visible(value) {
      if (value) {
        this.show()
      } else {
        this.hide()
      }
      this.$emit('input', value)
    },
    value(value) {
      this.visible = Boolean(value)
    }
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === this.modalId) {
        // this.visible = false
      }
    })
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === this.modalId) {
        this.visible = false
      }
    })
  },
  methods: {
    show() {
      this.$bvModal.show(this.modalId)
    },
    hide() {
      this.$bvModal.hide(this.modalId)
    },
    toggle() {
      this.visible = !this.visible
    }
  }
}
</script>

<style scoped>
.loader-wrap {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>