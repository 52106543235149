<template>
    <b-form @submit.prevent="submitForm">
        <b-row no-gutters class="align-items-center">
            <b-col class="px-3">
                <FancyEditor
                    usage-type="comment"
                    ref="fancyEditor"
                    :initialContent="form.text"
                    :leagueId="leagueId"
                    @input-changed="handleFancyEditorChange"
                    @on-enter="submitForm"
                    :posted-by-name="isPostedByName"
                    tabindex="0"
                    :comment-level-count="commentLevelCount"
                    :comment="comment"
                />
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import FancyEditor from "./FormComponents/FancyEditor";
export default {
    name: "CommentForm",
    components: { FancyEditor },
    props: {
        comment: Object,
        parent: Object,
        parentType: String,
        leagueId: {
            type: Number,
            required: true,
        },
        commentableId: {
            type: Number,
            required: true,
        },
        commentLevelCount: {
            type: Number,
            default: 0,
        },
        showCommentForm: {
            type: Boolean,
            default: false,
        },
        tagName: {
            type: String,
            default: "",
        },
        tagId: {
            type: Number,
            default: 0,
        },
        commentableType: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            form: {
                text: null,
            },
        };
    },
    mounted() {
        if (this.comment) {
            this.setForm();
        }

        if (this.commentLevelCount > 0) {
            this.addMentionTag();
        }
    },
    methods: {
        setForm() {
            this.form.text = this.comment.comment;
        },
        async submitForm() {
            // Dont even try to submit it unless there is content
            if (this.form.text) {
                this.loading = true;

                const requestData = {
                    comment: this.form.text,
                    commentId: this.comment ? this.comment.id : null,
                    parentId:
                        this.parent && this.parentType !== "post"
                            ? this.parent.id
                            : null,
                    commentableId: this.commentableId,
                    commentableType: this.commentableType,
                    leagueId: this.leagueId,
                };

                try {
                    if (this.comment) {
                        const response = await axios.post(
                            "/leagues/comments/edit",
                            requestData
                        );
                        this.$emit("comment-edited", response.data.comment);
                        this.$refs.fancyEditor?.setContent("");
                    } else {
                        const response = await axios.post(
                            "/leagues/comments/add",
                            requestData
                        );
                        this.$emit("comment-created", response.data.comment);
                        this.form.text = null;
                        this.$refs.fancyEditor.setContent("");
                    }
                } catch (error) {
                    // If there is an error for some reason, show alert
                    await this.$swal(error.response.data.message);
                    this.loading = false;
                }
            }
        },
        handleFancyEditorChange(val) {
            // Update our form data
            this.form.text = val;
        },
        addMentionTag() {
            if (this.showCommentForm) {
                //&& this.commentLevelCount == 2 //This enables comments on the level two only
                this.$refs.fancyEditor.setContent(
                    `<span class='metion' data-mention-id='${this.tagId}' contenteditable='false'>@${this.tagName}</span>&nbsp;&nbsp;`
                );
            }
        },
    },
    computed: {
        // Created this to avoid the error in render
        isPostedByName() {
            return this.parent ? this.parent.posted_by.name : null;
        },
    },
};
</script>

<style scoped></style>
