<template>
    <div class="thumbnail" @click="$emit('click')">
        <div class="title">
            {{ title }} <span v-if="isRequired" class="is-required">*</span>
        </div>
        <div class="box" :style="boxStyles">
            <div v-if="!showEditIcon && !isLoading" class="plus-icon-wrapper">
                <plus-icon2 />
            </div>
            <div v-if="showEditIcon && !isLoading" class="edit-icon-wrapper">
                <i class="fas fa-edit fa-2x"></i>
            </div>
            <div
                v-if="showDeleteIcon && !isLoading"
                class="delete-icon-wrapper"
                @click="handleDeleteMediaItem($event)"
            >
                <i class="fas fa-trash-alt fa-2x"></i>
            </div>
            <div v-if="isLoading" class="loading-icon-wrapper">
                <div class="spinner-grow text-success" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PlusIcon2 from "../CustomIcons/PlusIcon2";
export default {
    name: "StoreUploadFileThumbnailItem",
    components: {
        PlusIcon2,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        thumbnailImageUrl: {
            type: String,
            required: false,
        },
        loading: {
            type: Boolean,
            required: false,
        },
        canDelete: {
            type: Boolean,
            default: false,
        },
        mediaItemId: {
            type: Number,
        },
        position: {
            type: Number,
        },
        isRequired: {
            type: Boolean,
            required: true,
        },
    },
    mounted() {},
    computed: {
        boxStyles() {
            if (!this.thumbnailImageUrl) {
                return {};
            }
            return {
                backgroundImage: `url(${this.thumbnailImageUrl})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
            };
        },
        showEditIcon() {
            return !!this.thumbnailImageUrl;
        },
        isLoading() {
            return this.loading;
        },
        showDeleteIcon() {
            return (
                !!this.thumbnailImageUrl && this.canDelete && this.mediaItemId
            );
        },
    },
    methods: {
        async handleDeleteMediaItem(e) {
            e.preventDefault();
            e.stopPropagation();

            let { isConfirmed } = await this.$swal({
                title: "Delete Media",
                html: "Are you sure you want to delete this media item?",
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: "Delete",
                timer: 10000,
            });

            if (isConfirmed) {
                this.$emit(
                    "delete-media-item",
                    this.mediaItemId,
                    this.position,
                );
            }
        },
    },
};
</script>

<style scoped lang="scss">
.thumbnail {
    margin-bottom: 32px;
    cursor: pointer;
    .title {
        color: #aaaaaa;
        text-align: center;
        .is-required {
            color: red;
            font-weight: bold;
        }
    }
    .box {
        position: relative;
        display: flex;
        background-color: #e7e7e7;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 120px;
        .edit-icon-wrapper {
            position: absolute;
            bottom: -12px;
            right: -24px;
            .fa-edit {
                color: #aaa;
            }
        }

        .delete-icon-wrapper {
            position: absolute;
            bottom: -24px;
            left: -24px;
            padding: 12px;
            .fa-trash-alt {
                color: red;
            }
        }
    }
}
</style>
