<template>
    <div>
        <div class="d-flex justify-content-between mb-5">
            <h1 class="h2 font-weight-bold">Member Requests</h1>
        </div>

        <!-- <b-row>
            <b-col>
                <b-table
                    striped
                    hover
                    show-empty
                    responsive="sm"
                    :items="memberRequests"
                    :fields="fields"
                >
                    <template #cell(user)="row">
                        <b-avatar
                            :src="row.value.avatar_path"
                            variant="grey"
                            class="mr-md-2"
                        ></b-avatar>
                    </template>
                    <template #cell(email)="data">
                        <a
                            v-bind:href="
                                '/u/' + data.item.user_id + '?section=about'
                            "
                            target="_blank"
                        >
                            {{ data.item.user.email }}
                        </a>
                    </template>
                    <template #cell(history)="data">
                        <a href="#" @click="viewHistory(data.item.user_id)"
                            >View History</a
                        >
                    </template>
                    <template #cell(actions)="data">
                        <b-row>
                            <b-col sm="5">
                                <b-button
                                    size="sm"
                                    @click="sendMessage(data.item.user_id)"
                                >
                                    Send Message
                                </b-button>
                            </b-col>
                            <b-col sm="3">
                                <b-button
                                    size="sm"
                                    variant="success"
                                    @click="showModal(data.item, true)"
                                >
                                    Approve
                                </b-button>
                            </b-col>
                            <b-col sm="3">
                                <b-button
                                    size="sm"
                                    variant="danger"
                                    @click="showModal(data.item, false)"
                                >
                                    Reject
                                </b-button>


                            </b-col>
                        </b-row>
                    </template>
                </b-table>
            </b-col>
        </b-row> -->
        <table>
            <thead>
                <tr class="header-edit">
                    <th scope="col" class="avtar-w">Avatar</th>
                    <th scope="col" class="name-w">Name</th>
                    <th scope="col" class="email-w">Email</th>
                    <th scope="col" class="history-w">History</th>
                    <th scope="col" class="action-w">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="user in memberRequests" :key="user.id">
                    <td data-label="Avatar">
                        <b-avatar
                            :src="user.user.avatar_path"
                            variant="grey"
                            class="mr-md-2"
                        ></b-avatar>
                    </td>
                    <td data-label="Name">{{ user.user.name }}</td>
                    <td data-label="Email">
                        <a
                            v-bind:href="
                                '/u/' + user.user.id + '?section=about'
                            "
                            target="_blank"
                            >{{ user.user.email }}</a
                        >
                    </td>
                    <td data-label="History">
                        <a href="#" @click="viewHistory(user.user.id)"
                            >View History</a
                        >
                    </td>
                    <td>
                        <div style="width: 100%">
                            <b-button
                                size="sm"
                                @click="sendMessage(user.user.id)"
                                class="sm-block"
                            >
                                Send  Message
                            </b-button>
                            <b-button
                                size="sm"
                                variant="success"
                                @click="showModal(user, true)"
                                class="sm-block"
                            >
                                Approve
                            </b-button>
                            <b-button
                                size="sm"
                                variant="danger"
                                @click="showModal(user, false)"
                                class="sm-block"
                            >
                                Reject
                            </b-button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <!-- Confirmation Modal -->
        <b-modal
            ref="member-req-modal"
            hide-header
            v-model="open"
            centered
            @ok="handleOk"
            ok-title="Yes"
            cancel-variant="danger"
        >
            <b-row>
                <b-col>
                    <h5 class="pb-3 pt-3">{{ modalLabel }}</h5>
                    <b-form
                        ref="form-member-req"
                        @submit.stop.prevent="handleSubmit"
                    >
                        <b-form-group
                            label="Comments (optional)"
                            label-for="comments-input"
                        >
                            <b-form-textarea
                                v-model="form.comments"
                                id="comments-input"
                                rows="3"
                            ></b-form-textarea>
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
        </b-modal>

        <!-- Send Message Modal -->
        <create-chat-modal
            :modal-open.sync="messageModalOpen"
            :auth-user-id="authUser.id"
            :user-id="recepientUserId"
            :skip-is-friends="true"
            :upload-media="false"
        ></create-chat-modal>

        <!-- Member Request History Modal -->
        <member-request-history-modal
            :modal-open.sync="historyModalOpen"
            :data="memberRequestHistory"
        ></member-request-history-modal>
    </div>
</template>

<script>
import CreateChatModal from "../../Modals/CreateChatModal";
import MemberRequestHistoryModal from "../../Modals/MemberRequestHistoryModal";
export default {
    name: "LeagueSettingsMemberRequest",
    components: {
        CreateChatModal,
        MemberRequestHistoryModal,
    },
    props: {
        memberRequests: {
            type: Array,
        },
        authUser: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            fields: [
                {
                    key: "user",
                    label: "Avatar",
                },
                {
                    key: "user.name",
                    label: "Name",
                },
                {
                    key: "email",
                },
                {
                    key: "history",
                },
                // TODO add column send message
                {
                    key: "actions",
                    label: "Actions",
                    thStyle: {
                        textAlign: "center",
                    },
                },
            ],
            modalLabel: "",
            open: false,
            form: {},
            messageModalOpen: false,
            recepientUserId: 0,
            historyModalOpen: false,
            memberRequestHistory: [],
        };
    },
    methods: {
        showModal(obj, isApproved) {
            this.modalLabel =
                "Are you sure you want to " +
                (isApproved ? "approve" : "reject") +
                " this member?";
            this.form.userId = obj.user_id;
            this.form.leagueId = obj.league_id;
            this.form.comments = "";
            this.form.isApproved = isApproved;
            this.open = true;
        },
        hideModal() {
            this.open = false;
        },
        handleOk(e) {
            e.preventDefault();
            this.handleSubmit();
        },
        async handleSubmit() {
            try {
                const response = await axios.post(
                    "/leagues/members/request/update",
                    this.form,
                );
                const {
                    data: { success, message },
                } = response;
                if (success === true && message?.length > 0) {
                    await this.$swal("Success!",message, "success");
                }
                window.location.reload();
            } catch ({
                response: {
                    data: { message },
                },
            }) {
                // If there is an error for some reason, show alert
                await this.$swal(message);
            } finally {
                this.open = false;
            }
        },
        sendMessage(userId) {
            this.messageModalOpen = true;
            this.recepientUserId = userId;
        },
        async viewHistory(userId) {
            try {
                const response = await axios.get(
                    "/leagues/members/request/view",
                    {
                        params: { userId },
                    },
                );
                const {
                    data: { data },
                } = response;
                
                this.memberRequestHistory = data;
                console.log("response", response);
                this.historyModalOpen = true;
            } catch ({
                response: {
                    data: { message },
                },
            }) {
                await this.$swal(message);
            }
        },
    },
};
</script>
<style scoped>
/* @media (max-width: 460px) {

} */
.avtar-w {
    width: 10%;
}
.name-w {
    width: 10%;
}
.email-w {
    width: 24%;
}
.history-w {
    width: 15%;
}
.action-w {
    width: 32%;
}
body {
    font-family: "Open Sans", sans-serif;
    line-height: 1.25;
}

table {
    border: none;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: unset;
}

table caption {
    font-size: 1.5em;
    margin: 0.5em 0 0.75em;
}

table tr {
    background-color: #f8f8f8;
    border: none;
    padding: 0.35em;
}

table th {
    padding: 0.625em;
    text-align: left;
}

table th {
    font-size: 0.85em;
    letter-spacing: 0.1em;
    text-transform: capitalize;
}
.header-edit {
    background-color: white !important;
}

@media screen and (max-width: 991px) {
    table {
        border: 0;
    }

    table caption {
        font-size: 1.3em;
    }

    table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    table tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: 0.625em;
    }

    table td {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: 0.8em;
        text-align: right;
        display: flex !important;
        align-items: center;
        justify-content: space-between;
    }

    table td::before {
        /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: capitalize;
    }

    table td:last-child {
        border-bottom: 0;
    }
}

@media all and (max-width:480px) {
    .sm-block {
        width: 100%;
        display: block;
        margin-top: 0.5rem;
    }
}
</style>