<template>
    <div>
        <hr /> 
        <members-list
            :league-id="context.league.id"
            :auth-id="currentUser.id"
        ></members-list>
    </div>
</template>

<script>
import MembersList from "../../../Lists/MembersList";
import RemoveUserModal from "../../../Modals/removeUserModal";

export default {
    name: "LeagueProfileMembers",
    components: { RemoveUserModal, MembersList },
    props: {
        /* Contextual Object that contains league and authUser (including league roles) */
        parentContext: {
            type: Object,
            required: true,
        },
        currentUser:{
            type: Object,
            required: true,
        }
    },
    computed: {
        context() {
            return {
                profileTab: "Members",
                ...this.parentContext,
            };
        },
    }
};
</script>

<style scoped></style>
