<template>
    <div class="tooltip-box">
        <div class="pr-1">
            <div class="tooltip-icons">
                <span class="ic-like">
                    <template v-for="reaction in reactionTypes">
                        <i
                            v-if="counts[reaction.id] > 0"
                            :class="`${reaction.class} fa-fw`"
                            :key="reaction.id"
                        ></i>
                        <span v-if="reaction.name === 'Like'" class="reactionCount">{{reactionLikeCount}}</span>
                        <span v-if="reaction.name === 'Love'" class="reactionCount">{{reactionLoveCount}}</span>
                    </template>
                </span>
            </div>
        </div>
        <div class="tooltip" v-if="reactionCount > 0">
            <small v-if="reactionLikeCount>0">{{reactionLikeCount}} likes</small>
            <small v-if="reactionLoveCount>0">{{reactionLoveCount}} loves</small>
        </div>
    </div>
</template>

<script>
export default {
    name: "ReactionCounter",
    data() {
        return {
            totalCounts: 0,
        };
    },
    props: {
        reactionTypes: {
            type: Array,
            required: true,
        },
        counts: {
            type: Object,
            required: true,
        },
        reactions: {
            type: Object,
            required: true,
        },
    },
    mounted() {
        for (const type in this.reactionTypes) {
            this.totalCounts += this.counts[type.id];
        }
    },
    computed: {
        reactionCount() {
            return (this.counts['1'] + this.counts['2']) || '';
        },
        reactionLikeCount() {
            return (this.counts['1']) || '';
        },
        reactionLoveCount() {
            return (this.counts['2']) || '';
        },
        //likeUsers() {
            // return this.reactions.like_users.slice(0,3).map(x => x.firstName);
        //}
    }
};
</script>

<style scoped>
.tooltip-box {
    position: relative;
    display: inline-flex;
}

.tooltip-box:hover .tooltip {
    opacity: 1;
    display: flex;
}

.tooltip-icons {
    position: relative;
    display: inline-block;
}

.tooltip {
    color: #ffffff;
    padding: 10px 15px;
    border-radius: 10px;
    width: 200px;
    left: 0;
    opacity: 0;
    transition: opacity 1s;
    position: absolute;
    z-index: 1;
    background: #585858;
    display: none;
    flex-direction: column;
    top: 35px;
}
.tooltip a {
    color: #ffffff !important;
    text-transform: capitalize;
}
.tooltip span {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 800;
}
.text::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #585858 transparent transparent transparent;
}
.ic-like {
    color: #fff;
    flex-direction: row;
    display: flex;
}

.ic-like .fa-fw {
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.ic-like .fa-heart {
    background-color: #e4446a;
}

.ic-like .fa-thumbs-up {
    background-color: #5451de;
}

.fa-thumbs-up + .fa-heart {
    margin-left: -3px;
}

.reactionCount {
    margin-left: 5px;
    margin-right:5px;
    margin-top: 2px;
}
</style>
