<template>
    <b-form @submit.prevent="submitForm">
        <b-form-group aria-label="email" label-for="email-input">
            <b-form-input
                id="email-input"
                v-model.trim="form.email"
                :state="validateInput('email')"
                @input="checkUser"
                type="text"
                placeholder="Email"
                class="form-control-black"
                autocomplete="off"
            ></b-form-input>
            <invalid-feedback :errors="emailErrors"></invalid-feedback>
        </b-form-group>
        <b-form-group
            v-show="existingUser"
            aria-label="password"
            label-for="password-input"
        >
            <b-form-input
                id="password-input"
                type="password"
                v-model.trim="form.password"
                @change="$v.form.password.$touch()"
                placeholder="Password"
                class="form-control-black"
                autocomplete="off"
            ></b-form-input>
            <invalid-feedback :errors="passwordErrors"></invalid-feedback>
        </b-form-group>
        <submit-button
            v-show="existingUser"
            :loading="loading"
            text="Sign In"
        ></submit-button>
    </b-form>
</template>

<script>
import { required, requiredIf, email } from "vuelidate/lib/validators";
import InvalidFeedback from "./FormComponents/InvalidFeedback";
import SubmitButton from "../Buttons/SubmitButton";

export default {
    name: "LoginFormNoSub",
    components: { SubmitButton, InvalidFeedback },
    props: {
        leagueId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            existingUser: null,
            form: {
                email: "",
                password: "",
            },
            serverError: null,
        };
    },
    validations: {
        form: {
            email: { required, email },
            password: { required },
        },
    },
    computed: {
        /* Validation Errors */
        emailErrors() {
            const errors = [];
            if (!this.$v.form.email.$dirty) return errors;
            !this.$v.form.email.required && errors.push("Email is required");
            !this.$v.form.email.email &&
                errors.push("Must be a valid Email Address");
            return errors;
        },
        passwordErrors() {
            const errors = [];
            if (!this.$v.form.password.$dirty) return errors;
            !this.$v.form.password.required &&
                errors.push("Password is required");
            return errors;
        },
    },
    methods: {
        validateInput(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        async checkUser() {
            this.$v.form.email.$touch();

            if (!this.$v.form.email.$anyError) {
                // Axios request to check if the email is in our system
                try {
                    const response = await axios.post(
                        `/checkEmailNoSubscription`,
                        {
                            leagueId: this.leagueId,
                            email: this.form.email,
                        }
                    );
                    // If they exist update existingUser
                    this.existingUser = response.data.exists;
                } catch (error) {
                    // If there is an error for some reason, show SWAL
                    await this.$swal(error.response.data.message);
                }
            }
        },
        async submitForm() {
            this.$v.form.$touch();

            if (!this.$v.form.$anyError) {
                this.loading = true;

                try {
                    // Attempt to login
                    const response = await axios.post("/loginNoSubscription", {
                        leagueId: this.leagueId,
                        email: this.form.email,
                        password: this.form.password,
                    });
                    // Redirect once logged in
                    window.location.href = response.data.redirect || "/home";
                } catch (error) {
                    /// If there is an error for some reason, show alert
                    await this.$swal(error.response.data.message);
                    this.loading = false;
                }
            }
        },
    },
};
</script>

<style scoped></style>
