<template>
    <b-modal v-model="open"
             hide-footer
             title="History"
             @hide="handleClose"
             centered
             size="lg">
        <b-row>
            <b-col md="12">
                <b-table striped
                         show-empty
                         responsive="sm"
                         :items="data"
                         :fields="fields">
                    <template #cell(created_at)="row">
                        {{ row.value | moment('LL') }}
                    </template>
                    <template #cell(decision_date)="row">
                        {{ row.value | moment('LL') }}
                    </template>
                </b-table>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
export default {
    name: "MemberRequestHistoryModal",
    props: {
        data: Array,
        modalOpen: Boolean,
    },
    data() {
        return {
            fields: [
                {
                    key: 'created_at',
                    label: 'Last Request Date'
                },
                {
                    key: 'request_status',
                    label: 'Request Status'
                },
                {
                    key: 'comments',
                    label: 'Comment'
                },
                {
                    key: 'decision_maker.name',
                    label: 'Decided By'
                },
                {
                    key: 'decision_date',
                    label: 'Decided On'
                }
            ]
        };
    },
    computed: {
        open: {
            get() {
                return this.modalOpen;
            },
            set(value) {
                this.$emit("update:modalOpen", value);
            },
        },
    },
    methods: {
        handleClose() {
            this.open = false;
        }
    }
}
</script>