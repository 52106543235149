<template>
    <div>
        <payment-account-form
            v-show="state === 'user-account'"
            :league-id="leagueId"
            @payment-account-created="state = 'payout-account'"
        >
        </payment-account-form>
        <div v-show="state === 'payout-account'">
            <h1 class="h2 font-weight-bold">Payout Account</h1>
            <p class="text-muted mb-4">
                This is the account your money will be sent to.
            </p>
            <b-form-group label="Payout Method" class="mt-4">
                <b-form-select
                    v-model="payoutMethod"
                    :options="payoutMethodOptions"
                >
                </b-form-select>
            </b-form-group>
            <direct-deposit-form
                v-if="payoutMethod === 'direct'"
                @stripe-submitted="submitBankForm"
            ></direct-deposit-form>
            <stripe-payment-form
                v-if="payoutMethod === 'debit'"
                title="Payout Account"
                @stripe-submitted="submitBankForm"
            ></stripe-payment-form>
        </div>
        <league-price-form
            v-show="state === 'league-price'"
            :league-id="leagueId"
            :league-trial="leagueTrial"
            @price-set="submitFinished"
        ></league-price-form>
    </div>
</template>

<script>
import PaymentAccountForm from "../PaymentForms/PaymentAccountForm";
import DirectDepositForm from "../PaymentForms/DirectDepositForm";
import StripePaymentForm from "../PaymentForms/StripePaymentForm";
import LeaguePriceForm from "./LeaguePriceForm";

/** This is a wrapper component to handle Payment Account Setup **/
export default {
    name: "LeaguePaymentAccountForm",
    components: {
        LeaguePriceForm,
        StripePaymentForm,
        DirectDepositForm,
        PaymentAccountForm,
    },
    props: {
        leagueId: {
            required: true,
        },
      leagueTrial: {
          type: Number,
        default: 0,
      },
        leagueSettings: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            state: "user-account",
            payoutMethod: "direct",
            payoutMethodOptions: [
                { value: "direct", text: "Direct Deposit" },
                { value: "debit", text: "Debit Card" },
            ],
        };
    },
    methods: {
        async submitBankForm(token) {
            try {
                const response = await axios.post("/leagues/updateBank", {
                    token: token.id,
                    leagueId: this.leagueId,
                });
                // show the next step
                this.state = "league-price";
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        },
        submitFinished() {
            if (this.leagueSettings) {
                // Reload to get readonly version of forms
                window.location.reload();
            } else {
                // Redirect to the next step of league creation
                window.location = "/leagues/permissions";
            }
        },
    },
};
</script>
