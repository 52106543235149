<template>
    <div>
        <b-form
            @submit.prevent="submitForm"
            class="p-2"
        >
            <b-row v-if="mediaItem" no-gutters class="mb-4">
                <b-col v-if="mediaType === 'image'" cols="auto" class="mx-auto">
                    <div class="media-card">
                        <b-btn
                            variant="purple"
                            pill
                            size="sm"
                            class="delete-button"
                            v-b-tooltip.hover.left
                            title="Delete"
                            @click.stop="removeMedia"
                        >
                            <i class="fas fa-trash"></i>
                        </b-btn>
                        <b-img
                            :src="mediaItem"
                            fluid
                            class="mx-auto"
                            style="max-height: 400px;"
                        ></b-img>
                    </div>
                </b-col>
                <b-col v-if="mediaType === 'video'" cols="12" class="px-0 mb-n2">
                    <div
                        class="media-card mx-auto"
                        :style="{ position: 'relative', maxWidth: '600px' }"
                    >
                        <b-btn
                            variant="purple"
                            pill
                            size="sm"
                            class="delete-button"
                            v-b-tooltip.hover.left
                            title="Delete"
                            @click.stop="removeMedia"
                        >
                            <i class="fas fa-trash"></i>
                        </b-btn>
                        <video
                            ref="Player"
                            controls
                            preload="auto"
                            class="video-js"
                            data-setup="{}"
                        >
                            <source :src="mediaItem" type="application/x-mpegURL" />
                            <source :src="mediaItem" type="video/mp4" />
                            <source :src="mediaItem" type="video/webm" />
                            <source :src="mediaItem" type="video/ogg" />
                        </video>
                    </div>
                </b-col>
            </b-row>
            <b-form-textarea
                v-model="form.message"
                placeholder="Write a message..."
                :rows="rows"
                max-rows="8"
                class="mb-2"
            ></b-form-textarea>
            <div class="d-flex justify-content-between">
                <b-btn variant="link" @click="openMediaModal">
                    <i class="fas fa-photo-video"></i>
                </b-btn>
                <b-btn
                    variant="purple"
                    type="submit"
                    pill
                    class="btn-padded"
                    :disabled="loading"
                >
                    {{ submitText }}
                </b-btn>
            </div>
        </b-form>
        <media-modal
            :modal-open.sync="mediaModalOpen"
            :user-id="authUserId"
            @media-chosen="setMedia"
        ></media-modal>
    </div>
</template>

<script>
import MediaModal from "../Modals/MediaModal";
import PostCardSmall from "../Cards/PostCardSmall";
import videojs from 'video.js';
export default {
    name: "MessageForm",
    components: { PostCardSmall, MediaModal },
    props: {
        /* The user that is currently logged in */
        authUserId: {
            type: Number,
            required: true,
        },
        rows: {
            type: Number,
            default: 4,
        },
        conversationId: Number,
        message: Object,
        post: Object,
        isMemberRequest: {
            type: Boolean,
        }
    },
    data() {
        return {
            loading: false,
            mediaModalOpen: false,
            mediaItem: null,
            mediaType: "image",
            form: {
                message: null,
                mediaId: null,
            },
        };
    },
    mounted() {
        if (this.message) {
            this.setForm();
        }
    },
    computed: {
        submitText() {
            return this.loading ? "Submitting..." : "Submit";
        },
    },
    methods: {
        openMediaModal() {
            this.mediaModalOpen = true;
        },
        setMedia(media) {
            this.form.mediaId = media.id;
            this.mediaItem = media.file;
            const fileType = this.mediaItem.substring(
                this.mediaItem.length - 3,
                this.mediaItem.length
            );
            if (fileType === "mp4") {
                this.mediaType = "video";
                let _this = this;

                // have to delay it, because vue doesn't cache refs until the
                // element has been placed into the dom. Hacky? Sure. Feel free to find a better way.
                setTimeout(function() {
                    console.log(_this.$refs.Player);
                    videojs(_this.$refs.Player);

                }, 500);

            }
        },
        removeMedia() {
            this.form.mediaId = null;
            this.mediaItem = null;
        },
        setForm() {
            this.mediaItem = this.message.media
                ? this.message.media?.file
                : null;
            this.mediaType = this.message.media
                ? this.message.media?.type
                    ? "video"
                    : "image"
                : "image";
            this.form = {
                message: this.message.message,
                mediaId: this.message.media?.id,
            };
        },
        resetForm() {
            this.mediaItem = null;
            this.mediaType = "image";
            this.form = {
                message: null,
                mediaId: null,
            };
        },
        submitForm() {
            if (this.conversationId) {
                if (this.message) {
                    this.updateMessage();
                } else {
                    this.sendMessage();
                }
            } else {
                this.$emit("submit-convo", this.form);
            }
        },
        async sendMessage() {
            try {
                // When a placeholder conversation has been created by selecting a user from your friends list,
                // we use this temporary conversation ID.
                if (this.conversationId === -1) {
                    this.$emit('create-chat', this.form.message);
                    this.form.message = "";
                    return;
                }

                const response = await axios.post("/inbox/messages/add", {
                    chatId: this.conversationId,
                    ...this.form,
                });

                this.$emit("message-created", response.data.message);
                this.loading = false;
                this.resetForm();
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },

        async updateMessage() {
            try {
                const response = await axios.post("/inbox/messages/edit", {
                    chatMessageId: this.message.id,
                    ...this.form,
                });

                this.$emit("message-edited", response.data.message);
                this.loading = false;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
    },
};
</script>

<style scoped lang="scss">
textarea {
    background-color: white;
    border: none;
    border-radius: 0;
    & {
        background-color: white;
        border: none;
        box-shadow: none;
    }
}
.video-js{
    width: 449px !important;
    height: 268px;
}
@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .video-js{
        width: 337px !important;
        height: 253px;
    }
}
</style>
