<template>
    <div>
        <b-form>
            <b-form-row class="align-items-center">
                <b-col cols="12" lg="6">
                    <div class="d-inline-flex align-items-baseline">
                        <h1 class="h4 mb-0">Browse Categories</h1>
                        <b-button
                            variant="link"
                            size="sm"
                            class="ml-3"
                            @click="$emit('show-leagues')"
                            >Search Leagues</b-button
                        >
                    </div>
                </b-col>
                <b-col cols="12" md="6" lg="3" class="ml-auto">
                    <b-input-group>
                        <template v-slot:prepend>
                            <b-input-group-text
                                ><i class="fas fa-search"></i
                            ></b-input-group-text>
                        </template>
                        <b-form-input
                            type="text"
                            v-model="searchValue"
                            placeholder="Search for a category..."
                            @input="getCategories"
                        ></b-form-input>
                    </b-input-group>
                </b-col>
            </b-form-row>
        </b-form>
        <hr class="mb-5" />
        <b-row id="categories-list" v-show="categories.length > 0">
            <b-col
                cols="12"
                lg="6"
                v-for="category in paginatedCategories"
                :key="category.id"
            >
                <b-card
                    class="bg-lightgrey border-0 mb-4 clickable drop-shadow"
                    @click="selectCategory(category)"
                >
                    <div
                        class="d-flex align-items-center justify-content-center py-3"
                    >
                        <h4 class="text-muted mb-0">{{ category.category }}</h4>
                    </div>
                </b-card>
            </b-col>
        </b-row>
        <b-pagination
            @change="onPageChanged"
            v-if="totalCategories > perPage"
            v-model="currentPage"
            :total-rows="totalCategories"
            :per-page="perPage"
            aria-controls="categories-list"
            align="center"
            class="my-3"
        ></b-pagination>
        <div v-show="categories.length === 0">
            <h5 class="text-muted text-center">No Categories Found...</h5>
        </div>
    </div>
</template>

<script>
export default {
    name: "CategorySearch",
    components: {},
    props: {
        initialCategories: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            searchValue: "",
            perPage: 10,
            currentPage: 1,
            categories: [],
            totalCategories: 0,
            paginatedCategories: []
        };
    },
    created() {
        // Prevent hitting the server too many times on search
        this.getCategories = _.debounce(this.getCategories, 250);
    },
    mounted() {
        this.searchValue = this.search;
        this.categories = this.initialCategories;
        this.getCategories();
    },
    methods: {
        paginate(pageSize, pageNumber) {
            // Get all categories and split them at the intended place.
            let categoriesToParse = this.categories;
            this.paginatedCategories = categoriesToParse.slice(
                pageNumber * pageSize,
                (pageNumber + 1) * pageSize
            );
        },
        onPageChanged(page) {
            this.paginate(this.perPage, page - 1);
        },
        async getCategories() {
            this.loading = true;
            const response = await axios.post(`/leagues/categories/search`, {
                search: this.searchValue,
            });
            this.categories = response.data.categories;
            this.loading = false;
            this.totalCategories = response.data.categories.length;
            this.paginate(this.perPage, 0);
        },
        selectCategory(category) {
            this.$emit("category-selected", category);
        },
    },
};
</script>

<style scoped></style>
