<template>
    <div class="page-container">
        <b-row class="desktop-view">
            <b-col cols="12" lg="10" class="mx-auto">
                <a :href="`/${league.slug}`">
                    <h1 class="font-weight-bold mb-5">{{ league.name }}</h1>
                </a>
                <div class="mobile-view">
                    <b-dropdown
                        variant="outline-purple-purple"
                        class="d-lg-none"
                    >
                        <template v-slot:button-content>
                            <span class="text-capitalize mr-2">{{
                                activeSection
                            }}</span>
                        </template>
                        <b-dropdown-item
                            :active="activeSection === 'general'"
                            @click="updateActiveSection('general')"
                            >General Info</b-dropdown-item
                        >
                        <b-dropdown-item
                            :active="activeSection === 'payment-account'"
                            @click="updateActiveSection('payment-account')"
                            >Payment Account</b-dropdown-item
                        >
                        <b-dropdown-item
                            v-if="paymentAccount && !paymentAccount.pastDue"
                            :active="activeSection === 'subscription'"
                            @click="updateActiveSection('subscription')"
                            >Subscription</b-dropdown-item
                        >
                        <b-dropdown-item
                            :active="activeSection === 'subscribers'"
                            @click="updateActiveSection('subscribers')"
                            >Members</b-dropdown-item
                        >
                        <b-dropdown-item
                            :active="activeSection === 'permissions'"
                            @click="updateActiveSection('permissions')"
                            >Permissions</b-dropdown-item
                        >
                        <b-dropdown-item
                            :active="activeSection === 'member-requests'"
                            @click="updateActiveSection('member-requests')"
                            >Member Requests<b-badge
                                variant="danger"
                                pill
                                class="badge-top-right member-request-badge-top-right"
                                >{{ memberRequests.length }}</b-badge
                            ></b-dropdown-item
                        >

                        <b-dropdown-item
                            :active="activeSection === 'earnings'"
                            @click="updateActiveSection('earnings')"
                            >Earnings</b-dropdown-item
                        >
                    </b-dropdown>
                </div>
                <b-nav justified tabs class="mb-5 desktop-view-hide">
                    <b-nav-item
                        :active="activeSection === 'general'"
                        @click="updateActiveSection('general')"
                    >
                        General Info
                    </b-nav-item>
                    <b-nav-item
                        :active="activeSection === 'payment-account'"
                        @click="updateActiveSection('payment-account')"
                    >
                        Payment Account
                    </b-nav-item>
                    <!-- Only show the subscription tab if the league has a payment account -->
                    <b-nav-item
                        v-if="paymentAccount && !paymentAccount.pastDue"
                        :active="activeSection === 'subscription'"
                        @click="updateActiveSection('subscription')"
                    >
                        Subscription
                    </b-nav-item>
                    <b-nav-item
                        :active="activeSection === 'subscribers'"
                        @click="updateActiveSection('subscribers')"
                    >
                        Members
                    </b-nav-item>
                    <b-nav-item
                        :active="activeSection === 'permissions'"
                        @click="updateActiveSection('permissions')"
                    >
                        Permissions
                    </b-nav-item>
                    <b-nav-item
                        :active="activeSection === 'member-requests'"
                        @click="updateActiveSection('member-requests')"
                    >
                        Member Requests
                    </b-nav-item>
                    <b-badge
                        variant="danger"
                        pill
                        class="badge-top-right member-request-badge-top-right"
                        >{{ memberRequests.length }}</b-badge
                    >
                    <b-nav-item
                        :active="activeSection === 'earnings'"
                        @click="updateActiveSection('earnings')"
                    >
                        Earnings
                    </b-nav-item>

                </b-nav>
                <component
                    :is="activeSectionComponent"
                    :auth-user="authUser"
                    :league="league"
                    :permissions="permissions"
                    :payment-account="paymentAccount"
                    :categories="leagueCategories"
                    :balance="balance"
                    :member-requests="memberRequests"
                ></component>
            </b-col>
        </b-row>
    </div>
</template>

    

<script>
import LeagueSettingsGeneral from "./LeagueSettingsGeneral";
import LeagueSettingsMembers from "./LeagueSettingsMembers";
import LeagueSettingsPermissions from "./LeagueSettingsPermissions";
import LeagueSettingsPaymentAccount from "./LeagueSettingsPaymentAccount";
import LeagueSettingsSubscription from "./LeagueSettingsSubscription";
import LeagueSettingsMemberRequest from "./LeagueSettingsMemberRequest";

export default {
    name: "LeagueSettings",
    components: {
        LeagueSettingsSubscription,
        LeagueSettingsGeneral,
        LeagueSettingsPaymentAccount,
        LeagueSettingsPermissions,
        LeagueSettingsMembers,
        LeagueSettingsMemberRequest,
    },
    props: {
        section: {
            type: String,
            default: "general",
        },
        authUser: {
            type: Object,
            required: true,
        },
        league: {
            type: Object,
            required: true,
        },
        permissions: {
            type: Object,
            required: true,
        },
        leagueCategories: {
            type: Array,
            required: true,
        },
        balance: {
            type: Object,
        },
        paymentAccount: Object,
        memberRequests: {
            type: Array,
        },
    },
    data() {
        return {
            activeSection: "general",
        };
    },
    mounted() {
        this.activeSection = this.section;
    },
    computed: {
        activeSectionComponent() {
            switch (this.activeSection) {
                case "general":
                    return "league-settings-general";
                case "payment-account":
                    return "league-settings-payment-account";
                case "subscription":
                    if (this.paymentAccount && !this.paymentAccount.pastDue) {
                        return "league-settings-subscription";
                    }
                    return "league-settings-general";
                case "permissions":
                    return "league-settings-permissions";
                case "subscribers":
                    return "league-settings-members";
                case "member-requests":
                    return "league-settings-member-request";
                case "earnings":
                    return "league-settings-earnings";
                default:
                    return null;
            }
        },
    },
    methods: {
        updateActiveSection(newSection) {
            this.activeSection = newSection;

            // Change url to retain location on reload (but dont reload page currently)
            window.history.pushState(null, "", `?section=${newSection}`);
        },
    },
};
</script>

<style scoped>
.member-request-badge-top-right {
    position: relative;
    left: -20px;
    height: 24px;
    padding-top: 6px;
}
.mobile-view {
    display: none;
  }
  .desktop-view-hide {
    display: flex;
  }
@media(max-width: 767px) {
  .mobile-view {
    display: block;

  }
  .desktop-view-hide {
    display: none;
  }
  .b-dropdown{
      width: 100%;
  }
  /* .btn-group > .btn, .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: inherit;
} */
.dropdown-menu.show {
    display: block;
    width: 100% !important;
    border: 2px solid #6e6bf8 !important;
    text-align: center  !important;
}
.member-request-badge-top-right{
    position: relative;
    left: 7px;
    height: 24px;
    padding-top: 6px;
    width: 24px;
}
.dropdown-menu{
    width: 100% !important;
}
.dropdown .b-dropdown{
    margin-bottom: 5px;;
}

}
</style>
