<template>
    <div>
        <div v-if="files.length >= 1">
            <a @click="downloadProduct($event)" target="_blank">
                <b-button
                    pill
                    block
                    variant="purple"
                    type="button"
                    >Download</b-button
                >
            </a>
        </div>
        <div v-if="files.length == 0" class="text-red text-xs">
            File not found. Please contact the league owner.
        </div>
    </div>
</template>

<script>
export default {
    name: "StoreDownloadItem",
    props: {
        files: {
            //Files Object
            type: Array,
            default: false,
        },
        itemId: {
            type: Number,
            default: 0,
        },
        orderId: {
            type: Number,
            default: 0,
        },
    },
    mounted() {
        
    },
    methods: {
        downloadProduct($event) {
            $event.preventDefault();
            window.location = `/store/download?productId=${this.itemId}&orderId=${this.orderId}`;
            
            // Add slight delay to allow remaining products to update.
            setTimeout(() => {
                this.$emit("product-downloaded");
            }, 1000);
        },
    },
};
</script>
