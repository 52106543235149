<template>
    <b-list-group ref="messages" flush class="messages-list">
        <infinite-loading
            direction="top"
            @infinite="getMoreMessages"
            spinner="spiral"
        >
            <div slot="no-more"></div>
            <div slot="no-results"></div>
        </infinite-loading>
        <contact-message-item
            v-if="messages.length > 0"
            v-for="message in messages"
            :key="message.id"
            :message="message"
            :auth-user-id="authUserId"
            :conversation-id="conversationId"
            @message-edited="messageEdited"
            @message-deleted="messageDeleted"
        ></contact-message-item>
    </b-list-group>
</template>

<script>
import ContactMessageItem from "../ListItems/ContactMessageItem";
import InfiniteLoading from "vue-infinite-loading";

export default {
    name: "ContactMessagesList",
    components: { InfiniteLoading, ContactMessageItem },
    props: {
        authUserId: {
            type: Number,
            required: true,
        },
        leagueId: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            loading: false,
            messages: [],
            totalMessages: 0,
            currentPage: 2,
            perPage: 10,
            conversationId: null,
            messageInterval: null
        };
    },
    mounted() {
        this.messages = this.initialMessages;
        this.totalMessages = this.messageCount;
        this.scrollToEnd();
    },
    watch: {
        messageCount: function () {
            this.totalMessages = this.messageCount;
        },
    },
    methods: {
        init() {
            this.getMessages();
            this.messageInterval = setInterval(this.getNewMessages, 12000);
        },
        addMessage(message) {
            this.messages.push(message);
            this.scrollToEnd();
        },
        async getMessages() {
            try {
                const data = {
                    leagueId: this.leagueId
                }

                const response = await axios.post("/inbox/support/getChat", data);
                this.conversationId = response.data.chatId;

                this.$emit('conversation-updated', this.conversationId);

                const chatResponse = await axios.post("/inbox/getChat", {
                    chatId: this.conversationId,
                });

                this.messages = chatResponse.data.messages;
                this.loading = false;
                this.scrollToEnd();
            } catch (error) {
                // If we fail to get messages, we actually want to hide the whole window
                // TODO: HIDE

            }

        },
        // This method was pulled in from MessageList.vue and doesn't work right now because
        // we're in an embedded div.
        // TODO: Make scroll only within this container
        scrollToEnd() {
            setTimeout(()=>{
                const content = this.$refs.messages;
                content.scrollTop = content.scrollHeight + 100;
            },100);
        },
        messageEdited(updatedMessage) {
            // Find message item
            const index = this.messages.findIndex(
                (message) => message.id === updatedMessage.id
            );
            // force a rerender because vue will not notice if we change a child of an array otherwise
            Vue.set(this.messages, index, updatedMessage);
        },
        messageDeleted(id) {
            // Find message item
            const index = this.messages.findIndex(
                (message) => message.id === id
            );
            this.messages.splice(index, 1);
        },
        async getMoreMessages($state) {
            if (this.messages.length < this.totalMessages) {
                try {
                    const requestData = {
                        chatId: this.conversationId,
                        per_page: this.perPage,
                        page: this.currentPage,
                    };
                    const { data } = await axios.post(
                        "/inbox/messages",
                        requestData
                    );

                    if (data.messages.length) {
                        this.totalMessages = data.total;
                        this.currentPage += 1;
                        this.messages = this.messages.unshift(...data.messages);
                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                } catch (error) {
                    // If there is an error for some reason, show alert
                    await this.$swal(error.response.data.message);
                }
            } else {
                $state.complete();
            }
        },
        async getNewMessages() {
            const requestData = {
                chatId: this.conversationId,
                per_page: 1000, // Need to get ALL messages when we check for new ones
                page: 0,
                newer: true
            };

            const { data } = await axios.post(
                "/inbox/messages",
                requestData
            );

            if (data.messages.length) {
                this.totalMessages = data.total;
                this.messages = this.messages.push(...data.messages);

                this.scrollToEnd();
            }

            // No failure catch here, we can just do nothing if this request fails.
        }
    },
};
</script>

<style scoped>
.messages-list {
    max-height:60vh;
    overflow-y: scroll;

}
@media only screen and (max-width: 1200px) {
    .messages-list {
    max-height:40vh;
    overflow-y: scroll;
    }
}

@media only screen and (max-width: 900px) {
    .messages-list {
    max-height:45vh;
    overflow-y: scroll;
    }
}
@media only screen and (max-width: 500px) {
    .messages-list {
    max-height:40vh;
    overflow-y: scroll;
    }
}
@media only screen and (max-width: 360px) {
    .messages-list {
    max-height:40vh;
    overflow-y: scroll;
    }
}
</style>
