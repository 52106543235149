<template>
    <div>
        <div class="user-profile-header">
            <div class="banner-container">
                <!-- Banner Image -->
                <div
                    v-if="!bannerIsVideo"
                    class="banner-image"
                    :style="bannerStyles"
                ></div>
                <!-- Banner Video -->
                <div v-else class="bg-black">
                    <div>
                        <div 
                            class="d-flex position-relative" 
                            style="overflow:hidden; width:100%; align-content:center;"
                            :style="this.$mq === 'sm' ? `height:${videoHeight}px` : 'height:350px'"
                        >
                            <video
                                muted
                                autoplay
                                playsinline
                                loop
                                class="position-absolute"
                                :style="setVideoCenter"
                                height="auto"
                                @loadedmetadata="getVideoDimensions"
                            >
                                <source v-if="bannerImage.split('.').slice(-1)[0].toLowerCase() === 'mp4'" :src="bannerImage" type="video/mp4" />
                                <source v-if="bannerImage.split('.').slice(-1)[0].toLowerCase() === 'webm'"  :src="bannerImage" type="video/webm" />
                                <source v-if="bannerImage.split('.').slice(-1)[0].toLowerCase() === 'ogg'" :src="bannerImage" type="video/ogg" />
                                <source v-if="bannerImage.split('.').slice(-1)[0].toLowerCase() === 'm3u8'"  :src="bannerImage" type="application/x-mpegURL" />
                            </video>
                        </div>
                    </div>
                </div>
                <div v-show="editable" class="banner-overlay">
                    <b-btn
                        class="wh-center btn-padded"
                        :class="this.$mq === 'sm' ? 'top-0 mt-5' : null"
                        variant="outline-light"
                        pill
                        @click="$emit('editBanner')"
                        >Change Banner Photo</b-btn
                    >
                </div>
            </div>

            <b-container>
                <b-row>
                    <b-col cols="auto">
                        <div
                            class="profile-container header-content"
                            :style="{ marginTop: profilePhotoMargin }"
                        >
                            <div class="profile-container">
                                <b-avatar
                                    variant="grey"
                                    :src="profilePhoto"
                                    :size="profilePhotoHeight"
                                    class="profile-avatar"
                                ></b-avatar>
                                <div v-show="editable" class="profile-overlay">
                                    <!--  
                                        @click="$refs.open-input-file.click()"                        
                                    -->
                                    <b-btn
                                        class="wh-center"
                                        variant="outline-light"
                                        pill
                                        @click="$emit('editProfile')"
                                    >
                                        <i class="fas fa-plus"></i>
                                    </b-btn>
                                </div>
                            </div>
                        </div>
                    </b-col>
                    <b-col v-if="!small">
                        <div class="d-none">
                            <b-dropdown
                                right
                                class="mt-3 ml-auto"
                                variant="text"
                                no-flip
                                no-caret
                            >
                                <template v-slot:button-content>
                                    <i class="fas fa-bars fa-fw fa-2x"></i>
                                </template>
                                <b-dropdown-item
                                    :active="activeTab === 'about'"
                                    @click="updateActiveTab('about')"
                                    >About</b-dropdown-item
                                >
                                <b-dropdown-item
                                    :active="activeTab === 'leagues'"
                                    @click="updateActiveTab('leagues')"
                                    >My Leagues</b-dropdown-item
                                >
                                <!--<b-dropdown-item
                                    :active="activeTab === 'media'"
                                    @click="updateActiveTab('media')"
                                    >Media</b-dropdown-item
                                >-->
                                <b-dropdown-item
                                    :active="activeTab === 'friends'"
                                    @click="updateActiveTab('friends')"
                                    >Friends</b-dropdown-item
                                >
                                <b-dropdown-item
                                    v-if="showEdit"
                                    href="/settings"
                                    >Settings</b-dropdown-item
                                >
                                <b-dropdown-item
                                    v-else
                                    @click="$emit('open-block-user-modal')"
                                    >Block User</b-dropdown-item
                                >
                            </b-dropdown>
                        </div>
                        <b-navbar
                            type="light"
                            toggleable="md"
                            class="d-none d-md-flex align-items-center mt-3"
                        >
                            <b-navbar-nav
                                class="text-right text-md-left mr-auto mx-md-auto mr-lg-0"
                            >
                                <b-nav-item
                                    :active="activeTab === 'about'"
                                    @click="updateActiveTab('about')"
                                    class="mr-md-5"
                                >
                                    About
                                </b-nav-item>
                                <b-nav-item
                                    :active="activeTab === 'leagues'"
                                    @click="updateActiveTab('leagues')"
                                    class="mr-md-5 text-nowrap"
                                >
                                    My Leagues
                                </b-nav-item>
                                <!--<b-nav-item
                                    :active="activeTab === 'media'"
                                    @click="updateActiveTab('media')"
                                    class="mr-md-5"
                                >
                                    Media
                                </b-nav-item>-->
                                <b-nav-item
                                    :active="activeTab === 'friends'"
                                    @click="updateActiveTab('friends')"
                                    class="mr-md-5"
                                >
                                    Friends
                                </b-nav-item>
                            </b-navbar-nav>
                            <b-navbar-nav>
                                <b-nav-item-dropdown right>
                                    <template v-slot:button-content>
                                        <i class="fas fa-cog fa-fw fa-lg"></i>
                                    </template>
                                    <b-dropdown-item
                                        v-if="showEdit"
                                        href="/settings"
                                        >Settings</b-dropdown-item
                                    >
                                    <b-dropdown-item
                                        v-else
                                        @click="$emit('open-block-user-modal')"
                                        >Block User</b-dropdown-item
                                    >
                                </b-nav-item-dropdown>
                            </b-navbar-nav>
                        </b-navbar>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>
export default {
    name: "UserProfileHeader",
    data(){
        return{
            videoHeight: null,
        }
    },
    props: {
        profilePhoto: {
            type: String,
            default: null,
        },
        bannerImage: {
            type: String,
        },
        small: {
            type: Boolean,
            default: false,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        activeTab: String,
        showEdit: {
            type: Boolean,
            default: false,
        },
        zoomControls:{ 
            type: Number,
            default: 0,
        },
        isUserInfoForm:{
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            userAvatar: null,
        };
    },
    computed: {
        bannerIsVideo() {
            if (this.bannerImage) {
                const videoExtensions = ["mp4", "qt", "mov"];
                const extension = this.bannerImage.split(".").pop();

                return videoExtensions.includes(extension);
            }
            return null;
        },
        hideGradient() {
            return !this.bannerImage;
        },
        banner() {
            if (this.bannerImage) {
                return this.bannerImage;
            }
            if (this.editable) {
                return this.small
                    ? "/images/backgrounds/default-banner.jpg"
                    : "/images/backgrounds/default-banner@2x.jpg";
            }
            return null;
        },
        bannerHeight() {
            return this.small ? "200px" : "350px";
        },
        bannerStyles() {
            let background = [];

            if (this.bannerImage) {
                background.push(
                    "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.41) 100%)"
                );
            }

            if (this.banner === null) {
                background.push(
                    "linear-gradient(193deg, rgba(110,107,248,0.4) 0%, rgba(84,81,222,1) 100%)"
                );
            } else {
                background.push(`url(${this.banner})`);
            }

            return {
                borderRadius: this.small ? "3px" : 0,
                height: this.bannerHeight,
                backgroundImage: background.join(", "),
            };
        },
        profilePhotoHeight() {
            return this.small ? "150px" : "200px";
        },
        profilePhotoMargin() {
            return this.small ? "-8rem" : "-6.5rem";
        },
        navMargin() {
            return "-5rem";
        },
        topProperty(){
            let top = 0;
            //When the banner's width is full-screen
            if(this.zoomControls >= 130 && !this.isUserInfoForm){
                return top = (window.innerWidth / 15) * this.zoomControls / 100; //above 130%
            } 
            if(this.zoomControls <= 120 && !this.isUserInfoForm) {
                if(this.$mq === 'sm'){
                    return this.zoomControls === 120 ? top = 20 : top = 0;
                } if(this.$mq === 'md'){
                    if(window.innerWidth >= 1024 && window.innerWidth <= 1069 ){
                        return this.zoomControls === 120 ? top = 55 : top = 35; //iPad PRO
                    } else {
                        return this.zoomControls === 120 ? top = 30 : top = 10; //iPad
                    }
                } if(this.$mq === 'lg'){
                    if(window.innerWidth >= 1070 && window.innerWidth <= 1418 ){
                        return this.zoomControls === 120 ? top = 70 : top = 50; //Laptop
                    } else {
                        return this.zoomControls === 120 ? top = 140 : top = 100; //Desktop
                    }
                }
            }
            //When the banner's width is fixed (783 x 350)
            if(this.zoomControls >= 130 && this.isUserInfoForm){ 
                if(this.$mq === 'lg') return top = ((this.zoomControls - 100) / 10 ) * 7.5; // top ranges from 22.5 at 130% to 75 at 200%
                if(this.$mq === 'md') return top = ((this.zoomControls - 100) / 10 ) * 4.28; // top ranges from 24 at 130% to 55 at 200%
                if(this.$mq === 'sm') return top = ((this.zoomControls - 100) / 10 ) * 2;  //  top ranges from 6 at 130% to 35 at 200%
            } if(this.zoomControls <= 120 && this.isUserInfoForm){ 
                if(this.$mq === 'lg') return top = 15; else return top = 0; // Only lg screens require 'top:-15%'
            } 
        },
        setVideoCenter(){
            let right = w => Math.round((w - 100) / 10) * 5;
            return `width:${this.zoomControls}%; right:-${right(this.zoomControls)}%; top:-${this.topProperty}%;`;
        },
        
    },
    methods: {
        updateActiveTab(tab) {
            this.$emit("change-active-tab", tab);
        },
        getVideoDimensions (e) {
            if(this.zoomControls >= 130) return this.videoHeight = 207 //fixed
            else return this.videoHeight = e.target.offsetHeight //dynamic
        },
    },
};
</script>

<style scoped>
.nav-link {
    font-size: 20px;
}
.nav-link.active {
    color: #5451de !important;
    font-weight: bold;
}
</style>
