<template>
    <div>
        <b-form v-if="!signInHere" @submit.prevent="submitForm">
            <h6 v-if="whiteTheme" class="my-4 font-weight-bolder">
                Register your new account
            </h6>
            <b-form-group :label="whiteTheme ? null : 'Email'" label-for="email-input">
                <b-form-input
                    id="email-input"
                    type="text"
                    v-model.trim="form.email"
                    :state="validateInput('email')"
                    @change="$v.form.email.$touch()"
                    :class="whiteTheme ? 'form-control-white' : 'form-control-black'"
                    :placeholder="whiteTheme ? 'Email' : null"
                ></b-form-input>
                <invalid-feedback :errors="emailErrors"></invalid-feedback>
            </b-form-group>
            <b-form-group :label="whiteTheme ? null : 'Password'" label-for="password-input">
                <b-form-input
                    id="password-input"
                    type="password"
                    v-model.trim="form.password"
                    :state="validateInput('password')"
                    @change="$v.form.password.$touch()"
                    :class="whiteTheme ? 'form-control-white' : 'form-control-black'"
                    :placeholder="whiteTheme ? 'Choose a Password' : null"
                ></b-form-input>
                <invalid-feedback :errors="passwordErrors"></invalid-feedback>
            </b-form-group>
            <b-form-group
                :label="whiteTheme ? null : 'Confirm Password'"
                label-for="password-confirm-input"
                class="mb-5"
            >
                <b-form-input
                    id="password-confirm-input"
                    type="password"
                    v-model.trim="form.password_confirmation"
                    :state="validateInput('password_confirmation')"
                    @change="$v.form.password_confirmation.$touch()"
                    :class="whiteTheme ? 'form-control-white' : 'form-control-black'"
                    :placeholder="whiteTheme ? 'Confirm your Password' : null"
                ></b-form-input>
                <invalid-feedback
                    :errors="passwordConfirmErrors"
                ></invalid-feedback>
            </b-form-group>
            <b-form-row>
                <b-col cols="12" md="6">
                    <b-form-group :label="whiteTheme ? null : 'First Name'" label-for="first-name-input">
                        <b-form-input
                            id="first-name-input"
                            type="text"
                            v-model.trim="form.first_name"
                            :state="validateInput('first_name')"
                            @change="$v.form.first_name.$touch()"
                            :class="whiteTheme ? 'form-control-white' : 'form-control-black'"
                            :placeholder="whiteTheme ? 'First Name' : null"
                        ></b-form-input>
                        <invalid-feedback
                            :errors="firstNameErrors"
                        ></invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group :label="whiteTheme ? null : 'Last Name'" label-for="last-name-input">
                        <b-form-input
                            id="last-name-input"
                            type="text"
                            v-model.trim="form.last_name"
                            :state="validateInput('last_name')"
                            @change="$v.form.last_name.$touch()"
                            :class="whiteTheme ? 'form-control-white' : 'form-control-black'"
                            :placeholder="whiteTheme ? 'Last Name' : null"
                        ></b-form-input>
                        <invalid-feedback
                            :errors="lastNameErrors"
                        ></invalid-feedback>
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-group :label="whiteTheme ? null : 'Birthday'" label-for="birthday-input2" class="mb-5">
                <b-form-datepicker
                    id="birthday-input2"
                    v-model.trim="form.birth_date"
                    :state="validateInput('birth_date')"
                    @change="$v.form.birth_date.$touch()"
                    :class="whiteTheme ? 'form-control-white' : 'form-control-black'"
                    dark
                    show-decade-nav
                    no-flip
                    :max="maxDate"
                    :placeholder="whiteTheme ? 'Birthday' : null"
                ></b-form-datepicker>
                <invalid-feedback :errors="birthdayErrors"></invalid-feedback>
            </b-form-group>
            <b-form-group class="mb-5">
                <b-form-checkbox name="terms" v-model="form.terms">I agree to the <a href="#" @click="toggleTermsModal">Terms of Use</a></b-form-checkbox>
                <invalid-feedback :errors="termsErrors"></invalid-feedback>
            </b-form-group>
            <submit-button 
                :loading="loading" 
                :text="whiteTheme ? 'Continue' : 'Next'" 
                :disabled="hasError()"
                :class="whiteTheme ? 'bg-red' : null"
                :style="whiteTheme ? 'box-shadow: 0 6px 6px -6px black;' : null"
            >
            </submit-button>
            <div v-if="whiteTheme" class="mt-4" >
                <a href="#" @click="signInHere = !signInHere"> Already a member? Sign in here.</a>
            </div>
            <terms-modal
                :modal-open.sync="showTerms"
            ></terms-modal>
        </b-form>
        
        <!-- Login Form -->
        <div v-if="signInHere">
            <h6 class="my-4 font-weight-bolder text-center">
                Login with your existing account
            </h6>
            <login-form
                class="mt-5"
                :white-theme="signInHere"
            ></login-form>
            <p class="float-right mt-3">Don't have an account? <a href="#" @click="signInHere = !signInHere">Sign up here!</a></p>
        </div>
    </div>
</template>

<script>
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";
import InvalidFeedback from "../FormComponents/InvalidFeedback";
import SubmitButton from "../../Buttons/SubmitButton";
import TermsModal from "../../Modals/TermsModal";
import LoginForm from "../../Forms/LoginForm";

export default {
    name: "UserRegistrationForm",
    components: {TermsModal, SubmitButton, InvalidFeedback, LoginForm },
    props: {
        email: {
            type: String,
            required: false,
        },
        whiteTheme: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        const maxDate = new Date();
        maxDate.setFullYear(maxDate.getFullYear() - 18);
        return {
            loading: false,
            form: {
                email: "",
                password: "",
                password_confirmation: "",
                first_name: "",
                last_name: "",
                birth_date: null,
                terms: false
            },
            maxDate,
            showTerms: false,
            isModalOpen: false,
            signInHere: false,
            
        };
    },
    validations: {
        form: {
            email: { required, email },
            password: { 
                required, 
                minLength: minLength(6), 
                digitrequired(password) {
                    return (
                        /[0-9]/.test(password)
                    );
                },
                uppercase(password) {
                    return (
                        /[A-Z]/.test(password)
                    );
                },
                lowercase(password) {
                    return (
                        /[a-z]/.test(password)
                    );
                },
                specialChar(password) {
                    return (
                        /[!@#\$%\^\&*\)\(+=._-]/.test(password)
                    );
                } 
            },
            password_confirmation: {
                required,
                minLength: minLength(6),
                sameAsPassword: sameAs("password"),                
            },
            first_name: { required },
            last_name: { required },
            birth_date: { required },
            terms: { required },
        },
    },
    mounted() {
        this.form.email = this.email;
    },
    computed: {
        /* Validation Errors */
        emailErrors() {
            const errors = [];
            if (!this.$v.form.email.$dirty) return errors;
            !this.$v.form.email.required && errors.push("Email is required");
            !this.$v.form.email.email &&
                errors.push("Should be an Email Address");
            return errors;
        },
        passwordErrors() {
            const errors = [];
            if (!this.$v.form.password.$dirty) return errors;
            !this.$v.form.password.required &&
                errors.push("Password is required");
            !this.$v.form.password.minLength &&
                errors.push("Password must be at least 6 characters long");
            !this.$v.form.password.digitrequired &&
                errors.push("Must contain at least 1 digit");
            !this.$v.form.password.uppercase &&
                errors.push("Must contain at least 1 uppercase letter");
            !this.$v.form.password.lowercase &&
                errors.push("Must contain at least 1 lowercase letter");
            !this.$v.form.password.specialChar &&
                errors.push("Must contain at least 1 special character");
            return errors;
        },
        passwordConfirmErrors() {
            const errors = [];
            if (!this.$v.form.password_confirmation.$dirty) return errors;
            !this.$v.form.password_confirmation.required &&
                errors.push("Password Confirmation is required");
            !this.$v.form.password.minLength &&
                errors.push("Password must be at least 6 characters long");
            !this.$v.form.password_confirmation.sameAsPassword &&
                errors.push("Passwords do not match");
            return errors;
        },
        firstNameErrors() {
            const errors = [];
            if (!this.$v.form.first_name.$dirty) return errors;
            !this.$v.form.first_name.required &&
                errors.push("First name is required");
            return errors;
        },
        lastNameErrors() {
            const errors = [];
            if (!this.$v.form.last_name.$dirty) return errors;
            !this.$v.form.last_name.required &&
                errors.push("Last name is required");
            return errors;
        },
        birthdayErrors() {
            const errors = [];
            if (!this.$v.form.birth_date.$dirty) return errors;
            !this.$v.form.birth_date.required &&
                errors.push("Birthday is required");
            return errors;
        },
        termsErrors() {
            const errors = [];
            if (this.form.terms === false) {
                errors.push("You must agree to the Terms of Use");
            }

            return errors;
        },
    },
    methods: {
        toggleTermsModal() {
            this.showTerms = !this.showTerms;
        },
        validateInput(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        async submitForm() {
            this.$v.form.$touch();

            if (!this.$v.form.$anyError) {
                this.loading = true;
                try {
                    // Attempt to login
                    const response = await axios.post(
                        "/register/submit",
                        this.form
                    );
                    if(!this.whiteTheme){    
                        // Redirect once logged in
                        window.location = "/welcome";
                    } else{
                        this.$emit("plans-object", response);
                        this.$emit('next-step', 1)
                    }
                } catch (error) {
                    // If there is an error for some reason, show alert
                    await this.$swal(error.response.data.message);
                    this.loading = false;
                }
            }
        },
        hasError() {
            let result =  this.emailErrors.length > 0
                       || this.passwordErrors.length > 0
                       || this.passwordConfirmErrors.length > 0
                       || this.firstNameErrors.length > 0
                       || this.lastNameErrors.length > 0
                       || this.birthdayErrors.length > 0
                       || this.termsErrors.length > 0;
            return result;
        },
    },
};
</script>
