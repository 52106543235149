<template>
  <b-btn type="submit" :variant="redTheme ? 'red' : 'purple'" :disabled="loading || disabled" pill block @click="$emit('click')">
    <b-spinner v-show="loading" small class="mr-2"></b-spinner>
    {{ buttonText }}
  </b-btn>
</template>

<script>
  export default {
    name: "SubmitButton",
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      text: {
        type: String,
        default: "Submit"
      },
      submitText: {
        type: String,
        default: "Submitting..."
      },
      redTheme: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      buttonText() {
        return this.loading? this.submitText : this.text;
      }
    }
  }
</script>

<style scoped>

</style>
