<template>
    <b-card class="text-center drop-shadow">
        <b-row class="align-items-center" no-gutters>
            <b-col cols="12" sm="4" lg="12">
                <b-avatar
                    variant="grey"
                    :src="userAvatar"
                    class="responsive-size"
                ></b-avatar>
            </b-col>
            <b-col cols="12" sm="8" lg="12">
                <b-col>
                    <b-card-title class="font-weight-bold mt-3 mb-4">{{
                        userName
                    }}</b-card-title>
                </b-col>
                <b-col>
                    <b-row>
                        <b-col cols="12" md="4" lg="12" class="mx-auto">
                            <a
                                :href="`/u/${this.user.id}?section=friends`"
                                class="h6 text-dark stretched-link"
                                >Friends</a
                            >
                            <p class="h5 text-purple">
                                {{ numberAbbrev(user.friends) }}
                            </p>
                        </b-col>
                        <b-col cols="12" md="4" lg="6" class="mx-auto">
                            <a
                                :href="`/u/${this.user.id}?section=leagues`"
                                class="h6 text-dark stretched-link"
                                >Leagues</a
                            >
                            <p class="h5 text-purple">
                                {{ numberAbbrev(user.leagues) }}
                            </p>
                        </b-col>
                        <b-col cols="12" md="4" lg="6" class="mx-auto">
                            <a
                                :href="`/u/${this.user.id}?section=leagues`"
                                class="h6 text-dark stretched-link text-nowrap"
                                >Subscriptions</a
                            >
                            <p class="h5 text-purple">
                                {{ numberAbbrev(user.subscriptions) || 0 }}
                            </p>
                        </b-col>
                    </b-row>
                </b-col>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import { numberAbbreviation } from "../Mixins/numberAbbreviation";

export default {
    name: "AboutMeCard",
    mixins: [numberAbbreviation],
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    computed: {
        userAvatar() {
            return this.user.avatar ? this.user.avatar.file : null;
        },
        userName() {
            return `${this.user.firstName} ${this.user.lastName}`;
        },
    },
};
</script>

<style scoped lang="scss">
.responsive-size {
    font-size: 2.5rem;
    @media (min-width: 768px) {
        font-size: 3.5rem;
    }
}
</style>
