<template>
    <div class="border-bottom p-4">
        <div class="d-flex align-items-center justify-content-between mb-3">
            <h4 class="mb-0">Messages</h4>
            <!-- Cannot currently create conversations from the inbox -->
            <!--<b-btn variant="link">
                <i class="fas fa-edit fa-fw fa-lg"></i>
            </b-btn>-->
        </div>
    </div>
</template>

<script>
export default {
    name: "ChatSearchForm",
    props: ["value"],
    methods: {
        inputChanged(val) {
            this.$emit("input", val);
        },
    },
};
</script>

<style scoped></style>
