<template>
    <b-card :title="'Join ' + leagueName" class="drop-shadow">
        <hr />
        <b-card-text class="mb-2">
            To join this league, register or login
        </b-card-text>
        <b-btn variant="outline-purple-purple"
               @click="isModalOpen = !isModalOpen"
               class="text-nowrap">
            Join League
        </b-btn>
        <membership-plan-modal
            ref="membershipPlanModal"
            :is-modal-open.sync="isModalOpen"
            :league-id="leagueId"
            :league-name="leagueName"
            :league-price="leaguePrice"
            :league-trial="leagueTrial"
        ></membership-plan-modal>
    </b-card>
</template>

<script>
import MembershipPlanModal from "../Modals/MembershipPlanModal";

export default {
    name: "LoginRegisterCard",
    components: { MembershipPlanModal },
    props: {
        leagueName: {
            type: String,
            required: true,
        },
        leagueId: {
            type: Number,
            default: 0,
        },
        leaguePrice: {
            type: String,
            default: "",
        },
      leagueTrial: {
        type: Number,
        default: 0,
      }
    },
    data(){
        return {
            isModalOpen: false,
        }
    },
    mounted() {
     if(this.getUrlParameter('action') === 'register') {
       // If we got action query parameter "register", automatically open dialog
       setTimeout(() => {
         this.isModalOpen = true
       }, 0)
     }

     const email = this.getUrlParameter('email')
     if(email) {
       // If email is pass, pre-populate email from registration form
       this.$refs.membershipPlanModal.setEmail(email)
     }
    },
    methods: {
        loginRegister() {
            // store the league's url for redirect after user login or signup
            // If updating this, see view guest-join as well. It imitates the functionality
            // seen here.
            sessionStorage.setItem("redirectToLeague", window.location.href);
            sessionStorage.setItem("joinLeague", true);
            // redirect to login
            window.location.href = "/";
        },
        getUrlParameter(key) {
          if (!key) return null;

          let uri = window.location.search.substring(1);
          let params = new URLSearchParams(uri);
          return params.get(key);
        }
    }
};
</script>