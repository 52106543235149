<template>
    <b-modal
        id="terms-modal"
        v-model="open"
        centered
        hide-footer
        title="The Inner League Terms of Use"
    >
        <p>These Terms govern your use of theinnerleague.com (the “Site”). By using the Site, you agree to be bound by these Terms and acknowledge and agree to the collection, use and disclosure of your personal information in accordance with the Site’s Privacy Policy.</p>

        <p>The Site is a paid subscription private social network that enables its members to connect with like minded individuals and participate in social and professional activities through “leagues.” Members may create new leagues or join existing leagues. Leagues may be free or subject to an additional subscription fee.</p>

        <strong>1. YOUR USE OF THE SITE</strong>
        <p>Members are granted a limited, non-exclusive, non-transferable, and revocable license to use the Site for social and professional networking and to participate in events and activities hosted by or otherwise accessible through the Site, subject to compliance with these Terms. Unauthorized duplication, modification, distribution, or other unauthorized use of the Site is strictly prohibited and may subject the offender to criminal and civil liability.</p>

        <p>The Site may allow you to upload content such as text, images, and video. You retain all rights in any content that you upload, and are solely responsible for that content, including any intellectual property violations. You grant the Site a non-exclusive, royalty-free, worldwide, transferable, sub-licensable license to use, store, publicly display, publicly perform, reproduce, modify, create derivative works from, and distribute any such content for the purposes of operating, providing, and improving the Site. We may, in our sole discretion, remove or take down any content that you upload or submit for any reason, including violation of these Terms or any other policies, to comply with a court order, or to mitigate potential or identified legal liability.</p>

        <p>In order to use the Site, you will need to create a user account. You are responsible for all conduct and transactions that take place through your account and you agree to take precautions to keep your password and other account information secure. You also agree that you will comply with all applicable laws when accessing or using the Site and you will respect others in your use of the Site. We reserve the right to terminate or suspend accounts at any time in our sole discretion.</p>

        <p>We are constantly modifying and improving the Site and may introduce new features, change existing features, or remove features at any time and without notice. In addition, we may make changes to these Terms from time to time. When we do, we will post the most current version of the Terms on the Site and, if a revision to the Terms is material, we will notify you of the new Terms. If you do not agree to the modified terms, you should discontinue your use of the Site. Any new or renewed memberships will be subject to the terms and conditions in effect at that time.</p>

        <strong>2. MONTHLY SUBSCRIPTION FEE</strong>
        <p>Membership to the Site requires payment of a monthly membership fee. BY DEFAULT (AND WITH PRIOR NOTICE TO THE EXTENT REQUIRED BY APPLICABLE LAW), YOUR MEMBERSHIP WILL AUTOMATICALLY RENEW EACH MONTH, AND THE APPLICABLE MEMBERSHIP FEE WILL BE AUTOMATICALLY CHARGED TO YOU AT THE TIME OF RENEWAL. You can cancel your membership at any time, but you cannot transfer or assign your membership.</p>

        <strong>3. YOUR INFORMATION IS PRIVATE</strong>

        <p>The Site is private and your information will not be sold or shared with any third-party entities without your prior written consent.</p>

        <strong>4. AS-IS; LIMITATION OF LIABILITY</strong>
        <p>The site is provided “as is” and “as available.” To the maximum extent permitted by applicable law, we disclaim all representations, conditions, and warranties, express, legal, implied, or statutory, including the implied warranties or conditions of merchantability, quality, fitness for a particular purpose, durability, title, and non-infringement. In addition, to the maximum extent permitted by applicable law, we make no representation, warranty, conditions, or guarantee regarding the reliability, timeliness, quality, suitability, or availability of the Site. We may terminate access to the Site to you or any other users or stop offering all or part of the Site at any time without notice.</p>

        <p>In no event shall we be liable to you for any incidental, special, punitive, consequential, or indirect damages (including, but not limited to, damages for deletion, corruption, loss of data, loss of programs, or service interruptions) arising out of or in connection with the Site or these Terms.</p>

        <strong>5. INDEMNIFICATION</strong>
        <p>You agree to defend, indemnify and hold harmless the Site and its officers, directors, employees, agents, shareholders, affiliates, and partners (each, an "Indemnified Party") from and against any losses, claims, actions, costs, damages, penalties, fines and expenses, including without limitation attorneys’ and experts’ fees and expenses, that may be incurred by an Indemnified Party arising out of, relating to or resulting from your unauthorized use of the Site or from any breach by you of these Terms, including without limitation any actual or alleged violation of any law, rule or regulation.</p>

        <strong>6. DISPUTES & ARBITRATION</strong>
        <p>If you have a dispute with us arising out of your use of the Site, you agree to contact us first and attempt to work out any such dispute amicably. If we are unable to work out an amicable solution, you agree to attempt to resolve such dispute through binding arbitration or small claims court. The arbitration shall be administered by JAMS under its Comprehensive Rules in effect at the time the arbitration demand is made at a location in Maryland, United States. The current rules, effective July 1, 2014, can be found at: http://www.jamsadr.com/rules-comprehensive-arbitration/. JAMS arbitration rules can be found at: http://www.jamsadr.com/rules-clauses/. In the event of any conflict between the rules and this Agreement, this Agreement shall apply. The Parties agree to submit to the jurisdiction of a single neutral arbitrator selected in accordance with the JAMS Comprehensive Rules.</p>

        <p>The JAMS rules will govern payment of all arbitration fees and each party will be responsible for their own fees under those rules.</p>

        <strong>7. CONTROLLING LAW</strong>
        <p>To the extent permitted by applicable law, these Terms will be governed by the laws of the State of Maryland. To the extent permitted by applicable law, any claims arising out of or relating to these Terms or use of the Site that are not subject to Section 6 (Disputes & Arbitration) of these Terms shall be brought exclusively in a court located in Prince George’s County, Maryland.</p>

        <strong>8. ENTIRE AGREEMENT & SEVERABILITY</strong>
        <p>These Terms shall constitute the entire agreement between you and us with respect to your use of the Site. If any provision of these Terms is found to be invalid by a court of competent jurisdiction, that provision only will be limited to the minimum extent necessary and the remaining provisions will remain in full force and effect.</p>

        <strong>9. NO WAIVER</strong>
        <p>Our failure to monitor or enforce a provision of these Terms does not constitute a waiver of our right to do so in the future with respect to that provision, any other provision, or these Terms as a whole.</p>

        <strong>10. CONTACT INFORMATION</strong>
        <p>For purposes of this Agreement, the terms “we,” “us,” and “our” refer to Inner League LLC. If you have any questions, complaints or comments about the Site contact us at: info@theinnerleague.com.</p>

        <p>Please also see the terms of our trusted partners at <a href="https://stripe.com/connect/legal">Stripe</a> and <a href="https://stripe.com/connect-account/legal">Stripe Connect</a>.</p>

        <p>Updated 2020</p>

    </b-modal>
</template>

<script>
export default {
    name: "TermsModal",
    props: {
        modalOpen: Boolean,
    },
    computed: {
        open: {
            get() {
                return this.modalOpen;
            },
            set(value) {
                this.$emit("update:modalOpen", value);
            },
        },
    }
};
</script>
