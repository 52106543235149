<template>
    <div>
        <b-form @submit.prevent="submitForm">
            <h3 class="h4 font-weight-bold">Privacy</h3>
            <hr class="mt-2 mb-5" />
            <b-form-group
                label="Who can see your profile?"
                label-for="type-select"
            >
                <b-form-select
                    id="type-select"
                    v-model="form.view"
                    :options="permissions.view.options"
                ></b-form-select>
            </b-form-group>
            <b-form-group
                label="Who can send you friend requests?"
                label-for="search-select"
            >
                <b-form-select
                    id="search-select"
                    v-model="form.friend"
                    :options="permissions.friend.options"
                ></b-form-select>
            </b-form-group>
            <b-form-group
                label="Who can invite you to leagues?"
                label-for="post-select"
            >
                <b-form-select
                    id="post-select"
                    v-model="form.invite"
                    :options="permissions.invite.options"
                ></b-form-select>
            </b-form-group>
            <submit-button
                :loading="loading"
                text="Submit"
                class="mt-5"
            ></submit-button>
            <b-form-text
                v-show="saved"
                text-variant="success"
                class="h6 text-center mt-3"
                >Permissions Saved!</b-form-text
            >
        </b-form>
    </div>
</template>

<script>
import SubmitButton from "../../Buttons/SubmitButton";

/** There are no validations on this form **/
export default {
    name: "UserPrivacyForm",
    components: { SubmitButton },
    props: {
        permissions: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            saved: false,
            form: {
                view: 0, // Who can view profile
                friend: 0, // Who can friend user
                invite: 0, // Who can invite user
            },
        };
    },
    mounted() {
        this.form = {
            view: this.permissions.view.selected, // Who can view profile
            friend: this.permissions.friend.selected, // Who can friend user
            invite: this.permissions.invite.selected, // Who can invite user
        };
    },
    computed: {
        submitButtonText() {
            return this.editting ? "Submit" : "Next";
        },
    },
    methods: {
        async submitForm() {
            try {
                this.loading = true;
                // Attempt to update permissions
                const response = await axios.post(
                    "/settings/updatePrivacy",
                    this.form
                );

                this.loading = false;
                this.saved = true;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        },
    },
};
</script>
