<template>
    <div
        class="d-flex align-items-stretch justify-content-around py-1 px-0 mb-2 border-bottom"
    >
        <reaction-picker
            :reactions="reactions"
            :reaction-id="reactionId"
            :parent-id="postId"
            @react="passReaction"
        ></reaction-picker>
        <!-- <create-burst-reply-item
            v-if="showBurstReplyBtn"
            :post="post"
            :variant="'link'"
        ></create-burst-reply-item> -->
        <share-button
            v-show="canShare"
            :league-id="leagueId"
            :post-id="postId"
            @share-via-chat="$emit('share-post', 'chat')"
            @share-via-email="$emit('share-post', 'email')"
        ></share-button>
    </div>
</template>

<script>
import ReactionPicker from "../Forms/FormComponents/ReactionPicker";
import ShareButton from "../Buttons/ShareButton";
import BurstIcon from "../CustomIcons/BurstIcon";
import CreateBurstReplyItem from "../ListItems/CreateBurstReplyItem";

export default {
    name: "PostActionList",
    components: {
        ShareButton,
        ReactionPicker,
        BurstIcon,
        CreateBurstReplyItem
    },
    props: {
        post: {
            type: Object,
            required: true,
        },
        reactionId: Number,
        reactions: {
            type: Array,
            required: true,
        },
        showBurstReplyBtn: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            createBurstModalOpen: false,
        };
    },
    computed: {
        canShare() {
            if (!this.post.league) {
                return false;
            }
            return (
                this.post.league.permissions.shareable === "Yes" &&
                this.post.privacy === 0
            );
        },
        leagueId() {
            if (this.post.league) {
                return this.post.league.id;
            }

            return this.post.leagueId;
        },
        postId() {
            // Check if it's a regular post
            if (this.post.league) {
                return this.post.id;
            }

            // Check if it's a media item text only post
            if (this.post.type == 2) {
                return this.post.postId;
            }

            // Check if it's a media item (image or video)
            return this.post.mediaId;
        },
    },
    methods: {
        passReaction(id) {
            this.$emit("react-to-post", id);
        },
        openCreateBurstModal() {
            this.createBurstModalOpen = true;
        },
    },
};
</script>

<style scoped></style>
