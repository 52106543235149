<template>
    <b-list-group-item
        v-if="!loading"
        class="comment-item pb-0 px-0"
        :class="[
            lastItem ? 'border-bottom-0' : null,
            commentLevelCount >= 2 && !showMoreReplies && repliesLengthAllowed
                ? 'd-none'
                : null,
        ]"
        style="padding: 0"
    >
        <b-row no-gutters class="comment-header">
            <b-col class="col-11">
                <b-media class="align-items-start">
                    <template v-slot:aside>
                        <b-avatar
                            v-if="
                                totalCommentCount <= 1 ||
                                showMoreReplies ||
                                !showMoreReplies
                            "
                            :src="commentItem.posted_by.avatar"
                            variant="grey"
                            :size="avatarSize"
                        ></b-avatar>
                    </template>
                        <!-- if there's more than one reply -->
                        <div>
                            <div v-if="!editing" class="mb-0 editor rounded-xl px-3 py-2">
                                <a
                                    @mouseover="startHover"
                                    @mouseleave="stopHover"
                                    :href="`/u/${commentItem.posted_by.id}`">
                                    <span class="font-weight-bold mb-1">
                                        {{ commentItem.posted_by.name }}
                                    </span>
                                </a>

                              <div v-if="hover" class="drop-shadow" style="position:absolute;top:20px;left:20px;padding:16px; z-index:999;background:white;"  @mouseover="startHover"
                                   @mouseleave="stopHover">
                                <div v-if="userDetails">
                                  <p>
                                    <b-avatar
                                        v-show="hasAuthUser() || post.privacy === 0"
                                        :src="commentItem.posted_by.avatar"
                                        variant="grey"
                                        size="45"
                                    ></b-avatar>
                                    <span style="font-size:150%; font-weight: bold;">
                                       {{ commentItem.posted_by.name }}
                                </span>

                                  </p>
                                  <p align="center" v-if="userDetails && !userDetails.friends &&  !requested">

                                    <button class="btn btn-secondary mt-1" @click="sendMessage">
                                      <i class="fas fa-envelope fa-fw fa-lg"></i> Send Message
                                    </button>

                                    <button class="btn btn-secondary mt-1" @click="requestFriendship">
                                      <i class="fas fa-user-plus fa-fw fa-lg"></i> Add Friend
                                    </button>
                                  </p>

                                  <p align="center" v-if="userDetails && !userDetails.friends && requested">

                                    <button class="btn btn-secondary mt-1" @click="sendMessage">
                                      <i class="fas fa-envelope fa-fw fa-lg"></i> Send Message
                                    </button>

                                    <button class="btn btn-secondary mt-1" disabled>
                                      <i class="fas fa-user-plus fa-fw fa-lg"></i> Pending Friend Request
                                    </button>
                                  </p>


                                  <p align="center" v-if="userDetails && userDetails.friends">
                                    <button class="btn btn-secondary mt-1" @click="sendMessage">
                                      <i class="fas fa-envelope fa-fw fa-lg"></i> Send Message
                                    </button>

                                    <button class="btn btn-secondary mt-1" @click="openRemoveModal">
                                      <i class="fas fa-user-minus fa-fw fa-lg"></i> Remove Friend
                                    </button>
                                  </p>
                                </div>


                                <div v-if="!userDetails">
                                  Loading
                                </div>

                              </div>

                                <span v-html="removeElement" style="word-break:break-word;"></span>
                            </div>

                          <!-- Edit Comment field -->
                          <div v-if="editing">
                            <b-row>
                              <b-col cols="12" md="12">
                                <comment-form
                                    :comment="commentItem"
                                    :league-id="leagueId"
                                    :commentable-id="commentableId"
                                    :commentable-type="commentableType"
                                    @comment-edited="updateComment"
                                    @toggle-comment-form="toggleMainCommentForm"
                                    class="mb-2"
                                    :comment-level-count="commentLevelCount"
                                    ref="editComment"
                                ></comment-form>
                              </b-col>
                              <b-col cols="12" md="12" class="text-right">
                                <b-btn
                                    class="btn text-grey btn-link btn-sm"
                                    v-if="editing"
                                    type="cancel"
                                    variant="ghost-purple"
                                    @click="cancelEditMode()"
                                >
                                  Cancel
                                </b-btn>
                                <b-btn
                                    class="btn text-grey btn-link btn-sm"
                                    v-if="editing"
                                    type="submit"
                                    variant="ghost-purple"
                                    @click="$refs.editComment.submitForm()"
                                >
                                  Update
                                </b-btn>
                              </b-col>
                            </b-row>
                          </div>

                        </div>

                        <div v-if="!editing" class="my-2">
                            <small class="text-grey">{{ displayDate }}</small>
                            <a :href="`#comment-${this.comment.id}`">
                                <b-button
                                    v-if="commentLevelCount < 3"
                                    variant="link"
                                    size="sm"
                                    class="text-grey ml-2"
                                    @click="showCommentForm = !showCommentForm"
                                    >Reply</b-button
                                >
                            </a>
                            <reaction-picker
                                :reactions="reactions"
                                :reaction-id="reactionId"
                                :parent-id="comment.id"
                                small
                                @react="reactToComment"
                            ></reaction-picker>
                            <reaction-counter
                                :reactions="commentItem"
                                :reaction-types="reactions"
                                :counts="reactionCounts"
                            ></reaction-counter>
                        </div>

                </b-media>
            </b-col>
            <b-col class="col-1">
                <div v-if="canEdit || canDelete">
                    <b-dropdown
                        v-if="
                            totalCommentCount <= 1 ||
                            showMoreReplies ||
                            !showMoreReplies
                        "
                        class="post-menu-dropdown ellipsis"
                        variant="btn-ghost-purple"
                        toggle-class="text-decoration-none"
                        right
                        no-caret
                    >
                        <template v-slot:button-content>
                            <i class="fas fa-ellipsis-v text-grey"></i>
                            <span class="sr-only">Comment Menu</span>
                        </template>
                        <b-dropdown-item
                            v-if="canEdit"
                            @click.prevent="toggleEditMode()"
                            >Edit {{ commentItem.parentId ? 'Reply' : 'Comment' }}</b-dropdown-item
                        >
                        <b-dropdown-item @click="showDelete = true"
                            >Delete {{ commentItem.parentId ? 'Reply' : 'Comment' }}</b-dropdown-item
                        >
                    </b-dropdown>
                </div>
            </b-col>
        </b-row>

        <b-row v-if="commentLevelCount < 3">
            <b-col :cols="reduceCols" class="ml-auto">
                <CommentsList
                    :key="`comment-${commentItem.id}`"
                    :show-comment-form="showCommentForm"
                    parent-type="comment"
                    :commentable-type="commentableType"
                    :parent-item="commentItem"
                    :auth-user="authUser"
                    :reactions="reactions"
                    :league-id="leagueId"
                    :class="commentItem.comment_count ? 'border-top-0' : null"
                    @comment-added="commentItem.comment_count++"
                    @comment-removed="commentItem.comment_count--"
                    :comment-level-count="commentLevelCount"
                    :comment-id="comment.id"
                ></CommentsList>
            </b-col>
        </b-row>
        <delete-comment-modal
            v-if="showDelete"
            :modal-open="showDelete"
            :league-id="leagueId"
            :comment-id="commentItem.id"
            :commentable-type="commentableType"
            :commentable-id="commentableId"
            @comment-deleted="commentDeleted"
            @cancel-delete="cancelDelete"
        ></delete-comment-modal>

      <create-chat-modal
          :modal-open.sync="messageModalOpen"
          :auth-user-id="authUser.id"
          :user-id="this.commentItem.posted_by.id"
      ></create-chat-modal>
      <remove-user-modal
          :modal-open.sync="removeModalOpen"
          :user-name="this.commentItem.posted_by.name"
          @remove-user="removeFriend"
      >
      </remove-user-modal>
    </b-list-group-item>
</template>

<script>
import CommentForm from "../Forms/CommentForm";
import DeleteCommentModal from "../Modals/DeleteCommentModal";
import ReactionPicker from "../Forms/FormComponents/ReactionPicker";
import ReactionCounter from "../Forms/FormComponents/ReactionCounter";

import CreateChatModal from "../Modals/CreateChatModal";
import RemoveUserModal from "../Modals/removeUserModal";

export default {
    name: "CommentItem",
    components: {
        ReactionCounter,
        ReactionPicker,
        DeleteCommentModal,
        CommentForm,
      CreateChatModal,
      RemoveUserModal,
    },
    props: {
        authUser: {
            type: Object,
            required: true,
        },
        leagueId: {
            type: Number,
            required: true,
        },
        commentableId: {
            type: Number,
            required: true,
        },
        commentableType: {
            type: String,
            required: true,
        },
        comment: {
            type: Object,
            require: true,
        },
        reactions: {
            type: Array,
            require: true,
        },
        showReply: {
            type: Boolean,
            default: true,
        },
        lastItem: {
            type: Boolean,
            default: true,
        },
        commentLevelCount: {
            type: Number,
            default: 0,
        },
        totalCommentCount: {
            type: Number,
            default: 0,
        },
        repliesLengthAllowed: {
            type: Boolean,
            default: false,
        },
        showMoreReplies: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: true,
            editing: false,
            showDelete: false,
            commentItem: {},
            reactionId: null,
            showCommentForm: false,
            uniqueId: null,
            openedEditForm: null,
            closeCurrentOpenNewEditForm: false,
            hover: false,
            hoverTimer: false,
            userDetails: null,
            checkingDetails: false,
            requested: false,
            messageModalOpen: false,
            removeModalOpen: false,
        };
    },
    mounted() {
        this.commentItem = this.comment;
        this.reactionId = this.comment.reaction;
        this.loading = false;
        this.uniqueId = this._uid;
    },
    computed: {
        displayDate() {
            const date = moment(this.commentItem.created_at);
            return moment().diff(date, "days") > 0
                ? date.format("MMM D")
                : date.format("h:mm A");
        },
        moreComments() {
            return this.comments.length < this.commentItem.comment_count;
        },
        canEdit() {
            return this.commentItem.posted_by.id === this.authUser.id;
        },
        canDelete() {
            return (
                this.authUser.leaguePermissions?.isAdmin ||
                this.authUser.leaguePermissions?.isOwner
            );
        },
        /* TODO: Update when a better structure is provided */
        reactionCounts() {
            if (this.comment) {
                return {
                    1: this.commentItem.like_count,
                    2: this.commentItem.love_count,
                };
            }
            return null;
        },
        reduceCols() {
            if (this.commentLevelCount == 2) {
                return 12;
            } else {
                return 11;
            }
        },
        removeElement() {
            var str = this.commentItem.comment;
            if(this.commentItem.is_edited){
                str += '<small data-v-ee6eb56c="" class="text-grey"> (edited)</small>'
            }
            var str = str.replace(/<p>/g, "").replace(/<\/p>/g, ""); // results in 'b'
            return str;
        },
        avatarSize() {
            if (this.commentLevelCount >= 2) {
                return 30;
            } else {
                return 45;
            }
        },
        toggleShowMore() {
            if (this.totalCommentCount >= 2 && this.showMoreReplies) {
                return true;
            } else {
                return false;
            }
        },
    },
    methods: {
      cancelDelete() {
        this.showDelete = false;
      },
      hasAuthUser() {
        let result =
            this.authUser && Object.keys(this.authUser).length > 0
                ? true
                : false;
        return result;
      },
      openRemoveModal() {
        this.removeModalOpen = true;
      },
      async requestFriendship() {
        try {
          const response = await axios.post(`/users/friends/add`, {
            userId: this.commentItem.posted_by.id,
          });
          this.requested = true;
        } catch (error) {
          // If there is an error for some reason, show alert
          await this.$swal(error.response.data.message);
        }
      },
      async removeFriend() {
        const requestData = {
          friendId: this.userDetails.friends,
        };
        this.loading = true;
        try {
          const response = await axios.post(
              "/users/friends/remove",
              requestData
          );
          this.loading = false;
          this.removeModalOpen = false;
          this.localFriendStatus = 0;
          this.canFriend = true;
          this.userDetails = null;
          await this.getUserDetails();
        } catch (error) {
          // If there is an error for some reason, show alert
          await this.$swal(error.response.data.message);
          this.loading = false;
        }
      },
      sendMessage() {
        this.messageModalOpen = true;
      },
      startHover() {
        if(this.authUser.id !== this.commentItem.posted_by.id) {
          this.hover = true;
          clearTimeout(this.hoverTimer);
          this.getUserDetails();
        }
      },
      stopHover() {
        if( this.authUser.id !== this.commentItem.posted_by.id) {
          let that = this;
          this.hoverTimer = setTimeout(function(){that.hover = false;},500);
        }
      },
      async getUserDetails() {
        // Only grab details if we do not already have it.
        if( !this.userDetails && !this.checkingDetails ) {
          this.checkingDetails = true;
          try {
            const data = {
              userId: this.commentItem.posted_by.id,
            };
            const response = await axios.post( "/users/getUserDetails", data );

            this.userDetails = response.data;

            this.checkingDetails = false;
          } catch (e) {
            await this.$swal(error.response.data.message);
            this.checkingDetails = false;
          }
        }
      },
        async getComments() {
            if (this.moreComments) {
                this.loading = true;
                try {
                    this.currentPage++;

                    const data = {
                        commentableId: this.commentableId,
                        commentableType: this.commentableType,
                        leagueId: this.leagueId,
                        per_page: this.perPage,
                        page: this.currentPage,
                        parentId: this.comment.id,
                    };

                    const response = await axios.post(
                        "/leagues/comments",
                        data,
                    );

                    // Put "new" items on top
                    this.comments = this.comments.concat(
                        response.data.comments,
                    );
                    this.loading = false;
                } catch (error) {
                    // If there is an error for some reason, show alert
                    await this.$swal(error.response.data.message);
                    this.loading = false;
                }
            }
        },
        addComment(comment) {
            this.loading = true;
            this.comments.push(comment);
            this.totalCommentCount++;
            this.loading = false;
            this.$emit("comment-added");
        },
        deleteComment(id) {
            const index = this.comments.findIndex((c) => c.id === id);
            this.comments.splice(index, 1);
            this.totalCommentCount--;
            this.$emit("comment-removed");
        },
        updateComment(form) {
            this.cancelEditMode();
            if(this.commentItem.comment !== form.comment){
                this.commentItem.comment = form.comment
                this.commentItem.is_edited = form.is_edited
                this.$emit("update-comment", form)
            }
        },
        commentDeleted(id) {
            this.$emit("comment-deleted", id);
        },
        toggleEditMode() {
            // this.editing = false;
            var self = this;
            setTimeout(function () {
                self.editing = !self.editing;
                self.toggleMainCommentForm();
            }, 250);
        },
        cancelEditMode() {
            this.toggleMainCommentForm();
            this.editing = false;
        },
        toggleMainCommentForm() {
            this.$emit("toggle-main-comment-form");
        },
        async reactToComment(id) {
            try {
                const data = {
                    parentId: this.comment.id,
                    reactionableType: this.commentableType,
                    reactionableId: this.commentableId,
                    leagueId: this.leagueId,
                    reaction: id,
                };
                const response = await axios.post(
                    "/leagues/reactComment",
                    data,
                );
                this.reactionId = response.data.reaction;
                this.commentItem.like_count = response.data.like_count;
                this.commentItem.love_count = response.data.love_count;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
    },
    directives: {
        clickoutside: {
            bind: function (el, binding, vnode) {
                el.clickOutsideEvent = function (event) {
                    // here I check that click was outside the el and his childrens
                    if (!(el == event.target || el.contains(event.target))) {
                        // and if it did, call method provided in attribute value
                        vnode.context[binding.expression](event);
                    }
                };
                document.body.addEventListener("click", el.clickOutsideEvent);
            },
            unbind: function (el) {
                document.body.removeEventListener(
                    "click",
                    el.clickOutsideEvent,
                );
            },
            stopProp(event) {
                event.stopPropagation();
            },
        },
    },
};
</script>

<style scoped lang="scss">
.comment-item {
    .comment-menu-dropdown {
        display: none;
    }
    .comment-header:hover {
        .comment-menu-dropdown {
            display: block;
        }
    }
}
.mobile-size {
    @media (max-width: 768px) {
        margin-left: -13px !important;
    }
}
.editor {
    overflow: hidden;
    background-color: #e7e7e7;
}
.edit-background {
    background-color: white;
}
</style>
