<template>
    <b-modal
        id="remove-user-modal"
        v-model="open"
        centered
        hide-footer
        hide-header
    >
        <b-container class="p-4">
            <h3 class="font-weight-bold mb-4">
                Are you sure you want to remove
                <span class="text-purple">{{ userName }}</span
                > in <span class="text-purple">{{ leagueName }}</span> League?
            </h3>
            <b-btn
                variant="purple"
                block
                pill
                @click="$emit('remove-user')"
                :disabled="loading"
            >
                <i v-show="loading" class="fas fa-spinner fa-pulse"></i>
                <span v-show="!loading">Yes</span>
            </b-btn>
            <b-btn
                variant="outline-primary"
                block
                pill
                @click="$bvModal.hide('remove-user-modal')"
            >
                No
            </b-btn>
        </b-container>
    </b-modal>
</template>

<script>
export default {
    name: "RemoveUserModal",
    props: {
        modalOpen: {
            type: Boolean,
        },
        userName: String,
        leagueName: String,
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        open: {
            get() {
                return this.modalOpen;
            },
            set(value) {
                this.$emit("update:modalOpen", value);
            },
        },
    },
};
</script>
