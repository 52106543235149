<template>
    <div>
        <div class="d-flex d-md-none">
            <b-navbar-nav class="ml-auto">
                <b-nav-item v-if="!authUser && this.$mq === 'sm'" class="float-left">
                    <b-button 
                        pill 
                        class="btn-red" 
                        @click="isModalOpen = !isModalOpen"
                    >
                        Join League 
                    </b-button>
                    <membership-plan-modal
                        :is-modal-open.sync="isModalOpen"
                        :league-id="league.id"
                        :league-name="league.name"
                        :league-price="league.price"
                        :league-trial="league.trial"
                    ></membership-plan-modal>
                </b-nav-item>
            </b-navbar-nav>
            <b-dropdown
                toggle-class="btn-outline-purple-purple"
                class="ml-auto"
                right
                no-flip
            >
                <template v-slot:button-content>
                    <span class="text-capitalize mr-2">{{ activeItem }}</span>
                </template>
                <b-dropdown-item
                    :active="activeItem === 'discussion'"
                    @click="updateActiveItem('discussion')"
                    >Discussion</b-dropdown-item
                >
                <b-dropdown-item
                    :disabled="!authUser"
                    :active="activeItem === 'members'"
                    @click="updateActiveItem('members')"
                    >Members ({{ numberAbbrev(subscriberCount) }})</b-dropdown-item
                >
                <b-dropdown-item
                    v-show="!isOwner && authUser"
                    @click="openContactWindow"
                    >Contact</b-dropdown-item
                >
                <b-dropdown-item
                    v-if="isOwner"
                    @click="modalOpen = true"
                    >Invite</b-dropdown-item>
                <b-dropdown-item
                    :disabled="!authUser"
                    :active="activeItem === 'store'"
                    @click="updateActiveItem('store')"
                    >Store</b-dropdown-item
                >
                <b-dropdown-item
                    :active="activeItem === 'sub-leagues'"
                    @click="updateActiveItem('sub-leagues')"
                    >Sub Leagues ({{league.sub_league_count}})</b-dropdown-item
                >
                <b-dropdown-item
                    v-b-tooltip.hover title="Go to Parent League"
                    v-if="league.isSubLeague"
                    @click="redirectToParent(league.parent.slug)"
                    >{{league.parent.league_name}}</b-dropdown-item
                >
                <b-dropdown-divider></b-dropdown-divider>


              <b-dropdown-item
                  :href="`/leagues/edit/${leagueId}?section=member-requests`"
                  v-if="memberRequests.length > 0 && league.owner"
              > Subscriber Requests ({{memberRequests.length }})</b-dropdown-item
              >


                <!-- Follow -->
                <template v-if="showJoin && publicPaid">
                    <b-dropdown-item v-if="!following" @click="followLeague"
                        >Follow League</b-dropdown-item
                    >
                    <b-dropdown-item v-if="following" @click="unfollowLeague"
                        >Unfollow League</b-dropdown-item
                    >

                </template>
                <!-- Join -->
                <template v-if="showJoin">
                    <b-dropdown-item
                        v-if="paidLeague"
                        v-b-modal.join-league-modal
                        >Join League</b-dropdown-item
                    >
                    <b-dropdown-item v-else @click="joinFreeLeague"
                        >Join League</b-dropdown-item
                    >
                </template>
                <!-- Leave -->
                <b-dropdown-item v-if="showLeave" @click="leaveLeague"
                    >Leave League</b-dropdown-item
                >
                <!-- Edit -->
                <b-dropdown-item
                    v-if="authUser && authUser.leaguePermissions.isOwner"
                    :href="`/leagues/edit/${leagueId}`"
                    >Edit {{isParentLeague ? '' : 'Sub '}} League</b-dropdown-item
                >
                <!-- Create Sub League -->
                <b-dropdown-item
                    v-if="authUser && authUser.leaguePermissions.isOwner && isParentLeague"
                    :href="`/leagues/sub-leagues/create/${leagueId}`"
                    >Create Sub League</b-dropdown-item
                >
            </b-dropdown>
        </div>
        <b-navbar
            type="light"
            toggleable="md"
            class="d-none d-md-flex align-items-center px-0"
        >
            <b-navbar-nav class="mr-auto align-items-center">
                <b-nav-item
                    :active="activeItem === 'discussion'"
                    @click="updateActiveItem('discussion')"
                    class="mr-5"
                >
                    Discussion
                </b-nav-item>
                <b-nav-item
                    :active="activeItem === 'members'"
                    @click="updateActiveItem('members')"
                    class="mr-5 text-nowrap"
                    :disabled="!authUser"
                >
                    Members ({{ numberAbbrev(subscriberCount) }})
                </b-nav-item>
                <b-nav-item
                    v-if="isOwner"
                    class="mr-5"
                    @click="modalOpen = true"
                >
                    Invite
                </b-nav-item>
                <b-nav-item
                    :active="activeItem === 'store'"
                    @click="updateActiveItem('store')"
                    :class="this.$mq == 'md' ? 'mr-4' : 'mr-5'"
                    :disabled="!authUser"
                >
                    Store&nbsp;({{storeProductsCount}})
                </b-nav-item>
                 <b-button 
                    pill
                    variant="danger"
                    size="md"
                    v-if="memberRequests.length > 0 && league.owner"
                    @click.stop="routeToNotification('/leagues/edit/'+league.id+'?section=member-requests')"
                >
                    Subscriber Requests ({{memberRequests.length }})
                </b-button>
                <b-nav-item
                    v-show="!isOwner && authUser"
                    @click="openContactWindow"
                    :class="this.$mq == 'md' ? 'mr-4' : 'mr-5'"
                >
                    Contact
                </b-nav-item>
                <b-nav-item
                    v-if="league.isSubLeague"
                    v-b-tooltip.hover title="Go to Parent League"
                    @click="redirectToParent(league.parent.slug)"
                    :class="this.$mq == 'md' ? 'mr-4' : 'mr-5' + ' text-nowrap'"
                >
                    {{league.parent.league_name}}
                </b-nav-item>
                <b-nav-item v-show="authUser && league.price && league.price > 0 && !isOwner">
                  <b-btn
                      variant="outline-purple-purple"
                      pill
                      block
                      @click="toggleDonationModal()"
                  >
                    Donate <span class="d-none d-lg-inline">to League</span>
                  </b-btn>
                  <donation-form-dialog ref="donationFormDialog" :league-id="leagueId" centered hide-footer/>
                </b-nav-item>
                <add-members-modal
                    :modal-open.sync="modalOpen"
                    :league-id="leagueId"
                ></add-members-modal>
            </b-navbar-nav>
            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
                <b-nav-item v-if="!authUser && this.$mq != 'sm'" class="float-right">
                    <b-button 
                        pill 
                        class="btn-red" 
                        @click="isModalOpen = !isModalOpen"
                    >
                        Join League 
                    </b-button>
                    <membership-plan-modal
                        :is-modal-open.sync="isModalOpen"
                        :league-id="league.id"
                        :league-name="league.name"
                        :league-price="league.price"
                        :league-trial="league.trial"
                    ></membership-plan-modal>
                </b-nav-item>
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto align-items-center">
                <b-nav-item v-if="showJoin && publicPaid">
                    <b-btn
                        v-if="!following"
                        variant="purple"
                        class="btn-padded"
                        pill
                        @click="followLeague"
                    >
                        <b-spinner v-show="followLoading" small></b-spinner>
                        <span v-show="!followLoading">Follow League</span>
                    </b-btn>
                    <b-btn
                        v-else
                        variant="outline-purple-purple"
                        class="btn-padded"
                        pill
                        @click="unfollowLeague"
                    >
                        <b-spinner v-show="followLoading" small></b-spinner>
                        <span v-show="!followLoading">Stop Following</span>
                    </b-btn>
                </b-nav-item>
                <b-nav-item v-if="showJoin">
                    <b-btn
                        id="league-join-btn"
                        v-if="paidLeague"
                        variant="purple"
                        class="btn-padded"
                        v-b-modal.join-league-modal
                        pill
                        :disabled="memberHasPendingRequest"
                        >{{ memberHasPendingRequest ? 'Request Sent' : 'Join League' }}</b-btn
                    >
                    <b-btn
                        v-else
                        variant="purple"
                        class="btn-padded"
                        pill
                        @click="joinFreeLeague"
                        :disabled="memberHasPendingRequest"
                    >
                        <b-spinner v-show="joinLoading" small></b-spinner>
                        <span v-show="!joinLoading">{{memberHasPendingRequest ? 'Request Sent' : 'Join League' }}</span>
                    </b-btn>
                </b-nav-item>
              <b-nav-item v-if="cancelDate && memberHasPendingRequest" disabled title="You already requested to join this league.">
               <span class="disabled btn bg-purple btn-sm rounded-pill text-sm text-white">Pending Approval</span>
              </b-nav-item>
              <div v-else-if="cancelDate" class="text-center mr-4">
                    <small class="text-muted">Access Expires</small>
                    <br />
                    <span class="text-purple font-weight-bold mb-0">
                      {{ formattedCancelDate }}
                    </span>
                    <br>
                    <b-btn
                        v-if="paidLeague"
                        variant="purple"
                        class="btn-padded"
                        v-b-modal.join-league-modal
                        pill
                    >
                      Renew
                    </b-btn>
                </div>
                <b-nav-item-dropdown v-if="showEdit || showLeave" right>
                    <template v-slot:button-content>
                        <i class="fas fa-cog fa-fw fa-lg"></i>
                    </template>
                    <b-dropdown-item
                        v-if="authUser.leaguePermissions.isOwner"
                        :href="`/leagues/edit/${leagueId}`"
                        >Edit {{isParentLeague ? '' : 'Sub '}} League</b-dropdown-item
                    >
                    <b-dropdown-item v-if="showLeave" @click="leaveLeague"
                        >Leave League</b-dropdown-item
                    >
                    <!-- Create Sub League -->
                    <b-dropdown-item
                        v-if="authUser && authUser.leaguePermissions.isOwner && isParentLeague"
                        :href="`/leagues/sub-leagues/create/${leagueId}`"
                        >Create Sub League</b-dropdown-item
                    >
                </b-nav-item-dropdown>
            </b-navbar-nav>
        </b-navbar>
    </div>
</template>

<script>
import DonationForm from "../Forms/DonationForm";
import AddMembersModal from "../Modals/AddMembersModal";
import MembershipPlanModal from "../Modals/MembershipPlanModal";
import CreateEditStoreProductModal from "../Forms/LeagueForms/CreateEditStoreProductModal";
import DonationFormDialog from "../Forms/DonationFormDialog";
import { numberAbbreviation } from "../Mixins/numberAbbreviation";

export default {
    name: "LeagueProfileNav",
    mixins: [numberAbbreviation],
    components: {
        AddMembersModal,
        DonationForm,
        DonationFormDialog,
        MembershipPlanModal,
        CreateEditStoreProductModal
    },
    props: {
        activeItem: {
            type: String,
            default: "discussion",
        },
        paidLeague: {
            type: Boolean,
            default: false,
        },
        leagueId: {
            type: Number,
            required: true,
        },
        publicPaid: {
            type: Boolean,
            default: false,
        },
        following: {
            type: Boolean,
            default: false,
        },
        league: {
            type: Object
        },
        isAdmin: Boolean,
        isOwner: Boolean,
        cancelDate: [Number, String],
        authUser: Object,
        subscriberCount: {
            type: String,
            default: "",
        },
        hasPendingRequest: Boolean,
        memberRequests: Array,
        storeProductsCount: {
            type: Number,
        },
        isParentLeague: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            joinLoading: false,
            followLoading: false,
            modalOpen: false,
            disableJoin: false,
            isModalOpen: false,
            hasAuth: 'disabled'
        };
    },
    mounted() {
        if (sessionStorage.getItem("joinLeague")) {
            // Attempt to join the league
            if (this.showJoin) {
                if (this.paidLeague) {
                    setTimeout(function() {
                        document.getElementById('league-join-btn').click();
                    }, 1500);

                } else {
                    this.joinFreeLeague();
                }

                sessionStorage.removeItem("joinLeague");
            } else {
                // They can't join for any number of reasons, clear this session value so they don't accidentally join
                // another league.
                sessionStorage.removeItem("joinLeague");
            }
        }
    },
    computed: {
        formattedCancelDate() {
            if (this.cancelDate) {
                return moment(this.cancelDate).format("MMM D");
            }
        },
        showEdit() {
            if (this.authUser) {
                return this.authUser.leaguePermissions.isOwner;
            }
            return false;
        },
        showLeave() {
            if (this.authUser) {
                return (
                    this.authUser.leaguePermissions.isMember &&
                    !this.authUser.leaguePermissions.isOwner &&
                    !this.cancelDate
                );
            }
            return false;
        },
        showJoin() {
            if (this.authUser) {
                return !this.authUser.leaguePermissions.isMember;
            }
            return false;
        },
        memberHasPendingRequest() {
            return this.hasPendingRequest || this.disableJoin;
        }
    },
    methods: {
      routeToNotification(url) {
        window.location.href = url;
      },
        openContactWindow() {
            this.$emit('open-contact-window');
        },
        updateActiveItem(newActiveItem) {
            this.$emit("change-active-item", newActiveItem);
        },
        async followLeague() {
            if (this.authUser) {
                this.followLoading = true;
                try {
                    const response = await axios.post(`/leagues/follow`, {
                        leagueId: this.leagueId,
                    });
                    window.location.reload();
                } catch (error) {
                    // If there is an error for some reason, show alert
                    await this.$swal(error.response.data.message);
                    this.followLoading = false;
                }
            } else {
                window.location.href = "/";
            }
        },
        async unfollowLeague() {
            this.followLoading = true;
            try {
                const response = await axios.post(`/leagues/unfollow`, {
                    leagueId: this.leagueId,
                });
                window.location.reload();
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.followLoading = false;
            }
        },
        async joinFreeLeague() {
            this.joinLoading = true;
            try {
                const response = await axios.get(`/leagues/join`, {
                    params: { leagueId: this.leagueId },
                });
                const {data : {success, message}} = response;

                // this mean that the user does not have any plan selected. redirect to pick a plan
                if (!success && !message) {
                    await this.$swal('Please select a plan before you join this league.');
                    window.location.href = "register/plan";
                }

                // join league request
                if (success === 1 && message?.length > 0) {
                    await this.$swal(message);
                    this.joinLoading = false;
                    this.disableJoin = true;
                } else {
                    window.location.reload();
                }
            } catch (error) {
              if(error.response.data.redirect) {
                setTimeout( function () {
                  window.location = error.response.data.redirect;
                }, 8000 );
              }
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.joinLoading = false;
            }
        },
        async leaveLeague() {
            try {
                const response = await axios.post(`/leagues/cancel`, {
                    leagueId: this.leagueId,
                });
                window.location.reload();
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal( error.response.data.message);
            }
        },
        redirectToParent(parentSlug) {
            window.location.href = "/" + parentSlug;
        },
      toggleDonationModal(){
        this.$refs.donationFormDialog.toggle()
      }
    },
};
</script>

<style scoped>
.nav-link {
    font-size: 20px;
}
.nav-link.active {
    color: #5451de !important;
    font-weight: bold;
}
</style>
