<template>
    <div class="chat-message-form-wrapper">
        <b-form>
            <b-row v-if="mediaItem" no-gutters class="mb-4">
                <b-col v-if="mediaType === 'image'" cols="auto" class="mx-auto">
                    <div class="media-card">
                        <b-btn
                            variant="purple"
                            pill
                            size="sm"
                            class="delete-button"
                            v-b-tooltip.hover.left
                            title="Delete"
                            @click.stop="removeMedia"
                        >
                            <i class="fas fa-trash"></i>
                        </b-btn>
                        <b-img
                            :src="mediaItem"
                            fluid
                            class="mx-auto"
                            style="max-height: 400px"
                        ></b-img>
                    </div>
                </b-col>
                <b-col
                    v-if="mediaType === 'video'"
                    cols="12"
                    class="px-0 mb-n2"
                >
                    <div
                        class="media-card mx-auto"
                        :style="{ position: 'relative', maxWidth: '600px' }"
                    >
                        <b-btn
                            variant="purple"
                            pill
                            size="sm"
                            class="delete-button"
                            v-b-tooltip.hover.left
                            title="Delete"
                            @click.stop="removeMedia"
                        >
                            <i class="fas fa-trash"></i>
                        </b-btn>
                        <video
                            ref="Player"
                            controls
                            preload="auto"
                            width="640"
                            height="268"
                            class="video-js"
                            data-setup="{}"
                        >
                            <source
                                :src="mediaItem"
                                type="application/x-mpegURL"
                            />
                            <source :src="mediaItem" type="video/mp4" />
                            <source :src="mediaItem" type="video/webm" />
                            <source :src="mediaItem" type="video/ogg" />
                        </video>
                    </div>
                </b-col>
            </b-row>
            <div class="actions">
                <b-btn
                    variant="link"
                    @click="openMediaModal"
                    class="media-button"
                >
                    <i class="fas fa-photo-video"></i>
                </b-btn>
                <div class="textarea-wrapper">
                    <textarea
                        v-model="form.message"
                        class="form-control"
                        placeholder="Write a message..."
                        rows="1"
                    ></textarea>
                </div>
                <div class="send-button" @click="submitForm">
                    <i
                        class="fas fa-paper-plane"
                        :class="{ active: form.message && form.message.length }"
                    ></i>
                </div>
            </div>
        </b-form>
        <media-modal
            :modal-open.sync="mediaModalOpen"
            :user-id="authUserId"
            @media-chosen="setMedia"
        ></media-modal>
    </div>
</template>

<script>
import MediaModal from "../Modals/MediaModal";
import PostCardSmall from "../Cards/PostCardSmall";
import videojs from "video.js";
export default {
    name: "ChatMessageForm",
    components: { PostCardSmall, MediaModal },
    props: {
        /* The user that is currently logged in */
        authUserId: {
            type: Number,
            required: true,
        },
        conversationId: Number,
        message: Object,
        post: Object,
        isMemberRequest: {
            type: Boolean,
        },
    },
    data() {
        return {
            loading: false,
            mediaModalOpen: false,
            mediaItem: null,
            mediaType: "image",
            form: {
                message: "",
                mediaId: null,
            },
        };
    },
    mounted() {
        if (this.message) {
            this.setForm();
        }
    },
    computed: {
        submitText() {
            return this.loading ? "Submitting..." : "Submit";
        },
    },
    methods: {
        openMediaModal() {
            this.mediaModalOpen = true;
        },
        setMedia(media) {
            this.form.mediaId = media.id;
            this.mediaItem = media.file;
            const fileType = this.mediaItem.substring(
                this.mediaItem.length - 3,
                this.mediaItem.length,
            );
            if (fileType === "mp4") {
                this.mediaType = "video";
                let _this = this;

                // have to delay it, because vue doesn't cache refs until the
                // element has been placed into the dom. Hacky? Sure. Feel free to find a better way.
                setTimeout(function () {
                    videojs(_this.$refs.Player);
                }, 500);
            }
        },
        removeMedia() {
            this.form.mediaId = null;
            this.mediaItem = null;
        },
        setForm() {
            this.mediaItem = this.message.media
                ? this.message.media.file
                : null;
            this.mediaType = this.message.media
                ? this.message.media.type
                    ? "video"
                    : "image"
                : "image";
            this.form = {
                message: this.message.message,
                mediaId: this.message.media.id,
            };
        },
        resetForm() {
            this.mediaItem = null;
            this.mediaType = "image";
            this.form = {
                message: null,
                mediaId: null,
            };
        },
        submitForm() {
            if (this.conversationId) {
                if (this.message) {
                    this.updateMessage();
                } else {
                    this.sendMessage();
                }
            } else {
                this.$emit("submit-convo", this.form);
            }
        },
        async sendMessage() {
            try {
                // When a placeholder conversation has been created by selecting a user from your friends list,
                // we use this temporary conversation ID.
                if (this.conversationId === -1) {
                    this.$emit("create-chat", this.form.message);
                    this.form.message = "";
                    return;
                }

                const { data, status } = await axios.post(
                    "/inbox/messages/add",
                    {
                        chatId: this.conversationId,
                        ...this.form,
                    },
                );

                this.loading = false;

                if (status === 200) {
                    this.$emit("message-created", data.message);
                    this.resetForm();
                }
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },

        async updateMessage() {
            try {
                const response = await axios.post("/inbox/messages/edit", {
                    chatMessageId: this.message.id,
                    ...this.form,
                });

                this.$emit("message-edited", response.data.message);
                this.loading = false;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
    },
};
</script>

<style scoped lang="scss">
.chat-message-form-wrapper {
    position: fixed;
    bottom: 0px;
    width: 100%;
    background-color: #000000;
    //padding-bottom: --safe-area-inset-bottom;
    padding-bottom: 8px;
    .actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 8px;

        // env(safe-area-inset-bottom) will change dynamically
        // on iPhone 10 and up when the safari bottom bar is shown/hidden.
        padding-bottom: calc(env(safe-area-inset-bottom) + 4px);
        .media-button {
            padding: 2px;
            padding-left: 6px;
            .fa-photo-video {
                font-size: 18px;
            }
        }
        .textarea-wrapper {
            width: 77%;
            textarea {
                background-color: #e7e7e7;
                margin-left: 8px;
                margin-right: 8px;
            }
        }
        .send-button {
            margin-left: 16px;
            position: absolute;
            right: 10px;
            .fa-paper-plane {
                color: #aaa;
                font-size: 24px;
                &.active {
                    color: #5451de;
                }
            }
        }
    }
    @media (min-width: 768px) {
        width: 57%;
        background-color: #fff;
        .actions {
            .textarea-wrapper {
                width: 80%;
            }
            .send-button {
                right: 16px;
            }
        }
    }
    @media (min-width: 1200px) {
        .actions {
            .textarea-wrapper {
                width: 84%;
            }
        }
    }
}
</style>
