<template>
    <div>
        <b-card>
            <user-notification-form
                :notification-settings="notificationSettings"
                :user="user"
            ></user-notification-form>
        </b-card>
    </div>
</template>

<script>
import UserPrivacyForm from "../../Forms/UserForms/UserPrivacyForm";
import UserNotificationForm from "../../Forms/UserForms/UserNotificationForm";
export default {
    name: "UserSettingsNotifications",
    components: { UserNotificationForm, UserPrivacyForm },
    props: {
        user: { type: Object, required: true },
        notificationSettings: { type: Array, required: true },
    },
};
</script>
