<template>
    <b-list-group flush>
        <comment-item
            v-for="(comment, index) in commentItems"
            :key="comment.id"
            :comment="comment"
            :league-id="leagueId"
            :post-id="parentItem.id"
            :commentable-id="commentableId"
            :commentable-type="commentableType"
            :reactions="reactions"
            :auth-user="authUser"
            :show-reply="parentType === 'post'"
            :last-item="index === commentItems.length - 1"
            @update-comment="updateComment"
            @comment-deleted="deleteComment"
            :comment-level-count="commentLevelCount + 1"
            :parent-name="parentItem.posted_by.name"
            :total-comment-count="totalCommentCount"
            :replies-length-allowed="repliesLengthAllowed"
            :show-more-replies="showMoreReplies"
            v-on="$listeners"
        ></comment-item>
        <b-list-group-item
            v-if="repliesLengthAllowed && totalCommentCount >= 1"
            class="mt-n4 bg-transparent"
        >
            <b-link @click="showMoreReplies = true">
                <span v-if="!showMoreReplies && commentLevelCount >= 1">
                    <i class="fas fa-reply fa-rotate-180 text-black-50"></i>
                    {{ totalCommentCount }} replies
                </span>
            </b-link>
        </b-list-group-item>
        <b-list-group-item v-show="moreComments" class="text-center pb-1 px-0">
            <b-link @click="getComments">View More Comments</b-link>
        </b-list-group-item>
        <template v-if="showCommentForm">
            <b-list-group-item class="px-0">
                <div
                    :id="`comment-${this.parentItem.id}`"
                    style="position: absolute; bottom: 0; height: 50vh"
                ></div>
                <comment-form
                    :parent-type="parentType"
                    :parent="parentItem"
                    :league-id="leagueId"
                    :commentable-id="commentableId"
                    :commentable-type="commentableType"
                    @comment-created="addComment"
                    :comment-level-count="commentLevelCount"
                    :show-comment-form="showCommentForm"
                    :tag-name="parentItem.posted_by.name"
                    :tag-id="parentItem.id"
                ></comment-form>
            </b-list-group-item>
        </template>
    </b-list-group>
</template>

<script>
import CommentItem from "../ListItems/CommentItem";
import CommentForm from "../Forms/CommentForm";
import ReactionPicker from "../Forms/FormComponents/ReactionPicker";
import ShareButton from "../Buttons/ShareButton";

export default {
    name: "CommentsList",
    components: { ShareButton, ReactionPicker, CommentForm, CommentItem },
    props: {
        // Used to tell whether a comment is on a post or on another comment.
        parentType: {
            type: String,
            default: "post",
        },
        // Currently values can be post or media
        commentableType: {
            type: String,
            default: "post",
        },
        parentItem: {
            type: Object,
            required: true,
        },
        reactions: {
            type: Array,
            required: true,
        },
        showCommentForm: {
            type: Boolean,
            default: true,
        },
        authUser: {
            type: Object,
            required: true,
        },
        leagueId: {
            type: Number,
            required: true,
        },
        commentLevelCount: {
            type: Number,
            default: 0,
        },
        commentId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            perPage: 10,
            currentPage: 1,
            showMoreReplies: false,
        };
    },
    mounted() {
        this.loading = false;
    },
    computed: {
        commentItems: {
            get() {
                return this.parentItem.comments;
            },
            set(newValue) {
                return newValue;
            },
        },
        totalCommentCount: {
            get() {
                // Have to add this check because of the different naming
                // convention used for WallMediaResource
                if (typeof this.parentItem.comment_count === "undefined") {
                    return this.parentItem.commentCount;
                }
                return this.parentItem.comment_count;
            },
            set(newValue) {
                return newValue;
            },
        },
        repliesLengthAllowed() {
            return this.commentItems.length >= 2;
        },
        moreComments() {
            return this.commentItems.length < this.totalCommentCount;
        },
        commentableId() {
            let commentableId = 0;

            if (this.commentableType == "media") {
                // For video and image media items
                if ([0, 1].indexOf(this.parentItem.type) > -1) {
                    commentableId = this.parentItem.mediaId;
                }
                if (this.parentItem.commentable_id) {
                    commentableId = this.parentItem.commentable_id;
                }
            }

            if (this.commentableType == "post") {
                commentableId =
                    this.parentType === "post"
                        ? this.parentItem.id
                        : this.parentItem.commentable_id;

                if ([2, 5].indexOf(this.parentItem.type) > -1 && this.parentItem.postId) {
                    commentableId = this.parentItem.postId;
                }
            }

            // For debugging
            if (!commentableId) {
                console.error("commentableId not set: ", {
                    parentItem: this.parentItem,
                    parentType: this.parentType,
                    commentableType: this.commentableType,
                });
            }

            return commentableId;
        },
    },
    methods: {
        async getComments() {
            if (this.moreComments) {
                this.loading = true;
                if (this.parentType === "post") {
                    await this.getPostComments();
                } else {
                    await this.getCommentComments();
                }
            }
        },
        async getReplies() {
            if (this.repliesLengthAllowed) {
                this.loading = true;
                if (this.parentType === "post") {
                    await this.getPostComments();
                } else {
                    await this.getCommentComments();
                }
            }
        },
        async getPostComments() {
            try {
                this.currentPage++;
                const data = {
                    commentableId: this.parentItem.id,
                    commentableType: this.commentableType,
                    leagueId: this.parentItem.league.id,
                    per_page: this.perPage,
                    page: this.currentPage,
                };
                const response = await axios.post("/leagues/comments", data);
                // Put "new" items on top
                // @TODO - commentsItems list is not updating when I add more items, not sure why
                this.commentItems = [
                    ...this.commentItems,
                    ...response.data.comments,
                ];
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        },
        async getCommentComments() {
            try {
                this.currentPage++;
                const data = {
                    parentId: this.parentItem.id,
                    commentableId: this.parentItem.commentable_id,
                    commentableType: this.commentableType,
                    leagueId: this.leagueId,
                    per_page: this.perPage,
                    page: this.currentPage,
                };
                /* TODO: Update for comments */
                const response = await axios.post("/leagues/comments", data);
                // Put "new" items on top
                // @TODO - commentsItems list is not updating when I add more items, not sure why
                this.commentItems = [
                    ...this.commentItems,
                    ...response.data.comments,
                ];
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        },
        addComment(comment) {
            this.loading = true;
            this.commentItems.push(comment);
            this.totalCommentCount = this.totalCommentCount + 1;

            this.loading = false;
            this.$emit("comment-added");
        },
        updateComment(comment) {
            const index = this.commentItems.findIndex(
                (c) => c.id === comment.id
            );
            this.commentItems[index] = comment;
        },
        deleteComment(id) {
            const index = this.commentItems.findIndex((c) => c.id === id);
            this.commentItems.splice(index, 1);
            this.totalCommentCount = this.totalCommentCount - 1;
            this.$emit("comment-removed");
        },
        toggleMainCommentForm(){
            this.$emit("toggle-main-comment-form");
        },
    },
};
</script>

<style></style>
