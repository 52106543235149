<template>
    <b-card no-body>
        <b-card-body class="d-flex align-items-start justify-content-between">
            <b-media class="align-items-center" @click="$emit('click')">
                <template v-slot:aside>
                    <b-avatar 
                        
                        variant="grey"
                        :src="user.avatar"
                        square
                        size="85px"
                    ></b-avatar>
                </template>
                <div>
                    <h5  
                    @click="$emit('click')" class="font-weight-bold">{{ user.name }}</h5>
                    <h6 v-if="user.role" class="text-muted">{{ user.role }}</h6>
                </div>
            </b-media>
            <b-dropdown
                class="user-menu-dropdown mr-n2"
                variant="ghost-purple"
                toggle-class="text-decoration-none"
                left
                no-caret
            >
                <template v-slot:button-content>
                    <i class="fas fa-ellipsis-v"></i
                    ><span class="sr-only">User Menu</span>
                </template>
                <b-dropdown-item @click.stop="$emit('nuke-user', user.id)"
                    >Nuke User</b-dropdown-item
                >
            </b-dropdown>
        </b-card-body>
        <b-card-body class="justify-content-between pt-0" v-if="user.leagueSubscriptions.length>0">
            <div>
                <h6 >{{ 'All User Leagues:' }}</h6>
                <b-list-group>
                  <b-list-group-item v-for="league in user.leagueSubscriptions" :key="league.id" class="d-flex justify-content-between align-items-center">
                    <a :href="`/${league.slug}`" target="_blank"> <span>{{ league.league_name}}</span></a>
                    <b-badge variant="danger" pill @click="removeUserFromLeague( league, user )">Remove</b-badge>
                  </b-list-group-item>
                </b-list-group>
            </div>
        </b-card-body>
    </b-card>
</template>

<script>
import EventBus from '../../EventBus/EventBus.js';

export default {
    name: "UserCard",
    props: {
        user: {
            type: Object,
            required: true,
        },
        removeText: {
            type: String,
            default: "Remove",
        },
        editable: {
            type: Boolean,
            default: false,
        },
    },
    methods:{
      async removeUserFromLeague(league,user){
        
        EventBus.$emit('remove-user', {
            league: league,
            user: user,
        });

      }
    }
};
</script>

<style scoped></style>
