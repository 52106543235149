<template>
    <b-modal
        id="delete-comment-modal"
        v-model="open"
        centered
        hide-footer
        hide-header
    >
        <b-container class="p-4">
            <h3 class="font-weight-bold mb-4">
                Are you sure you want to delete this Comment?
            </h3>
            <b-btn
                variant="purple"
                block
                pill
                @click="deleteComment"
                :disabled="loading"
                class="mb-3"
            >
                <i v-show="loading" class="fas fa-spinner fa-pulse"></i>
                <span v-show="!loading">Yes</span>
            </b-btn>
            <b-btn
                variant="outline-purple"
                block
                pill
                @click="cancelDelete"
                class="text-purple"
            >
                No
            </b-btn>
        </b-container>
    </b-modal>
</template>

<script>
export default {
    name: "DeleteCommentModal",
    props: {
        modalOpen: {
            type: Boolean,
        },
        leagueId: Number,
        commentId: Number,
        commentableId: Number,
        commentableType: String,
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        open: {
            get() {
                return this.modalOpen;
            },
            set(value) {
                this.$emit("update:modalOpen", value);
            },
        },
    },
    methods: {

      cancelDelete() {
        this.$emit("cancel-delete");
        this.$bvModal.hide('delete-comment-modal');
      },

        async deleteComment() {
            this.loading = true;
            try {
                const response = await axios.post("/leagues/comments/delete", {
                    leagueId: this.leagueId,
                    commentId: this.commentId,
                    commentableId: this.commentableId,
                    commentableType: this.commentableType
                });
                this.$emit("comment-deleted", this.commentId);
                this.$bvModal.hide("delete-comment-modal");
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped>
.text-purple:hover {
  color:white !important;

}
</style>
