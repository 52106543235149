<template>
    <b-card>
        <b-row class="align-items-center">
            <b-col class="text-center" cols="12" lg="3">
                <template v-if="payment.league">
                    <b-avatar
                        :src="payment.league.photo"
                        variant="grey"
                        size="50"
                    ></b-avatar>
                    <p class="font-weight-bold mb-0">
                        {{ payment.league.name }}
                    </p>
                </template>
                <template v-else>
                    <b-img
                        :src="tilInfo.photo"
                        :style="{ maxWidth: '85px', maxHeight: '50px' }"
                    ></b-img>
                    <p class="font-weight-bold mb-0">{{ tilInfo.name }}</p>
                </template>
            </b-col>
            <b-col cols="6" lg="3" class="text-center text-lg-left mx-auto">
                <p class="small text-muted mb-0">
                    {{ subscription ? "Subscription" : paymentType }}
                </p>
                <p class="mb-lg-0">{{ paymentAmount }}</p>
            </b-col>
            <b-col cols="6" lg="3" class="text-center text-lg-left mx-auto">
                <p class="small text-muted mb-0">
                    {{ subscription ? "Next Billing Date" : "Date" }}
                </p>
                <p class="mb-lg-0">
                    {{ paymentDate | moment("MMMM Do YYYY") }}
                </p>
            </b-col>
            <b-col cols="12" lg="2">
                <template v-if="showCancel">
                    <b-btn
                        variant="outline-dark"
                        v-if="payment.league"
                        pill
                        block
                        @click="$emit('leave-league', payment.league.id)"
                        >Cancel</b-btn
                    >
                    <b-btn
                        variant="ghost-purple"
                        v-else
                        pill
                        block
                        @click="$emit('cancel-membership')"
                        >Close Account</b-btn
                    >
                </template>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
export default {
    name: "PaymentCard",
    props: {
        payment: {
            type: Object,
            required: true,
        },
        subscription: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        paymentAmount() {
            return this.subscription
                ? `$${this.payment.price} per month`
                : `$${this.payment.amount}`;
        },
        paymentDate() {
            return this.subscription
                ? this.payment.next_payment
                : this.payment.date;
        },
        paymentType() {
            return this.payment.paymentType === 1 ? "Payment" : "Donation";
        },
        tilInfo() {
            return {
                name: "The Inner League",
                photo: "/images/logos/shield.png",
            };
        },
        showCancel() {
            return this.subscription;
        },
    },
};
</script>

<style scoped></style>
