<template>
    <svg
        width="27"
        height="27"
        viewBox="0 0 27 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M26.4906 2.7394C22.3873 6.8794 18.2473 10.9827 14.1423 15.1227C13.9988 15.2661 13.854 15.411 13.674 15.5544C13.2774 15.8427 12.7023 15.9144 12.234 15.771C11.8023 15.6275 11.4057 15.231 11.2623 14.7992C11.0823 14.2592 11.2257 13.6475 11.6223 13.251C15.7255 9.14767 19.8306 5.04259 23.899 0.974233C24.1155 0.757671 24.3307 0.505945 24.5824 0.362521C24.7624 0.25424 24.9424 0.219082 25.1224 0.182521C25.519 0.110801 25.9858 0.25424 26.3107 0.505959C26.6341 0.757678 26.8507 1.11767 26.8873 1.54938C26.9956 1.94736 26.8156 2.41562 26.4907 2.73908L26.4906 2.7394Z"
            :fill="fill"
        />
        <path
            d="M25.6627 19.7677C25.5909 20.8477 25.2661 21.8912 24.6909 22.8277C24.1509 23.6912 23.4309 24.4112 22.5675 24.9512C21.7041 25.4912 20.6958 25.8146 19.6875 25.9229C19.471 25.9229 19.2558 25.9229 19.0041 25.9594C17.3124 26.1394 15.6207 26.2477 13.9641 26.2829C13.4607 26.2829 12.9924 26.3194 12.4875 26.2829C10.9041 26.2463 9.31923 26.1746 7.73588 25.9946C7.51932 25.958 7.30416 25.9229 7.08759 25.9229C5.39588 25.8512 3.77588 25.0946 2.62431 23.8712C1.79602 23.0077 1.22089 21.9277 0.967733 20.7395C0.824295 20.1629 0.824295 19.5512 0.751172 18.976C0.53461 17.356 0.499452 15.6995 0.462891 14.0444V12.9996C0.499452 11.3796 0.53461 9.75956 0.71461 8.13956C0.751172 7.77956 0.786329 7.38299 0.822891 7.02299C0.89461 6.23128 1.11117 5.43956 1.47118 4.71956C2.08289 3.49614 3.0546 2.48785 4.24289 1.83956C5.17947 1.29956 6.25947 1.04785 7.33947 1.04785H14.1427C14.3227 1.04785 14.4662 1.19129 14.4662 1.37129V3.85471C14.4662 4.03471 14.3227 4.17815 14.1427 4.17815L7.37583 4.17956C6.6924 4.17956 6.04412 4.39612 5.46754 4.75613C4.74754 5.22442 4.20754 5.97956 4.02754 6.80785C3.95582 7.13128 3.91926 7.45613 3.91926 7.77956C3.8827 8.17613 3.81098 8.57127 3.77582 8.96785C3.63238 10.4078 3.59582 11.8113 3.59582 13.2511C3.59582 14.7994 3.63238 16.3477 3.77582 17.8944C3.81238 18.291 3.8841 18.7227 3.91926 19.1178C3.95582 19.4778 3.95582 19.8744 4.0627 20.2344C4.35098 21.2427 5.10612 22.1061 6.07927 22.5027C6.51098 22.6827 7.01585 22.791 7.4827 22.7544C8.95927 22.971 10.471 23.0779 11.9827 23.0779C14.251 23.1144 16.5194 23.0061 18.7511 22.7544C19.0393 22.7179 19.3276 22.7544 19.6145 22.6827C20.6579 22.5393 21.5579 21.891 22.0979 20.991C22.3496 20.5593 22.4945 20.091 22.5296 19.6227V19.0461L22.531 12.9992C22.531 12.7826 22.711 12.6026 22.9276 12.6026H25.2676C25.4842 12.6026 25.6642 12.7826 25.6642 12.9992V18.3276C25.6628 18.7958 25.6993 19.2993 25.6628 19.7676L25.6627 19.7677Z"
            :fill="fill"
        />
    </svg>
</template>

<script>
export default {
    name: "ComposeIcon",
    props: {
        fill: {
            type: String,
            default: "#5451DE",
        },
    },
};
</script>

<style scoped></style>
