<template>
    <div>
        <div v-if="payments.length > 0">
            <payment-card
                v-for="payment in payments"
                :payment="payment"
                :key="payment.id"
                class="my-2"
            ></payment-card>
        </div>
        <h5 v-else class="text-center text-muted">No payments made yet</h5>
    </div>
</template>

<script>
import PaymentCard from "../Cards/PaymentCard";
export default {
    name: "BillingHistoryList",
    components: { PaymentCard },
    props: {
        payments: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style scoped></style>
