var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item-select",staticStyle:{"display":"flex","flex-direction":"column"}},[_c('div',{staticClass:"position-relative"},[_c('div',{staticClass:"burst-reply",style:({
                backgroundImage: _vm.burstReply.files[0].poster
                    ? ("url(" + (_vm.burstReply.files[0].poster) + ")")
                    : "url('/images/backgrounds/default_avtar.png')",
            })},[_c('video',{ref:"burstVideo",staticClass:"video-show video-js d-none",attrs:{"muted":"","playsinline":""},domProps:{"muted":true},on:{"ended":_vm.videoEnded}},[(
                        _vm.burstReply.files[0].file
                            .split('.')
                            .slice(-1)[0]
                            .toLowerCase() === 'mp4'
                    )?_c('source',{attrs:{"src":_vm.burstReply.files[0].file,"type":"video/mp4"}}):_vm._e(),_vm._v(" "),(
                        _vm.burstReply.files[0].file
                            .split('.')
                            .slice(-1)[0]
                            .toLowerCase() === 'webm'
                    )?_c('source',{attrs:{"src":_vm.burstReply.files[0].file,"type":"video/webm"}}):_vm._e(),_vm._v(" "),(
                        _vm.burstReply.files[0].file
                            .split('.')
                            .slice(-1)[0]
                            .toLowerCase() === 'ogg'
                    )?_c('source',{attrs:{"src":_vm.burstReply.files[0].file,"type":"video/ogg"}}):_vm._e(),_vm._v(" "),(
                        _vm.burstReply.files[0].file
                            .split('.')
                            .slice(-1)[0]
                            .toLowerCase() === 'm3u8'
                    )?_c('source',{attrs:{"src":_vm.burstReply.files[0].file,"type":"application/x-mpegURL"}}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"hover-thumnail"},[_c('div',{staticClass:"video-control"},[_c('span',{on:{"click":_vm.videoMuteToggle}},[_c('img',{attrs:{"src":("/images/icons/" + (_vm.videoMute ? 'mute.png' : 'unmute.png'))}})]),_vm._v(" "),_c('span',{class:{ 'is-selected': _vm.isSelected },on:{"click":_vm.openVideoPopUpModal}},[_c('img',{attrs:{"src":"/images/icons/fullscreen.png"}})])]),_vm._v(" "),_c('div',{staticClass:"show-button"},[(!_vm.videoPlay)?_c('img',{attrs:{"src":"/images/icons/play.png"},on:{"click":_vm.startVideo}}):_vm._e(),_vm._v(" "),(_vm.videoPlay)?_c('img',{attrs:{"src":"/images/icons/pause.png"},on:{"click":_vm.pauseVideo}}):_vm._e()])]),_vm._v(" "),(_vm.showDeleteBtn)?_c('div',{staticClass:"delete-btn",on:{"click":_vm.handleShowDeleteModal}},[_c('i',{staticClass:"fas fa-times-circle fa-lg"})]):_vm._e()]),_vm._v(" "),_c('a',{staticClass:"user-name",staticStyle:{"color":"white","display":"flex","justify-content":"center"},attrs:{"href":'/u/' + _vm.burstReply.posted_by.id}},[_vm._v(_vm._s(_vm.burstReply.posted_by.name))])]),_vm._v(" "),(_vm.deleteModalIsOpen)?_c('delete-post-modal',{attrs:{"message":'Are you sure you want to delete this Burst reply?',"modal-open":_vm.deleteModalIsOpen,"league-id":_vm.burstReply.league.id,"post-id":_vm.burstReply.id},on:{"update:modalOpen":function($event){_vm.deleteModalIsOpen=$event},"update:modal-open":function($event){_vm.deleteModalIsOpen=$event},"post-deleted":_vm.burstReplyDeleted}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }