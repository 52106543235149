<template>
    <b-card @click="$emit('click')" no-body>
        <b-card-body class="d-flex align-items-start justify-content-between">
            <b-media class="align-items-center">
                <template v-slot:aside>
                    <b-avatar
                        variant="grey"
                        :src="user.avatar"
                        square
                        size="85px"
                    ></b-avatar>
                </template>
                <div>
                    <h5 class="font-weight-bold">{{ user.name }}</h5>
                    <h6 v-if="user.role" class="text-muted">{{ user.role }}</h6>
                </div>
            </b-media>
            <b-dropdown
                v-if="editable"
                class="user-menu-dropdown mr-n2"
                variant="ghost-purple"
                toggle-class="text-decoration-none"
                left
                no-caret
            >
                <template v-slot:button-content>
                    <i class="fas fa-ellipsis-v"></i
                    ><span class="sr-only">User Menu</span>
                </template>
                <b-dropdown-item
                    v-if="user.role !== 'Admin'"
                    @click.stop="$emit('make-admin', user.id)"
                    >Make Admin</b-dropdown-item
                >
                <b-dropdown-item
                    v-else
                    @click.stop="$emit('remove-admin', user.id)"
                    >Remove Admin</b-dropdown-item
                >
                <b-dropdown-item @click.stop="$emit('remove-user', user.id)"
                    >Remove User</b-dropdown-item
                >
            </b-dropdown>
        </b-card-body>
    </b-card>
</template>

<script>
export default {
    name: "UserCard",
    props: {
        user: {
            type: Object,
            required: true,
        },
        removeText: {
            type: String,
            default: "Remove",
        },
        editable: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped></style>
