<template>
    <b-modal
        id="nuke-user-modal"
        v-model="open"
        centered
        hide-footer
        hide-header
    >
        <b-container class="p-4">
             <div class="form-group">
                        <p>Are you sure you want to nuke this user?  This will delete all traces of them.  If they have leagues, they will be completely removed as well.  Paid leagues will be deleted ASAP, but will be delayed until all subscriptions have been cancelled..  However, access will be revoked for the user immediately.  If the user wants to come back to the site, they will need to signup normally again.</p>
                        <p><span style="font-weight: bold;color:red;"> This cannot be undone, so be very certain this is the action you would like to take.</span></p>

                        <label class="ml-2" for="reason">Reason for nuking user</label>
                        <input v-model="reason" type="text" class="form-control" id="reason" name="reason" />
                    </div>
            <b-btn
                variant="purple"
                block
                pill
                @click="$emit('nuke-user')"
                :disabled="loading"
            >
                <i v-show="loading" class="fas fa-spinner fa-pulse"></i>
                <span v-show="!loading">Nuke User</span>
            </b-btn>
            <b-btn
                variant="outline-primary"
                block
                pill
                @click="$bvModal.hide('nuke-user-modal')"
            >
                Cancel
            </b-btn>
        </b-container>
    </b-modal>
</template>

<script>
export default {
    name: "AdminNukeUserModal",
    props: {
        modalOpen: {
            type: Boolean,
        },
        userName: String,
    },
    data() {
        return {
            loading: false,
            reason: ''
        };
    },
    computed: {
        open: {
            get() {
                return this.modalOpen;
            },
            set(value) {
                this.$emit("update:modalOpen", value);
            },
        },
    },
};
</script>
