
<script>
//Importing Line class from the vue-chartjs wrapper
import { Line } from "vue-chartjs";

//Exporting this so it can be used in other components
export default {
    extends: Line,
    props: {
        chartdata: {
            type: Object,
            default: null
        },
        options: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            decimals: 3,
        };
    },
    mounted() {
        // renderChart function renders the chart with the chartdata and options object from the LeagueSettingsEarning.vue
        this.renderChart(
            this.chartdata, 
            this.options, 
        );
    },
    watch: {
        chartdata:{
            deep: true,
            handler(newVal, oldVal){
                console.log(newVal);
                this.renderChart(
                    newVal, 
                    this.options, 
                );
            }
        }
    },
    computed:{
        
    },
    methods: {
      
    },

};
</script>
