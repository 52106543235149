import { render, staticRenderFns } from "./FreePlanMessage.vue?vue&type=template&id=b9dc0606&scoped=true&"
import script from "./FreePlanMessage.vue?vue&type=script&lang=js&"
export * from "./FreePlanMessage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9dc0606",
  null
  
)

export default component.exports