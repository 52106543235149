<template>
    <b-modal
        id="delete-media-modal"
        v-model="open"
        centered
        hide-footer
        hide-header
    >
        <b-container class="p-4">
            <h3 class="font-weight-bold mb-4">
                Are you sure you want to delete this Media?
            </h3>
            <b-btn
                variant="purple"
                block
                pill
                @click="$emit('delete-media')"
                :disabled="loading"
                class="mb-3"
            >
                <i v-show="loading" class="fas fa-spinner fa-pulse"></i>
                <span v-show="!loading">Yes</span>
            </b-btn>
            <b-btn
                variant="outline-purple"
                block
                pill
                @click="$bvModal.hide('delete-media-modal')"
                class="text-purple"
            >
                No
            </b-btn>
        </b-container>
    </b-modal>
</template>

<script>
export default {
    name: "DeleteMediaModal",
    props: {
        modalOpen: {
            type: Boolean,
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        open: {
            get() {
                return this.modalOpen;
            },
            set(value) {
                this.$emit("update:modalOpen", value);
            },
        },
    },
};
</script>
