<template>
    <div ref="editorWrapper">
        <div
            class="editor"
            :class="{ 'is-comment': usageType === 'comment' ? true : false }"
        >
            <editor-content class="editor__content" :editor="editor" />
        </div>

        <tag-people-autocomplete
            v-if="filteredUsers.length"
            :tagPeopleAutocompleteResults="filteredUsers"
            :offsetTop="suggestionsListOffsetTop"
            @user-selected="selectUser"
        />
    </div>
</template>

<script>
import Bowser from "bowser";
import { Editor, EditorContent, Extension } from "tiptap";
import { HardBreak, Mention, Placeholder, Link } from "tiptap-extensions";
import TagPeopleAutocomplete from "./TagPeopleAutocomplete";

export default {
    name: "FancyEditor",
    components: {
        EditorContent,
        TagPeopleAutocomplete,
    },
    props: {
        initialContent: {
            type: String,
            default: "",
        },
        leagueId: {
            type: Number,
            default: 0,
        },
        usageType: {
            type: String,
            default: "",
        },
        postedByName: {
            type: String,
            default: "",
        },
        commentLevelCount: {
            type: Number,
            default: 0,
        },
      comment: {
          type: Object,
        default: null,
      }
    },
    data() {
        return {
            browser: "",
            os: "",
            editor: new Editor({
                extensions: [
                    new Placeholder({
                        emptyNodeText:
                            this.usageType === "post"
                                ? "Share your thoughts..."
                                : "Write a comment",
                    }),
                    new (class extends Extension {
                        constructor(fancyEditorThis) {
                            super();
                            this.fancyEditorThis = fancyEditorThis;
                        }
                        keys() {
                            const fancyEditorThis = this.fancyEditorThis;
                            const usageType = fancyEditorThis.usageType;

                            return {
                                Enter(state, dispatch, view) {
                                    if (usageType === "comment") {
                                        fancyEditorThis.$emit("on-enter");

                                        // return true prevents default behaviour
                                        return true;
                                    }
                                },
                            };
                        }
                    })(this),
                    new HardBreak(),
                    new Mention({
                        matcher: {
                            char: "@",
                            allowSpaces: false,
                            startOfLine: false,
                        },
                        items: () => {
                            return [];
                        },
                        onEnter: ({
                            items,
                            query,
                            range,
                            command,
                            virtualNode,
                        }) => {
                            this.query = query;
                            this.filteredUsers = items;
                            this.suggestionRange = range;
                            this.setSuggestionsOffset(virtualNode);
                            this.insertMention = command;
                        },
                        onChange: ({ items, query, range, virtualNode }) => {
                            // Mentions are currently not supported on android
                            // because of weird backspace bugs.
                            if (this.os == "Android") {
                                return;
                            }

                            this.query = query;
                            this.filteredUsers = items;
                            this.suggestionRange = range;
                            this.navigatedUserIndex = 0;
                            if (query.length > 0) {
                                this.setSuggestionsOffset(virtualNode);
                            }
                        },
                        onExit: () => {
                            this.query = null;
                            this.filteredUsers = [];
                            this.suggestionRange = null;
                            this.navigatedUserIndex = 0;
                        },
                        // is called on every keyDown event while a suggestion is active
                        onKeyDown: ({ event }) => {
                            // Mentions are currently not supported on android
                            // because of weird backspace bugs.
                            if (this.os == "Android") {
                                return;
                            }

                            if (event.key === "ArrowUp") {
                                this.upHandler();
                                return true;
                            }
                            if (event.key === "ArrowDown") {
                                this.downHandler();
                                return true;
                            }
                            return false;
                        },
                        onFilter: async (_items, query) => {
                            const leagueId = this.leagueId;

                            // Mentions are currently not supported on android
                            // because of weird backspace bugs.
                            if (this.os == "Android") {
                                return [];
                            }

                            if (!query) {
                                return [];
                            }
                            return await new Promise((resolve) => {
                                const params = {
                                    leagueId: leagueId,
                                    searchQuery: query,
                                };

                                axios
                                    .get("/tags/users", {
                                        params: params,
                                    })
                                    .then((res) => {
                                        return resolve(res.data.users);
                                    });
                            });
                        },
                    }),
                    new Link(),
                ],
                content: ( this.comment && typeof this.comment.comment != 'undefined') ?this.comment.comment  : this.initialContent ? this.initialContent : '',
                 function () {
                    if (this.usageType === "comment") {
                        this.$emit("on-enter");

                        // return true prevents default behaviour
                        return true;
                    }
                },
                onUpdate: function (editorState) {
                    this.$emit("input-changed", editorState.getHTML());
                }.bind(this),
            }),
            query: null,
            suggestionRange: null,
            filteredUsers: [],
            navigatedUserIndex: 0,
            insertMention: () => {},
            lastTriggerWord: null,
            tagPeopleAutocompleteResults: [],
            mentionOffsetTop: 0,
        };
    },
    beforeMount() {
        const browser = Bowser.getParser(window.navigator.userAgent);
        this.browser = browser.getBrowser().name;
        this.os = browser.getOS().name;
    },
    mounted() {

    },
    computed: {
        hasResults() {
            return this.filteredUsers.length;
        },
        showSuggestions() {
            return this.query || this.hasResults;
        },
        suggestionsListOffsetTop() {
            let baseOffset = 150;
            if (this.usageType === "comment") {
                baseOffset = 50;
            }

            if (this.os === "iOS" && this.browser === "Safari") {
                baseOffset = 210;
                if (this.usageType === "comment") {
                    baseOffset = 50;
                }
            }
            return baseOffset + this.mentionOffsetTop;
        },
    },
    methods: {
        setContent(content) {
            this.editor.setContent(content);
        },
        upHandler() {
            this.navigatedUserIndex =
                (this.navigatedUserIndex + this.filteredUsers.length - 1) %
                this.filteredUsers.length;
        },
        downHandler() {
            this.navigatedUserIndex =
                (this.navigatedUserIndex + 1) % this.filteredUsers.length;
        },
        selectUser(user) {
            this.insertMention({
                range: this.suggestionRange,
                attrs: {
                    id: user.id,
                    label: user.name,
                },
            });
            this.editor.focus();
        },
        setSuggestionsOffset(node) {
            const mentionPos = node.getBoundingClientRect();
            const editorPos = this.$refs.editorWrapper.getBoundingClientRect();
            this.mentionOffsetTop = mentionPos.y - editorPos.y;
        },
    },
};
</script>

<style lang="scss">
.editor {
    overflow: hidden;
    background-color: #e7e7e7;

    .ProseMirror {
        padding: 16px;
    }

    &.is-comment {
        border-radius: 50px;
        .ProseMirror {
            padding-bottom: 10px;
            padding-top: 10px;
            background-color: #e7e7e7;
            &:focus {
                outline: none !important;
            }
            p {
                margin-bottom: 0px !important;
            }
        }
    }
}

.mention {
    background: rgba(24, 119, 242, 0.2);
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.8rem;
    font-weight: bold;
    border-radius: 5px;
    padding: 0.2rem 0.5rem;
    white-space: nowrap;
}
.mention-suggestion {
    color: rgba(0, 0, 0, 0.6);
}

p.is-empty:first-child::before {
    content: attr(data-empty-text);
    float: left;
    color: #888;
    pointer-events: none;
    height: 0;
}
</style>
