<template>
    <b-row id="league-list" >
        <b-col
            cols="12"
            md="6"
            lg="4"
            v-for="league in leagues"
            :key="league.id"
            v-show="leagues.length > 0"
        >
            <league-card
                :league="league"
                :user-id="userId"
                :auth-id="authUser.id"
                :is-owner="league.owner"
                :is-sub-league="league.isSubLeague"
                class="my-3"
                small
                @click="routeToLeague"
            ></league-card>
        </b-col>
        <b-col v-show="leagues.length === 0" class="text-center my-5">
            <span class="h5 text-muted">No Leagues...</span>
        </b-col>
    </b-row>
</template>

<script>
import LeagueCard from "../Cards/LeagueCard";
import LeagueMembersFormVue from '../Forms/LeagueForms/LeagueMembersForm.vue';

export default {
    name: "LeaguesList",
    components: { LeagueCard },
    props: {
        userId: {
            type: Number,
            required: true,
        },
        authUser: {
            type: Object,
            required: false,
        },
        usersLeagues: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            loading: true,
            perPage: 12,
            currentPage: 1,
            leagues: [],
            totalLeagues: 0,
        };
    },
    mounted() {
        this.getLeagues();
    },
    methods: {
        async getLeagues() {
            this.loading = true;
            const data = {
                userId: this.userId,
                per_page: this.perPage,
                page: this.currentPage,
                usersLeagues: this.usersLeagues,
            };

            try {
                const response = await axios.post(`/users/leagues`, data);
                this.leagues = response.data.leagues;
                this.totalLeagues = response.data.total;
                this.loading = false;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
        routeToLeague(league) {
            window.location.href = `/${league.slug}`;
        },
    },
};
</script>

<style scoped></style>
