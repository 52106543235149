<template>
    <b-row>
        <b-col>
            <!-- <h3 class="h4 text-muted">Taking a break?</h3> -->
            <h3 class="h4 text-muted">Cancel Account</h3>
            <hr class="mt-2 mb-3" />
            <!-- As per Scott hide the disable for now
            <p>
                If you're just hoping to take a break, or you aren't sure whether you'll return, we recommend disabling your account, <span class="font-weight-bold">not</span> deleting it.
            </p>
            <div>
                <h6 class="font-weight-bold">Disable your account</h6>
                <ul>
                    <li>Your profile photos will be removed from the site, but we'll keep everything for you in case you return so that you can start right where you left off.</li>
                    <li>Your account will be totally hidden, friends will not be able to find and you people will not be able to send you friend requests or messages</li>
                    <li>Your subscription will continue. You can cancel the subscription in your payment settings.</li>
                    <li>To re-enable your account, simply log back in at anytime.</li>
                </ul>
                <b-button
                    variant="outline-primary"
                    title="Disable Account"
                    @click="showUserDisableModal">
                    Disable Account
                </b-button>
            </div>
            <hr class="mt-4 mb-3" /> -->
            <div>
                <h6 class="font-weight-bold">Delete your account</h6>
                <ul>
                    <li class="text-danger">THIS IS PERMANENT</li>
                    <li>Your subscription will be cancelled.</li>
                    <li>If you want to use again, you'll need to create a new account and fill out your profile again.</li>
                    <li>Note: Any messages you've sent will still be in the recipients inboxes, even if you delete your account.</li>
                    <li>Read more about how account deletion works in our FAQ.</li>
                </ul>
                <b-button
                    variant="danger"
                    title="Disable Account"
                    @click="showConfirmationModal"
                    >
                    Delete Account
                </b-button>
            </div>
        </b-col>
        <user-disable-modal
            :modal-open.sync="showUserDisableForm"
            :options="options"
        ></user-disable-modal>
        <confirmation-modal
            title="Are you sure?"
            :modal-open.sync="showConfirmationForm"
            :on-confirm="handleConfirm"
            title-class="text-white"
            header-bg-variant="danger"
        >
            <p>
                <span class="font-weight-bold font-italic">Note: We do not refund Premium memberships.</span><br/>
                By permanently deleting your account, you will lose any unused time left on your Premium account.
            </p>
        </confirmation-modal>
    </b-row>
</template>
<script>
import UserDisableModal from '../../Modals/UserDisableModal';
import ConfirmationModal from '../../Modals/ConfirmationModal';

export default {
    name: "UserCancelAccountForm",
    components: {
        UserDisableModal,
        ConfirmationModal,
    },
    mounted(){
        this.getAccountDisableReasons();
    },
    data() {
        return {
            showUserDisableForm: false,
            showConfirmationForm: false,
            options: [],
        }
    },
    methods: {
        showUserDisableModal() {
            this.showUserDisableForm = !this.showUserDisableForm;
        },
        showConfirmationModal() {
            this.showConfirmationForm = !this.showConfirmationForm;
        },
        async handleConfirm() {
            const response = await axios.post("/account/delete");
            await this.$swal({
                    title: "Your account has been successfully deleted!",
                    icon: "success",
                    type: "success",
                    position: "center",
                }).then(() => {
                    window.location = "/logout";
                });
        },
        async getAccountDisableReasons() {
            try {
                const response = await axios.get("/account/disable/reasons");
                const {data: {result}} = response
                this.options = result;
            } catch (error) {
                console.error(error);
                await this.$swal(error.response?.data.message);
            }
        }
    },
}
</script>