<template>
    <b-modal
        id="add-members-modal"
        size="lg"
        v-model="open"
        centered
        hide-header
        hide-footer
    >
        <league-members-form
            :league-id="leagueId"
            in-settings
            @members-added="closeModal"
            class="p-1"
        ></league-members-form>
    </b-modal>
</template>

<script>
import LeagueMembersForm from "../Forms/LeagueForms/LeagueMembersForm";
export default {
    name: "AddMembersModal",
    components: { LeagueMembersForm },
    props: {
        modalOpen: Boolean,
        leagueId: {
            type: Number,
            required: true,
        },
    },
    computed: {
        open: {
            get() {
                return this.modalOpen;
            },
            set(value) {
                this.$emit("update:modalOpen", value);
            },
        },
    },
    methods: {
        closeModal(response) {
            const message = !response.data.message ? 'Invites successfully sent.' : JSON.parse(JSON.stringify(response?.data?.message));
            this.$swal({
                title: message,
                width: '800px'
            });
            this.open = false;
        },
    },
};
</script>

<style scoped></style>
