<template>
    <div>
        <b-card>
            <user-privacy-form
                :permissions="privacySettings"
            ></user-privacy-form>
        </b-card>
    </div>
</template>

<script>
import UserPrivacyForm from "../../Forms/UserForms/UserPrivacyForm";
export default {
    name: "UserSettingsPrivacy",
    components: { UserPrivacyForm },
    props: {
        user: { type: Object, required: true },
        privacySettings: { type: Object, required: true },
    },
};
</script>
