<template>
    <div>
        <b-row class="align-items-center">
            <b-col>
                <h1 class="user-name">{{ user.name }}</h1>
            </b-col>
            <b-col cols="auto">
                <div v-if="authUser.id !== user.id">
                    <b-btn
                        variant="purple"
                        class="btn-padded mr-4 mb-2"
                        pill
                        @click="sendMessage"
                        >Send Message</b-btn
                    >
                    <b-dropdown
                        v-if="canInvite && leagueOptions.length > 0"
                        id="invite-league-dropdown"
                        text="Invite to League"
                        variant="purple"
                        class="mr-4 mb-2"
                        toggle-class="btn-padded rounded-pill"
                    >
                        <template v-for="league in leagueOptions">
                            <b-dropdown-item
                                :key="league.id"
                                v-if="league.isUserInvited"
                                disabled
                                >{{ league.name }} pending invitation sent {{ league.inviteDate }}</b-dropdown-item
                            >
                            <b-dropdown-item
                                :key="league.id"
                                v-else-if="league.isUserSubscribed"
                                disabled
                                >{{ league.name }} member since {{ league.subscribedDate }}</b-dropdown-item
                            >
                            <b-dropdown-item
                                :key="league.id"
                                v-else
                                @click="inviteToLeague(league.id)"
                                >{{ league.name }}</b-dropdown-item
                            >
                        </template>
                    </b-dropdown>
                    <b-btn
                        v-if="canFriend && localFriendStatus !== 2"
                        variant="purple"
                        class="btn-padded mb-2"
                        pill
                        :disabled="localFriendStatus === 1"
                        @click="requestFriendship"
                        >{{ friendButtonText }}</b-btn
                    >
                    <b-btn
                        v-if="localFriendStatus === 2"
                        variant="purple"
                        class="btn-padded mb-2"
                        pill
                        @click="openRemoveModal"
                    >
                        <i v-show="loading" class="fas fa-spinner fa-pulse"></i>
                        <span v-show="!loading">Remove Friend</span>
                    </b-btn>
                </div>
            </b-col>
        </b-row>
        <hr class="mt-4 mb-5" />
        <b-row>
            <b-col cols="12">
                <about-card :content="user.about" class="mb-4"></about-card>
            </b-col>
            <!--<b-col cols="12" lg="8">
                <b-card title="Media" class="drop-shadow mb-4">
                    <hr />
                    <b-row no-gutters>
                        <b-col
                            v-for="media in user.media"
                            :key="media.id"
                            cols="12"
                            md="6"
                        >
                            <media-card :media="media" class="m-2"></media-card>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>-->
            <b-col cols="12">
                <b-card title="Leagues" class="drop-shadow mb-4">
                    <hr />
                    <b-row no-gutters>
                        <b-col>
                            <leagues-list
                                :user-id="user.id"
                                :auth-user="authUser"
                                :users-leagues="true"
                            ></leagues-list>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <create-chat-modal
            :modal-open.sync="messageModalOpen"
            :auth-user-id="authUser.id"
            :user-id="user.id"
        ></create-chat-modal>
        <remove-user-modal
            :modal-open.sync="removeModalOpen"
            :user-name="user.name"
            @remove-user="removeFriend"
        >
        </remove-user-modal>
    </div>
</template>

<script>
import AboutCard from "../../Cards/AboutCard";
import MediaCard from "../../Cards/MediaCard";
import LeagueCard from "../../Cards/LeagueCard";
import CreateChatModal from "../../Modals/CreateChatModal";
import RemoveUserModal from "../../Modals/removeUserModal";
export default {
    name: "UserProfileAbout",
    components: {
        RemoveUserModal,
        CreateChatModal,
        LeagueCard,
        AboutCard,
        MediaCard,
    },
    props: {
        authUser: { type: Object, required: true },
        user: { type: Object, required: true },
        friendStatus: { type: Number, required: true },
        friendToDelete: { type: Number, required: true },
        leagueOptions: { type: Array, required: true },
        canInvite: { type: Boolean, required: true },
        canFriend: { type: Boolean, required: true },
    },
    data() {
        return {
            loading: false,
            removeModalOpen: false,
            messageModalOpen: false,
            localFriendStatus: this.friendStatus,
        };
    },
    computed: {
        friendButtonText() {
            if (this.localFriendStatus === 1) {
                return "Request Pending...";
            }
            return "Add Friend";
        },
    },
    methods: {
        routeToLeague(league) {
            window.location.href = `/${league.slug}`;
        },
        async requestFriendship() {
            try {
                const response = await axios.post(`/users/friends/add`, {
                    userId: this.user.id,
                });
                window.location.reload();
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
            }
        },
        openRemoveModal() {
            this.removeModalOpen = true;
        },
        async removeFriend() {
            const requestData = {
                friendId: this.friendToDelete,
            };
            this.loading = true;
            try {
                const response = await axios.post(
                    "/users/friends/remove",
                    requestData,
                );
                this.loading = false;
                this.removeModalOpen = false;
                this.localFriendStatus = 0;
                this.canFriend = true;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        },
        sendMessage() {
            this.messageModalOpen = true;
        },
        async inviteToLeague(leagueId) {
            try {
                this.loading = true;
                const response = await axios.post("/leagues/invite", {
                    leagueId: leagueId,
                    members: [{ user: this.user }],
                });

                await this.$swal({
                    title: "Invite Sent!",
                    icon: "success",
                    toast: false,
                    position: "center",
                });
                this.loading = false;
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped>
.user-name {
    font-size: 48px;
    font-weight: bold;
}
</style>
