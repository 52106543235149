<template>
    <div>
        <div class="d-flex justify-content-between mb-5">
            <h1 class="h2 font-weight-bold">
                Permissions
            </h1>
            <b-button variant="ghost-purple" v-if="!isCloseDisabled" @click="closeLeague"
                >Close League</b-button
            >
            <b-button 
                variant="ghost-purple" 
                disabled 
                v-else
            >
                A request to close {{league.name}} was scheduled on {{deletedDate}} <br>
                it will remain open until {{closingDate}}
            </b-button
            >
        </div>
        <league-permissions-form
            :league-id="league.id"
            :permissions="permissions"
            :is-subleague="league.isSubLeague"
            editing
        ></league-permissions-form>
    </div>
</template>

<script>
import LeaguePermissionsForm from "../../Forms/LeagueForms/LeaguePermissionsForm";
export default {
    name: "LeagueSettingsPermissions",
    components: { LeaguePermissionsForm },
    props: {
        league: {
            type: Object,
            required: true,
        },
        permissions: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isCloseDisabled: false,
            closingDate: '',
            deletedDate: ''
        }
    },
    mounted() {
        this.checkCloseButton();
    },
    methods: {
        async closeLeague() {
            const swal = await this.$swal({
                timer: undefined,
                title: "Are you sure?",
                text:
                    "All of your subscribers will be unsubscribed and the league will be closed. " +
                    "You won't be able to revert this!",
                icon: "error",
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonColor: "#d33",
                confirmButtonText: "Yes, close it!",
                reverseButtons: true,
            });
            if (swal.isConfirmed) {
                try {
                    const response = await axios.post("/leagues/delete", {
                        leagueId: this.league.id,
                    });

                    if (response.data.success) {
                        await this.$swal({
                            icon: "success",
                            title: "League Closed!",
                            text: response.data.closeDate
                                ? `This league will remain open until ${moment(
                                      response.data.closeDate
                                  ).format("LL")}`
                                : "This league has been close.",
                        });
                        this.isCloseDisabled = true;
                        this.closingDate = moment(response.data.closeDate).format("LL");
                        this.deletedDate = moment(response.data.closeDate).subtract(30, 'days').format("LL");
                    }
                } catch (error) {
                    await this.$swal(error.response.data.message);
                }
            }
        },
        async checkCloseButton() {
            if(this.league.scheduled_deletion != null) {
                this.closingDate = moment(this.league.scheduled_deletion).format("LL");
                this.deletedDate = moment(this.league.scheduled_deletion).subtract(30, 'days').format("LL");
                this.isCloseDisabled = true;
            }
        }
    },
};
</script>

<style scoped></style>
