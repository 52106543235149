<template>
    <b-modal
        id="create-burst-modal"
        v-model="open"
        size="lg"
        centered
        hide-footer
        modal-class="create-burst-modal"
    >
        <template v-slot:modal-header="{ close }">
            <!-- Emulate built in modal header close button action -->
            <b-button
                size="sm"
                variant="black"
                @click="close()"
                class="float-right"
            >
                <svg
                    width="42"
                    height="42"
                    viewBox="0 0 42 42"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M27.6358 12.3066L20.9994 18.9838L14.363 12.3066L12.3057 14.364L18.9828 21.0004L12.3057 27.6368L14.363 29.6941L20.9994 23.017L27.6358 29.6941L29.6932 27.6368L23.016 21.0004L29.6932 14.364L27.6358 12.3066Z"
                        fill="white"
                    />
                    <path
                        d="M20.9998 1.0498C9.99623 1.0498 1.0498 9.99623 1.0498 20.9998C1.0498 32.0034 9.99623 40.9498 20.9998 40.9498C32.0034 40.9498 40.9498 32.0034 40.9498 20.9998C40.9498 9.99623 32.0034 1.0498 20.9998 1.0498ZM20.9998 38.0526C11.5926 38.0526 3.94696 30.4074 3.94696 20.9998C3.94696 11.5922 11.5922 3.94696 20.9998 3.94696C30.4074 3.94696 38.0526 11.5922 38.0526 20.9998C38.0526 30.4074 30.4074 38.0526 20.9998 38.0526Z"
                        fill="white"
                    />
                </svg>
            </b-button>
        </template>
        <b-container class="p-sm-4">
            <b-row>
                <b-col class="text-center">
                    <div v-if="isReply">
                        <div class="text-white">Tag users in your Burst</div>
                        <v-select
                            @search="searchUsers"
                            :options="tagPeopleUsersList"
                            label="name"
                            :filterable="false"
                            v-model="taggedUsers"
                            multiple
                        >
                        </v-select>
                    </div>

                    <p v-if="isReply" class="text-white mt-4">
                        To create a burst message, create a 20 second video on
                        your phone and upload here.
                    </p>
                    <p v-if="!isReply"  class="text-white mt-4">
                        To create a burst message, create a video on
                        your phone and upload here.
                    </p>
                    <div>
                        <b-btn
                            variant="outline-white"
                            pill
                            @click="uploadBurstVideo"
                        >
                            Please select a video
                        </b-btn>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import SubmitButton from "../Buttons/SubmitButton";

export default {
    name: "CreateBurstModal",
    components: { SubmitButton },
    props: {
        modalOpen: Boolean,
        leagueId: {
            type: Number,
            required: true,
        },
        isReply: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            taggedUserId: null,
            tagPeopleUsersList: [],
            taggedUsers: [],
        };
    },
    computed: {
        open: {
            get() {
                return this.modalOpen;
            },
            set(value) {
                this.$emit("update:modalOpen", value);
            },
        },
    },
    methods: {
        uploadBurstVideo(e) {
            this.$emit("upload-burst-video", this.taggedUsers.map(item => item.id));
        },
        async searchUsers(searchQuery, loading) {
            if (!searchQuery || searchQuery.length === 0) {
                return;
            }

            const { status, data } = await axios.get("/tags/users", {
                params: {
                    leagueId: this.leagueId,
                    searchQuery: searchQuery,
                },
            });

            if (status == 200) {
                this.tagPeopleUsersList = data.users.map((item) => ({
                    id: item.id,
                    name: item.name,
                }));
            }
        },
    },
};
</script>
<style scoped lang="scss"></style>
