<template>
    <div>
        <div class="d-flex align-items-center justify-content-between">
            <h1 class="h2 font-weight-bold mb-4">Manage Members</h1>
            <b-button variant="purple" pill @click="modalOpen = true"
                >Invite</b-button
            >
        </div>
        <members-list
            :league-id="league.id"
            :auth-id="authUser.id"
            editable
        ></members-list>
        <add-members-modal
            :modal-open.sync="modalOpen"
            :league-id="league.id"
        ></add-members-modal>
    </div>
</template>

<script>
import LeagueMembersForm from "../../Forms/LeagueForms/LeagueMembersForm";
import MembersList from "../../Lists/MembersList";
import AddMembersModal from "../../Modals/AddMembersModal";
export default {
    name: "LeagueSettingsMembers",
    components: { AddMembersModal, MembersList, LeagueMembersForm },
    props: {
        league: {
            type: Object,
            required: true,
        },
        authUser: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            modalOpen: false,
        };
    },
};
</script>

<style scoped></style>
