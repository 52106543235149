<template>
    <div>
        <b-btn
            v-show="state === 'button'"
            variant="outline-purple-purple"
            pill
            :block="block"
            @click="checkPaymentMethod"
            :size="formSize"
            >{{ formTitle }}</b-btn
        >
        <b-form v-show="state === 'form'">
            <b-form-row>
                <b-col
                    cols="auto"
                    class="mb-2"
                    :class="!postId ? 'mx-auto' : null"
                >
                    <b-form-select
                        v-if="selected !== 'other'"
                        :id="`donation-options-${leagueId}-${postId}`"
                        v-model="selected"
                        :options="options"
                        :size="formSize"
                        :disabled="loading"
                        placeholder="Select Amount"
                        style="max-width: 150px; min-width: 80px"
                        class="form-control"
                    ></b-form-select>
                    <b-form-input
                        v-if="selected === 'other'"
                        v-model.number="otherAmount"
                        type="number"
                        :size="formSize"
                        placeholder="Enter Amount"
                        step=".01"
                        :disabled="loading"
                        style="max-width: 150px;"
                    ></b-form-input>
                </b-col>
                <b-col
                    cols="auto"
                    class="mb-2"
                    :class="!postId ? 'mx-auto' : null"
                >
                    <b-btn
                        type="submit"
                        variant="purple"
                        pill
                        :size="formSize"
                        :disabled="buttonDisabled || loading"
                        class="px-3"
                        @click.prevent="submitDonation()"
                        >{{ buttonText }}</b-btn
                    >
                    <b-btn
                        variant="ghost-purple"
                        pill
                        :size="formSize"
                        @click="cancelDonation"
                        :disabled="loading"
                        >Cancel</b-btn
                    >
                </b-col>
            </b-form-row>
        </b-form>
        <b-btn
            v-show="state === 'complete'"
            variant="outline-purple-purple"
            :size="formSize"
            pill
            @click="state = 'form'"
            >Donated ${{ existingTotal }}</b-btn
        >
        <add-payment-method-modal
            v-if="!hasPaymentMethod"
            :modal-open="modalOpen"
            @payment-method-added="paymentMethodAdded"
        ></add-payment-method-modal>
    </div>
</template>

<script>
import AddPaymentMethodModal from "../Modals/AddPaymentMethodModal";
export default {
    name: "DonationForm",
    components: { AddPaymentMethodModal },
    props: {
        leagueId: {
            type: Number,
            required: true,
        },
        postId: {
            type: Number,
            required: false,
        },
        donated: [String, Number],
        block: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            state: "button",
            loading: false,
            selected: null,
            otherAmount: null,
            hasPaymentMethod: false,
            modalOpen: false,
            options: [
                // { text: "Select Amount", value: null },
                { text: "$10", value: 10 },
                { text: "$25", value: 25 },
                { text: "$50", value: 50 },
                { text: "$100", value: 100 },
                { text: "Other", value: "other" },
            ],
            existingTotal: 0,
        };
    },
    mounted() {
        if (this.donated > 0) {
            this.existingTotal = parseFloat(this.donated);
            this.state = "complete";
        }
    },
    computed: {
        formSize() {
            return this.postId ? "sm" : "md";
        },
        formTitle() {
            return this.postId || this.$mq == "md"
                ? "Donate"
                : "Donate";
        },
        buttonText() {
            return this.loading ? "Sending..." : "Send";
        },
        buttonDisabled() {
            return this.selected !== "other"
                ? this.selected === null
                : this.otherAmount === null;
        },
    },
    methods: {
        async checkPaymentMethod() {
            try {
                const response = await axios.get(`/settings/hasCard`);

                this.hasPaymentMethod = response.data.hasCard;

                if (this.hasPaymentMethod) {
                    this.state = "form";
                } else {
                    // Trigger Payment Modal
                    this.modalOpen = true;
                }
            } catch (error) {
                this.state = "error";
                this.errorMessage = error.response.data.message;
            }
        },
        paymentMethodAdded() {
            this.hasPaymentMethod = true;
            this.state = "form";
        },
        cancelDonation() {
            this.state = "button";
            this.selected = null;
        },
        async submitDonation() {
            this.loading = true;
            try {
                const data = {
                    leaguePostId: this.postId,
                    leagueId: this.leagueId,
                    amount:
                        this.selected !== "other"
                            ? this.selected
                            : this.otherAmount,
                };
                await axios.post("/leagues/donate", data);

                this.existingTotal += data.amount;
                this.state = "complete";
                this.selected = null;
                this.otherAmount = null;
                this.loading = false;
                this.$emit('success')
            } catch (error) {
                // If there is an error for some reason, show alert
                await this.$swal(error.response.data.message);
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.form-control {
    background-color: white;
    border: 2px solid #5451de;
    &:disabled {
        background-color: #6e6bf826 !important;
    }
}
</style>
