<template>
    <b-overlay :show="loading" rounded="sm">
        <b-card
            no-body
            class="media-card overflow-hidden"
            :class="selected ? 'active-border' : null"
            @click="$emit('click', media)"
        >
            <b-btn
                    v-show="!loading"
                    v-if="mediaType === 'image'"
                    variant="purple"
                    pill
                    size="sm"
                    class="edit-button"
                    v-b-tooltip.hover.left
                    title="Edit"
                    @click.stop="$emit('edit-media', media)"
            >
                <i class="fas fa-edit"></i>
            </b-btn>
            <b-btn
                v-show="deletable && !loading"
                variant="purple"
                pill
                size="sm"
                class="delete-button"
                v-b-tooltip.hover.left
                title="Delete"
                @click.stop="$emit('delete-media', media)"
            >
                <i class="fas fa-trash"></i>
            </b-btn>
            <div
                class="embed-responsive embed-responsive-16by9"
            >
                <video width="400" class="video-controls" preload="metadata" v-if="mediaType === 'video'">
                    <source :src="media.file" type="video/mp4">
                </video>
                <b-img-lazy
                    v-if="media.thumbnail !== undefined && media.thumbnail === null && mediaType === 'image'"
                    :src="media.file"
                    class="embed-responsive-item img-fit"
                ></b-img-lazy>
                <b-img-lazy
                    v-if="!!media.thumbnail && mediaType === 'image'"
                    :src=" media.file"
                    class="embed-responsive-item img-fit"
                ></b-img-lazy>
            </div>
        </b-card>
    </b-overlay>
</template>

<script>
export default {
    name: "MediaCard",
    props: {
        media: {
            type: Object,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        deletable: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        mediaType() {
            return this.media.type ? "video" : "image";
        },
    },
};
</script>

<style scoped>
.img-fit {
    object-fit: cover;
    object-position: center;
}
.video-controls{
    background: #999;
}
</style>
