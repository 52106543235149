<template>
    <div>
        <b-form @submit.prevent="submitForm">
            <b-form-group label="Old Password" label-for="old-password-input">
                <b-form-input
                    id="old-password-input"
                    type="password"
                    v-model.trim="form.current"
                    :state="validateInput('current')"
                    @change="$v.form.current.$touch()"
                ></b-form-input>
                <invalid-feedback :errors="currentErrors"></invalid-feedback>
            </b-form-group>
            <b-form-group label="New Password" label-for="new-password-input">
                <b-form-input
                    id="new-password-input"
                    type="password"
                    v-model.trim="form.password"
                    :state="validateInput('password')"
                    @change="$v.form.password.$touch()"
                ></b-form-input>
                <invalid-feedback :errors="passwordErrors"></invalid-feedback>
            </b-form-group>
            <b-form-group
                label="Confirm Password"
                label-for="confirm-password-input"
            >
                <b-form-input
                    id="confirm-password-input"
                    type="password"
                    v-model.trim="form.password_confirmation"
                    :state="validateInput('password_confirmation')"
                    @change="$v.form.password_confirmation.$touch()"
                ></b-form-input>
                <invalid-feedback :errors="passwordConfirmErrors"></invalid-feedback>
            </b-form-group>
            <submit-button :loading="loading" class="mt-5"></submit-button>
            <b-form-text
                v-show="saved"
                text-variant="success"
                class="h6 text-center mt-3"
                >Saved!</b-form-text
            >
        </b-form>
    </div>
</template>

<script>
import { required, sameAs, minLength } from "vuelidate/lib/validators";

import MediaModal from "../../Modals/MediaModal";
import SubmitButton from "../../Buttons/SubmitButton";
import UserProfileHeader from "../../Headers/UserProfileHeader";

export default {
    name: "UserPasswordForm",
    components: {
        UserProfileHeader,
        SubmitButton,
        MediaModal,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            saved: false,
            form: {
                current: null,
                password: null,
                password_confirmation: null,
            },
        };
    },
    validations: {
        form: {
            current: { required },
            password: { 
                required, 
                minLength: 
                minLength(6),
                digitrequired(password) {
                    return (
                        /[0-9]/.test(password)
                    );
                },
                uppercase(password) {
                    return (
                        /[A-Z]/.test(password)
                    );
                },
                lowercase(password) {
                    return (
                        /[a-z]/.test(password)
                    );
                },
                specialChar(password) {
                    return (
                        /[!@#\$%\^\&*\)\(+=._-]/.test(password)
                    );
                } 
            },
            password_confirmation: {
                required,
                sameAsPassword: sameAs("password"),
            },
        },
    },
    computed: {
        currentErrors() {
            const errors = [];
            if (!this.$v.form.current.$dirty) return errors;
            !this.$v.form.current.required &&
                errors.push("Current password is required");
            return errors;
        },
        passwordErrors() {
            const errors = [];
            if (!this.$v.form.password.$dirty) return errors;
            !this.$v.form.password.required &&
                errors.push("New password is required");
            !this.$v.form.password.minLength &&
                errors.push("New password must be at least 6 characters long");
            !this.$v.form.password.digitrequired &&
                errors.push("Must contain at least 1 digit");
            !this.$v.form.password.uppercase &&
                errors.push("Must contain at least 1 uppercase letter");
            !this.$v.form.password.lowercase &&
                errors.push("Must contain at least 1 lowercase letter");
            !this.$v.form.password.specialChar &&
                errors.push("Must contain at least 1 special character");
            return errors;
        },
        passwordConfirmErrors() {
            const errors = [];
            if (!this.$v.form.password_confirmation.$dirty) return errors;
            !this.$v.form.password_confirmation.required &&
                errors.push("Password confirmation is required");
            !this.$v.form.password_confirmation.sameAsPassword &&
                errors.push("Passwords do not match");
            return errors;
        },
    },
    methods: {
        validateInput(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        async submitForm() {
            this.$v.form.$touch();

            if (!this.$v.form.$anyError) {
                this.loading = true;

                try {
                    // Attempt to update user info
                    const response = await axios.post(
                        `/settings/updatePassword`,
                        this.form
                    );
                    this.loading = false;
                    this.saved = true;
                } catch (error) {
                    // If there is an error for some reason, show alert
                    await this.$swal(error.response.data.message);
                    this.loading = false;
                }
            }
        },
    },
};
</script>
